import React, { createContext, useContext, useState } from "react";
import { getApiCallLocalPath, toastErrorMessageStyle, toastSuccessMessageStyle } from "../../utils/apiCallFunction";
import axios from "axios";
import { getApiCallHeadersData, removeStorageItem } from "../../utils/storageFunction";
import { createFormConfigandAddGridKeyInForm } from "./utils/formUtilsFunction";
import toast from "react-hot-toast";
import PopupBoxSection from "../viewlib/PopupBoxSection/PopupBoxSection";
import FormRenderer from ".";
import ProcessBarSection from "../viewlib/ProcessBarSection/PopupBoxSection";
import { useDispatch } from "react-redux";
import { deleteFormDataFunction } from "../../redux/formview/formviewaction";

const FormHandlerContext = createContext();

export const FormHandlerContextProvider = ({ children }) => {
  const [formExData, setFormExData] = useState();
  const [isOpenExForm, setIsOpenExForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [processLookupTask, setProcessLookupTask] = useState();
  const dispatch = useDispatch();

  const openFillNewLookupForm = async (data) => {
    setFormExData(data);
    setIsOpenExForm(true);
    setIsLoading(true);
    let responseData = await getNewFormInsideForm(
      {
        entity_id: data.table_id,
        front_end: { action_config: {}, params_config: {} }
      },
      data.function_name,
      data?.lookup || {}
    );

    if (responseData) {
      setProcessLookupTask(responseData);
    } else {
      setFormExData();
      setIsOpenExForm(false);
    }

    setIsLoading(false);
  };

  const closeFillNewLookupForm = async () => {
    setProcessLookupTask();
    setFormExData();
    setIsOpenExForm(false);
    dispatch(deleteFormDataFunction(processLookupTask?.form_config?.id || "get_new_form_inside_form", {}));
  };

  const submitFillNewLookupForm = async (respons, form_local_key) => {
    setIsLoading(true);
    let responseData = await getNewFormInsideForm(
      {
        entity_id: formExData.table_id,
        front_end: {
          action_config: {},
          params_config: {}
        }
      },
      "register_entity_bg",
      respons.data
    );
    if (responseData) {
      let newFormExData = { ...formExData };
      newFormExData["form_response"] = responseData.form_response;
      setFormExData(newFormExData);
      dispatch(deleteFormDataFunction(processLookupTask?.form_config?.id || "get_new_form_inside_form", {}));
      if (form_local_key) {
        removeStorageItem(form_local_key.local_storage_key);
      }
    }
    setIsLoading(false);
    setIsOpenExForm(false);
  };

  return (
    <FormHandlerContext.Provider
      value={{ openFillNewLookupForm, closeFillNewLookupForm, formExData, setFormExData, isOpenExForm }}
    >
      {children}
      <PopupBoxSection showPopup={isOpenExForm} className="slide-left-to-right">
        {isLoading ? (
          <ProcessBarSection />
        ) : (
          <div>
            <div className="popupSection_navbar">
              <span className="popupSection_navbar_display">{processLookupTask?.form_config?.name || ""}</span>
              <div onClick={closeFillNewLookupForm} className="popupSection_navbar_close_container">
                <span className="material-symbols-outlined popupSection_navbar_closeBTN">close</span>
              </div>
            </div>
            <div style={{ padding: "0 5px" }}>
              {processLookupTask?.type === "form" ? (
                <FormRenderer
                  apidata={{
                    apicalldata: processLookupTask.formconfig
                  }}
                  callbackfunction={{
                    formsubmitgetresponscbfun: submitFillNewLookupForm
                  }}
                  namespace={processLookupTask?.form_config?.id || "get_new_form_inside_form"}
                />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        )}
      </PopupBoxSection>
    </FormHandlerContext.Provider>
  );
};

// Custom hook to access the FormHandler context
export const useFormHandlerContext = () => {
  return useContext(FormHandlerContext);
};

const getNewFormInsideForm = async (params, actionName, dataRes = {}) => {
  try {
    let { data } = await axios.post(
      `${getApiCallLocalPath()}api/v1/dynamic`,
      {
        data: dataRes,
        // function_name: ,
        function_name: actionName,
        params: params
      },
      {
        headers: getApiCallHeadersData()
      }
    );

    data = data.data;

    if (data.type === "form") {
      data.formconfig = await createFormConfigandAddGridKeyInForm(data);
    }
    if (data?.notification) {
      for (let index = 0; index < data.notification.length; index++) {
        const element = data.notification[index];
        if (element.error) {
          toast.error(element.message, toastErrorMessageStyle());
        } else {
          toast.success(element.message, toastSuccessMessageStyle());
        }
      }
    }

    return data;
  } catch (error) {
    toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    return null;
  }
};
