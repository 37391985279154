import React from "react";
import DetailsViewItem from "./DetailsViewItem";

const ActivityCardRender = ({
  itemConfig,
  activityCardsConfig,
  detailsViewDataActivityCard,
  detailsViewDataTimeline,
  detailsViewDataActivitiesHistory,
  jobParamsConfig,
  detailsViewDataOrg,
  functionObject
}) => {
  if (!itemConfig) return <></>;

  let detailsViewDataTimelineObject = {};
  for (let index = 0; index < detailsViewDataTimeline.length; index++) {
    const element = detailsViewDataTimeline[index];
    detailsViewDataTimelineObject[element.activity_id] = element;
  }

  let detailsViewDataActivityCardObject = {};
  for (let index = 0; index < detailsViewDataActivityCard.length; index++) {
    const element = detailsViewDataActivityCard[index];
    detailsViewDataActivityCardObject[element.activity_id] = element;
  }

  let formdata = {};
  if (itemConfig.data?.activity_id) {
    if (detailsViewDataTimelineObject?.[itemConfig.data.activity_id]?.activity_id) {
      if (
        detailsViewDataActivityCardObject[detailsViewDataTimelineObject[itemConfig.data.activity_id].activity_id]
          ?.form_data
      ) {
        formdata =
          detailsViewDataActivityCardObject[detailsViewDataTimelineObject[itemConfig.data.activity_id].activity_id]
            .form_data;
      }
    }
  }

  let activitiesHistoryIs = detailsViewDataActivitiesHistory?.[itemConfig.data.activity_id] || [];
  return (
    <>
      <div className="detailsView_field">
        {itemConfig.data?.config?.allow_activity_history ? (
          activitiesHistoryIs?.length !== 0 ? (
            activitiesHistoryIs.map((formdataIs, index) => {
              return (
                <DetailsViewItem
                  key={index}
                  itemConfig={itemConfig.data.elements}
                  activityCardsConfig={activityCardsConfig}
                  detailsViewDataFormData={formdataIs}
                  detailsViewDataTimeline={detailsViewDataTimeline}
                  detailsViewDataActivityCard={detailsViewDataActivityCard}
                  detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
                  jobParamsConfig={jobParamsConfig}
                  detailsViewDataOrg={detailsViewDataOrg}
                  functionObject={functionObject}
                />
              );
            })
          ) : (
            <div className="noRecordsLabel">
              {itemConfig?.data?.config?.non_perform_activity_message || "No Records Found"}
            </div>
          )
        ) : Object?.keys(formdata).length !== 0 ? (
          <DetailsViewItem
            itemConfig={itemConfig.data.elements}
            activityCardsConfig={activityCardsConfig}
            detailsViewDataFormData={formdata}
            detailsViewDataTimeline={detailsViewDataTimeline}
            detailsViewDataActivityCard={detailsViewDataActivityCard}
            detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
            jobParamsConfig={jobParamsConfig}
            detailsViewDataOrg={detailsViewDataOrg}
            functionObject={functionObject}
          />
        ) : (
          <div className="noRecordsLabel">
            {itemConfig?.data?.config?.non_perform_activity_message || "No Records Found"}
          </div>
        )}
      </div>
    </>
  );
};

export default ActivityCardRender;
