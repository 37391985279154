import React, { useEffect, useState } from "react";

import "./Components.css";

import { commonFunction } from "../utils/commonFunction";
import MapGoogle from "./MapGoogle";
import MapFilter from "./MapFilter";
// import FilterSearchFieldLV from "./filter/FilterSearchFieldLV";

const MapComponent = ({ namespace, mapviewTableData, mapviewDataConfig }) => {
  // eslint-disable-next-line no-unused-vars
  const [tableDataHeader, setTableDataHeader] = useState([]);

  useEffect(() => {
    let newData = commonFunction.changeDataHeaderArray(mapviewDataConfig.columns);
    setTableDataHeader(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapviewDataConfig.columns]);

  if (tableDataHeader) {
    let tableData = mapviewTableData.documents;
    let locationPoint = mapviewDataConfig.location_point;

    return (
      <div
        className="DashboardRF_dashboardBox"
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          overflow: "hidden",
          fontSize: "14px",
          color: "#10182b"
        }}
      >
        <div style={{ width: "100%" }}>
          {/* {mapviewDataConfig.search && (
            <div style={{display:"flex", gap:"25px"}}>
              <p style={{ padding: "10px 0", fontSize: "16px", fontWeight: "600", color: "gray", textWrap:"nowrap" }}>Search term</p>
              <FilterSearchFieldLV namespace={namespace} />
            </div>
          )} */}

          <MapFilter
            namespace={namespace}
            recordviewTableData={mapviewTableData}
            recordviewDataConfig={mapviewDataConfig}
            tableDataHeader={tableDataHeader}
          />
        </div>
        <MapGoogle
          locationArray={tableDataHeader}
          locationPoint={locationPoint}
          locationDataArray={tableData}
          namespace={namespace}
        />
      </div>
    );
  } else {
    return (
      <div className="zino_renderer_loader_box">
        <span className="zino_renderer_loader"></span>
      </div>
    );
  }
};

export default MapComponent;
