import React, { useEffect, useState } from "react";
import RecordDataViewerField from "./RecordDataViewerField";
import { useSelector } from "react-redux";
import RecordCatalogView from "./RecordCatalogView";
import { getDataFromDataObjectByKey } from "../../../../utils/updateConfigFunction";
import { usePageHandlerContext } from "../../../../pagehandler/PageHandlerContext";
const RecordField = ({ namespace, selectedRecords, setSelectedRecords }) => {
  const storeData = useSelector((state) => state.recordviewstore);
  const recordsviewLayoutConfig = storeData?.[`${namespace}_recordsviewLayoutConfig`];
  const recordsviewDataConfig = storeData?.[`${namespace}_recordsviewDataConfig`];
  const [classNameList, setClassNameList] = useState("");
  const { createNewTask } = usePageHandlerContext();

  const handleBarColor = (record) => {
    let barColor = "";
    if (recordsviewLayoutConfig?.layout?.style?.static_bar_color?.show_color === true) {
      barColor = `4px solid ${recordsviewLayoutConfig?.layout?.style?.static_bar_color.color}`;
    } else if (recordsviewLayoutConfig?.layout?.style?.dynamic_bar_color?.show_dynamic_color === true) {
      let data_is = [];
      getDataFromDataObjectByKey(
        record,
        data_is,
        recordsviewLayoutConfig?.layout?.style?.dynamic_bar_color?.selected_dynamic_field?.data_key
      );
      let value = data_is.join(" , ");
      recordsviewLayoutConfig?.layout?.style?.dynamic_bar_color?.color_config?.forEach((item) => {
        if (item?.value?.toLowerCase() === value?.toLowerCase()) {
          barColor = `4px solid ${item?.color}`;
        }
      });
    }
    return barColor;
  };

  let pressTimer;

  const handleMouseDown = (record) => {
    pressTimer = setTimeout(() => {
      // Long press detected
      setSelectedRecords([
        ...selectedRecords,
        record?.id || record?._id || record?.[recordsviewDataConfig?.primary_key]
      ]);
    }, 300); // Change the duration according to your preference
  };

  const handleMouseUp = () => {
    clearTimeout(pressTimer);
  };

  const handleSelectedRecords = (e, id) => {
    let selectedRecordsCopy = [...selectedRecords];
    const indexToRemove = selectedRecordsCopy.indexOf(id);
    if (indexToRemove !== -1) {
      selectedRecordsCopy.splice(indexToRemove, 1);
      setSelectedRecords([...selectedRecordsCopy]);
    }
  };

  useEffect(() => {
    const classNameObject = recordsviewLayoutConfig?.layout?.style?.className || {};
    const classNameArray = classNameObject ? Object.values(classNameObject) : [];
    setClassNameList(classNameArray.join(" ") || "");
  }, [recordsviewLayoutConfig]);

  const handleOnClickJob = (reqData) => {
    let itemConfig = recordsviewLayoutConfig?.layout?.on_row_action;
    if (!itemConfig?.job_template) {
      return;
    }
    let element_config = {
      element_id: recordsviewLayoutConfig?.layout?.id,
      action_start: {},
      action_end: {},
      callback_function: [],
      callback_function_after: []
    };

    let action_config = {
      action_in: itemConfig?.job_template,
      navigate_to: itemConfig?.params?.name || "",
      action_data: itemConfig?.action_data,
      action_name: itemConfig?.job_template,
      sm_id: itemConfig?.params?.sm_id || itemConfig?.params?.entity_id || recordsviewLayoutConfig?.layout?.table_id,
      activity_id: itemConfig?.params?.activity_id || itemConfig?.params?.activity_id_init,
      params: itemConfig?.params || {}
    };

    action_config["instance_id"] = reqData.instance_id || reqData.id || reqData._id || null;

    if (["send_email_bg", "send_sms_bg"].includes(action_config.action_name)) {
      reqData = {};
    } else if (["perform_activity_ai"].includes(action_config.action_name)) {
    } else if (["perform_record_ai"].includes(action_config.action_name)) {
    } else {
      reqData = { ...reqData, sm_id: recordsviewLayoutConfig?.layout?.table_id };
    }
    createNewTask(element_config, action_config, reqData);
  };

  const handleClick = (event, record) => {
    const target = event.target;
    const hasClickHandler = target.onclick;
    if (!hasClickHandler) {
      handleOnClickJob(record);
    }
  };

  return (
    <div className="recordListContainer">
      {recordsviewDataConfig && Object?.keys(recordsviewDataConfig).length === 0 ? (
        <div className="noRecordsContainer">
          <p style={{ fontSize: "16px", fontWeight: "600", color: "#F04438" }}>Unknown error!</p>
        </div>
      ) : recordsviewDataConfig && recordsviewDataConfig?.total_documents !== 0 ? (
        recordsviewDataConfig?.documents?.map((record, index) =>
          recordsviewLayoutConfig?.layout?.catalog &&
          recordsviewLayoutConfig?.layout?.catalog?.elements?.length !== 0 ? (
            <div
              className={`recordCatalogContainer ${classNameList}`}
              key={index}
              style={{
                borderLeft: `${handleBarColor(record, index)}`,
                ...recordsviewLayoutConfig?.layout?.style?.inline_style
              }}
              onTouchStart={
                recordsviewLayoutConfig?.layout?.multi_select_custom_actions?.length > 0
                  ? () => handleMouseDown(record)
                  : null
              }
              onTouchCancel={
                recordsviewLayoutConfig?.layout?.multi_select_custom_actions?.length > 0 ? handleMouseUp : null
              }
              onTouchEnd={
                recordsviewLayoutConfig?.layout?.multi_select_custom_actions?.length > 0 ? handleMouseUp : null
              }
              onClick={(event) => handleClick(event, record)}
            >
              <RecordCatalogView
                elements={recordsviewLayoutConfig?.layout?.catalog?.elements}
                record={record}
                namespace={namespace}
              />
              {selectedRecords.includes(record?.id || record?.[recordsviewDataConfig?.primary_key]) && (
                <div
                  className="recordSelectedContainer "
                  onClick={(e) => handleSelectedRecords(e, record?.id || record?.[recordsviewDataConfig?.primary_key])}
                ></div>
              )}
            </div>
          ) : (
            <div
              className="recordContainer theme_recordContainer"
              key={index}
              style={{ borderLeft: `${handleBarColor(record, index)}` }}
              onTouchStart={() => handleMouseDown(record)}
              onTouchCancel={handleMouseUp}
              onTouchEnd={handleMouseUp}
            >
              <div className="recordHeaderContainer">
                <div
                  style={{
                    width: `${recordsviewLayoutConfig?.layout?.header.column1.width}%`,
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px"
                  }}
                >
                  {recordsviewLayoutConfig?.layout?.header?.column1?.elements?.map((headerdata) => {
                    return (
                      <RecordDataViewerField
                        itemIs={headerdata}
                        index={headerdata?.id}
                        namespace={namespace}
                        record={record}
                        table_id={recordsviewLayoutConfig?.layout?.table_id}
                        key={`${headerdata?.id}.${record?.id}`}
                      />
                    );
                  })}
                </div>
                <div
                  style={{
                    width: `${recordsviewLayoutConfig?.layout?.header.column2.width}%`,
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px"
                  }}
                >
                  {recordsviewLayoutConfig?.layout?.header?.column2?.elements?.map((headerdata) => {
                    return (
                      <RecordDataViewerField
                        itemIs={headerdata}
                        index={headerdata?.id}
                        namespace={namespace}
                        record={record}
                        table_id={recordsviewLayoutConfig?.layout?.table_id}
                        key={`${headerdata?.id}.${record?.id}`}
                      />
                    );
                  })}
                </div>
              </div>
              {(recordsviewLayoutConfig?.layout?.header?.column1?.elements.length !== 0 ||
                recordsviewLayoutConfig?.layout?.header?.column2?.elements.length !== 0) &&
              (recordsviewLayoutConfig?.layout?.footer?.column1?.elements.length !== 0 ||
                recordsviewLayoutConfig?.layout?.footer?.column2?.elements.length !== 0) ? (
                <div className="recordMargin theme_recordMargin"></div>
              ) : null}
              <div className="recordFooterContainer">
                <div
                  style={{
                    width: `${recordsviewLayoutConfig?.layout?.footer.column1.width}%`,
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px"
                  }}
                >
                  {recordsviewLayoutConfig?.layout?.footer?.column1.elements?.map((footerdata) => {
                    return (
                      <RecordDataViewerField
                        itemIs={footerdata}
                        index={footerdata?.id}
                        namespace={namespace}
                        record={record}
                        table_id={recordsviewLayoutConfig?.layout?.table_id}
                        key={`${footerdata?.id}.${record?.id}`}
                      />
                    );
                  })}
                </div>
                <div
                  style={{
                    width: `${recordsviewLayoutConfig?.layout?.footer.column2.width}%`,
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px"
                  }}
                >
                  {recordsviewLayoutConfig?.layout?.footer?.column2.elements?.map((footerdata) => {
                    return (
                      <RecordDataViewerField
                        itemIs={footerdata}
                        index={footerdata?.id}
                        namespace={namespace}
                        record={record}
                        table_id={recordsviewLayoutConfig?.layout?.table_id}
                        key={`${footerdata?.id}.${record?.id}`}
                      />
                    );
                  })}
                </div>
              </div>
              {selectedRecords.includes(record?.id || record?.[recordsviewDataConfig?.primary_key]) && (
                <div
                  className="recordSelectedContainer "
                  onClick={(e) => handleSelectedRecords(e, record?.id || record?.[recordsviewDataConfig?.primary_key])}
                ></div>
              )}
            </div>
          )
        )
      ) : recordsviewDataConfig && recordsviewDataConfig?.total_documents === 0 ? (
        <div className="noRecordsContainer">
          <p style={{ fontSize: "16px", fontWeight: "600" }}>Records Not Found</p>
        </div>
      ) : (
        <div className="recordsViewShimmerLoaderContainer">
          <div className="recordsViewLoaderRecord">
            <div className="recordsViewLoaderImageContainer">
              <div className="shimmerBG image-line"></div>
              <div className="recordsViewLoaderImageField">
                <div className="shimmerBG title-line"></div>
                <div className="shimmerBG title-line"></div>
              </div>
            </div>
            <div className="shimmerBG title-line"></div>
            <div className="shimmerBG title-line"></div>
          </div>
          <div className="recordsViewLoaderRecord">
            <div className="recordsViewLoaderImageContainer">
              <div className="shimmerBG image-line"></div>
              <div className="recordsViewLoaderImageField">
                <div className="shimmerBG title-line"></div>
                <div className="shimmerBG title-line"></div>
              </div>
            </div>
            <div className="shimmerBG title-line"></div>
            <div className="shimmerBG title-line"></div>
          </div>
          <div className="recordsViewLoaderRecord">
            <div className="recordsViewLoaderImageContainer">
              <div className="shimmerBG image-line"></div>
              <div className="recordsViewLoaderImageField">
                <div className="shimmerBG title-line"></div>
                <div className="shimmerBG title-line"></div>
              </div>
            </div>
            <div className="shimmerBG title-line"></div>
            <div className="shimmerBG title-line"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecordField;
