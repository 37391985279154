import axios from "axios";
import { createContext, useContext, useState } from "react";
import { getApiCallLocalPath } from "../utils/apiCallFunction";
import { getApiCallHeadersData } from "../utils/storageFunction";

const CartContext = createContext();
export const useCartContext = () => useContext(CartContext);

export const CartContextProvider = ({ children }) => {
  const [cartLayout, setCartLayout] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const fetchCart = async (payload) => {
    try {
      if (payload) {
        const { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, payload, {
          headers: getApiCallHeadersData()
        });
        setCartLayout(data?.data);
      } else {
        setCartLayout([]);
      }
    } catch (error) {
      throw error?.response;
    }
  };

  const fetchCartItems = async (payload) => {
    try {
      if (payload) {
        const { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, payload, {
          headers: getApiCallHeadersData()
        });
        setCartItems(data?.data);
        return data?.data;
      } else {
        setCartItems([]);
        return [];
      }
    } catch (error) {
      throw error?.response;
    }
  };

  const deleteCartItem = async (payload) => {
    try {
      if (payload) {
        await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, payload, {
          headers: getApiCallHeadersData()
        });
      }
    } catch (error) {
      throw error?.response;
    }
  };

  return (
    <CartContext.Provider value={{ cartLayout, cartItems, fetchCart, fetchCartItems, deleteCartItem }}>
      {children}
    </CartContext.Provider>
  );
};
