import React, { useEffect, useState } from "react";

import "./DropdownActionRenderer.css";
// import { ExpandLessIconSvg, ExpandMoreIconSvg } from "../../zinoIcon";
import { usePageHandlerContext } from "../../../pagehandler/PageHandlerContext";
import axios from "axios";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../../utils/apiCallFunction";
import toast from "react-hot-toast";
import { getApiCallHeadersData } from "../../../utils/storageFunction";
import IconRender from "../../../components/IconRender";

const DropdownActionRenderer = ({ itemConfig, jobParamsConfig, type = "dropdown" }) => {
  const [popupShow, setPopupShow] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { createNewTask, cacheProcessData } = usePageHandlerContext();

  const setOptionEmpty = () => {};

  const startNewTask = (optionValue) => {
    let element_config = {
      element_id: itemConfig.id,
      action_start: itemConfig.action_start,
      action_end: itemConfig.action_end,
      callback_function: [setOptionEmpty]
    };

    let action_config = {
      action_in: itemConfig?.job_template || itemConfig?.action_in || itemConfig.action,
      navigate_to: itemConfig?.params?.name || itemConfig.navigate_to,
      action_data: itemConfig?.action_data,
      sm_id:
        jobParamsConfig?.sm_id ||
        itemConfig?.data_id ||
        itemConfig?.action_data?.sm_id ||
        cacheProcessData?.value?.sm_id ||
        null,
      instance_id: jobParamsConfig?.instance_id || null,
      instance_ids: jobParamsConfig?.instance_ids || null,
      activity_id: itemConfig?.action_data?.activity_id,
      data_source: itemConfig?.data_source,
      data_source_type: itemConfig?.data_type,
      params: itemConfig?.params || {}
    };

    if (itemConfig?.job_template) {
      if (itemConfig.job_template === "get_activities") {
        action_config["action_name"] = "perform_activity";
        action_config["activity_id"] = optionValue;
      } else if (itemConfig.job_template === "perform_transition") {
        let source_activity = "";
        for (let index = 0; index < optionList?.data.length; index++) {
          const element = optionList.data[index];
          if (element.id === optionValue) {
            source_activity = element.source_activity;
            break;
          }
        }
        action_config["activity_id"] = source_activity;
        action_config["action_name"] = "perform_activity";
        action_config["state_id"] = optionValue;
      } else {
        action_config["action_name"] = itemConfig.job_template;
      }
    } else {
      return undefined;
    }
    setOptionList([]);
    createNewTask(element_config, action_config, cacheProcessData?.value || {});
  };

  const handleChange = (value) => {
    if (itemConfig.action_call_onchange && value) {
      startNewTask(value);
    }
    setPopupShow(false);
  };

  const apiCallFunction = async (processData) => {
    if (!processData?.value && Object.values(jobParamsConfig).length === 0) return;
    try {
      setLoading(true);
      let function_name = itemConfig?.job_template;
      let configObject = {
        data: {},
        function_name: function_name,
        params: {
          sm_id: jobParamsConfig?.sm_id || itemConfig?.data_id || cacheProcessData?.value?.sm_id,
          instance_id: jobParamsConfig?.instance_id || processData?.value?.instance_id,
          ...itemConfig.params
        }
      };
      let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, configObject, {
        headers: getApiCallHeadersData()
      });
      let object = {};
      for (let index = 0; index < data.data.length; index++) {
        const element = data.data[index];
        object[element.id] = element.name;
      }
      setOptionList({ data: data.data, value: object } || []);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (popupShow) {
      apiCallFunction(cacheProcessData);
      if (type === "dropdown") {
        document.body.style.overflow = "hidden";
      }
    } else {
      if (type === "dropdown") {
        document.body.style.overflow = "scroll";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheProcessData, popupShow]);

  // let class_name = "";
  // if (itemConfig.className) {
  //   if (typeof itemConfig.className === "object") {
  //     class_name = Object.values(itemConfig.className).join(" ");
  //   } else {
  //     class_name = itemConfig.className;
  //   }
  // }

  return (
    <>
      {type === "dropdown" ? (
        <div>
          <button className="secondary_default_button" onClick={() => setPopupShow(true)}>
            {itemConfig?.icon && <IconRender iconValue={itemConfig?.icon} />}
            {itemConfig?.name}
            <span className="material-symbols-outlined">more_vert</span>
          </button>
          {popupShow && (
            <div className="dropdown_bottom_container">
              <div className="dropdown_bottom_child">
                <div className="dropdown_bottom_header">
                  <p className="dropdown_bottom_label">
                    <span className="material-symbols-outlined">arrow_split</span>
                    Choose action
                  </p>
                  <span className="material-symbols-outlined" onClick={() => setPopupShow(false)}>
                    close
                  </span>
                </div>
                <div className="dropdown_bottom_body">
                  {loading ? (
                    <div className="dropdown_bottom_loader">
                      <span className="loader"></span>
                    </div>
                  ) : (
                    <div className="dropdown_bottom_option_container">
                      {itemConfig?.data?.map((optionItem, index) => {
                        return (
                          <div
                            key={index}
                            className="dropdown_bottom_option"
                            onClick={() => {
                              handleChange(optionItem.value);
                            }}
                          >
                            {optionItem.label}
                          </div>
                        );
                      })}

                      {optionList?.data?.map((optionItem, index) => {
                        return (
                          <div
                            key={index}
                            className="dropdown_bottom_option"
                            onClick={() => {
                              handleChange(optionItem.id);
                            }}
                          >
                            {optionItem.name}
                          </div>
                        );
                      })}
                      {itemConfig?.data?.length === 0 && optionList?.data?.length === 0 && <div>No Actions</div>}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="floating_button_container">
          {popupShow && (
            <div className="floating_button_group">
              {itemConfig?.data?.map((optionItem, index) => {
                return (
                  <button
                    className="floating_dropdown_button_list"
                    key={index}
                    onClick={() => handleChange(optionItem.value)}
                  >
                    {optionItem?.label}
                  </button>
                );
              })}
              {optionList?.data?.map((optionItem, index) => {
                return (
                  <button
                    className="floating_dropdown_button_list"
                    key={index}
                    onClick={() => handleChange(optionItem.id)}
                  >
                    {optionItem?.name}
                  </button>
                );
              })}
            </div>
          )}
          <button className="static_floating_button">
            <span className="material-symbols-outlined" onClick={() => setPopupShow(!popupShow)}>
              {itemConfig?.icon ? itemConfig?.icon?.split(" ")[0] : "add"}
            </span>
          </button>
        </div>
      )}
    </>
  );
};

export default DropdownActionRenderer;
