import { convertNumbetToDate, convertNumbetToTime } from "../../utils/commonFunction";
import { getDataFromDataObjectByKey } from "../../../utils/updateConfigFunction";
const getMapPopupDataComponent = (location, row) => {
  let res = "<div>";

  for (let index = 0; index < location.length; index++) {
    const itemIs = location[index];

    let dataIs = [];
    getDataFromDataObjectByKey(row, dataIs, itemIs?.id || "");

    if (itemIs.type === "field") {
      if ((itemIs.data_type === "file" || itemIs.data_type === "image") && itemIs?.media?.type === "image") {
        let imageFile = [];
        for (let s = 0; s < dataIs.length; s++) {
          const element = dataIs[s];
          if (typeof element === "object") {
            imageFile = [...imageFile, ...element];
          } else {
            imageFile.push(element);
          }
        }
        for (let index = 0; index < imageFile.length; index++) {
          const image_src = imageFile[index];
          res += `<img style="height=20px; width=20px, borderRadius=20px" src="${image_src.name}" alt="${image_src.name}"/>`;
        }
      } else if (itemIs.data_type === "datetime" || itemIs.data_type === "date" || itemIs.data_type === "day") {
        if (dataIs) {
          let formattedDate = "";
          for (let index = 0; index < dataIs.length; index++) {
            const dateItem = dataIs[index];
            if (itemIs.field_type === "time") {
              if (index !== 0) formattedDate += " , ";
              formattedDate += convertNumbetToTime(dateItem);
            } else {
              if (index !== 0) formattedDate += " ";
              formattedDate += convertNumbetToDate(dateItem, itemIs.field_type);
            }
          }
          res += `<p>${itemIs.label} : ${formattedDate}</p>`;
        } else {
          res += "";
        }
      } else if (itemIs.data_type === "object") {
        if (dataIs) {
          res += `<p>${itemIs.label} : ${JSON.stringify(dataIs, null, 2)}</p>`;
        }
      } else if (itemIs.data_type === "geolocation" || itemIs.data_type === "selectboxes") {
        if (dataIs) {
          res += `<p>${itemIs.label} : ${dataIs.join(" , ")}</p>`;
        }
      } else if (itemIs.field_type === "radio") {
        if (dataIs) {
          res += `<p>${itemIs.label} : ${dataIs.join(" , ")}</p>`;
        }
      } else {
        res += `<p>${itemIs.label} : ${dataIs.join(", ")}</p>`;
      }
    } else if (itemIs.type === "custom") {
      for (let index = 0; index < itemIs.config.length; index++) {
        const itemConfig = itemIs.config[index];
        switch (itemConfig.type) {
          case "button":
            res += `<div><button class="zino_btn_outline_primary" onClick="handleJobCallFunction('${itemConfig.id}', '${row.id}')">${itemConfig?.name}</button></div>`;
            break;
          case "dropdown":
            // res +=``;
            break;
          default:
          // res +=``;
        }
      }
    }
  }

  res += "</div>";
  return res;
};

export { getMapPopupDataComponent };
