import React from "react";
import { gridCommonFunction } from "../utils/gridCommonFunction";
import {
  JobActionRenderer,
  SectionRenderer,
  TableViewRenderer,
  TabViewRenderer,
  DropdownActionRenderer
} from "../index";
import CardItems from "../sectionrendererlib/card/CardItems";
import TimelineRenderer from "../timeline/TimelineRenderer";
import RecordsetRenderer from "./RecordsetRenderer";
import ActivityCardRender from "./ActivityCardRender";

import LabelField from "../../components/fields/LabelField";
import ImageField from "../../components/fields/ImageField";
import ButtonGroupField from "../../components/fields/ButtonGroupField";

import FormComponent from "../../components/appview/FormComponent";
import ActivityRecordsViewRenderer from "./ActivityRecordsViewRenderer";
import { stringToJSONChange } from "../utils/commonFunction";
import ProgressBar from "../widgetsLib/ProgressBar";
import ProgressBarRenderer from "../progressbarlib/ProgressBarRenderer";

const DetailsViewItem = ({
  itemConfig,
  detailsViewDataFormData,
  detailsViewDataTimeline,
  activityCardsConfig,
  detailsViewDataActivityCard,
  detailsViewDataActivitiesHistory,
  detailsViewDataActivityRecord,
  jobParamsConfig,
  detailsViewPopup,
  detailsViewDataOrg,
  functionObject,
  detailsViewItemConfig
}) => {
  let rowItemElements = [];
  let LayoutArray = [];
  for (let index = 0; index < itemConfig?.length; index++) {
    let gridElement = itemConfig[index];
    LayoutArray.push(gridElement.grid);
    rowItemElements.push(gridElement.element_config);
  }

  let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(LayoutArray);

  return (
    <div style={styleDataForGrid}>
      {rowItemElements.map((colItem, colItemIndexIs) => {
        let styleObject = {
          gridArea: `zino-${colItem?.i || colItem.id}`
        };
        if (colItem.hide) {
          return undefined;
        } else {
          switch (colItem.type) {
            case "button":
              colItem.params = { ...colItem.params, detailsview_id: detailsViewItemConfig?.id || "" };
              return (
                <div
                  className="app_entry_dashboard_itemS dashboard_btn"
                  key={colItem.id + colItemIndexIs}
                  style={styleObject}
                >
                  <JobActionRenderer
                    itemConfig={colItem}
                    jobParamsConfig={jobParamsConfig}
                    reqData={detailsViewDataFormData}
                    functionObject={functionObject}
                  />
                </div>
              );
            case "dropdown":
              return (
                <div className="app_entry_dashboard_itemS" key={colItem.id + colItemIndexIs} style={styleObject}>
                  <DropdownActionRenderer
                    itemConfig={colItem}
                    jobParamsConfig={jobParamsConfig}
                    reqData={detailsViewDataFormData}
                    functionObject={functionObject}
                    type="button-group"
                  />
                </div>
              );
            case "section":
              //classnames coming from builder for that element
              let class_name = "";
              if (colItem.className) {
                if (typeof colItem.className === "object") {
                  class_name = Object.values(colItem.className).join(" ");
                }
              }
              return (
                <div
                  className={`app_entry_dashboard_itemS dashboard_section detailsView_section_field ${class_name}`}
                  key={colItem.id + colItemIndexIs}
                  style={{ ...styleObject }}
                >
                  <SectionRenderer
                    itemConfig={colItem}
                    detailsViewData={detailsViewDataFormData}
                    detailsViewDataTimeline={detailsViewDataTimeline}
                    activityCardsConfig={activityCardsConfig}
                    detailsViewDataActivityCard={detailsViewDataActivityCard}
                    detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
                    jobParamsConfig={jobParamsConfig}
                    detailsViewDataOrg={detailsViewDataOrg}
                    functionObject={functionObject}
                    detailsViewItemConfig={detailsViewItemConfig}
                  />
                </div>
              );
            case "separator":
              const value = stringToJSONChange(colItem?.custom_css);
              return <hr className="separator_field" style={{ ...styleObject, ...value }} />;
            case "tableview":
              return (
                <div className="app_entry_dashboard_itemS" key={colItem.id + colItemIndexIs} style={styleObject}>
                  <TableViewRenderer
                    dataConfig={colItem.config}
                    tableConfig={colItem}
                    detailsViewData={detailsViewDataFormData}
                  />
                </div>
              );

            case "tabview":
              return (
                <div className="dashboard_tabview" key={colItem.id + colItemIndexIs} style={styleObject}>
                  <TabViewRenderer
                    configData={colItem.config}
                    detailsViewData={detailsViewDataFormData}
                    detailsViewDataTimeline={detailsViewDataTimeline}
                    activityCardsConfig={activityCardsConfig}
                    detailsViewDataActivityCard={detailsViewDataActivityCard}
                    detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
                    styleConfig={colItem.className}
                    jobParamsConfig={jobParamsConfig}
                    detailsViewDataOrg={detailsViewDataOrg}
                    functionObject={functionObject}
                    detailsViewItemConfig={detailsViewItemConfig}
                  />
                </div>
              );

            case "timeline":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <TimelineRenderer
                    itemConfig={colItem}
                    timelineViewData={detailsViewDataTimeline}
                    activityCardsConfig={activityCardsConfig}
                    detailsViewDataActivityCard={detailsViewDataActivityCard}
                    detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
                    jobParamsConfig={jobParamsConfig}
                    functionObject={functionObject}
                    detailsViewDataOrg={detailsViewDataOrg}
                    detailsViewItemConfig={detailsViewItemConfig}
                  />
                </div>
              );

            case "activitycard":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <ActivityCardRender
                    itemConfig={activityCardsConfig[colItem.id]}
                    activityCardsConfig={activityCardsConfig}
                    detailsViewDataActivityCard={detailsViewDataActivityCard}
                    detailsViewDataTimeline={detailsViewDataTimeline}
                    detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
                    jobParamsConfig={jobParamsConfig}
                    detailsViewDataOrg={detailsViewDataOrg}
                    functionObject={functionObject}
                    detailsViewItemConfig={detailsViewItemConfig}
                  />
                </div>
              );

            case "activity-recordsview":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <ActivityRecordsViewRenderer
                    recordData={detailsViewDataActivityRecord}
                    recordLayout={colItem}
                    jobParamsConfig={jobParamsConfig}
                    functionObject={functionObject}
                  />
                </div>
              );
            case "recordset":
            case "recordset-list":
            case "grid":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <RecordsetRenderer itemConfig={colItem} detailsViewData={detailsViewDataFormData} />
                </div>
              );

            case "html":
              return (
                <div
                  className="app_entry_dashboard_itemS dashboard_title"
                  key={colItem.id + colItemIndexIs}
                  style={styleObject}
                  dangerouslySetInnerHTML={{
                    __html: colItem?.content
                  }}
                ></div>
              );

            case "variable":
              return (
                <div
                  className="app_entry_dashboard_itemS dashboard_title"
                  key={colItem.id + colItemIndexIs}
                  style={{ ...styleObject, padding: "0px 5px", fontSize: "14px" }}
                >
                  <span>{detailsViewDataFormData?.[colItem?.id] || "-"}</span>
                </div>
              );
            case "label":
              return (
                <div
                  className={Object.values(colItem.className).join(" ")}
                  key={colItem.id + colItemIndexIs}
                  style={{ ...styleObject, padding: "0px 5px", ...colItem.inline_style }}
                >
                  <LabelField labelConfig={colItem} />
                </div>
              );

            case "image":
              return (
                <div className="app_entry_dashboard_itemS" key={colItem.id + colItemIndexIs} style={styleObject}>
                  <ImageField imgConfig={colItem} />
                </div>
              );

            case "empty":
              return <div key={colItem.id + colItemIndexIs} style={styleObject}></div>;

            case "field":
              return (
                <div key={colItem.id + colItemIndexIs} style={{ ...styleObject, ...colItem.inline_style }}>
                  <CardItems
                    colItem={colItem}
                    dataConfig={detailsViewDataFormData}
                    layoutDataLineHeight={40}
                    jobParamsConfig={jobParamsConfig}
                    functionObject={functionObject}
                  />
                </div>
              );

            case "spacer":
              return (
                <div
                  key={colItem.id + colItemIndexIs}
                  style={{ ...styleObject, height: `${colItem?.height || 40}px` }}
                ></div>
              );
            case "button-group":
              return (
                <ButtonGroupField
                  itemConfig={colItem}
                  jobParamsConfig={jobParamsConfig}
                  detailsViewPopup={detailsViewPopup}
                  reqData={detailsViewDataFormData}
                  functionObject={functionObject}
                />
              );
            case "form":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <FormComponent
                    itemConfig={colItem}
                    extraRowData={{
                      ...(jobParamsConfig || {}),
                      instance_ids:
                        detailsViewDataFormData["id"] || detailsViewDataFormData["_id"]
                          ? [detailsViewDataFormData["id"] || detailsViewDataFormData["_id"]]
                          : null,
                      instance_id: detailsViewDataFormData["id"] || detailsViewDataFormData["_id"] || ""
                    }}
                    functionObject={functionObject}
                    jobParamsTokenConfig={jobParamsConfig}
                  />
                </div>
              );
            case "progress-tracker":
              return (
                <div key={colItem?.id + colItemIndexIs} style={styleObject}>
                  <ProgressBar itemConfig={colItem} data={detailsViewDataFormData} />
                </div>
              );
            case "progress-bar":
              return (
                <div key={colItem?.id + colItemIndexIs} style={styleObject}>
                  <ProgressBarRenderer
                    itemConfig={colItem}
                    progressBarData={detailsViewDataOrg?.detailsview_data?.progress_bar?.[colItem?.id]}
                  />
                </div>
              );

            default:
              return (
                // <div className="app_entry_dashboard_itemS" key={colItem.id + colItemIndexIs} style={styleObject}>
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  {colItem.type}
                </div>
              );
          }
        }
      })}
    </div>
  );
};

export default DetailsViewItem;
