import { DETAILS_VIEW_DATA } from "./detailsviewaction";

export const detailsviewreducer = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case DETAILS_VIEW_DATA:
      return { ...state, [`${payload.namespace}_detailsviewdata`]: payload?.payloadData };
    default:
      return state;
  }
};
