import React, { useLayoutEffect, useState, useCallback, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { commonFunction } from "../utils/commonFunction";
import { useSelector } from "react-redux";
import axios from "axios";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../../utils/storageFunction";
// import { useFormHandlerContext } from "../FormContext";
import { getDataFromDataObjectByKey } from "../../../utils/updateConfigFunction";
import toast from "react-hot-toast";

function FFLookupSMField({ component, currentConfigKey, namespace }) {
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];
  let formConfigData = storeData?.[`${namespace}_formConfigData`] || {};

  // const { openFillNewLookupForm, formExData, setFormExData, isOpenExForm } = useFormHandlerContext();

  const [outputOption, setOutputOption] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || ""
  );
  const [inputOption, setInputOption] = useState([]);

  const apiCallAndGetDataDebounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const apiCallAndGetData = async (term) => {
    try {
      let { data } = await axios.post(
        `${getApiCallLocalPath()}api/v1/dynamic`,
        {
          Params: {
            search_query: {
              search_term: term || "",
              search_fields: [],
              facet_by: [],
              page: 1,
              per_page: 25
            },
            table_id: component.table_id
          },
          data: {
            form_id: formConfigData.id,
            field_id: component.id,
            activity_id: formConfigData.activity_id,
            entity_id: formConfigData.entity_id,
            sm_id: formConfigData.sm_id,
            instance_id: formConfigData.instance_id
          },
          function_name: "perform_search"
        },
        { headers: getApiCallHeadersData() }
      );

      let document = data?.data?.documents || [];
      setInputOption(document);
      return document;
    } catch (error) {
      toast.error(`${component?.label} data not available`, toastErrorMessageStyle());
      setInputOption([]);
      return [];
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiCallAndGetDataOptimizedFun = useCallback(apiCallAndGetDataDebounce(apiCallAndGetData), []);

  useEffect(() => {
    if (component.show_default_option) {
      apiCallAndGetDataOptimizedFun();
      apiCallAndGetDataDebounce(apiCallAndGetData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (outputOption && inputOption.length > 0) {
      let flag = Object.keys(outputOption).length < 3;
      if (flag) {
        for (let index = 0; index < inputOption.length; index++) {
          const element = inputOption[index];
          if (element?.id === outputOption?.instance_id) {
            validate(element);
            break;
          }
        }
      }
    } else if (outputOption && inputOption.length === 0 && !component.show_default_option) {
      apiCallAndGetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputOption, inputOption]);

  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setOutputOption(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    if (value) {
      value["sm_id"] = component.table_id;
    }
    setOutputOption(value || "");
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component, false);
    // setInputOption([]);
  };

  const getOptionLabelFun = (option) => {
    let label = [];
    for (let index = 0; index < component.display_fields.length; index++) {
      const element = component.display_fields[index];
      let ans = [];
      getDataFromDataObjectByKey(option, ans, element.id);
      label = [...label, ...ans];
    }
    return label.join(" , ");
  };

  return (
    <div className="form_field_outer_box">
      <Stack spacing={2} width={"100%"}>
        <Autocomplete
          id={component.id || component._id}
          options={inputOption}
          getOptionLabel={getOptionLabelFun}
          value={outputOption || null}
          renderInput={(params) => (
            <TextField
              {...params}
              label={component.label}
              onChange={(e) => apiCallAndGetDataOptimizedFun(e.target.value)}
              required={component.validate.required}
              size="small"
              sx={{
                "& .MuiInputLabel-root": {
                  color: "var(--mui-label-color)"
                },
                "& .MuiInputLabel-root.Mui-error": {
                  color: "var(--mui-label-error-color)" // color when there's an error
                },
                "& .MuiInputLabel-root.MuiInputLabel-shrink": {
                  color: "var(--mui-label-shrink-color)" // color when the label is at the top (shrunk)
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-color)" // default border color
                  },
                  "&:hover fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-color)" // border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-color)" // border color when focused
                  },
                  "&.Mui-disabled fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-color)" // border color when focused
                  },
                  "&.Mui-error fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-error-color)" // border color when there's an error
                  }
                }
              }}
            />
          )}
          onChange={(e, value) => {
            validate(value);
          }}
          disabled={component.disable}
          ListboxProps={{ sx: { fontSize: "14px" } }}
        />
      </Stack>
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id || component._id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default FFLookupSMField;
