import React, { useState } from "react";
import IconRenderer from "../../../components/IconRender";
import FilterScreen from "./filter/FilterScreen";
import { useSelector } from "react-redux";

function LeftPart({ namespace, filterData }) {
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const chartFilterDataConfig = useSelector((state) => state.chartviewstore?.[`${namespace}_chartFilterDataForField`]);
  const handleFilterPopup = () => {
    setOpenFilterPopup(true);
    document.body.style.overflow = "hidden";
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          paddingTop: "20px",
          paddingRight: "5px",
          marginBottom: "12px"
        }}
      >
        <button className="primary_default_button chartFilterButton" onClick={handleFilterPopup}>
          <IconRenderer iconValue="filter_alt" />
          <span>Filters</span>
          {chartFilterDataConfig?.filters?.filter_by?.length > 0 && (
            <span className="chartFilterCount">{chartFilterDataConfig?.filters?.filter_by?.length}</span>
          )}
        </button>
      </div>
      {openFilterPopup && (
        <div className="filterscreenPopup">
          <FilterScreen namespace={namespace} setOpenFilterPopup={setOpenFilterPopup} filterLayout={filterData} />
        </div>
      )}
    </>
  );
}

export default LeftPart;
