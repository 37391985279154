import React from "react";
import { updateNumberFieldStringFormat } from "../utils/commonFunction";

const NumberFormater = ({ prefix, postfix, number, type, curr_icon }) => {
  let updatedNumber = updateNumberFieldStringFormat(number, type, curr_icon);

  let display = updatedNumber ? (prefix || "") + updatedNumber + (postfix || "") : "";

  return <>{display}</>;
};

export default NumberFormater;
