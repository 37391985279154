import React, { useState } from "react";
import IconRender from "../../../components/IconRender";
import "./JobActionRenderer.css";
import { usePageHandlerContext } from "../../../pagehandler/PageHandlerContext";
import PopupBoxSection from "../../viewlib/PopupBoxSection/PopupBoxSection";
import axios from "axios";
import { getApiCallHeadersData } from "../../../utils/storageFunction";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../../utils/apiCallFunction";
import ProcessBarSection from "../../viewlib/ProcessBarSection/PopupBoxSection";
import toast from "react-hot-toast";
import evaluatePreFiltersExpression from "../../../utils/prefiltersFn";
const JobActionRenderer = ({ itemConfig, reqData = {}, jobParamsConfig, button_className }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [aiPrompt, setAiPrompt] = useState("");
  const [loader, setLoader] = useState(false);
  const [aiResponse, setAiResponse] = useState({});
  const [aiResponsePopup, setAiResponsePopup] = useState(false);

  let aiJobs = ["perform_activity_ai", "perform_timeline_ai"];

  const { createNewTask, cacheProcessData } = usePageHandlerContext();
  const handleJobCallFunction = async () => {
    let element_config = {
      element_id: itemConfig.id,
      action_start: itemConfig.action_start,
      action_end: itemConfig.action_end
    };
    let action_config = {
      action_in: itemConfig?.job_template || itemConfig?.action_in || itemConfig.action,
      navigate_to: itemConfig?.params?.name || itemConfig.navigate_to,
      action_data: itemConfig?.action_data,
      action_name: itemConfig?.job_template || itemConfig?.action_data?.job_name,
      sm_id:
        jobParamsConfig?.sm_id ||
        itemConfig.data_id ||
        itemConfig?.action_data?.sm_id ||
        cacheProcessData?.value?.sm_id ||
        null,
      instance_id: jobParamsConfig?.instance_id || null,
      instance_ids: jobParamsConfig?.instance_ids || null,
      activity_id: itemConfig?.action_data?.activity_id,
      data_source: itemConfig?.data_source,
      data_source_type: itemConfig?.data_type,
      params: itemConfig?.params || {}
    };
    if (Object.keys(reqData).length === 0 && Object.keys(cacheProcessData?.value || {}).length > 0) {
      reqData = cacheProcessData?.value;
    }

    if (["send_email_bg", "send_sms_bg"].includes(action_config.action_name)) {
      reqData = {};
    } else if (["perform_activity_ai"].includes(action_config.action_name)) {
      reqData = { prompt: aiPrompt };
    } else if (["perform_timeline_ai"].includes(action_config.action_name)) {
      reqData = { prompt: aiPrompt };
      setIsPopupOpen(false);
      await handleTimelineAijob(action_config, reqData);
    } else {
      reqData = { ...reqData, sm_id: action_config.sm_id };
    }
    createNewTask(element_config, action_config, reqData);
    setAiPrompt("");
    setIsPopupOpen(false);
  };

  let class_name = "";
  if (itemConfig.className) {
    if (typeof itemConfig.className === "object") {
      class_name = Object.values(itemConfig.className).join(" ");
    } else {
      class_name = itemConfig.className;
    }
  }

  const handleTimelineAijob = async (action_config, reqData) => {
    try {
      setLoader(true);
      const payload = {
        function_name: "timeline_ai",
        function_id: "",
        data: {},
        params: {
          detailsview_id: itemConfig?.params?.detailsview_id,
          instance_id: action_config?.instance_id || null,
          user_prompt: reqData.prompt,
          // instance_ids: action_config?.instance_ids || [],

          front_end: { action_config: {}, params_config: {} }
        }
      };
      const { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, payload, {
        headers: getApiCallHeadersData()
      });
      setAiResponse(data?.data);
      setAiResponsePopup(true);
    } catch (error) {
      toast.error("something went wrong", toastErrorMessageStyle());
    } finally {
      setLoader(false);
    }
  };

  const showBtn = evaluatePreFiltersExpression(
    itemConfig?.pre_filters_join_by,
    itemConfig?.pre_filters || [],
    reqData?.data || reqData || {}
  );

  if (!showBtn) {
    return null;
  }

  if (aiJobs.includes(itemConfig?.job_template)) {
    return (
      <>
        <button
          className={`primary_default_button theme_mob_primary_btn ${
            button_className ? button_className : null
          } ${class_name}`}
          onClick={() => {
            setIsPopupOpen(true);
          }}
          style={itemConfig.inline_style || {}}
        >
          {itemConfig?.icon && <IconRender iconValue={itemConfig.icon} />}
          {itemConfig?.name}
        </button>
        {isPopupOpen && (
          <PopupBoxSection>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "15px"
              }}
            >
              <label htmlFor="aiprompt"></label>
              <textarea
                className="aiprompt_textarea"
                id="aiprompt"
                name="prompt"
                rows="10"
                cols="40"
                onChange={(e) => {
                  setAiPrompt(e.target.value);
                }}
                defaultValue={aiPrompt}
              ></textarea>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "15px",
                padding: "15px"
              }}
            >
              <button
                className="secondary_default_button"
                onClick={() => {
                  setAiPrompt("");
                  setIsPopupOpen(false);
                }}
                style={itemConfig.inline_style || {}}
              >
                Close
              </button>
              <button
                className="primary_default_button"
                onClick={handleJobCallFunction}
                style={itemConfig?.inline_style || {}}
              >
                Submit
              </button>
            </div>
          </PopupBoxSection>
        )}
        {aiResponsePopup && (
          <PopupBoxSection>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "12px",
                  borderBottom: "1px solid #dadada"
                }}
              >
                <p style={{ fontSize: "14px", fontWeight: "600" }}>{aiResponse?.workflow_name || "Response"}</p>
                <span
                  className="material-symbols-outlined"
                  style={{
                    fontWeight: 600,
                    padding: "8px",
                    color: "#fff",
                    background: "#10182b",
                    borderRadius: "50%",
                    textAlign: "center"
                  }}
                  onClick={() => setAiResponsePopup(false)}
                >
                  close
                </span>
              </div>
              <div style={{ minHeight: "50vh", maxHeight: "80vh", overflow: "scroll", background: "white" }}>
                {/* <p style={{ wordBreak: "break-word", wordWrap: "break-word", padding: "4px 8px" }}>
                  {aiResponse?.output || "No Response"}
                </p> */}
                <div dangerouslySetInnerHTML={{ __html: aiResponse?.output }} />
              </div>
            </div>
          </PopupBoxSection>
        )}
        {loader && <ProcessBarSection />}
      </>
    );
  } else {
    return (
      <button
        className={`primary_default_button theme_mob_primary_btn ${
          button_className ? button_className : null
        } ${class_name}`}
        onClick={handleJobCallFunction}
        style={itemConfig.inline_style || {}}
      >
        {itemConfig?.icon && <IconRender iconValue={itemConfig.icon} />}
        {itemConfig?.name}
      </button>
    );
  }
};

export default JobActionRenderer;
