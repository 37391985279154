import React, { useState, useRef, useEffect } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { useLocation } from "react-router-dom";
import { DateRangePicker } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import { getFilterDataForAPICall } from "../../../../redux/chartview/chartviewaction";

const DateRangePickerField = ({ namespace = "", filter = {}, reset }) => {
  const location = useLocation();
  const screen_name = location.pathname;
  const dispatch = useDispatch();
  const [popupShow, setPopupShow] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection"
  });
  const [dateRangeValueDate, setDateRangeValueDate] = useState(null);
  const storeData = useSelector((state) => state.chartviewstore);
  let filterDataForField = storeData?.[`${namespace}_chartFilterDataForField`] || {};
  let chartLayout = storeData?.[`${namespace}_chartDashboardDataConfig`];
  // Close popup on side click
  const isClickOutside = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isClickOutside.current && !isClickOutside.current.contains(event.target)) {
        setPopupShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleReset = () => {
    let date = { startDate: new Date(), endDate: new Date(), key: "selection" };
    setDateRangeValue(date);
    setPopupShow(false);
    setDateRangeValueDate(null);
    callToApi(false, date);
  };

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setDateRangeValue(selection);

    // 86400 One Day
    let startDate = new Date(selection.startDate);
    let endDate = new Date(selection.endDate);
    startDate = formatDate(startDate);
    endDate = formatDate(endDate);
    setDateRangeValueDate({ startDate: startDate, endDate: endDate });
  };

  const handleClose = () => {
    setPopupShow(false);
  };

  useEffect(() => {
    let previousFilters = filterDataForField?.filters?.filter_by;
    if (previousFilters?.length !== 0) {
      previousFilters?.forEach((prevFilter) => {
        if (prevFilter?.id === filter?.id) {
          let custom_date_range = prevFilter?.custom_date_range;
          let tempFilterObj = {
            startDate: new Date(custom_date_range[0]),
            endDate: new Date(custom_date_range[1]),
            key: "selection"
          };
          setDateRangeValue(tempFilterObj);
          let startDate = formatDate(tempFilterObj?.startDate);
          let endDate = formatDate(tempFilterObj?.endDate);
          setDateRangeValueDate({ startDate: startDate, endDate: endDate });
        }
      });
    } else {
      setDateRangeValue({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection"
      });
      setDateRangeValueDate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const callToApi = (flag, dateIs) => {
    let previousFilters = filterDataForField?.filters;
    let newFilters = previousFilters?.filter_by?.filter((prev) => prev?.id !== filter?.id);
    if (flag) {
      let startDate = new Date(dateIs.startDate);
      let endDate = new Date(dateIs.endDate);
      // Convert dates to formatted strings
      const formattedDate1 = startDate.toLocaleDateString("en-GB"); // "12/02/2024" (dd/mm/yyyy)
      const formattedDate2 = endDate.toLocaleDateString("en-GB"); // "14/02/2024" (dd/mm/yyyy)

      // Split and rearrange date parts
      const rearrangedDate1 = formattedDate1.split("/").reverse().join("-"); // "12-02-2024"
      const rearrangedDate2 = formattedDate2.split("/").reverse().join("-"); // "14-02-2024"
      let tempFilterObj = {
        id: filter?.id,
        operator: "custom_date_range",
        text_value: "",
        text_array: "",
        number_value: "",
        number_range: "",
        human_date: "",
        custom_date_range: [rearrangedDate1, rearrangedDate2]
      };
      newFilters.push(tempFilterObj);
    }
    previousFilters.filter_by = newFilters;
    dispatch(getFilterDataForAPICall(namespace, previousFilters, screen_name, chartLayout?.table_type));
  };

  useEffect(() => {
    if (!popupShow && dateRangeValueDate) {
      callToApi(dateRangeValueDate, dateRangeValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupShow]);

  return (
    <div className="filter_item_container">
      <span className="filter_item_label">{filter.custom_label || filter.display}</span>

      <div
        className={`recordview_filter_field_outerBox theme_recordview_filter_field_outerBox ${
          dateRangeValueDate ? "border_outerBox" : null
        }`}
        ref={isClickOutside}
      >
        <span
          className="recordview_filter_field_exp theme_recordview_filter_field_exp"
          onClick={() => setPopupShow(!popupShow)}
        >
          {dateRangeValueDate ? (
            <span className="value">
              {dateRangeValueDate.startDate} - {dateRangeValueDate.endDate}
            </span>
          ) : (
            <span className="exp">DD MM YYYY - DD MM YYYY</span>
          )}
          <span className="material-symbols-outlined">{popupShow ? "keyboard_arrow_up" : "keyboard_arrow_down"}</span>
        </span>
        {popupShow && (
          <div className="recordview_filter_field theme_recordview_filter_field">
            <DateRangePicker
              onChange={handleOnChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={[dateRangeValue]}
              direction="horizontal"
            />
            <div style={{ display: "flex", gap: "8px", padding: "8px" }}>
              <button className="secondary_default_button theme_btn_outline_primary" size="small" onClick={handleReset}>
                Reset
              </button>
              <button className="primary_default_button theme_btn_outline_primary" size="small" onClick={handleClose}>
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DateRangePickerField;
