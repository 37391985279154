import React from "react";

//fields
import RecordsetRenderer from "../../../../detailsviewlib/RecordsetRenderer";
import JobAction from "../../actions/JobAction";

//redux
import { useSelector } from "react-redux";

//icons
import IconRender from "../../../../../components/IconRender";
import file_save from "../../../../../assets/images/downloadfileicon.png";
import no_img from "../../../../../assets/images/noimg.jpeg";

//functions
import { formatTimestamp } from "../../../../utils/commonFunction";
import { getDataFromDataObjectByKey } from "../../../../../utils/updateConfigFunction";
import { useImagePreviewContext } from "../../../../../applicationcontext/ImagePreviewContext";
import { getApiCallLocalPath } from "../../../../../utils/apiCallFunction";
import MapPopup from "../../../../../components/Map/MapPopup";
import NumberFormater from "../../../../components/NumberFormater";

const FieldType = ({ element, record, namespace }) => {
  let storeData = useSelector((state) => state.recordviewstore);
  let recordsviewLayoutConfig = storeData?.[`${namespace}_recordsviewLayoutConfig`];
  let recordsviewDataConfig = storeData?.[`${namespace}_recordsviewDataConfig`];
  let table_id = recordsviewLayoutConfig?.layout?.table_id;

  let data = [];
  const { showPopupImagePreview } = useImagePreviewContext();
  if (element?.field_type === "lookup-sm") {
    getDataFromDataObjectByKey(record, data, `${element?.id}.instance_id`);
  } else {
    getDataFromDataObjectByKey(record, data, element?.id);
  }

  //for text dynamic color
  let colorIf = {};
  if (element?.colorif) {
    for (let index = 0; index < element.colorif.length; index++) {
      const elem = element.colorif[index];
      colorIf[elem.value.toLowerCase()] = elem.color;
    }
  }

  let bgColor = "";
  if (typeof data[0] === "string") {
    bgColor = colorIf[data[0].toLowerCase()];
  } else if (typeof data[0] === "number") {
    bgColor = colorIf[data[0]];
  }

  //builder-classnames
  let classNameList = Object.values(element?.className)?.join(" ");

  //dynamic color
  let colorObject = bgColor ? (element?.show_chips ? { backgroundColor: bgColor } : { color: bgColor }) : {};

  const handleNavigate = (urlData) => {
    if (urlData?.length > 0) {
      window.open(urlData?.join(","));
    }
  };

  let showPrefix =
    data?.length > 0 && data[0] !== "-" && (element?.prefix_data || element?.postfix_data) ? true : false;

  //return function
  if ((element.parent_type === "lookup" || element.field_type === "lookup-sm") && element.field_action) {
    element.field_action.data_id = table_id;
    const newRecord = record[element.id.split(".")[0]];
    return (
      <JobAction
        itemConfig={element?.field_action}
        itemType="single"
        reqData={{
          data: newRecord,
          instance_id: newRecord?.id || newRecord?.instance_id || newRecord?.[recordsviewDataConfig?.primary_key]
        }}
      />
    );
  }
  if (element.field_type === "textfield" || element.field_type === "varchar" || element.field_type === "int") {
    return (
      <p
        style={{
          ...element?.inline_style,
          ...colorObject
        }}
        className={`fieldDefaultClass ${element?.show_chips ? "fieldChipStyle" : null} ${classNameList}`}
      >
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {element?.show_avatar && <span className="fieldAvatarStyle">{data[0]?.slice(0, 1)}</span>}
        {showPrefix
          ? `${element?.prefix_data || ""} ${data.join(",")} ${element?.postfix_data || ""}`
          : data.join(",") || "-"}
      </p>
    );
  } else if (element.field_type === "url") {
    return (
      <div
        style={{
          ...element?.inline_style,
          ...colorObject
        }}
        className={`fieldDefaultClass ${element?.show_chips ? "fieldChipStyle" : null} ${classNameList}`}
        onClick={() => handleNavigate(data)}
      >
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {element?.show_avatar && <span className="fieldAvatarStyle">{data[0]?.slice(0, 1)}</span>}
        {showPrefix
          ? `${element?.prefix_data || ""} ${data.join(",")} ${element?.postfix_data || ""}`
          : data.join(",") || "-"}
      </div>
    );
  } else if (element.field_type === "textarea") {
    return (
      <p
        style={{
          ...element?.inline_style,
          ...colorObject
        }}
        className={`fieldDefaultClass ${element?.show_chips ? "fieldChipStyle" : null} ${classNameList}`}
      >
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {element?.show_avatar && <span className="fieldAvatarStyle">{data[0]?.slice(0, 1)}</span>}
        {showPrefix && (element?.prefix_data || "")}
        {<span dangerouslySetInnerHTML={{ __html: data?.join("<br/>")?.replaceAll(/\n/g, "<br/>") || "-" }}></span>}
        {showPrefix && (element?.postfix_data || "")}
      </p>
    );
  } else if (element.field_type === "email") {
    return (
      <p
        style={{
          ...element?.inline_style,
          ...colorObject
        }}
        className={`fieldDefaultClass ${element?.show_chips ? "fieldChipStyle" : null} ${classNameList}`}
      >
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {element?.show_avatar && <span className="fieldAvatarStyle">{data[0]?.slice(0, 1)}</span>}
        {showPrefix
          ? `${element?.prefix_data || ""} ${data.join(",")} ${element?.postfix_data || ""}`
          : data.join(",") || "-"}
      </p>
    );
  } else if (element.field_type === "number" || element.field_type === "percentage") {
    return (
      <p
        style={{
          ...element?.inline_style,
          ...colorObject
        }}
        className={`fieldDefaultClass ${element?.show_chips ? "fieldChipStyle" : null} ${classNameList}`}
      >
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {showPrefix
          ? data?.map((value, index) => (
              <NumberFormater
                prefix={element?.prefix_data || ""}
                postfix={element?.postfix_data || ""}
                number={value}
                curr_icon={element?.currency_code}
                type={element?.field_type === "percentage" ? "percentage" : element?.format}
                key={index}
              />
            ))
          : data?.map((value, index) => (
              <NumberFormater
                number={value}
                curr_icon={element?.currency_code}
                type={element?.field_type === "percentage" ? "percentage" : element?.format}
                key={index}
              />
            ))}
      </p>
    );
  } else if (element.field_type === "password") {
    return (
      <p
        style={{
          ...element?.inline_style,
          ...colorObject
        }}
        className={`fieldDefaultClass ${element?.show_chips ? "fieldChipStyle" : null} ${classNameList}`}
      >
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {element?.show_avatar && <span className="fieldAvatarStyle">{data[0]?.slice(0, 1)}</span>}
        {showPrefix
          ? `${element?.prefix_data || ""} ${data.join(",")} ${element?.postfix_data || ""}`
          : data.join(",") || "-"}
      </p>
    );
  } else if (element.field_type === "currency") {
    function formatValue(currencyObject) {
      if (typeof currencyObject === "object") {
        const formatter = new Intl.NumberFormat(undefined, {
          style: "currency",
          currency: currencyObject?.currency
        });
        return formatter.format(currencyObject?.value);
      } else {
        return "-";
      }
    }
    return (
      <p
        style={{
          ...element?.inline_style,
          ...colorObject
        }}
        className={`fieldDefaultClass ${element?.show_chips ? "fieldChipStyle" : null} ${classNameList}`}
      >
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {element?.show_avatar && <span className="fieldAvatarStyle">{data[0]?.slice(0, 1)}</span>}
        {data?.length > 0
          ? showPrefix
            ? data?.map(
                (value, index) => `${element?.prefix_data || ""} ${formatValue(value)} ${element?.postfix_data || ""}`
              )
            : data?.map((value, index) => formatValue(value))
          : "-"}
      </p>
    );
  } else if (element.field_type === "disabled") {
    return (
      <p
        style={{
          ...element?.inline_style,
          ...colorObject
        }}
        className={`fieldDefaultClass ${element?.show_chips ? "fieldChipStyle" : null} ${classNameList}`}
      >
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {element?.show_avatar && <span className="fieldAvatarStyle">{data[0]?.slice(0, 1)}</span>}
        {showPrefix
          ? `${element?.prefix_data || ""} ${data.join(",")} ${element?.postfix_data || ""}`
          : data.join(",") || "-"}
      </p>
    );
  } else if (element.field_type === "select") {
    return (
      <p
        style={{
          ...element?.inline_style,
          ...colorObject
        }}
        className={`fieldDefaultClass ${element?.show_chips ? "fieldChipStyle" : null} ${classNameList}`}
      >
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {element?.show_avatar && <span className="fieldAvatarStyle">{data[0]?.slice(0, 1)}</span>}
        {showPrefix
          ? `${element?.prefix_data || ""} ${data.join(",")} ${element?.postfix_data || ""}`
          : data.join(",") || "-"}
      </p>
    );
  } else if (element.field_type === "selectboxes") {
    let newData = flattenArray(data);
    return (
      <div className="fieldDefaultClass">
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {newData && (
          <div className="chipDefaultContainer">
            {newData?.map((item, index) => (
              <p
                key={index}
                style={{
                  ...element?.inline_style,
                  ...colorObject
                }}
                className={`chipDefaultStyle ${classNameList}`}
              >
                {item}
              </p>
            ))}
          </div>
        )}
      </div>
    );
  } else if (element.field_type === "checkbox") {
    return (
      <p
        style={{
          ...element?.inline_style,
          ...colorObject
        }}
        className={`fieldDefaultClass ${element?.show_chips ? "fieldChipStyle" : null} ${classNameList}`}
      >
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {showPrefix
          ? `${element?.prefix_data || ""} ${data.join(",")} ${element?.postfix_data || ""}`
          : data.join(",") || "-"}
      </p>
    );
  } else if (element.field_type === "radio") {
    return (
      <p
        style={{
          ...element?.inline_style,
          ...colorObject
        }}
        className={`fieldDefaultClass ${element?.show_chips ? "fieldChipStyle" : null} ${classNameList}`}
      >
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {showPrefix
          ? `${element?.prefix_data || ""} ${data.join(",")} ${element?.postfix_data || ""}`
          : data.join(",") || "-"}
      </p>
    );
  } else if (element.field_type === "toggle") {
    return (
      <p
        style={{
          ...element?.inline_style,
          ...colorObject
        }}
        className={`fieldDefaultClass ${element?.show_chips ? "fieldChipStyle" : null} ${classNameList}`}
      >
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {showPrefix
          ? `${element?.prefix_data || ""} ${data.join(",")} ${element?.postfix_data || ""}`
          : data.join(",") || "-"}
      </p>
    );
  } else if (["multi-date"].includes(element.field_type)) {
    if (data.length !== 0) {
      let formattedDate = "";
      let newDateArray = data.flat() || [];
      for (let index = 0; index < newDateArray.length; index++) {
        const timestamp = newDateArray[index];
        if (timestamp !== "-") {
          try {
            formattedDate += `${formatTimestamp(timestamp, "date", element?.format || "DD-MON-YYYY")} ${
              index !== newDateArray.length - 1 ? ", " : ""
            }`;
          } catch (error) {
            formattedDate += "-";
          }
        } else {
          formattedDate += "-";
        }
      }
      if (formattedDate !== "-") {
        if (element?.prefix_data) {
          formattedDate = `${element?.prefix_data || ""} ${formattedDate}`;
        }
        if (element?.postfix_data) {
          formattedDate = ` ${formattedDate} ${element?.postfix_data || ""}`;
        }
      }
      return (
        <p
          style={{
            ...element?.inline_style,
            colorObjectlor: bgColor
          }}
          className={`fieldDefaultClass ${classNameList}`}
        >
          {element?.icon && <IconRender iconValue={element?.icon} />}
          {formattedDate}
        </p>
      );
    } else {
      return "-";
    }
  } else if (["date", "time", "datetime"].includes(element.field_type)) {
    if (data?.length !== 0) {
      let formattedDate = "";
      for (let dateIndex = 0; dateIndex < data.length; dateIndex++) {
        const timestamp = data[dateIndex];
        if (timestamp !== "-") {
          try {
            formattedDate += formatTimestamp(timestamp, element?.field_type, element?.format || "DD-MM-YYYY");
          } catch (error) {
            formattedDate += "-";
          }
        } else {
          formattedDate += "-";
        }
      }
      if (formattedDate !== "-") {
        if (element?.prefix_data) {
          formattedDate = `${element?.prefix_data || ""} ${formattedDate}`;
        }
        if (element?.postfix_data) {
          formattedDate = ` ${formattedDate} ${element?.postfix_data || ""}`;
        }
      }
      return (
        <p
          style={{
            ...element?.inline_style,
            colorObjectlor: bgColor
          }}
          className={`fieldDefaultClass ${classNameList}`}
        >
          {element?.icon && <IconRender iconValue={element?.icon} />}
          {formattedDate}
        </p>
      );
    } else {
      return (
        <p
          style={{
            ...element?.inline_style,
            colorObjectlor: bgColor
          }}
          className={`fieldDefaultClass ${classNameList}`}
        >
          {element?.icon && <IconRender iconValue={element?.icon} />}-
        </p>
      );
    }
  } else if (["file", "image"].includes(element.field_type)) {
    if (element?.media?.type === "image") {
      let imageFile = [];
      for (let s = 0; s < data.length; s++) {
        const element = data[s];
        if (typeof element === "object") {
          imageFile = [...imageFile, ...element];
        } else {
          imageFile.push(element);
        }
      }
      if (imageFile?.length !== 0) {
        return (
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            {imageFile?.map((img_src, img_index) => {
              if (["image/png", "image/jpeg", "image/jpg"].includes(img_src?.type)) {
                return (
                  <img
                    key={img_index}
                    style={{ width: "60px", height: "60px", ...element?.inline_style }}
                    src={`${getApiCallLocalPath()}api/v1/assets/fileName/${img_src?.name}`}
                    alt="img"
                    onClick={() => showPopupImagePreview(img_src)}
                    className={classNameList}
                  />
                );
              } else {
                if (img_src?.name) {
                  return (
                    <img
                      key={img_index}
                      style={{ height: "24px", width: "20px" }}
                      src={file_save}
                      alt={img_src?.name}
                      onClick={() => {
                        const isConfirmed = window.confirm("Please confirm for downloading file");
                        if (isConfirmed) {
                          window.open(`${getApiCallLocalPath()}api/v1/assets/fileName/${img_src?.name}`, "_blank");
                        }
                      }}
                    />
                  );
                } else {
                  return <div key={img_index}>-</div>;
                }
              }
            })}
          </div>
        );
      } else {
        return (
          <div>
            <img src={no_img} alt="img" style={{ width: "60px", height: "60px" }} />
          </div>
        );
      }
    } else {
      return <p>{element?.media?.type}</p>;
    }
  } else if (element.field_type === "id-gen") {
    return (
      <p
        style={{
          ...element?.inline_style,
          ...colorObject
        }}
        className={`fieldDefaultClass ${element?.show_chips ? "fieldChipStyle" : null} ${classNameList}`}
      >
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {element?.show_avatar && <span className="fieldAvatarStyle">{data[0]?.slice(0, 1)}</span>}
        {showPrefix
          ? `${element?.prefix_data || ""} ${data.join(",")} ${element?.postfix_data || ""}`
          : data.join(",") || "-"}
      </p>
    );
  } else if (element.field_type === "geolocation") {
    const lat = data?.[0]?.[0];
    const lng = data?.[0]?.[1];

    if (lat && lng) {
      return (
        <div
          style={{
            ...element?.inline_style,
            ...colorObject
          }}
          className={`fieldDefaultClass ${classNameList}`}
        >
          <MapPopup lat={lat} lng={lng} />
        </div>
      );
    } else {
      return (
        <p
          style={{
            ...element?.inline_style,
            ...colorObject
          }}
          className={`fieldDefaultClass ${classNameList}`}
        >
          -
        </p>
      );
    }
  } else if (element.field_type === "ratings") {
    return (
      <p
        style={{
          ...element?.inline_style,
          ...colorObject
        }}
        className={`fieldDefaultClass ${element?.show_chips ? "fieldChipStyle" : null} ${classNameList}`}
      >
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {showPrefix
          ? `${element?.prefix_data || ""} ${data.join(",")} ${element?.postfix_data || ""}`
          : data.join(",") || "-"}
      </p>
    );
  } else if (["recordset", "recordset-list", "grid"].includes(element.field_type)) {
    return (
      <div className="recordset_default_container">
        <RecordsetRenderer itemConfig={element} detailsViewData={record} />
      </div>
    );
  } else if (element.field_type === "learning-text") {
    return (
      <p
        style={{
          ...element?.inline_style,
          ...colorObject
        }}
        className={`fieldDefaultClass ${element?.show_chips ? "fieldChipStyle" : null} ${classNameList}`}
      >
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {element?.show_avatar && <span className="fieldAvatarStyle">{data[0]?.slice(0, 1)}</span>}
        {data.join(",") || "-"}
      </p>
    );
  } else if (element.field_type === "learning-tags") {
    let newData = flattenArray(data);
    return (
      <div className="fieldDefaultClass">
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {newData && (
          <div className="chipDefaultContainer">
            {newData?.map((item, index) => (
              <p
                key={index}
                style={{
                  ...element?.inline_style,
                  ...colorObject
                }}
                className={`chipDefaultStyle ${classNameList}`}
              >
                {item}
              </p>
            ))}
          </div>
        )}
      </div>
    );
  } else if (element.field_type === "select-chips") {
    let newData = flattenArray(data);
    return (
      <div className="fieldDefaultClass">
        {element?.icon && <IconRender iconValue={element?.icon} />}
        {newData && (
          <div className="chipDefaultContainer">
            {newData?.map((item, index) => (
              <p
                key={index}
                style={{
                  ...element?.inline_style,
                  ...colorObject
                }}
                className={`chipDefaultStyle ${classNameList}`}
              >
                {item}
              </p>
            ))}
          </div>
        )}
      </div>
    );
  } else {
    return <div className="fieldDefaultClass">{element.field_type}</div>;
  }
};

export default FieldType;

function flattenArray(arr) {
  let flatArray = [];
  arr.forEach((element) => {
    if (Array.isArray(element)) {
      flatArray = flatArray.concat(flattenArray(element));
    } else {
      flatArray.push(element);
    }
  });
  return flatArray;
}
