import React from "react";
import CardRenderer from "./card/CardRenderer";
import Collapse from "./collapse/collapse";

const SectionRenderer = ({
  itemConfig,
  detailsViewData,
  activityCardsConfig,
  detailsViewDataActivityCard,
  detailsViewDataTimeline,
  detailsViewDataActivitiesHistory,
  jobParamsConfig,
  detailsViewDataOrg,
  functionObject,
  detailsViewItemConfig
}) => {
  //classnames coming from builder for that element
  let class_name = "";
  if (itemConfig.className) {
    if (typeof itemConfig.className === "object") {
      class_name = Object.values(itemConfig.className).join(" ");
    } else {
      class_name = itemConfig.className;
    }
  }
  // let bodyClassName = "sectionStyle theme_section_style";
  if (itemConfig.collapse) {
    itemConfig.config.parent_className = "";
    itemConfig.config.height = itemConfig.height;
    return (
      <Collapse styleClassName={class_name} sectionTitle={itemConfig.display}>
        <CardRenderer
          repeatCard={itemConfig.repeat}
          configData={itemConfig.config}
          detailsViewData={detailsViewData}
          activityCardsConfig={activityCardsConfig}
          detailsViewDataActivityCard={detailsViewDataActivityCard}
          detailsViewDataTimeline={detailsViewDataTimeline}
          detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
          jobParamsConfig={jobParamsConfig}
          inline_style={itemConfig.inline_style}
          detailsViewDataOrg={detailsViewDataOrg}
          functionObject={functionObject}
          custom_css={itemConfig?.custom_css}
          detailsViewItemConfig={detailsViewItemConfig}
        />
      </Collapse>
    );
  } else {
    itemConfig.config.parent_className = "";
    return (
      <CardRenderer
        repeatCard={itemConfig.repeat}
        configData={itemConfig.config}
        detailsViewData={detailsViewData}
        activityCardsConfig={activityCardsConfig}
        detailsViewDataActivityCard={detailsViewDataActivityCard}
        detailsViewDataTimeline={detailsViewDataTimeline}
        detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
        jobParamsConfig={jobParamsConfig}
        inline_style={itemConfig.inline_style}
        detailsViewDataOrg={detailsViewDataOrg}
        functionObject={functionObject}
        custom_css={itemConfig?.custom_css}
        detailsViewItemConfig={detailsViewItemConfig}
      />
    );
  }
};

export default SectionRenderer;
