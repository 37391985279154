import React, { useEffect, useState } from "react";
import IconRender from "../components/IconRender";
import { useParams, useNavigate } from "react-router-dom";
const Footer = ({ footerConfig, selectedFooterModule }) => {
  const { pagename } = useParams();
  const navigate = useNavigate();
  const [FooterMenu, setFooterMenu] = useState([]);

  useEffect(() => {
    if (selectedFooterModule?.length > 0) {
      setFooterMenu(selectedFooterModule);
    } else {
      setFooterMenu(footerConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFooterModule]);

  const handleMenu = (pageName) => {
    navigate(`/${pageName}`);
  };
  return (
    <div>
      {FooterMenu?.length !== 0 ? (
        <div className="footerMenuBar">
          {FooterMenu?.length > 2
            ? FooterMenu?.map((menu, index) => (
                <div
                  key={index}
                  className={
                    pagename.toLowerCase() === menu.display.toLowerCase()
                      ? "header_drawer_menu header_drawer_menu_active_screen theme_menu_active_screen icon_menu"
                      : "header_drawer_menu theme_nav_menu_item icon_menu"
                  }
                  onClick={() => handleMenu(menu.display)}
                >
                  {menu.icon ? <IconRender iconValue={menu.icon} /> : menu.display === "home" ? "Home" : menu.display}
                </div>
              ))
            : FooterMenu?.map((menu, index) => (
                <div
                  key={index}
                  className={
                    pagename.toLowerCase() === menu.display.toLowerCase()
                      ? "header_drawer_menu header_drawer_menu_active_screen theme_menu_active_screen"
                      : "header_drawer_menu theme_nav_menu_item"
                  }
                  onClick={() => handleMenu(menu.display)}
                >
                  {menu.icon && <IconRender iconValue={menu.icon} />}
                  {menu.display === "home" ? "Home" : menu.display}
                </div>
              ))}
        </div>
      ) : null}
    </div>
  );
};

export default Footer;
