import {
  getMapviewTableData,
  setMapviewTableFPayloadData,
  setMapviewTableFilterData
} from "../../../redux/mapview/mapviewaction";
import { store } from "../../../redux/store";

export class commonFunction {
  static async callGetMapviewTableDataByFilter(namespace, inputArray) {
    let mapviewApiConfig = store.getState()["mapviewstore"][`${namespace}_mapviewApiConfig`] || {};
    let mapviewTableFilterData = store.getState()["mapviewstore"][`${namespace}_mapviewTableFilterData`] || {};
    let mapviewTableFPayloadData = store.getState()["mapviewstore"][`${namespace}_mapviewTableFPayloadData`];

    let mapviewTableFilter = JSON.parse(JSON.stringify(mapviewTableFilterData));
    mapviewTableFilter[inputArray[0]] = inputArray;

    let mapviewTableFPayload = JSON.parse(JSON.stringify(mapviewTableFPayloadData));
    let mapviewFilterArray = Object.values(mapviewTableFilter);
    let filter_by = [];
    for (let index = 0; index < mapviewFilterArray.length; index++) {
      const element = mapviewFilterArray[index];
      if (element[2]) filter_by.push(element[2]);
    }
    mapviewTableFPayload["filter_by"] = filter_by;
    mapviewTableFPayload["page"] = 1;
    let res = await store.dispatch(getMapviewTableData(namespace, mapviewApiConfig, mapviewTableFPayload));
    if (res) {
      store.dispatch(setMapviewTableFilterData(namespace, mapviewTableFilter));
      store.dispatch(setMapviewTableFPayloadData(namespace, mapviewTableFPayload));
    }
  }

  static async callGetMapviewTableDataByTerm(namespace, value) {
    let mapviewApiConfig = store.getState()["mapviewstore"][`${namespace}_mapviewApiConfig`] || {};
    let mapviewTableFPayloadData = store.getState()["mapviewstore"][`${namespace}_mapviewTableFPayloadData`];

    let mapviewTableFPayload = JSON.parse(JSON.stringify(mapviewTableFPayloadData));
    mapviewTableFPayload["term"] = value;

    let res = await store.dispatch(getMapviewTableData(namespace, mapviewApiConfig, mapviewTableFPayload));
    if (res) {
      store.dispatch(setMapviewTableFPayloadData(namespace, mapviewTableFPayload));
    }
  }

  static async callGetMapviewTableDataBySort(namespace, value) {
    let mapviewApiConfig = store.getState()["mapviewstore"][`${namespace}_mapviewApiConfig`] || {};
    let mapviewTableFPayloadData = store.getState()["mapviewstore"][`${namespace}_mapviewTableFPayloadData`];

    let mapviewTableFPayload = JSON.parse(JSON.stringify(mapviewTableFPayloadData));
    mapviewTableFPayload["sort_by"] = value;

    let res = await store.dispatch(getMapviewTableData(namespace, mapviewApiConfig, mapviewTableFPayload));
    if (res) {
      store.dispatch(setMapviewTableFPayloadData(namespace, mapviewTableFPayload));
    }
  }

  static changeData2DtoObjectArray(data) {
    const headers = data[0];
    const objects = data.slice(1).map((row) => {
      const obj = {};
      headers.forEach((header, index) => {
        obj[header] = row[index];
      });
      return obj;
    });

    return objects;
  }

  static changeDataHeaderArray(data) {
    if (!data) return undefined;
    let headerArray = [];
    for (let datIndex = 0; datIndex < data.length; datIndex++) {
      const element = data[datIndex];
      let obj = {
        type: element.type,
        custom_label: element.custom_label,
        data_type: element.data_type,
        id: element?.data_key || element?.job_id || "",
        label: element.display,
        locked: element?.locked || false
      };
      if (element.type === "field") {
        // obj.numeric = element.data_type === "number" ? "right" : "left";
        obj.numeric = "left";
        obj.sort = true;

        if (element.data_type === "file" || element.data_type === "image") {
          // obj.numeric = "center";
          obj.sort = false;
        }
      } else if (["custom", "job", "jobs"].includes(element.type)) {
        // obj.numeric = "center";
        obj.numeric = "left";
        obj.sort = false;
      }
      // } else if (element.type === "jobs") {
      //   obj.numeric = "center";
      //   obj.sort = false;
      // }

      headerArray.push(obj);
    }
    return headerArray;
  }
}
