import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import axios from "axios";
import toast from "react-hot-toast";

import { usePageHandlerContext } from "../pagehandler/PageHandlerContext";
import { getNotificationsFunction } from "../redux/appview/appviewaction";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../utils/apiCallFunction";
import { getApiCallHeadersData } from "../utils/storageFunction";

function NotificationPopup({ setShowNotificationPopup }) {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const notificationsArray = useSelector((state) => state.appviewstore.notificationsArray);
  const { createNewTask } = usePageHandlerContext();

  const openTask = async (config) => {
    try {
      await axios.put(
        `${getApiCallLocalPath()}api/v1/todo/update-status/${config.id}`,
        {},
        {
          headers: getApiCallHeadersData()
        }
      );

      dispatch(getNotificationsFunction(setIsLoading));
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };

  function handleActivity(config) {
    const element_config = {
      element_id: config?._id || "",
      action_start: {
        hide: [],
        refresh: [],
        show: []
      },
      action_end: {
        hide: [],
        refresh: [],
        show: []
      },
      callback_function: [],
      callback_function_after: [
        () => {
          openTask(config);
        }
      ]
    };
    const action_config = {
      action_in: "perform_activity",
      action_name: "perform_activity",
      sm_id: config?.sm_id,
      params: {
        activity_id: config?.activity_id,
        variable_mapping: []
      },
      instance_id: config?.sm_instance_id
    };
    createNewTask(element_config, action_config);
  }

  return (
    <div className="popup_outer_container">
      <div className="popup_inner_container">
        <div className="popup_header_container">
          <p className="popup_title">Notificatons</p>
          <span className="material-symbols-outlined popup_close_icon" onClick={() => setShowNotificationPopup(false)}>
            close
          </span>
        </div>
        <div className="popup_body_container">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
                height: "150px"
              }}
            >
              <div className="zino_renderer_loader_box" style={{ width: "50px", height: "50px" }}>
                <span className="zino_renderer_loader"></span>
              </div>
            </div>
          ) : notificationsArray && notificationsArray?.length !== 0 ? (
            <div className="notification_all_list">
              {notificationsArray?.map((notification, index) => (
                <div key={index} onClick={() => handleActivity(notification)} className="notification_container">
                  <div className="table_avatar_text" style={{ backgroundColor: "#667085" }}>
                    {notification?.owner_name?.trim()[0].toUpperCase()}
                  </div>

                  <div className="d_flex" style={{ marginLeft: "10px" }}>
                    <p className="notification_content">{notification?.notification_text || "No Message"}</p>
                    {notification?.data && <p className="notification_content">{notification?.data}</p>}
                    <p className="notification_time">{moment(notification?.created_at).fromNow()}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : notificationsArray && notificationsArray?.length === 0 ? (
            <p className="no_notifications_message">No new notifications at the moment.</p>
          ) : (
            <div>
              <p>loading...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NotificationPopup;
