import { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { removeStorageItem } from "../utils/storageFunction";
import { toastErrorMessageStyle, toastSuccessMessageStyle } from "../utils/apiCallFunction";

const useAxios = (baseUrl) => {
  const [url, setUrl] = useState(baseUrl);

  const axiosInstance = axios.create({
    baseURL: url + "/api/v1",
    timeout: 20000
    // headers: {
    //   Authorization: `Bearer ${getStorageItem("Zino_app_user")}`,
    //   accept: "application/octet-stream"
    // }
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.data) {
        return response.data;
      }

      return response;
    },
    (error) => {
      throw error;
    }
  );

  const handleError = (error) => {
    console.error(error);

    if (error?.response?.data?.code === 401) {
      removeStorageItem("zino_app_user");
      window.open("/", "_self");
    }

    if (error?.name !== "CanceledError") {
      let message = "";

      if (error?.response?.data?.message) {
        message = error.response.data.message;
      } else if (typeof error === "string") {
        message = error;
      } else {
        message = "Something went wrong";
      }

      toast.error(message, toastErrorMessageStyle());
    }
  };

  const handleSuccess = (message) => {
    toast.success(message, toastSuccessMessageStyle());
  };

  return { axiosInstance, handleSuccess, handleError, setUrl };
};

export default useAxios;
