import React from "react";
import "./TableViewRenderer.css";
import { TableHead } from "../components/TableHead";
import { TableBody } from "../components/TableBody";

const TableViewRenderer = ({ dataConfig = {}, tableConfig = {}, detailsViewData }) => {
  let { headerData = [], bodyData = [] } = dataConfig;
  let class_name = "";
  if (tableConfig.className) {
    if (typeof tableConfig.className === "object") {
      class_name = Object.values(tableConfig.className).join(" ");
    } else {
      class_name = tableConfig.className;
    }
  }

  class_name = class_name + " " + tableConfig.table_type;
  return (
    <div className="details_block_outer">
      <div className="tableview_tablebox_overflowbox static_field_blocks">
        <div className={tableConfig?.className || "recordview_table_title"}>{tableConfig?.name}</div>
        <table className={"tableview_tablebox" + class_name}>
          {headerData.length > 0 && tableConfig?.header && (
            <TableHead tableData={headerData} borderFlag={tableConfig.border} />
          )}
          <TableBody tableBody={bodyData} tableData={detailsViewData} borderFlag={tableConfig.border} />
        </table>
      </div>
    </div>
  );
};

export default TableViewRenderer;
