import React from "react";
import ChartField, { SparkChartField } from "./ChartField";
import { CircularProgressSvg } from "../../../zinoIcon";
import { usePageHandlerContext } from "../../../../pagehandler/PageHandlerContext";
import NoDataImage from "../../../../assets/images/nodataImage.png";
import NumberFormater from "../../../components/NumberFormater";

function Chart({ chartConfig, seriesData, chartValues }) {
  const { createNewTask } = usePageHandlerContext();
  if (seriesData) {
    if (chartConfig["chart_type"] === "table") {
      const handleJobCallFunction = (value) => {
        let field_action = chartConfig.add_event_config;
        if (!field_action || Object.keys(field_action).length === 0) {
          return;
        }

        let instanceIdIs = null;

        let element_config = {
          element_id: chartConfig.id,
          action_start: field_action.action_start,
          action_end: field_action?.action_end,
          callback_function: [],
          callback_function_after: []
        };
        if (!field_action.params) {
          field_action.params = {};
        }
        let action_config = {
          action_in: field_action?.job_template,
          navigate_to: field_action?.params?.name,
          action_data: field_action?.action_data,
          action_name: field_action?.job_template,
          params: field_action?.params || {},
          instance_id: instanceIdIs || null,
          ...field_action.params
        };

        createNewTask(element_config, action_config, {
          instance_id: instanceIdIs || null,
          [chartConfig.xaxis_title]: value[0],
          [chartConfig.yaxis_title]: value[1]
        });
      };

      if (chartConfig["type"] === "2d") {
        return (
          <>
            <h4 style={{ textAlign: "center" }}>{chartConfig["title"]}</h4>
            <p style={{ textAlign: "center" }}>{chartConfig["sub_title"]}</p>
            {seriesData?.length > 0 ? (
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead style={{ backgroundColor: "#e8e7e7" }}>
                  <tr>
                    <th style={{ border: "1px solid #dadce0", padding: "8px", textAlign: "center", fontWeight: 500 }}>
                      {chartConfig.xaxis_title}
                    </th>
                    <th style={{ border: "1px solid #dadce0", padding: "8px", textAlign: "center", fontWeight: 500 }}>
                      {chartConfig.yaxis_title}
                    </th>
                    {Object.keys(chartConfig?.add_event_config).length > 0 && (
                      <th style={{ border: "1px solid #dadce0", padding: "8px", textAlign: "center" }}></th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {seriesData.map((data) => (
                    <tr key={data[0]}>
                      <td style={{ border: "1px solid #dadce0", padding: "8px", textAlign: "left" }}>
                        {data[0] || "--"}
                      </td>
                      <td style={{ border: "1px solid #dadce0", padding: "8px", textAlign: "center" }}>
                        {data[1] || 0}
                      </td>
                      {Object.keys(chartConfig?.add_event_config).length > 0 && (
                        <td
                          onClick={() => handleJobCallFunction(data)}
                          style={{
                            border: "1px solid #dadce0",
                            padding: "8px",
                            textAlign: "center",
                            wordBreak: "keep-all"
                          }}
                        >
                          <span className="material-symbols-outlined">chevron_right</span>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="noDataImageContainer">
                <img src={NoDataImage} alt="img not found" />
              </div>
            )}
          </>
        );
      } else if (chartConfig["type"] === "3d") {
        return (
          <div style={{ overflow: "hidden" }}>
            <h4 style={{ textAlign: "center" }}>{chartConfig["title"]}</h4>
            <p style={{ textAlign: "center" }}>{chartConfig["sub_title"]}</p>
            <div style={{ width: "100%", overflowX: "scroll" }}>
              {seriesData?.length > 0 ? (
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                  <thead style={{ backgroundColor: "#e8e7e7" }}>
                    <tr>
                      {seriesData?.map((series, index) => {
                        if (index === 0) {
                          return (
                            <th
                              style={{
                                border: "1px solid #dadce0",
                                padding: "8px",
                                textAlign: "center",
                                fontWeight: 500,
                                wordBreak: "keep-all"
                              }}
                            >
                              {chartConfig?.xaxis_title || "--"}
                            </th>
                          );
                        } else {
                          return (
                            <th
                              style={{
                                border: "1px solid #dadce0",
                                padding: "8px",
                                textAlign: "center",
                                fontWeight: 500,
                                wordBreak: "keep-all"
                              }}
                            >
                              {series[0]}
                            </th>
                          );
                        }
                      })}
                      {Object.keys(chartConfig?.add_event_config).length > 0 && (
                        <th
                          style={{ border: "1px solid #dadce0", padding: "8px", textAlign: "center", minWidth: "50px" }}
                        ></th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {seriesData[0]?.map((rows, rowIndex) => {
                      if (rowIndex !== 0) {
                        return (
                          <tr key={`row_${rowIndex}`}>
                            <td
                              style={{
                                border: "1px solid #dadce0",
                                padding: "8px",
                                textAlign: "left",
                                wordBreak: "keep-all"
                              }}
                            >
                              {rows}
                            </td>
                            {seriesData?.map((dataArray, dataIndex) => {
                              if (dataIndex !== 0) {
                                return (
                                  <td
                                    style={{
                                      border: "1px solid #dadce0",
                                      padding: "8px",
                                      textAlign: "center",
                                      wordBreak: "keep-all"
                                    }}
                                    key={dataIndex}
                                  >
                                    {dataArray[rowIndex]}
                                  </td>
                                );
                              } else {
                                return null;
                              }
                            })}
                            {Object.keys(chartConfig?.add_event_config).length > 0 && (
                              <td
                                onClick={() => handleJobCallFunction([rows])}
                                style={{
                                  border: "1px solid #dadce0",
                                  padding: "8px",
                                  textAlign: "center",
                                  wordBreak: "keep-all"
                                }}
                              >
                                <span className="material-symbols-outlined">chevron_right</span>
                              </td>
                            )}
                          </tr>
                        );
                      } else return null;
                    })}
                  </tbody>
                </table>
              ) : (
                <div className="noDataImageContainer">
                  <img src={NoDataImage} alt="img not found" />
                </div>
              )}
            </div>
          </div>
        );
      }
    }

    let config = {};
    if (["pie", "bar", "line", "donut"].includes(chartConfig["chart_type"])) {
      config = {
        title: { text: chartConfig["title"] },
        subtitle: { text: chartConfig["sub_title"] }
      };

      let data = [],
        label = [];
      if (chartConfig["type"] === "2d") {
        data = [];
        label = [];
        for (let i = 0; i < seriesData.length; i++) {
          label.push(seriesData[i][0]);
          data.push(seriesData[i][1]);
        }
      } else if (chartConfig["type"] === "3d") {
        data = [];
        label = [...seriesData[0]];
        label.shift();

        for (let i = 1; i < seriesData.length; i++) {
          let name = seriesData[i][0];
          let array = [...seriesData[i]];
          array.shift();
          data.push({ name: name, turboThreshold: 0, data: array });
        }
      }

      if (chartConfig["type"] === "2d") {
        config.series = [{ name: chartConfig["data_label"], turboThreshold: 0, data: data }];
        config.xAxis = {
          categories: label,
          title: { text: chartConfig["xaxis_title"] }
        };
        config.yAxis = { title: { text: chartConfig["yaxis_title"] } };
      } else if (chartConfig["type"] === "3d") {
        config.series = data;
        config.xAxis = {
          categories: label,
          title: { text: chartConfig["xaxis_title"] }
        };
        config.yAxis = { title: { text: chartConfig["yaxis_title"] } };
      }

      if (chartConfig["chart_type"] === "pie") {
        config["chart"] = { type: "pie", polar: false };
        config["plotOptions"] = {
          pie: {
            allowPointSelect: true,
            cursor: true,
            showInLegend: true,
            dataLabels: { enabled: false }
          },
          series: { animation: true }
        };

        config.series = [{ name: chartConfig["data_label"], turboThreshold: 0, data: seriesData }];
        config.xAxis = {
          categories: label,
          title: { text: chartConfig["xaxis_title"] }
        };
        config.yAxis = { title: { text: chartConfig["yaxis_title"] } };
      } else if (chartConfig["chart_type"] === "donut") {
        config["chart"] = { type: "pie", polar: false };
        config["plotOptions"] = {
          pie: {
            innerSize: "60%",
            allowPointSelect: true,
            cursor: true,
            showInLegend: true,
            dataLabels: { enabled: false }
          },
          series: { animation: true }
        };

        config.series = [{ name: chartConfig["data_label"], turboThreshold: 0, data: seriesData }];
        config.xAxis = {
          categories: label,
          title: { text: chartConfig["xaxis_title"] }
        };
        config.yAxis = { title: { text: chartConfig["yaxis_title"] } };
      } else if (chartConfig["chart_type"] === "bar") {
        config["chart"] = {
          type: "column",
          inverted: chartConfig["axis_inverted"] || false,
          polar: false
        };
        config["plotOptions"] = { series: { animation: true } };
      } else if (chartConfig["chart_type"] === "line") {
        config["chart"] = {
          type: "line",
          inverted: chartConfig["axis_inverted"] || false
        };
        config["plotOptions"] = { series: { animation: true } };
      }
    } else if (["spark_pie", "spark_line", "spark_area", "spark_column"].includes(chartConfig["chart_type"])) {
      let chart_styles = chartConfig?.chart_styles;

      config = {
        chart: {
          type: "area",
          borderWidth: 0,
          height: (1 / 2) * 100 + "%",
          style: {
            overflow: "visible"
          },
          skipClone: true
        },
        title: {
          text: ""
        },
        exporting: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        xAxis: {
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          lineWidth: 0,
          startOnTick: false,
          endOnTick: false,
          tickPositions: []
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          tickPositions: [0]
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: true
          },
          column: {
            negativeColor: "#910000"
          }
        },
        series: [
          {
            name: chartConfig["data_label"],
            color: "rgba(76, 175, 254, 0.6)",
            data: seriesData,
            marker: { enabled: false }
          }
        ]
      };

      if (chartConfig["chart_type"] === "spark_pie") {
        config["chart"]["type"] = "pie";
        config["plotOptions"] = {
          pie: {
            allowPointSelect: false,
            cursor: "pointer",
            dataLabels: {
              enabled: false
            },
            showInLegend: false
          },
          series: {
            animation: true,
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            marker: {
              radius: 1,
              states: {
                hover: {
                  radius: 2
                }
              }
            },
            fillOpacity: 0.25
          },
          column: {
            negativeColor: "#910000",
            borderColor: "silver"
          }
        };
      } else if (chartConfig["chart_type"] === "spark_line") {
        config["chart"]["type"] = "line";
        config["chart"]["inverted"] = chartConfig["axis_inverted"] || false;
      } else if (chartConfig["chart_type"] === "spark_area") {
        config["chart"]["type"] = "area";
        config["chart"]["inverted"] = chartConfig["axis_inverted"] || false;
        config["series"] = [
          {
            data: seriesData,
            name: chartConfig["data_label"],
            marker: { enabled: false },
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, "rgba(76, 175, 254, 0.6)"],
                [1, "rgba(76, 175, 254, 0)"]
              ]
            }
          }
        ];
      } else if (chartConfig["chart_type"] === "spark_column") {
        config["chart"]["type"] = "column";
        config["chart"]["inverted"] = chartConfig["axis_inverted"] || false;
      }

      return (
        <div className="spark_chart">
          {chartConfig["title"] && (
            <p
              style={{
                textAlign: "left",
                fontSize: "14px",
                paddingLeft: "8px",
                fontWeight: "500",
                color: "#10182b",
                wordBreak: "break-word",
                ...(chart_styles?.title_css || {})
              }}
            >
              {chartConfig["title"]}
            </p>
          )}
          {typeof chartValues[chartConfig["id"]] !== "undefined" && (
            <p
              style={{
                textAlign: "left",
                fontSize: "20px",
                paddingLeft: "8px",
                fontWeight: "600",
                ...(chart_styles?.sub_title_css || {})
              }}
            >
              <NumberFormater
                number={chartValues[chartConfig["id"]]}
                type={chartConfig.number_format}
                curr_icon={chartConfig.currency_code}
              />
            </p>
          )}
          <SparkChartField config={config} />
        </div>
      );
    } else {
      return <></>;
    }

    return <ChartField config={config} />;
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "200px"
        }}
      >
        <CircularProgressSvg />
      </div>
    );
  }
}

export default Chart;
