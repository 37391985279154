import React from "react";
import IconRender from "../../../components/IconRender";
import { formatTimestamp } from "../../utils/commonFunction";
import { getDataFromDataObjectByKey } from "../../../utils/updateConfigFunction";
import NumberFormater from "../../components/NumberFormater";

export const TableBody = ({ tableBody, tableData, borderFlag }) => {
  return (
    <tbody>
      {tableBody.map((row, index) => {
        return (
          <tr key={index} style={{ borderWidth: borderFlag ? "1px" : "0" }}>
            {row.map((itemIs, index) => {
              let dataIs = [];
              itemIs?.data_key && getDataFromDataObjectByKey(tableData || {}, dataIs, itemIs?.data_key || "");
              if (itemIs.data_type === "text") {
                return (
                  <td
                    key={index}
                    align={"left"}
                    style={{ borderWidth: borderFlag ? "1px" : "0" }}
                    className="tableContext"
                  >
                    {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
                    {itemIs.data || dataIs.join(", ") || "-"}
                  </td>
                );
              } else if (itemIs.data_type === "number") {
                //formatting value according to selected value type
                // function formatValue(formatType, number) {
                //   if (number !== "-") {
                //     if (formatType === "rupee") {
                //       const rupeeFormatter = new Intl.NumberFormat("en-IN", {
                //         style: "currency",
                //         currency: "INR"
                //       });
                //       return rupeeFormatter.format(number);
                //     } else if (formatType === "dollar") {
                //       const dollarFormatter = new Intl.NumberFormat("en-US", {
                //         style: "currency",
                //         currency: "USD"
                //       });
                //       return dollarFormatter.format(number);
                //     } else if (formatType === "percentage") {
                //       return `${number}%`;
                //     } else {
                //       return number;
                //     }
                //   } else {
                //     return "-";
                //   }
                // }
                return (
                  <td
                    key={index}
                    align={"left"}
                    className="tableContext"
                    style={{ borderWidth: borderFlag ? "1px" : "0" }}
                  >
                    {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
                    {dataIs?.length > 0
                      ? dataIs?.map((num, numindex) => (
                          <NumberFormater
                            key={numindex}
                            number={num !== "-" ? num : 0}
                            type={itemIs?.format}
                            curr_icon={itemIs?.currency_code}
                          />
                          // <React.Fragment key={numindex}>{formatValue(itemIs?.format, num)}</React.Fragment>
                        ))
                      : "-"}
                  </td>
                );
              } else if (itemIs.data_type === "currency") {
                const formatValue = (currencyObject) => {
                  if (typeof currencyObject === "object") {
                    const formatter = new Intl.NumberFormat(undefined, {
                      style: "currency",
                      currency: currencyObject?.currency
                    });
                    return formatter.format(currencyObject?.value);
                  } else {
                    return "-";
                  }
                };
                return (
                  <td
                    key={index}
                    align={"left"}
                    className="tableContext"
                    style={{ borderWidth: borderFlag ? "1px" : "0" }}
                  >
                    {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
                    {dataIs?.length > 0
                      ? dataIs?.map((num, numindex) => (
                          <React.Fragment key={numindex}>{formatValue(num)}</React.Fragment>
                        ))
                      : "-"}
                  </td>
                );
              } else if (
                itemIs.data_type === "date" ||
                itemIs.data_type === "time" ||
                itemIs.data_type === "datetime"
              ) {
                if (dataIs?.length > 0) {
                  let formattedDate = "";
                  for (let iIs = 0; iIs < dataIs?.length; iIs++) {
                    const dateItem = dataIs[iIs];
                    if (dateItem) {
                      try {
                        formattedDate += formatTimestamp(dateItem, itemIs.data_type, itemIs.format || "DD-MM-YYYY");
                      } catch (error) {
                        formattedDate += "-";
                      }
                    }
                  }
                  return (
                    <td
                      key={index}
                      align={"left"}
                      className="tableContext"
                      style={{ borderWidth: borderFlag ? "1px" : "0" }}
                    >
                      {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
                      {formattedDate || "-"}
                    </td>
                  );
                } else {
                  return (
                    <td
                      key={index}
                      align={"left"}
                      style={{ borderWidth: borderFlag ? "1px" : "0" }}
                      className="tableContext"
                    >
                      {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
                      {"-"}
                    </td>
                  );
                }
              } else {
                return (
                  <td key={index} style={{ borderWidth: borderFlag ? "1px" : "0" }} className="tableContext">
                    {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
                    {dataIs.join(", ") || itemIs.data || "-"}
                  </td>
                );
              }
            })}
          </tr>
        );
      })}
    </tbody>
  );
};
