import React from "react";
import Select from "react-select";
import { store } from "../../../redux/store";
import { getCalendarFilterData } from "../../../redux/calendarview/calendarviewaction";
import { useLocation } from "react-router-dom";

const CalendarDropDownFilter = ({ namespace, filter }) => {
  const location = useLocation();
  const screen_name = location.pathname;
  const calendarViewFilterData = store.getState()["calendarviewstore"]?.[`${namespace}_calendarFilterData`];
  const calendarViewLayoutData = store.getState()["calendarviewstore"]?.[`${namespace}_calendarLayoutData`];
  const calendarViewFilterPayloadData = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}_filters`))?.params
    ?.search_query?.filter_by;

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: "8rem",
      fontSize: "12px"
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "12px"
    })
  };

  function handleOptions() {
    let filterOptions = [];
    calendarViewFilterData?.facets?.forEach((obj) => {
      if (obj?.id === filter?.id) {
        filterOptions = obj.facet_values || [];
      }
    });
    return filterOptions;
  }

  const checkboxOptions = [
    { label: "Yes", value: "istrue" },
    { label: "No", value: "isfalse" }
  ];

  function handleOptionChange(option) {
    let temp_filter =
      filter?.field_type === "checkbox"
        ? {
            id: filter?.id,
            operator: option?.value
          }
        : {
            id: filter?.id,
            operator: "is",
            text_value: option?.value
          };
    let previousFiltersArray = calendarViewFilterPayloadData;
    let newFilterArray = previousFiltersArray?.filter((obj) => obj?.id !== filter?.id);
    if (option?.value) {
      newFilterArray.push(temp_filter);
    }
    store.dispatch(
      getCalendarFilterData({
        namespace: namespace,
        filter_config: calendarViewLayoutData?.data?.subject_filter,
        filter_by: newFilterArray,
        facet_by: [calendarViewLayoutData?.data?.subject_field?.id?.split(".")[1]],
        screen_name: screen_name
      })
    );
  }

  function handlePrevOptions() {
    const options = handleOptions();
    let value =
      options?.find(
        (option) =>
          option.value === calendarViewFilterPayloadData.find((prevFilter) => prevFilter.id === filter.id)?.text_value
      ) || null;
    return value;
  }

  function handleCheckboxOptions() {
    let value =
      checkboxOptions?.find(
        (option) =>
          option.value === calendarViewFilterPayloadData.find((prevFilter) => prevFilter.id === filter.id)?.operator
      ) || null;
    return value;
  }

  switch (filter?.field_type) {
    case "textfield":
    case "select":
    case "radio":
    case "email":
    case "learning-text":
      return (
        <div className="filterContainer">
          <p>{filter?.display}</p>
          {handleOptions()?.length !== 0 ? (
            <Select
              styles={customStyles}
              placeholder={"select"}
              options={handleOptions()}
              value={handlePrevOptions()}
              getOptionLabel={(option) => option?.value}
              getOptionValue={(option) => option?.value}
              isClearable
              isSearchable
              onChange={(option) => handleOptionChange(option)}
            />
          ) : (
            <Select styles={customStyles} placeholder={"select"} />
          )}
        </div>
      );
    case "checkbox":
      return (
        <div className="filterContainer">
          <p>{filter?.display}</p>
          <Select
            styles={customStyles}
            placeholder={"select"}
            options={checkboxOptions}
            value={handleCheckboxOptions()}
            onChange={(option) => handleOptionChange(option)}
            isClearable
          />
        </div>
      );
    default:
      return null;
  }
};

export default CalendarDropDownFilter;
