import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { getDashboardConfigFunction, setSelectedApp } from "../redux/appview/appviewaction";

import Header from "../components/Header";
import Pageview from "../components/Pageview";

import ProcessTaskComponent from "../pagehandler/ProcessTaskComponent";
import { getStorageItem } from "../utils/storageFunction";

import toast from "react-hot-toast";
import { toastErrorMessageStyle } from "../utils/apiCallFunction";
import { injectCSS, injectJS, waitforsometime } from "../utils/loadfiles";
import Footer from "../components/Footer";

import { useCartContext } from "../applicationcontext/CartContext";
import { usePageHandlerContext } from "../pagehandler/PageHandlerContext";

const PageDashboard = () => {
  const { pagename } = useParams();
  const { dashboardAppConfig } = useSelector((state) => state.appviewstore);
  const { cacheProcessData } = usePageHandlerContext();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { fetchCart } = useCartContext();

  const [pageLoadFlag, setPageLoadFlag] = useState(false);
  const [pageExtraJSLoad, setPageExtraJSLoad] = useState(false);
  const [pageExtraCSSLoad, setPageExtraCSSLoad] = useState(false);
  const [selectedFooterModule, setSelectedFooterModule] = useState([]);

  const getUserMetadata = async () => {
    setPageLoadFlag(true);
    await dispatch(getDashboardConfigFunction(pagename));
    dispatch(setSelectedApp(JSON.parse(getStorageItem("selectedApp")) || {}));
    setPageLoadFlag(false);
  };

  const handleFetchCart = async () => {
    try {
      const payload = {
        function_name: "get_cart",
        data: {},
        params: {
          entity_id: ""
        }
      };
      await fetchCart(payload);
    } catch (error) {
      toast.error("something went wrong", toastErrorMessageStyle());
    }
  };

  useEffect(() => {
    handleFetchCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const user = urlParams.get("user");
    if (user) {
      navigate(`/${dashboardAppConfig?.header?.menu?.[0]?.display || "apps menu"}`);
      getUserMetadata();
    } else {
      getUserMetadata();
    }
    setPageExtraJSLoad(true);
    setPageExtraCSSLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagename]);

  useEffect(() => {
    if (
      Object.keys(dashboardAppConfig).length > 0 &&
      typeof dashboardAppConfig.is_access !== "undefined" &&
      !dashboardAppConfig.is_access
    ) {
      if (dashboardAppConfig?.header?.menu.length === 0) {
        toast.error("Screens not found", toastErrorMessageStyle());
      }
      navigate(`/${dashboardAppConfig?.page_name || "apps menu"}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardAppConfig]);

  useEffect(() => {
    if (Object.keys(dashboardAppConfig).length > 0) {
      loadExtraCssAndJs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardAppConfig]);

  // load_extra_js
  const loadExtraCssAndJs = async () => {
    if (pageExtraCSSLoad) {
      let css = dashboardAppConfig.custom_css || "";
      injectCSS(css);
      setPageExtraCSSLoad(false);
    }

    if (pageExtraJSLoad) {
      await waitforsometime(500);
      let js = dashboardAppConfig.custom_js || "";
      injectJS(js);
      setPageExtraJSLoad(false);
    }
  };

  if (pageLoadFlag || pageExtraCSSLoad || pageExtraJSLoad) {
    return (
      <div className="zino_renderer_loader_box">
        <span className="zino_renderer_loader"></span>
      </div>
    );
  }

  return (
    <>
      <div>
        {dashboardAppConfig?.header && (
          <div>
            <Header headerConfig={dashboardAppConfig.header} setSelectedFooterModule={setSelectedFooterModule} />
          </div>
        )}
        {dashboardAppConfig?.page && (
          <div
            style={{
              marginTop: "56px",
              marginBottom: `${dashboardAppConfig?.header?.menu_bottom?.length > 0 ? "46px" : "0px"}`
            }}
          >
            <Pageview
              pageConfig={dashboardAppConfig.page}
              jobParamsTokenConfig={cacheProcessData?.value || {}}
              functionObject={{ reloadFunction: getUserMetadata }}
            />
          </div>
        )}
        {dashboardAppConfig?.header?.menu_bottom?.length !== 0 || selectedFooterModule?.length > 0 ? (
          <div className="fixedFooter">
            <Footer
              footerConfig={dashboardAppConfig?.header?.menu_bottom}
              selectedFooterModule={selectedFooterModule}
            />
          </div>
        ) : null}
      </div>
      <ProcessTaskComponent />
    </>
  );
};

export default PageDashboard;
