import { getStorageItem, getApiCallHeadersData } from "../../../utils/storageFunction";

export const formDataPrefill = (
  prefillDataObject,
  prefillErrorObject,
  formConfig,
  flag = "inside",
  masterData = {},
  parentField = "form",
  recordset_list_flagObject = {}
) => {
  let formFillData = JSON.parse(getStorageItem(formConfig.local_storage_key));
  let localStoreDataIs = {};
  if (flag === "outside") {
    localStoreDataIs = formFillData?.["form_data"] || {};
  }
  if (flag === "reset") {
    flag = "outside";
  }
  let newFormData = {};

  for (let i = 0; i < formConfig.sections.length; i++) {
    const sectionObject = formConfig.sections[i];
    const componentsArray = sectionObject?.components || [];
    for (let j = 0; j < componentsArray.length; j++) {
      const componentObject = componentsArray[j];
      setDataInFormKeys(
        formConfig,
        masterData,
        componentObject.elements,
        prefillDataObject,
        prefillErrorObject?.[sectionObject.id] || {},
        localStoreDataIs?.[sectionObject.id] || {},
        newFormData,
        {
          parent_type: "section",
          parent_id: sectionObject.id,
          action_call: true
        },
        flag,
        parentField,
        recordset_list_flagObject
      );
    }
  }

  return newFormData;
};

export const setDataInFormKeys = (
  formConfig,
  masterData,
  formArray,
  formPreFillData = {},
  prefillErrorObject = {},
  localStoreDataIs = {},
  createNewFormData,
  parentObject,
  flag,
  parentField,
  recordset_list_flagObject
) => {
  let newFormData = createNewFormData;
  if (newFormData[parentObject.parent_id]) {
    newFormData = newFormData[parentObject.parent_id];
  } else {
    newFormData[parentObject.parent_id] = {
      errors: []
    };
    if (parentObject.parent_type === "section") {
      newFormData[parentObject.parent_id] = {
        errorCount: 0
      };
    }
    newFormData = newFormData[parentObject.parent_id];
  }
  for (let formIndex = formArray.length - 1; formIndex >= 0; formIndex--) {
    if (["empty", "button", "label", "content", "spacer", "seperator"].includes(formArray[formIndex].element_type))
      continue;

    const colItem = formArray[formIndex].field;
    if (
      [
        "disabled",
        "textfield",
        "email",
        "number",
        "password",
        "textarea",
        "select",
        "radio",
        "ratings",
        "id-gen",
        "learning-text",
        "learning-tags"
      ].includes(colItem.type)
    ) {
      let inputValue = formPreFillData?.[colItem.id];
      let inputError = prefillErrorObject?.[colItem.id] || [];

      if (inputValue !== undefined && inputValue !== null) {
        newFormData[colItem.id] = {
          value: inputValue,
          errors: inputError || []
        };
      } else if (inputError.length > 0) {
        newFormData[colItem.id] = {
          errors: inputError || []
        };
      } else {
        inputValue = localStoreDataIs?.[colItem.id];
        if (inputValue) {
          if (inputValue.value || inputValue?.errors?.length > 0) newFormData[colItem.id] = inputValue;
        }
      }
      if (
        flag === "outside" &&
        parentObject.action_call &&
        !["undefined"].includes(typeof newFormData?.[colItem.id]?.["value"])
      ) {
        customFunForFieldShowHide(colItem, newFormData[colItem.id]["value"], formConfig, masterData);
      }
    } else if (["geolocation"].includes(colItem.type)) {
      let inputValue = formPreFillData?.[colItem.id];
      let inputError = prefillErrorObject?.[colItem.id] || [];

      if (inputValue !== undefined && inputValue !== null) {
        newFormData[colItem.id] = {
          value: inputValue,
          errors: inputError || []
        };
      } else if (inputError.length > 0) {
        newFormData[colItem.id] = {
          errors: inputError || []
        };
      } else {
        inputValue = localStoreDataIs?.[colItem.id];
        if (inputValue) {
          if (inputValue.value || inputValue?.errors?.length > 0) newFormData[colItem.id] = inputValue;
        }
      }
    } else if (["checkbox", "toggle"].includes(colItem.type)) {
      let inputValue = formPreFillData?.[colItem.id];
      let inputError = prefillErrorObject?.[colItem.id] || [];

      if (inputValue) {
        newFormData[colItem.id] = {
          value: inputValue,
          errors: inputError || []
        };
      } else if (inputError.length > 0) {
        newFormData[colItem.id] = {
          errors: inputError || []
        };
      } else {
        inputValue = localStoreDataIs?.[colItem.id];
        if (inputValue) {
          if (inputValue.value || inputValue?.errors?.length > 0) newFormData[colItem.id] = inputValue;
        }
      }
      if (
        flag === "outside" &&
        parentObject.action_call &&
        !["undefined"].includes(typeof newFormData?.[colItem.id]?.["value"])
      ) {
        customFunForFieldShowHide(colItem, newFormData[colItem.id]["value"], formConfig, masterData);
      }
    } else if (["recordset", "container"].includes(colItem.type)) {
      let inputValue = formPreFillData?.[colItem.id];
      if (inputValue) {
        setDataInFormKeys(
          formConfig,
          masterData,
          colItem.components,
          formPreFillData?.[colItem.id],
          prefillErrorObject?.[colItem.id],
          localStoreDataIs?.[colItem.id],
          newFormData,
          {
            parent_type: colItem.type,
            parent_id: colItem.id,
            action_call: true
          },
          flag,
          parentField,
          recordset_list_flagObject
        );
      } else {
        inputValue = localStoreDataIs?.[colItem.id];
        if (inputValue) {
          if (inputValue?.errors?.length > 0) newFormData[colItem.id]["errors"] = inputValue.errors;
          setDataInFormKeys(
            formConfig,
            masterData,
            colItem.components,
            formPreFillData?.[colItem.id],
            prefillErrorObject?.[colItem.id],
            inputValue,
            newFormData,
            {
              parent_type: colItem.type,
              parent_id: colItem.id,
              action_call: true
            },
            flag,
            parentField,
            recordset_list_flagObject
          );
        }
      }
    } else if (["recordset-list"].includes(colItem.type)) {
      if (flag === "inside") {
        let inputValue = formPreFillData?.[colItem.id];
        let inputError = prefillErrorObject?.[colItem.id];
        if (inputValue) {
          if (Array.isArray(inputValue)) {
            if (inputValue?.length === 0 && inputError.length > 0) {
              inputValue = [{}];
            }
            let fillData = [];
            for (let index = 0; index < inputValue.length; index++) {
              const inputValueObject = inputValue[index];
              const inputErrorObject = inputError?.[index] || {};
              let dataISFor = {};
              setDataInFormKeys(
                formConfig,
                masterData,
                colItem.components,
                inputValueObject,
                inputErrorObject,
                {},
                dataISFor,
                {
                  parent_type: colItem.type,
                  parent_id: colItem.id,
                  action_call: false
                },
                flag,
                parentField,
                recordset_list_flagObject
              );
              fillData.push(dataISFor[colItem.id]);
            }
            if (parentField === "recordset-list" && recordset_list_flagObject[colItem.id] < fillData.length) {
              newFormData[colItem.id] = { errors: [], ...(fillData?.pop() || {}) };
            } else if (parentField !== "recordset-list" && recordset_list_flagObject[colItem.id] < fillData.length) {
              newFormData[colItem.id] = { errors: [], ...(fillData?.pop() || {}) };
            } else {
              newFormData[colItem.id] = { errors: [] };
            }
            newFormData[colItem.id + "_recordset_list"] = fillData;
          } else if (inputValue && typeof inputValue === "object") {
            setDataInFormKeys(
              formConfig,
              masterData,
              colItem.components,
              formPreFillData?.[colItem.id],
              prefillErrorObject?.[colItem.id],
              localStoreDataIs?.[colItem.id],
              newFormData,
              {
                parent_type: colItem.type,
                parent_id: colItem.id,
                action_call: false
              },
              flag,
              parentField,
              recordset_list_flagObject
            );
            newFormData[colItem.id].errors = inputError || [];
          }
        } else {
          inputValue = localStoreDataIs?.[colItem.id];
          if (inputValue) {
            if (inputValue?.errors?.length > 0) newFormData[colItem.id]["errors"] = inputValue.errors;
            setDataInFormKeys(
              formConfig,
              masterData,
              colItem.components,
              formPreFillData?.[colItem.id],
              prefillErrorObject?.[colItem.id],
              inputValue,
              newFormData,
              {
                parent_type: colItem.type,
                parent_id: colItem.id,
                action_call: false
              },
              flag,
              parentField,
              recordset_list_flagObject
            );
          }
          inputValue = localStoreDataIs?.[colItem.id + "_recordset_list"];
          if (inputValue) {
            newFormData[colItem.id + "_recordset_list"] = inputValue;
          }
        }
      } else if (flag === "outside") {
        let inputValue = formPreFillData?.[colItem.id];
        let inputError = prefillErrorObject?.[colItem.id];
        if (inputValue) {
          if (Array.isArray(inputValue)) {
            if (inputValue?.length === 0 && inputError.length > 0) {
              inputValue = [{}];
            }
            let fillData = [];
            for (let index = 0; index < inputValue.length; index++) {
              const inputValueObject = inputValue[index];
              let dataISFor = {};
              let componentsArray = colItem?.components || [];
              for (let j = 0; j < componentsArray.length; j++) {
                const component = componentsArray[j];
                if (["time"].includes(component.element_type)) {
                  let data = inputValueObject[component?.element_id];
                  if (typeof data === "string") {
                    inputValueObject[component?.element_id] = data.split("T")[1] || data;
                  }
                  if (component?.field?.prefill_current) {
                    let nowDate = Date.now();
                    inputValueObject[component?.element_id] = changeDataFormat(component?.element_type, nowDate);
                  }
                } else if (["date"].includes(component.element_type)) {
                  let data = inputValueObject[component?.element_id];
                  if (typeof data === "string") {
                    inputValueObject[component?.element_id] = data.split("T")[0] || data;
                  }
                  if (component?.field?.prefill_current) {
                    let nowDate = Date.now();
                    inputValueObject[component?.element_id] = changeDataFormat(component?.element_type, nowDate);
                  }
                } else if (["multi-date"].includes(component?.element_type)) {
                  let dataArray = inputValueObject[component?.element_id];
                  let newDateArray = [];
                  for (let j = 0; j < dataArray.length; j++) {
                    const dataStr = dataArray[j];
                    if (typeof dataStr === "string") {
                      newDateArray.push(dataStr.split("T")[0]);
                    }
                  }
                  if (component?.field?.prefill_current) {
                    let nowDate = Date.now();
                    let currDate = changeDataFormat("date", nowDate);
                    if (!newDateArray.includes(currDate)) {
                      newDateArray.push(currDate);
                    }
                  }
                  inputValueObject[component?.element_id] = newDateArray;
                }
              }
              setDataInFormKeys(
                formConfig,
                masterData,
                colItem.components,
                inputValueObject,
                prefillErrorObject?.[colItem.id],
                {},
                dataISFor,
                {
                  parent_type: colItem.type,
                  parent_id: colItem.id,
                  action_call: index === inputValue.length - 1
                },
                flag,
                parentField,
                recordset_list_flagObject
              );
              fillData.push(dataISFor[colItem.id]);
            }
            newFormData[colItem.id] = { errors: inputError || [] };
            newFormData[colItem.id + "_recordset_list"] = fillData;
          } else if (inputValue && typeof inputValue === "object") {
            setDataInFormKeys(
              formConfig,
              masterData,
              colItem.components,
              formPreFillData?.[colItem.id],
              prefillErrorObject?.[colItem.id],
              localStoreDataIs?.[colItem.id],
              newFormData,
              {
                parent_type: colItem.type,
                parent_id: colItem.id,
                action_call: true
              },
              flag,
              parentField,
              recordset_list_flagObject
            );
          }
        } else {
          inputValue = localStoreDataIs?.[colItem.id];
          if (inputValue) {
            if (inputValue?.errors?.length > 0) newFormData[colItem.id]["errors"] = inputValue.errors;
            setDataInFormKeys(
              formConfig,
              masterData,
              colItem.components,
              formPreFillData?.[colItem.id],
              prefillErrorObject?.[colItem.id],
              inputValue,
              newFormData,
              {
                parent_type: colItem.type,
                parent_id: colItem.id
              },
              flag,
              parentField,
              recordset_list_flagObject
            );
          }
          inputValue = localStoreDataIs?.[colItem.id + "_recordset_list"];
          if (inputValue) {
            newFormData[colItem.id + "_recordset_list"] = inputValue;
          }
        }
      }
    } else if (["grid"].includes(colItem.type)) {
      if (flag === "inside") {
        let inputValue = formPreFillData?.[colItem.id];
        let inputError = prefillErrorObject?.[colItem.id];
        if (inputValue) {
          if (Array.isArray(inputValue)) {
            if (inputValue?.length === 0 && inputError?.length > 0) {
              inputValue = [{}];
            }
            let fillData = [];
            for (let index = 0; index < inputValue.length; index++) {
              let inputValueObject = inputValue[index];
              const inputErrorObject = inputError?.[index] || {};
              let dataISFor = {};
              let componentsArray = colItem?.components || [];
              for (let j = 0; j < componentsArray.length; j++) {
                const component = componentsArray[j];
                let custom_js = component?.field?.validate?.custom_js;
                if (custom_js) {
                  inputValueObject = runCustomJSFunction(
                    inputValueObject[component?.element_id] || "",
                    inputValueObject,
                    custom_js,
                    formPreFillData || {},
                    index
                  );
                }
              }
              setDataInFormKeys(
                formConfig,
                masterData,
                colItem.components,
                inputValueObject,
                inputErrorObject,
                {},
                dataISFor,
                {
                  parent_type: colItem.type,
                  parent_id: colItem.id,
                  action_call: false
                },
                flag,
                parentField,
                recordset_list_flagObject
              );
              fillData.push(dataISFor[colItem.id]);
            }
            if (parentField === "grid" && recordset_list_flagObject[colItem.id] < fillData.length) {
              newFormData[colItem.id] = { errors: [], ...(fillData?.pop() || {}) };
            } else if (parentField !== "grid" && recordset_list_flagObject[colItem.id] < fillData.length) {
              newFormData[colItem.id] = { errors: [], ...(fillData?.pop() || {}) };
            } else {
              newFormData[colItem.id] = { errors: [] };
            }
            newFormData[colItem.id + "_grid"] = fillData;
          } else if (inputValue && typeof inputValue === "object") {
            setDataInFormKeys(
              formConfig,
              masterData,
              colItem.components,
              formPreFillData?.[colItem.id],
              prefillErrorObject?.[colItem.id],
              localStoreDataIs?.[colItem.id],
              newFormData,
              {
                parent_type: colItem.type,
                parent_id: colItem.id,
                action_call: false
              },
              flag,
              parentField,
              recordset_list_flagObject
            );
            newFormData[colItem.id].errors = inputError || [];
          }
        } else {
          inputValue = localStoreDataIs?.[colItem.id];
          if (inputValue) {
            if (inputValue?.errors?.length > 0) newFormData[colItem.id]["errors"] = inputValue?.errors;
            setDataInFormKeys(
              formConfig,
              masterData,
              colItem.components,
              formPreFillData?.[colItem.id],
              prefillErrorObject?.[colItem.id],
              inputValue,
              newFormData,
              {
                parent_type: colItem.type,
                parent_id: colItem.id,
                action_call: false
              },
              flag,
              parentField,
              recordset_list_flagObject
            );
          }
          inputValue = localStoreDataIs?.[colItem.id + "_grid"];
          if (inputValue) {
            newFormData[colItem.id + "_grid"] = inputValue;
          }
        }
      } else if (flag === "outside") {
        let inputValue = formPreFillData?.[colItem.id];
        let inputError = prefillErrorObject?.[colItem.id];
        if (inputValue) {
          if (Array.isArray(inputValue)) {
            if (inputValue?.length === 0 && inputError?.length > 0) {
              inputValue = [{}];
            }
            let fillData = [];
            for (let index = 0; index < inputValue.length; index++) {
              let inputValueObject = inputValue[index];
              const inputErrorObject = inputError?.[index] || {};
              let dataISFor = {};
              let componentsArray = colItem?.components || [];
              for (let j = 0; j < componentsArray.length; j++) {
                const component = componentsArray[j];
                if (["time"].includes(component.element_type)) {
                  let data = inputValueObject[component?.element_id];
                  if (typeof data === "string") {
                    inputValueObject[component?.element_id] = data.split("T")[1] || data;
                  }
                  if (component?.field?.prefill_current) {
                    let nowDate = Date.now();
                    inputValueObject[component?.element_id] = changeDataFormat(component?.element_type, nowDate);
                  }
                } else if (["date"].includes(component.element_type)) {
                  let data = inputValueObject[component?.element_id];
                  if (typeof data === "string") {
                    inputValueObject[component?.element_id] = data.split("T")[0] || data;
                  }
                  if (component?.field?.prefill_current) {
                    let nowDate = Date.now();
                    inputValueObject[component?.element_id] = changeDataFormat(component?.element_type, nowDate);
                  }
                } else if (["multi-date"].includes(component?.element_type)) {
                  let dataArray = inputValueObject[component?.element_id];
                  let newDateArray = [];
                  for (let j = 0; j < dataArray.length; j++) {
                    const dataStr = dataArray[j];
                    if (typeof dataStr === "string") {
                      newDateArray.push(dataStr.split("T")[0]);
                    }
                  }
                  if (component?.field?.prefill_current) {
                    let nowDate = Date.now();
                    let currDate = changeDataFormat("date", nowDate);
                    if (!newDateArray.includes(currDate)) {
                      newDateArray.push(currDate);
                    }
                  }
                  inputValueObject[component?.element_id] = newDateArray;
                }
              }
              for (let j = 0; j < componentsArray.length; j++) {
                const component = componentsArray[j];
                let custom_js = component?.field?.validate?.custom_js;
                if (custom_js) {
                  inputValueObject = runCustomJSFunction(
                    inputValueObject[component?.element_id] || "",
                    inputValueObject,
                    custom_js,
                    formPreFillData || {},
                    index
                  );
                }
              }
              setDataInFormKeys(
                formConfig,
                masterData,
                colItem.components,
                inputValueObject,
                inputErrorObject,
                {},
                dataISFor,
                {
                  parent_type: colItem.type,
                  parent_id: colItem.id,
                  action_call: false
                },
                flag,
                parentField,
                recordset_list_flagObject
              );
              fillData.push(dataISFor[colItem.id]);
            }
            if (parentField === "grid" && recordset_list_flagObject[colItem.id] < fillData.length) {
              newFormData[colItem.id] = { errors: [], ...(fillData?.pop() || {}) };
            } else if (parentField !== "grid" && recordset_list_flagObject[colItem.id] < fillData.length) {
              newFormData[colItem.id] = { errors: [], ...(fillData?.pop() || {}) };
            } else {
              newFormData[colItem.id] = { errors: [] };
            }
            newFormData[colItem.id + "_grid"] = fillData;
          } else if (inputValue && typeof inputValue === "object") {
            setDataInFormKeys(
              formConfig,
              masterData,
              colItem.components,
              formPreFillData?.[colItem.id],
              prefillErrorObject?.[colItem.id],
              localStoreDataIs?.[colItem.id],
              newFormData,
              {
                parent_type: colItem.type,
                parent_id: colItem.id,
                action_call: true
              },
              flag,
              parentField,
              recordset_list_flagObject
            );
          }
        } else {
          inputValue = localStoreDataIs?.[colItem.id];
          if (inputValue) {
            if (inputValue?.errors?.length > 0) newFormData[colItem.id]["errors"] = inputValue.errors;
            setDataInFormKeys(
              formConfig,
              masterData,
              colItem.components,
              formPreFillData?.[colItem.id],
              prefillErrorObject?.[colItem.id],
              inputValue,
              newFormData,
              {
                parent_type: colItem.type,
                parent_id: colItem.id
              },
              flag,
              parentField,
              recordset_list_flagObject
            );
          }
          inputValue = localStoreDataIs?.[colItem.id + "_grid"];
          if (inputValue) {
            newFormData[colItem.id + "_grid"] = inputValue;
          }
        }
      }
    } else if (["rich-grid"].includes(colItem.type)) {
      if (flag === "inside") {
        let inputValue = formPreFillData?.[colItem.id]?.rows;
        let inputError = prefillErrorObject?.[colItem.id];
        if (inputValue) {
          if (Array.isArray(inputValue)) {
            if (inputValue?.length === 0 && inputError?.length > 0) {
              inputValue = [{}];
            }
            let fillData = [];
            for (let index = 0; index < inputValue.length; index++) {
              let inputValueObject = inputValue[index];
              const inputErrorObject = inputError?.[index] || {};
              let dataISFor = {};
              let componentsArray = colItem?.components || [];
              for (let j = 0; j < componentsArray.length; j++) {
                const component = componentsArray[j];
                let custom_js = component?.field?.validate?.custom_js;
                if (custom_js) {
                  inputValueObject = runCustomJSFunction(
                    inputValueObject[component?.element_id] || "",
                    inputValueObject,
                    custom_js,
                    formPreFillData || {},
                    index
                  );
                }
              }
              setDataInFormKeys(
                formConfig,
                masterData,
                colItem.components,
                inputValueObject,
                inputErrorObject,
                {},
                dataISFor,
                {
                  parent_type: colItem.type,
                  parent_id: colItem.id,
                  action_call: false
                },
                flag,
                parentField,
                recordset_list_flagObject
              );
              fillData.push(dataISFor[colItem.id]);
            }
            if (parentField === "rich-grid" && recordset_list_flagObject[colItem.id] < fillData.length) {
              newFormData[colItem.id] = { errors: [], ...(fillData?.pop() || {}) };
            } else if (parentField !== "rich-grid" && recordset_list_flagObject[colItem.id] < fillData.length) {
              newFormData[colItem.id] = { errors: [], ...(fillData?.pop() || {}) };
            } else {
              newFormData[colItem.id] = { errors: [] };
            }
            newFormData[colItem.id + "_rich_grid"] = fillData;
          } else if (inputValue && typeof inputValue === "object") {
            setDataInFormKeys(
              formConfig,
              masterData,
              colItem.components,
              formPreFillData?.[colItem.id],
              prefillErrorObject?.[colItem.id],
              localStoreDataIs?.[colItem.id],
              newFormData,
              {
                parent_type: colItem.type,
                parent_id: colItem.id,
                action_call: false
              },
              flag,
              parentField,
              recordset_list_flagObject
            );
            newFormData[colItem.id].errors = inputError || [];
          }
        } else {
          inputValue = localStoreDataIs?.[colItem.id];
          if (inputValue) {
            if (inputValue?.errors?.length > 0) newFormData[colItem.id]["errors"] = inputValue.errors;
            setDataInFormKeys(
              formConfig,
              masterData,
              colItem.components,
              formPreFillData?.[colItem.id],
              prefillErrorObject?.[colItem.id],
              inputValue,
              newFormData,
              {
                parent_type: colItem.type,
                parent_id: colItem.id,
                action_call: false
              },
              flag,
              parentField,
              recordset_list_flagObject
            );
          }
          inputValue = localStoreDataIs?.[colItem.id + "_rich_grid"];
          if (inputValue) {
            newFormData[colItem.id + "_rich_grid"] = inputValue;
          }
        }
      } else if (flag === "outside") {
        let inputValue = formPreFillData?.[colItem.id]?.rows;
        let inputError = prefillErrorObject?.[colItem.id];
        if (inputValue) {
          if (Array.isArray(inputValue)) {
            if (inputValue?.length === 0 && inputError?.length > 0) {
              inputValue = [{}];
            }
            let fillData = [];
            for (let index = 0; index < inputValue.length; index++) {
              let inputValueObject = inputValue[index];
              const inputErrorObject = inputError?.[index] || {};
              let dataISFor = {};
              let componentsArray = colItem?.components || [];
              for (let j = 0; j < componentsArray.length; j++) {
                const component = componentsArray[j];
                if (["time"].includes(component.element_type)) {
                  let data = inputValueObject[component?.element_id];
                  if (typeof data === "string") {
                    inputValueObject[component?.element_id] = data.split("T")[1] || data;
                  }
                  if (component?.field?.prefill_current) {
                    let nowDate = Date.now();
                    inputValueObject[component?.element_id] = changeDataFormat(component?.element_type, nowDate);
                  }
                } else if (["date"].includes(component.element_type)) {
                  let data = inputValueObject[component?.element_id];
                  if (typeof data === "string") {
                    inputValueObject[component?.element_id] = data.split("T")[0] || data;
                  }
                  if (component?.field?.prefill_current) {
                    let nowDate = Date.now();
                    inputValueObject[component?.element_id] = changeDataFormat(component?.element_type, nowDate);
                  }
                } else if (["multi-date"].includes(component?.element_type)) {
                  let dataArray = inputValueObject[component?.element_id];
                  let newDateArray = [];
                  for (let j = 0; j < dataArray.length; j++) {
                    const dataStr = dataArray[j];
                    if (typeof dataStr === "string") {
                      newDateArray.push(dataStr.split("T")[0]);
                    }
                  }
                  if (component?.field?.prefill_current) {
                    let nowDate = Date.now();
                    let currDate = changeDataFormat("date", nowDate);
                    if (!newDateArray.includes(currDate)) {
                      newDateArray.push(currDate);
                    }
                  }
                  inputValueObject[component?.element_id] = newDateArray;
                }
              }
              for (let j = 0; j < componentsArray.length; j++) {
                const component = componentsArray[j];
                let custom_js = component?.field?.validate?.custom_js;
                if (custom_js) {
                  inputValueObject = runCustomJSFunction(
                    inputValueObject[component?.element_id] || "",
                    inputValueObject,
                    custom_js,
                    formPreFillData || {},
                    index
                  );
                }
              }
              setDataInFormKeys(
                formConfig,
                masterData,
                colItem.components,
                inputValueObject,
                inputErrorObject,
                {},
                dataISFor,
                {
                  parent_type: colItem.type,
                  parent_id: colItem.id,
                  action_call: false
                },
                flag,
                parentField,
                recordset_list_flagObject
              );
              fillData.push(dataISFor[colItem.id]);
            }
            if (parentField === "rich-grid" && recordset_list_flagObject[colItem.id] < fillData.length) {
              newFormData[colItem.id] = { errors: [], ...(fillData?.pop() || {}) };
            } else if (parentField !== "rich-grid" && recordset_list_flagObject[colItem.id] < fillData.length) {
              newFormData[colItem.id] = { errors: [], ...(fillData?.pop() || {}) };
            } else {
              newFormData[colItem.id] = { errors: [] };
            }
            newFormData[colItem.id + "_rich_grid"] = fillData;
          } else if (inputValue && typeof inputValue === "object") {
            setDataInFormKeys(
              formConfig,
              masterData,
              colItem.components,
              formPreFillData?.[colItem.id],
              prefillErrorObject?.[colItem.id],
              localStoreDataIs?.[colItem.id],
              newFormData,
              {
                parent_type: colItem.type,
                parent_id: colItem.id,
                action_call: true
              },
              flag,
              parentField,
              recordset_list_flagObject
            );
          }
        } else {
          inputValue = localStoreDataIs?.[colItem.id];
          if (inputValue) {
            if (inputValue?.errors?.length > 0) newFormData[colItem.id]["errors"] = inputValue.errors;
            setDataInFormKeys(
              formConfig,
              masterData,
              colItem.components,
              formPreFillData?.[colItem.id],
              prefillErrorObject?.[colItem.id],
              inputValue,
              newFormData,
              {
                parent_type: colItem.type,
                parent_id: colItem.id
              },
              flag,
              parentField,
              recordset_list_flagObject
            );
          }
          inputValue = localStoreDataIs?.[colItem.id + "_rich_grid"];
          if (inputValue) {
            newFormData[colItem.id + "_rich_grid"] = inputValue;
          }
        }
      }
    } else if (["lookup", "lookup-sm"].includes(colItem.type)) {
      let inputValue = formPreFillData?.[colItem.id];
      let inputError = prefillErrorObject?.[colItem.id] || [];

      if (inputValue) {
        newFormData[colItem.id] = {
          value: inputValue,
          errors: inputError || []
        };
      } else if (inputError.length > 0) {
        newFormData[colItem.id] = {
          errors: inputError || []
        };
      } else {
        inputValue = localStoreDataIs?.[colItem.id];
        if (inputValue) {
          if (inputValue.value || inputValue?.errors?.length > 0) newFormData[colItem.id] = inputValue;
        }
      }
    } else if (["app-user"].includes(colItem.type)) {
      let inputValue = formPreFillData?.[colItem.id];
      let inputError = prefillErrorObject?.[colItem.id] || [];

      if (inputValue) {
        newFormData[colItem.id] = {
          value: inputValue,
          errors: inputError || []
        };
      } else if (inputError.length > 0) {
        newFormData[colItem.id] = {
          errors: inputError || []
        };
      } else {
        if (!colItem.prefill_current_user) {
          inputValue = localStoreDataIs?.[colItem.id];
          if (inputValue) {
            if (inputValue.value || inputValue?.errors?.length > 0) newFormData[colItem.id] = inputValue;
          }
        }
      }
    } else if (["day", "date", "time", "datetime", "multi-date"].includes(colItem.type)) {
      let inputValue = formPreFillData?.[colItem.id];
      let inputError = prefillErrorObject?.[colItem.id] || [];
      if (inputValue) {
        if (["multi-date"].includes(colItem.type)) {
          let dateArray = [];
          for (let j = 0; j < (inputValue || []).length; j++) {
            const dateElement = inputValue[j];
            dateArray.push(changeDataFormat("date", dateElement));
          }
          newFormData[colItem.id] = {
            value: dateArray,
            errors: inputError || []
          };
        } else {
          newFormData[colItem.id] = {
            value: changeDataFormat(colItem.type, inputValue),
            errors: inputError || []
          };
        }
      } else if (inputError.length > 0) {
        newFormData[colItem.id] = {
          errors: inputError || []
        };
      } else {
        inputValue = localStoreDataIs?.[colItem.id];
        if (inputValue) {
          if (inputValue.value || inputValue?.errors?.length > 0) newFormData[colItem.id] = inputValue;
        }

        if (colItem.prefill_current) {
          let nowDate = Date.now();
          if (["multi-date"].includes(colItem.type)) {
            newFormData[colItem.id] = {
              value: [changeDataFormat("date", nowDate)],
              errors: []
            };
          } else {
            newFormData[colItem.id] = {
              value: changeDataFormat(colItem.type, nowDate),
              errors: []
            };
          }
        }
      }
      if (
        flag === "outside" &&
        parentObject.action_call &&
        !["undefined"].includes(typeof newFormData?.[colItem.id]?.["value"])
      ) {
        customFunForFieldShowHide(colItem, newFormData[colItem.id]["value"], formConfig, masterData);
      }
    } else if (["selectboxes"].includes(colItem.type)) {
      let dataObj = formPreFillData?.[colItem.id];
      let inputError = prefillErrorObject?.[colItem.id] || [];

      if (dataObj) {
        let inputValue = {};
        let dataArray = colItem?.data?.values || [];
        for (let index = 0; index < dataArray.length; index++) {
          const element = dataArray[index];
          if (dataObj.includes(element.label || element.value)) {
            inputValue[index] = element.label;
          }
        }

        newFormData[colItem.id] = {
          value: inputValue,
          errors: inputError || []
        };
      } else if (inputError.length > 0) {
        newFormData[colItem.id] = {
          errors: inputError || []
        };
      } else {
        let inputValue = localStoreDataIs?.[colItem.id];
        if (inputValue) {
          if (inputValue.value || inputValue?.errors?.length > 0) newFormData[colItem.id] = inputValue;
        }
      }
      if (
        flag === "outside" &&
        parentObject.action_call &&
        !["undefined"].includes(typeof newFormData?.[colItem.id]?.["value"])
      ) {
        customFunForFieldShowHide(colItem, newFormData[colItem.id]["value"], formConfig, masterData);
      }
    } else if (["image", "file"].includes(colItem.type)) {
      let inputValue = formPreFillData?.[colItem.id];
      let inputError = prefillErrorObject?.[colItem.id] || [];
      if (inputValue) {
        newFormData[colItem.id] = {
          value: inputValue,
          errors: inputError || []
        };
      } else if (inputError.length > 0) {
        newFormData[colItem.id] = {
          errors: inputError || []
        };
      } else {
        inputValue = localStoreDataIs?.[colItem.id];
        if (inputValue) {
          let images = inputValue.value || [];
          for (let index = images.length - 1; index >= 0; index--) {
            const element = images[index];
            if (element.url === "true") {
              images.splice(index, 1);
            }
          }
          if (inputValue.value || inputValue?.errors?.length > 0) {
            inputValue.value = images;
            newFormData[colItem.id] = inputValue;
          }
        }
      }
    } else {
      let inputValue = formPreFillData?.[colItem.id];
      let inputError = prefillErrorObject?.[colItem.id] || [];

      if (inputValue) {
        newFormData[colItem.id] = {
          value: inputValue,
          errors: inputError || []
        };
      } else if (inputError.length > 0) {
        newFormData[colItem.id] = {
          errors: inputError || []
        };
      } else {
        inputValue = localStoreDataIs?.[colItem.id];
        if (inputValue) {
          if (inputValue.value || inputValue?.errors?.length > 0) newFormData[colItem.id] = inputValue;
        }
      }
      if (
        flag === "outside" &&
        parentObject.action_call &&
        !["undefined"].includes(typeof newFormData?.[colItem.id]?.["value"])
      ) {
        customFunForFieldShowHide(colItem, newFormData[colItem.id]["value"], formConfig, masterData);
      }
    }
  }
};

export const customFunForFieldShowHide = (component, value, formConfigData, masterData) => {
  if (component.on_change) {
    // check and apply all action
    for (let index = 0; index < component.on_change.length; index++) {
      const element = component.on_change[index];

      let onChangeFunFlag = false;
      switch (component.type) {
        case "number":
          if (element.gt && element.lt) {
            onChangeFunFlag = value > element.gt && value < element.lt;
          } else if (!element.gt && element.lt) {
            onChangeFunFlag = value < element.lt;
          } else if (element.gt && !element.lt) {
            onChangeFunFlag = value > element.gt;
          } else if (!element.gt && !element.lt) {
            onChangeFunFlag = true;
          }
          break;
        case "geolocation":
        case "email":
        case "date":
        case "multi-date":
        case "time":
        case "day":
        case "datetime":
        case "lookup":
        case "lookup-sm":
        case "app-user":
        case "select-chips":
          onChangeFunFlag = false;
          break;
        case "checkbox":
        case "toggle":
          onChangeFunFlag = value;
          break;
        case "selectboxes":
          let valuesArray = Object.values(value);
          for (let index = 0; index < valuesArray.length; index++) {
            valuesArray[index] = valuesArray[index].toLowerCase();
          }
          onChangeFunFlag = !element.has || valuesArray.indexOf(element.has.includes()) !== -1;
          break;
        default:
          onChangeFunFlag = true;
          if (element.eq) onChangeFunFlag = value.toLowerCase() === element.eq.toLowerCase();
          if (!onChangeFunFlag && element.has) onChangeFunFlag = element.has.includes(value);
          break;
      }

      if (onChangeFunFlag) {
        for (let eleIndex = 0; eleIndex < element.action.length; eleIndex++) {
          const item = element.action[eleIndex];
          switch (item.type) {
            case "set_options":
              setItemOptions(value, item, formConfigData.sections, masterData);
              break;
            case "show":
              showFormItem(item, formConfigData.sections);
              break;
            case "hide":
              hideFormItem(item, formConfigData.sections);
              break;
            default:
              break;
          }
        }
      }
    }
  }
};

// Hide function
function hideFormItem(component, formsArray) {
  let hideItem = [...component.child_id];
  for (let formsIndex = 0; formsIndex < formsArray.length; formsIndex++) {
    if (hideItem.length <= 0) return;
    const componentsArray = formsArray[formsIndex].components;
    for (let index = 0; index < componentsArray.length; index++) {
      const formArray = componentsArray[index].elements;
      setFormShowHideKey(hideItem, formArray, false);
    }
  }
}

// Show function
function showFormItem(component, formsArray) {
  let showItem = [...component.child_id];
  for (let formsIndex = 0; formsIndex < formsArray.length; formsIndex++) {
    if (showItem.length <= 0) return;
    const componentsArray = formsArray[formsIndex].components;
    for (let index = 0; index < componentsArray.length; index++) {
      const formArray = componentsArray[index].elements;
      setFormShowHideKey(showItem, formArray, true);
    }
  }
}

// Show and Hide Key set function
function setFormShowHideKey(formItemsArray, formArray, value) {
  for (let formIndex = 0; formIndex < formArray.length; formIndex++) {
    if (formItemsArray.length <= 0) return;
    if (["empty"].includes(formArray[formIndex].element_type)) continue;
    const item = formArray[formIndex].field;
    if (formItemsArray.indexOf(item.id) !== -1) {
      item.show = value;
      formItemsArray.splice(formItemsArray.indexOf(item.id), 1);
    }
    if (
      item.type === "container" ||
      item.type === "recordset" ||
      item.type === "recordset-list" ||
      item.type === "grid"
    )
      setFormShowHideKey(formItemsArray, item.components, value);
  }
}

// Set Option function
function setItemOptions(value, component, formsArray, masterData) {
  let dataConfig = getDataConfigFromMasterData(masterData, component.data_key);
  if (!dataConfig) return undefined;
  let componentReference;
  for (let formsIndex = 0; formsIndex < formsArray.length; formsIndex++) {
    const componentsArray = formsArray[formsIndex].components;
    for (let index = 0; index < componentsArray.length; index++) {
      const formArray = componentsArray[index].elements;
      componentReference = getComponentRefFromForm(formArray, component.child_id);
      if (componentReference) break;
    }
    if (componentReference) break;
  }

  if (!componentReference) return;
  componentReference.show = true;
  if (["select", "selectboxes", "radio", "select-chips"].includes(componentReference.type)) {
    if (component["method"] === "lookup") {
      let searchIndex, valueIndex, lableIndex;
      for (let index = 0; index < dataConfig[0].length; index++) {
        const element = dataConfig[0][index];
        if (element === component.search_key_col) searchIndex = index;
        if (element === component.label_from_col) lableIndex = index;
        if (element === component.value_from_col) valueIndex = index;
      }
      let dataArray = [];
      for (let index = 1; index < dataConfig.length; index++) {
        const element = dataConfig[index];

        // eslint-disable-next-line eqeqeq
        if (element[searchIndex] == value) {
          dataArray.push({
            label: element[lableIndex],
            value: element[valueIndex]
          });
        }
      }

      componentReference.data.values = dataArray;
    } else {
      let lableIndex, valueIndex;
      for (let index = 0; index < dataConfig[0].length; index++) {
        const element = dataConfig[0][index];
        if (element === component.label_from_col) lableIndex = index;
        if (element === component.value_from_col) valueIndex = index;
      }
      let dataArray = [];
      for (let index = 1; index < dataConfig.length; index++) {
        const element = dataConfig[index];

        dataArray.push({
          label: element[lableIndex],
          value: element[valueIndex]
        });
      }
      componentReference.data.values = dataArray;
    }
  }
}

// Set Option in item
function getComponentRefFromForm(formArray, componentKey) {
  for (let formIndex = 0; formIndex < formArray.length; formIndex++) {
    if (["empty"].includes(formArray[formIndex].element_type)) continue;
    const item = formArray[formIndex].field;
    if (item.id === componentKey) {
      return item;
    }
    if (
      item.type === "container" ||
      item.type === "recordset" ||
      item.type === "recordset-list" ||
      item.type === "grid"
    ) {
      let data = getComponentRefFromForm(item.components, componentKey);
      if (data) return data;
    }
  }
  return;
}

function getDataConfigFromMasterData(dataConfig, data_key) {
  if (!data_key) return undefined;
  if (typeof dataConfig?.[data_key]?.[0] === "object" && Array.isArray(dataConfig[data_key][0])) {
    return dataConfig[data_key];
  }
  return undefined;
}

export const changeDataFormat = (type, dataIs) => {
  switch (type) {
    case ("day", "date"):
      let dateNow01;
      if (isNaN(dataIs)) {
        dateNow01 = new Date(dataIs);
      } else if (parseInt(dataIs) > 9999999999) {
        dateNow01 = new Date(parseInt(dataIs));
      } else {
        dateNow01 = new Date(parseInt(dataIs) * 1000);
      }
      let day01 = dateNow01.getDate();
      let month01 = dateNow01.getMonth() + 1;
      let year01 = dateNow01.getFullYear();

      if (day01 < 10) day01 = "0" + day01;
      if (month01 < 10) month01 = "0" + month01;

      return year01 + "-" + month01 + "-" + day01;

    case "time":
      let dateNow02;
      if (isNaN(dataIs)) {
        if (dataIs.includes("T")) {
          // Handle "2000-01-02T12:00:00+05:30" format
          dateNow02 = new Date(dataIs);
        } else if (dataIs.includes(":")) {
          // Handle "12:00" format
          dateNow02 = new Date("2000-01-02T" + dataIs + ":00+05:30");
        } else {
          dateNow02 = new Date(dataIs);
        }
      } else if (parseInt(dataIs) < 86401) {
        let totalMinutes = Math.floor(dataIs / 60);
        let hours = Math.floor(totalMinutes / 60);
        let minutes = totalMinutes % 60;

        if (hours < 10) hours = "0" + hours;
        if (minutes < 10) minutes = "0" + minutes;
        dateNow02 = new Date("2000-01-01T" + hours + ":" + minutes);
      } else if (parseInt(dataIs) > 9999999999) {
        dateNow02 = new Date(parseInt(dataIs));
      } else {
        dateNow02 = new Date(parseInt(dataIs) * 1000);
      }
      let hours02 = dateNow02.getHours();
      let minutes02 = dateNow02.getMinutes();
      if (hours02 < 10) hours02 = "0" + hours02;
      if (minutes02 < 10) minutes02 = "0" + minutes02;

      return hours02 + ":" + minutes02;

    case "datetime":
      let dateNow03;
      if (isNaN(dataIs)) {
        dateNow03 = new Date(dataIs);
      } else if (parseInt(dataIs) > 9999999999) {
        dateNow03 = new Date(parseInt(dataIs));
      } else {
        dateNow03 = new Date(parseInt(dataIs) * 1000);
      }
      let day03 = dateNow03.getDate();
      let month03 = dateNow03.getMonth() + 1;
      let year03 = dateNow03.getFullYear();

      if (day03 < 10) day03 = "0" + day03;
      if (month03 < 10) month03 = "0" + month03;

      let hours03 = dateNow03.getHours();
      let minutes03 = dateNow03.getMinutes();
      if (hours03 < 10) hours03 = "0" + hours03;
      if (minutes03 < 10) minutes03 = "0" + minutes03;

      return year03 + "-" + month03 + "-" + day03 + "T" + hours03 + ":" + minutes03;

    default:
      return "";
  }
};

const runCustomJSFunction = (value, data, customString, formData, rowIndex) => {
  try {
    let newData = JSON.parse(JSON.stringify(data));
    let newFunWithData = `var data = ${JSON.stringify(newData)}
    var form_data = ${JSON.stringify(formData)}
    var row_index = ${rowIndex}
    var user_token = ${JSON.stringify(getApiCallHeadersData()?.Authorization || "")}
    var value = ${typeof value === "number" ? value : JSON.stringify(value)} 

     ${customString}
    `;

    if (
      customString.includes("http://") ||
      customString.includes("https://") ||
      customString.includes("getApiCallLocalPath()")
    ) {
      // eslint-disable-next-line no-eval
      let res = eval(`(async () => { ${newFunWithData} })()`);

      return res?.data || newData;
    }

    // eslint-disable-next-line no-eval
    let res = eval(newFunWithData);
    return res?.data || newData;
  } catch (error) {
    return data;
  }
};
