// File: src/components/TabScreenDateFilter.js

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { usePageHandlerContext } from "../../../../pagehandler/PageHandlerContext";
import { useLocation } from "react-router-dom";
import { createRecordsViewPayload } from "../../../../redux/recordview/recordviewaction";

const TabScreenDateFilter = ({ namespace = "" }) => {
  const { cacheProcessData } = usePageHandlerContext();
  const location = useLocation();
  const screen_name = location.pathname;
  const storeData = useSelector((state) => state.recordviewstore);
  const recordsviewPayloadConfig = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}`));
  const recordsviewLayoutConfig = storeData?.[`${namespace}_recordsviewLayoutConfig`];
  const recordsviewDataConfig = storeData?.[`${namespace}_recordsviewDataConfig`];

  const [currentDate, setCurrentDate] = useState(new Date());
  const [dates, setDates] = useState([]);
  const dispatch = useDispatch();
  const [tabId, setTabId] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const selectedDateRef = useRef(null);

  useEffect(() => {
    generateDatesForMonth(currentDate);
  }, [currentDate]);

  const generateDatesForMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const numDays = new Date(year, month + 1, 0).getDate();
    const newDates = [];

    for (let i = 1; i <= numDays; i++) {
      newDates.push(new Date(year, month, i));
    }

    setDates(newDates);
  };

  const handlePreviousMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const handleDateClick = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Month is zero-indexed, so we add 1
    const day = date.getDate();

    // Construct formatted date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;

    setSelectedDate(formattedDate);

    let previousFilters = recordsviewPayloadConfig?.params?.search_query?.filter_by;
    let previousSearch = recordsviewPayloadConfig?.params?.search_query?.search_term;

    let tempFilterObj = {
      id: recordsviewLayoutConfig?.layout?.filter_layout?.filter_field?.id,
      operator: "custom_date_range",
      text_value: "",
      text_array: "",
      number_value: "",
      number_range: "",
      human_date: "",
      custom_date_range: [formattedDate, formattedDate]
    };

    let updatedFilters = previousFilters?.filter((prevFilter) => prevFilter?.id !== tabId);
    if (tempFilterObj) {
      updatedFilters.push(tempFilterObj);
    }

    dispatch(
      createRecordsViewPayload({
        namespace: namespace,
        layoutConfig: recordsviewLayoutConfig,
        filter_by: updatedFilters,
        search_term: previousSearch,
        page: 1,
        per_page: 10,
        screen_name: screen_name,
        cacheProcessData: cacheProcessData
      })
    );
  };

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "short" };
    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    setTabId(recordsviewLayoutConfig?.layout?.filter_layout?.filter_field?.id);
    if (!selectedDate) {
      let previousFilters = recordsviewPayloadConfig?.params?.search_query?.filter_by || [];
      let prevDate = "";
      previousFilters?.forEach((filter) => {
        if (filter?.id === recordsviewLayoutConfig?.layout?.filter_layout?.filter_field?.id) {
          prevDate = filter?.custom_date_range[0] || null;
        }
      });
      if (prevDate) {
        let date = new Date(prevDate);
        setSelectedDate(prevDate);
        setCurrentDate(date);
      } else {
        handleDateClick(new Date());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsviewDataConfig]);

  useEffect(() => {
    if (selectedDateRef.current) {
      selectedDateRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [recordsviewDataConfig]);

  const displayUserDate = () => {
    const date = new Date(selectedDate);
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    const [day, month, year] = formattedDate.split(" ");
    const dayWithSuffix = `${day}`;
    return `${dayWithSuffix} ${month} ${year}`;
  };

  return (
    <div className="filterDateTabMainContainer">
      <span className="selectedDateValue">{displayUserDate()}</span>
      <div className="filterDateTabContainer">
        <span className="material-symbols-outlined" onClick={handlePreviousMonth}>
          chevron_left
        </span>
        <div className="filterDateListContainer">
          {dates.map((date) => {
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // Month is zero-indexed, so we add 1
            const day = date.getDate();
            const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}`;
            return (
              <span
                key={date}
                onClick={() => handleDateClick(date)}
                className={selectedDate === formattedDate ? "default-date selected-date" : "default-date"}
                ref={selectedDate === formattedDate ? selectedDateRef : null}
              >
                {formatDate(date)}
              </span>
            );
          })}
        </div>
        <span className="material-symbols-outlined" onClick={handleNextMonth}>
          chevron_right
        </span>
      </div>
    </div>
  );
};

export default TabScreenDateFilter;
