import React, { useEffect } from "react";
import { store } from "../../redux/store";
import { getMapviewConfig } from "../../redux/mapview/mapviewaction";
import MapviewRendererFunction from "./MapviewRendererFunction";

const MapViewRender = ({ itemConfig, namespace }) => {
  useEffect(() => {
    if (namespace) {
      let mapRoot = document.getElementById("maproot");
      if (!mapRoot.innerHTML) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${"AIzaSyDMWCLgNVsigqHdgwgdV0yurVhy6n5dnb0"}&v=weekly&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          store.dispatch(getMapviewConfig(namespace, itemConfig));
        };
        mapRoot.appendChild(script);
      } else {
        store.dispatch(getMapviewConfig(namespace, itemConfig));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namespace]);

  if (!namespace) {
    return (
      <div
        style={{
          width: "100%",
          minHeight: "250px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px"
        }}
      >
        <p style={{ marginTop: "50px", fontSize: "16px" }}>{"Please add namespace key"}</p>
      </div>
    );
  }
  return <MapviewRendererFunction namespace={namespace} />;
};

export default MapViewRender;
