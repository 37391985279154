import React, { useEffect, useState, useRef } from "react";
import { gridCommonFunction } from "../../utils/gridCommonFunction";
import FieldView from "../pages/FieldView";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../../redux/formview/formviewaction";
import { FSIconRenderer } from "../../zinoIcon";
import { getApiCallLocalPath } from "../../../utils/apiCallFunction";
import { form_images_store } from "../utils/store";
import { commonFunction } from "../utils/commonFunction";
import { convertNumbetToDate, convertNumbetToTime } from "../../utils/commonFunction";

const FFGridField = ({ gridComponent, currentConfigKey, namespace }) => {
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];
  let keysArray = currentConfigKey.split(":");

  const [recordsetListData, setRecordsetListData] = useState([]);
  const [recordsetListErrorFlag, setRecordsetListErrorFlag] = useState(false);

  const [showForm, setShowForm] = useState(false);
  const [showCards, setShowCards] = useState(false);
  const [showTotalTable, setShowTotalTable] = useState(false);

  useEffect(() => {
    let formDataOnject = formData;
    for (let i = 0; i < keysArray.length - 1; i++) {
      formDataOnject = formDataOnject?.[keysArray[i]];
    }
    let recordsetListDataFill = formDataOnject?.[keysArray[keysArray.length - 1]] || {};
    let recordsetListDataIs = formDataOnject?.[keysArray[keysArray.length - 1] + "_rich_grid"] || [];
    setRecordsetListData(recordsetListDataIs);
    let errorFlag = false;
    if (Object.keys(recordsetListDataFill).length > 1) {
      for (let index = 0; index < rowItemElements.length; index++) {
        const element = rowItemElements[index];
        let errorArray = recordsetListDataFill?.[element?.element_id]?.errors || [];
        if (errorArray.length > 0) {
          errorFlag = true;
          break;
        }
      }
    }
    setRecordsetListErrorFlag(errorFlag);
    if (recordsetListDataIs.length > 0) {
      setShowCards(true);
    } else {
      setShowCards(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    const formPreFillData = storeData[`${namespace}_formPreFillData`] || {};
    let prefillRecordsetListData = formPreFillData?.[keysArray[1]];

    if (Array.isArray(prefillRecordsetListData)) {
      let inputValue = [];
      let componentsArray = gridComponent.components || [];
      for (let index = 0; index < prefillRecordsetListData.length; index++) {
        const recordsetListPreData = prefillRecordsetListData[index];
        let object = {};
        for (let index = 0; index < componentsArray.length; index++) {
          const element = componentsArray[index].field;
          if (element) {
            object[element.id] = { value: recordsetListPreData[element.id], errors: [] };
          }
        }
        inputValue.push(object);
      }

      let form_data = storeData?.[`${namespace}_formData`] || {};
      let dataObject = form_data;
      let currentConfigKeyA = currentConfigKey + "_rich_grid";
      let keyArrayA = currentConfigKeyA.split(":");
      let index = 0;
      while (index < keyArrayA.length) {
        const key = keyArrayA[index];
        // add object in form data
        if (!dataObject[key]) dataObject[key] = { errors: [] };

        if (index === keyArrayA.length - 1) {
          dataObject[key] = inputValue;
        } else {
          dataObject = dataObject[key];
        }
        index++;
      }
      dispatch(updateFormData(namespace, { ...form_data }));
      if (prefillRecordsetListData.length > 0) {
        setShowCards(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewRecordset = () => {
    if (recordsetListErrorFlag) return;

    let form_data = JSON.parse(JSON.stringify(formData));
    let formDataOnject = form_data;
    let keysArray = currentConfigKey.split(":");
    for (let i = 0; i < keysArray.length - 1; i++) {
      formDataOnject = formDataOnject?.[keysArray[i]];
    }

    let dataObject = { ...formDataOnject[keysArray[keysArray.length - 1]] };
    let flag = checkDataIsOrNot(dataObject);
    if (flag) {
      formDataOnject[keysArray[keysArray.length - 1] + "_rich_grid"] = [
        ...(formDataOnject[keysArray[keysArray.length - 1] + "_rich_grid"] || []),
        dataObject
      ];
      formDataOnject[keysArray[keysArray.length - 1]] = { errors: [] };
      dispatch(updateFormData(namespace, { ...form_data }));
      let custom_js = gridComponent?.validate?.custom_js;
      if (custom_js) {
        let fullformData = commonFunction.getKeyCompleteDataFromForm(namespace, "value");
        let formDataObj = commonFunction.getOnlyDataFromObject(fullformData, namespace, true);
        const newData = callCustomGrid(formDataObj, custom_js);
        const newJson = updateData(newData, { ...form_data }, gridComponent?.id);
        dispatch(updateFormData(namespace, { ...newJson }));
      }
      setShowCards(true);
    }
    setShowForm(false);
  };

  const editOldRecordset = (index) => {
    let form_data = JSON.parse(JSON.stringify(formData));
    let formDataOnject = form_data;
    let keysArray = currentConfigKey.split(":");
    for (let i = 0; i < keysArray.length - 1; i++) {
      formDataOnject = formDataOnject?.[keysArray[i]];
    }

    let dataObject = { ...formDataOnject[keysArray[keysArray.length - 1]] };
    let flag = checkDataIsOrNot(dataObject, "edit");

    let array = formDataOnject[keysArray[keysArray.length - 1] + "_rich_grid"];
    if (flag) array.push(dataObject);

    let editObject = array.splice(index, 1);
    formDataOnject[keysArray[keysArray.length - 1] + "_rich_grid"] = array;
    formDataOnject[keysArray[keysArray.length - 1]] = {
      errors: [],
      ...editObject[0]
    };

    dispatch(updateFormData(namespace, { ...form_data }));
  };

  const removeOldRecordset = (index) => {
    let form_data = JSON.parse(JSON.stringify(formData));
    let formDataOnject = form_data;
    let keysArray = currentConfigKey.split(":");
    for (let i = 0; i < keysArray.length - 1; i++) {
      formDataOnject = formDataOnject?.[keysArray[i]];
    }
    let array = formDataOnject[keysArray[keysArray.length - 1] + "_rich_grid"];
    array.splice(index, 1);
    formDataOnject[keysArray[keysArray.length - 1] + "_rich_grid"] = array;
    dispatch(updateFormData(namespace, { ...form_data }));
    let custom_js = gridComponent?.validate?.custom_js;
    if (custom_js) {
      let fullformData = commonFunction.getKeyCompleteDataFromForm(namespace, "value");
      let formDataObj = commonFunction.getOnlyDataFromObject(fullformData, namespace, true);
      const newData = callCustomGrid(formDataObj, custom_js);
      const newJson = updateData(newData, { ...form_data }, gridComponent?.id);
      dispatch(updateFormData(namespace, { ...newJson }));
    }
    if (array.length === 0) {
      setShowCards(false);
      setShowForm(false);
    }
  };

  const checkDataIsOrNot = (dataObject, callFrom = "add") => {
    let rowItemElements = gridComponent.components;
    let flag = false;

    for (let index = 0; index < rowItemElements.length; index++) {
      const element = rowItemElements[index].field;
      let value = dataObject?.[element.id]?.["value"] || null;

      if (typeof value !== "undefined" && value !== null && value !== "") {
        flag = true;
      }
    }

    if (callFrom !== "edit") {
      for (let index = 0; index < rowItemElements.length; index++) {
        const element = rowItemElements[index].field;
        let value = dataObject?.[element.id]?.["value"] || null;
        if (element?.show && element?.validate?.required) {
          if (typeof value === "undefined" || value === null) {
            flag = false;
          }
        }
      }
    }
    return flag;
  };

  useEffect(() => {
    if (showForm) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showForm]);

  let rowItemElements = gridComponent.components;
  let sectionRowGridArray = [];
  for (let index = 0; index < rowItemElements.length; index++) {
    let element = rowItemElements[index].grid;
    sectionRowGridArray.push({ ...element, static: true });
  }
  let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(sectionRowGridArray);
  let mobileGridSystem = gridComponent?.card_design?.mobile || {};
  let cardElementsArray = [];
  if (Object?.keys(mobileGridSystem).length > 0) {
    let elementKeys = Object?.keys(mobileGridSystem);
    for (let index = 0; index < elementKeys?.length; index++) {
      cardElementsArray.push(mobileGridSystem[elementKeys[index]].grid);
    }
  }
  let cardGridSystem =
    cardElementsArray?.length > 0 ? gridCommonFunction.convertArrayInGridTemplateAreas(cardElementsArray) : {};

  useEffect(() => {
    for (let index = 0; index < rowItemElements.length; index++) {
      let field = rowItemElements[index]?.field;
      if (field?.type === "number" && field?.show && field?.show_sum) {
        setShowTotalTable(true);
      }
    }
  }, [rowItemElements]);

  return (
    <div style={{ position: "relative" }}>
      <p className="grid_field_label">{gridComponent.label}</p>
      <div
        style={{
          padding: showCards ? "0px 0px 8px 0px" : "12px 8px 8px 8px"
        }}
        className="grid_content_container"
      >
        {showCards && (
          <>
            <div className="grid_rows_container">
              {gridComponent?.is_card_format ? (
                <>
                  {recordsetListData.map((oneRecordsetListData, oneRecordsetListIndex) => {
                    return (
                      <div key={oneRecordsetListIndex} className="grid_data_container">
                        <CardExtraActions
                          editOldRecordset={editOldRecordset}
                          removeOldRecordset={removeOldRecordset}
                          oneRecordsetListIndex={oneRecordsetListIndex}
                          setShowForm={setShowForm}
                          allow_delete={gridComponent?.allow_delete}
                        />
                        <div style={{ ...cardGridSystem }}>
                          {rowItemElements.map((colItem, colItemIndex) => {
                            let dataObject = colItem.field;
                            if (!dataObject.show) return undefined;
                            let data = oneRecordsetListData?.[dataObject.id]?.value;
                            if (typeof data === "object") {
                              if (colItem.element_type === "selectboxes") {
                                return (
                                  <div
                                    className="recordset_field_container"
                                    key={colItemIndex}
                                    style={{ gridArea: `zino-${colItem?.element_id || colItemIndex}` }}
                                  >
                                    <p className="recordset_field_label">{dataObject.label}</p>
                                    <p className="recordset_field_content">{Object.values(data).join(", ") || "-"}</p>
                                  </div>
                                );
                              } else if (colItem.element_type === "file") {
                                return (
                                  <div
                                    className="recordset_field_container"
                                    key={colItemIndex}
                                    style={{ gridArea: `zino-${colItem?.element_id || colItemIndex}` }}
                                  >
                                    <p className="recordset_field_label">{dataObject.label}</p>
                                    <>
                                      {data.map((imageIs, index) => {
                                        return (
                                          <p className="recordset_field_content" key={index}>
                                            {imageIs.file_name || imageIs.name || ""}
                                          </p>
                                        );
                                      })}
                                    </>
                                  </div>
                                );
                              } else if (colItem.element_type === "image") {
                                return (
                                  <div
                                    className="recordset_field_container"
                                    key={colItemIndex}
                                    style={{ gridArea: `zino-${colItem?.element_id || colItemIndex}` }}
                                  >
                                    {/* <p className="recordset_field_label">{dataObject.label}</p> */}
                                    <div className="recordset_field_content image_content">
                                      {data?.length > 0
                                        ? data.map((imageIs, index) => {
                                            if (imageIs.url === "true") {
                                              return <ReadFile file={imageIs.file} dataObject={imageIs} />;
                                            } else if (imageIs.url) {
                                              return (
                                                <img
                                                  key={index}
                                                  src={imageIs.url}
                                                  alt={imageIs.file_name}
                                                  style={{ height: "40px", width: "100%" }}
                                                />
                                              );
                                            } else if (imageIs.name) {
                                              return (
                                                <img
                                                  key={index}
                                                  src={`${getApiCallLocalPath()}api/v1/assets/fileName/${imageIs.name}`}
                                                  alt={imageIs.name}
                                                  style={{ height: "40px", width: "100%" }}
                                                />
                                              );
                                            } else {
                                              return <p className="recordset_field_content">-</p>;
                                            }
                                          })
                                        : "-"}
                                    </div>
                                  </div>
                                );
                              } else if (colItem.element_type === "lookup" || colItem.element_type === "lookup-sm") {
                                // colItem.field.display_fields
                                let dataIs = [];
                                for (let index = 0; index < colItem.field.display_fields.length; index++) {
                                  const element = colItem.field.display_fields[index];
                                  if (data?.[element.id]) {
                                    dataIs.push(data[element.id]);
                                  }
                                }
                                return (
                                  <div
                                    className="recordset_field_container"
                                    key={colItemIndex}
                                    style={{ gridArea: `zino-${colItem?.element_id || colItemIndex}` }}
                                  >
                                    <p className="recordset_field_label">{dataObject.label}</p>
                                    <p className="recordset_field_content">{dataIs.join(", ") || "-"}</p>
                                  </div>
                                );
                              } else if (colItem.element_type === "app-user") {
                                return (
                                  <div
                                    className="recordset_field_container"
                                    key={colItemIndex}
                                    style={{ gridArea: `zino-${colItem?.element_id || colItemIndex}` }}
                                  >
                                    <p className="recordset_field_label">{dataObject.label}</p>
                                    <p className="recordset_field_content">{data.name || "-"}</p>
                                  </div>
                                );
                              } else if (colItem.element_type === "phone") {
                                return (
                                  <div
                                    className="recordset_field_container"
                                    key={colItemIndex}
                                    style={{ gridArea: `zino-${colItem?.element_id || colItemIndex}` }}
                                  >
                                    <p className="recordset_field_label">{dataObject.label}</p>
                                    <p className="recordset_field_content">
                                      {data?.dial_code && data?.phone ? `+${data?.dial_code} ${data?.phone}` : `-`}
                                    </p>
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    className="recordset_field_container"
                                    key={colItemIndex}
                                    style={{ gridArea: `zino-${colItem?.element_id || colItemIndex}` }}
                                  >
                                    <p className="recordset_field_label">{dataObject.label}</p>
                                    <p className="recordset_field_content">{JSON.stringify(data) || "-"}</p>
                                  </div>
                                );
                              }
                            } else {
                              if (["date", "time", "datetime"]?.includes(colItem.element_type)) {
                                let formattedDate = "";
                                let timestamp = data;
                                if (timestamp) {
                                  if (colItem.element_type === "time") {
                                    formattedDate += convertNumbetToTime(timestamp);
                                  } else {
                                    formattedDate += convertNumbetToDate(timestamp, colItem?.element_type, "string", [
                                      "dd",
                                      "mm",
                                      "yyyy"
                                    ]);
                                  }
                                }
                                return (
                                  <div
                                    className="recordset_field_container"
                                    key={colItemIndex}
                                    style={{ gridArea: `zino-${colItem?.element_id || colItemIndex}` }}
                                  >
                                    <p className="recordset_field_label">{dataObject.label}</p>
                                    <p className="recordset_field_content">{formattedDate || "-"}</p>
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    className="recordset_field_container"
                                    key={colItemIndex}
                                    style={{ gridArea: `zino-${colItem?.element_id || colItemIndex}` }}
                                  >
                                    <p className="recordset_field_label">{dataObject.label}</p>
                                    <p className="recordset_field_content">{data || "-"}</p>
                                  </div>
                                );
                              }
                            }
                          })}
                        </div>
                      </div>
                    );
                  })}
                  {showTotalTable && (
                    <table className="recordset_table_container" style={{ width: "95%" }}>
                      <thead className="recordset_table_header_container">
                        <tr>
                          <th className="recorset_table_header_label">Label</th>
                          <th className="recorset_table_header_label">Total</th>
                        </tr>
                      </thead>
                      <tbody className="recordset_table_body_container">
                        {rowItemElements?.map((colItem, colItemIndex) => {
                          let dataObject = colItem?.field;
                          if (!dataObject.show) return undefined;
                          if (colItem?.element_type === "number" && colItem?.field?.show_sum) {
                            let value = 0;
                            recordsetListData?.forEach((eachRecord) => {
                              value = value + eachRecord?.[colItem?.element_id]?.value || 0;
                            });
                            return (
                              <tr key={colItemIndex}>
                                <td className="recordset_table_content">{colItem?.field?.label}</td>
                                <td className="recordset_table_content">{value}</td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tbody>
                    </table>
                  )}
                </>
              ) : (
                <table className="recordset_table_container">
                  <thead className="recordset_table_header_container">
                    <tr>
                      {rowItemElements?.map((rowItem, rowIndex) => {
                        let dataObject = rowItem.field;
                        if (!dataObject.show) return undefined;
                        return (
                          <th key={rowIndex} className="recorset_table_header_label">
                            {rowItem.field.label}
                          </th>
                        );
                      })}
                      <th className="recordset_table_header_label">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="recordset_table_body_container">
                    {recordsetListData?.map((oneRecordsetListData, oneRecordsetListIndex) => {
                      return (
                        <tr key={oneRecordsetListIndex}>
                          {rowItemElements?.map((colItem, colItemIndex) => {
                            let dataObject = colItem.field;
                            if (!dataObject.show) return undefined;
                            let data = oneRecordsetListData?.[dataObject.id]?.value;
                            if (typeof data === "object") {
                              if (colItem.element_type === "selectboxes") {
                                return (
                                  <td key={colItemIndex} className="recordset_table_content">
                                    {Object.values(data).join(",")}
                                  </td>
                                );
                              } else if (colItem.element_type === "file") {
                                return (
                                  <td key={colItemIndex} className="recordset_table_content">
                                    {data.map((imageIs, index) => {
                                      return <span key={index}>{imageIs.file_name || imageIs.name || "-"}</span>;
                                    })}
                                  </td>
                                );
                              } else if (colItem.element_type === "image") {
                                return (
                                  <td key={colItemIndex} className="recordset_table_content recordset_image_content">
                                    {data.length > 0
                                      ? data.map((imageIs, index) => {
                                          if (imageIs.url === "true") {
                                            return <ReadFile file={imageIs.file} dataObject={imageIs} />;
                                          } else if (imageIs.url) {
                                            return (
                                              <img
                                                key={index}
                                                src={imageIs.url}
                                                alt={imageIs.file_name}
                                                style={{ height: "40px" }}
                                              />
                                            );
                                          } else if (imageIs.name) {
                                            return (
                                              <img
                                                key={index}
                                                src={`${getApiCallLocalPath()}api/v1/assets/fileName/${imageIs.name}`}
                                                alt={imageIs.name}
                                                style={{ height: "40px" }}
                                              />
                                            );
                                          } else {
                                            return "-";
                                          }
                                        })
                                      : "-"}
                                  </td>
                                );
                              } else if (colItem.element_type === "lookup" || colItem.element_type === "lookup-sm") {
                                let dataIs = [];
                                for (let index = 0; index < colItem.field.display_fields.length; index++) {
                                  const element = colItem.field.display_fields[index];
                                  if (data?.[element.id]) {
                                    dataIs.push(data[element.id]);
                                  }
                                }
                                return (
                                  <td key={colItemIndex} className="recordset_table_content">
                                    {dataIs.join(",")}
                                  </td>
                                );
                              } else if (colItem.element_type === "app-user") {
                                return (
                                  <td key={colItemIndex} className="recordset_table_content">
                                    {data.name}
                                  </td>
                                );
                              } else if (colItem.element_type === "phone") {
                                return (
                                  <td key={colItemIndex} className="recordset_table_content">
                                    {data?.dial_code && data?.phone ? `+${data?.dial_code} ${data?.phone}` : `-`}
                                  </td>
                                );
                              } else {
                                return (
                                  <td key={colItemIndex} className="recordset_table_content">
                                    {JSON.stringify(data)}
                                  </td>
                                );
                              }
                            } else {
                              if (["date", "time", "datetime"]?.includes(colItem.element_type)) {
                                let formattedDate = "";
                                let timestamp = data;
                                if (timestamp) {
                                  if (colItem.element_type === "time") {
                                    formattedDate += convertNumbetToTime(timestamp);
                                  } else {
                                    formattedDate += convertNumbetToDate(timestamp, colItem?.element_type, "string", [
                                      "dd",
                                      "mm",
                                      "yyyy"
                                    ]);
                                  }
                                }
                                return (
                                  <td key={colItemIndex} className="recordset_table_content">
                                    {formattedDate || "-"}
                                  </td>
                                );
                              } else {
                                return (
                                  <td key={colItemIndex} className="recordset_table_content">
                                    {data || "-"}
                                  </td>
                                );
                              }
                            }
                          })}
                          <td>
                            <div className="recordset_table_action_container">
                              <span
                                className="material-symbols-outlined edit_recordset_icon"
                                onClick={() => {
                                  editOldRecordset(oneRecordsetListIndex);
                                  setShowForm(true);
                                }}
                              >
                                edit
                              </span>
                              {gridComponent?.allow_delete && (
                                <span
                                  className="material-symbols-outlined delete_recordset_icon"
                                  onClick={() => {
                                    removeOldRecordset(oneRecordsetListIndex);
                                  }}
                                >
                                  delete
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    {showTotalTable && (
                      <tr>
                        {rowItemElements?.map((colItem, colItemIndex) => {
                          let dataObject = colItem?.field;
                          if (!dataObject.show) return undefined;
                          if (colItem?.element_type === "number" && colItem?.field?.show_sum) {
                            let value = 0;
                            recordsetListData?.forEach((eachRecord) => {
                              value = value + eachRecord?.[colItem?.element_id]?.value || 0;
                            });

                            return (
                              <td className="recordset_table_content" key={colItemIndex}>
                                {value}
                              </td>
                            );
                          } else {
                            return <td className="recordset_table_content" key={colItemIndex}></td>;
                          }
                        })}
                        <td className="recordset_table_content"></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
            {gridComponent?.allow_add && (
              <div className="add_grid_button_container ">
                <span className="add_grid_button" onClick={() => setShowForm(true)}>
                  {`Add ${gridComponent?.label}`}
                </span>
              </div>
            )}
          </>
        )}
        {!showCards && !showForm && (
          <div>
            <div style={styleDataForGrid}>
              {rowItemElements.map((colItem, colItemIndex) => {
                let dataObject = colItem.field;
                let currentConfigKeyRS = `${currentConfigKey}:${dataObject?.id || colItemIndex}`;
                let styleObject = {
                  gridArea: `zino-${colItem.element_id}`
                };
                if (dataObject.show) {
                  return (
                    <FieldView
                      key={colItem.element_id}
                      colItem={colItem}
                      dataObject={dataObject}
                      styleObject={styleObject}
                      currentConfigKey={currentConfigKeyRS}
                      namespace={namespace}
                      parentField="grid"
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
            <div className="add_grid_button_container">
              <span className="done_grid_button" onClick={addNewRecordset}>
                {`Add ${gridComponent?.label}`}
              </span>
            </div>
          </div>
        )}
        {showForm && (
          <div className="right_popup_container">
            <div className="right_popup_child">
              <div className="right_popup_header">
                <p className="right_popup_label">{gridComponent.label}</p>
                <span className="material-symbols-outlined right_popup_icon" onClick={addNewRecordset}>
                  close
                </span>
              </div>
              <div className="right_popup_body">
                <div style={styleDataForGrid}>
                  {rowItemElements.map((colItem, colItemIndex) => {
                    let dataObject = colItem.field;
                    let currentConfigKeyRS = `${currentConfigKey}:${dataObject?.id || colItemIndex}`;
                    let styleObject = {
                      gridArea: `zino-${colItem.element_id}`
                    };
                    if (dataObject.show) {
                      return (
                        <FieldView
                          key={colItem.element_id}
                          colItem={colItem}
                          dataObject={dataObject}
                          styleObject={styleObject}
                          currentConfigKey={currentConfigKeyRS}
                          namespace={namespace}
                          parentField="grid"
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
                <div className="add_grid_button_container">
                  <span className="done_grid_button" onClick={addNewRecordset}>
                    {`Add ${gridComponent?.label}`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FFGridField;

const ReadFile = ({ file, dataObject }) => {
  if (form_images_store[dataObject.file_id]) {
    return (
      <img
        src={form_images_store[dataObject.file_id]}
        alt=""
        style={{ height: "80px", width: "100%", objectFit: "cover", borderRadius: "4px" }}
      />
    );
  }
  return "-";
};

function callCustomGrid(fullData, customJsFunction) {
  try {
    if (typeof customJsFunction === "string" && customJsFunction) {
      // eslint-disable-next-line no-new-func
      // const callMe = new Function("data", customJsFunction);
      // let res = callMe(fullData);
      let newFunWithData = `let data = ${JSON.stringify(fullData)}
      ${customJsFunction}`;
      // eslint-disable-next-line no-eval
      let res = eval(newFunWithData);
      return res?.data || fullData;
    }
    return fullData;
  } catch (error) {
    return fullData;
  }
}

function updateData(newData, oldDataJson, componentKey) {
  // Iterate over the keys in newData
  for (const key in newData) {
    if (newData.hasOwnProperty(key)) {
      // Check if the key exists in the oldDataJson
      for (const section in oldDataJson) {
        if (oldDataJson[section].hasOwnProperty(key)) {
          // Update the value
          oldDataJson[section][key].value = newData[key];
        } else if (key !== componentKey) {
          oldDataJson[section][key] = { value: newData[key], errors: [] };
        }
      }
    }
  }
  return oldDataJson;
}

const CardExtraActions = ({
  editOldRecordset,
  removeOldRecordset,
  oneRecordsetListIndex,
  setShowForm,
  allow_delete = true
}) => {
  const [showActions, setShowActions] = useState(false);

  const cardRef = useRef(null);

  const handleClickOutside = (event) => {
    if (cardRef.current && !cardRef.current.contains(event.target)) {
      setShowActions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={cardRef}
      className="card_extra_action_container"
      style={{
        backgroundColor: showActions ? "#fafafa" : "#fafafa70"
      }}
      onClick={() => setShowActions(!showActions)}
    >
      <span className="material-symbols-outlined" style={{ fontSize: "24px", color: "#667085" }}>
        {showActions ? "arrow_right" : "arrow_left"}
      </span>
      {showActions && (
        <div className="card_extra_actions_list_container">
          <span
            onClick={() => {
              editOldRecordset(oneRecordsetListIndex);
              setShowForm(true);
            }}
            className="edit_recordset_icon"
          >
            <FSIconRenderer icon="edit" iconColor="#4a1fb8" marginR="0px" />
          </span>
          {allow_delete && (
            <span
              onClick={() => {
                removeOldRecordset(oneRecordsetListIndex);
              }}
              className="delete_recordset_icon"
            >
              <FSIconRenderer icon="delete" iconColor="#f04438" marginR="0px" />
            </span>
          )}
        </div>
      )}
    </div>
  );
};
