import axios from "axios";
import React, { useRef, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { toastErrorMessageStyle } from "../../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../../utils/storageFunction";
import noimg from "../../../assets/images/noimg.jpeg";
import { Skeleton } from "@mui/material";

const CabinetPopup = ({ setShowCabinent, handleCabinetfileSelect, component, imageDataArray }) => {
  const cabinentRef = useRef(null);
  const [cabinetArray, setCabinetArray] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selecteCabinetTab, setSelectedCabinetTab] = useState(false);
  const skeletons = Array.from({ length: 15 });

  const fetchCabinetList = async () => {
    try {
      setLoader(true);
      const { data } = await axios.get(`${process.env.REACT_APP_CABINET_API}/api/v1/asset-file/default`, {
        headers: getApiCallHeadersData()
      });
      setCabinetArray(data?.data);
    } catch (error) {
      setCabinetArray([]);
      toast("files not able to fetch", toastErrorMessageStyle());
    } finally {
      setLoader(false);
    }
  };

  const handlePreviousSelectedImages = () => {
    if (imageDataArray?.length > 0) {
      setSelectedImages(imageDataArray?.map((image) => image?.id));
    } else {
      setSelectedImages([]);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cabinentRef.current && !cabinentRef.current.contains(event.target)) {
        setShowCabinent(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchCabinetList();
    handlePreviousSelectedImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageError = (e) => {
    e.target.src = noimg;
  };

  const handleImageSelect = (id) => {
    let prev = [...selectedImages] || [];

    if (prev?.includes(id)) {
      prev = prev?.filter((item) => item !== id);
    } else {
      if (prev?.length < Number(component?.fileMaxRange)) {
        prev.push(id);
      } else {
        toast?.error("Max allowed files is reached", toastErrorMessageStyle());
      }
    }
    setSelectedImages(prev);
  };

  const handleSubmit = () => {
    let imagesArrayObj = selectedImages?.map((id) => cabinetArray?.find((cabinetImage) => cabinetImage?.id === id));
    handleCabinetfileSelect(imagesArrayObj);
    setShowCabinent(false);
  };

  return (
    <div className="cabinent_parent_container" ref={cabinentRef} onClick={() => setShowCabinent(false)}>
      <div className="cabinent_content_container" onClick={(e) => e.stopPropagation()}>
        <div className="cabinent_header_container">
          <div className="cabinent_header_title">
            <span>Cabinet</span>
          </div>
          <span className="material-symbols-outlined cabinet_close_icon" onClick={() => setShowCabinent(false)}>
            close
          </span>
        </div>
        <div className="cabinent_body_container">
          {loader ? (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "4px", justifyContent: "space-around" }}>
              {skeletons.map((_, index) => (
                <Skeleton key={index} variant="rectangular" width={"32vw"} height={100} />
              ))}
            </div>
          ) : (
            <div>
              <div className="cabinet_body_tabs">
                <div
                  className={`${
                    selecteCabinetTab === false ? "cabinet_tab_container selected_cabinet_tab" : "cabinet_tab_container"
                  }`}
                  onClick={() => setSelectedCabinetTab(false)}
                >
                  <span className="cabinet_tab">Un used</span>
                </div>
                <div
                  className={`${
                    selecteCabinetTab === true ? "cabinet_tab_container selected_cabinet_tab" : "cabinet_tab_container"
                  }`}
                  onClick={() => setSelectedCabinetTab(true)}
                >
                  <span className="cabinet_tab">Used</span>
                </div>
              </div>
              <div className="cabinet_img_container">
                {cabinetArray
                  ?.filter((imgObject) => imgObject?.read === selecteCabinetTab)
                  ?.map((imgObject, imgIndex) => {
                    return (
                      <div
                        key={imgIndex}
                        className="cabiner_each_img_container"
                        onClick={() => handleImageSelect(imgObject?.id)}
                      >
                        <img
                          src={`${process.env.REACT_APP_CABINET_API}/api/v1/asset-file/default/${imgObject?.id}`}
                          alt={imgObject?.name}
                          className="cabinet_image"
                          onError={handleImageError}
                        />
                        {selectedImages?.includes(imgObject?.id) && (
                          <div className="selectedImageEffect">
                            <span className="material-symbols-outlined selectedImageIcon">check</span>
                          </div>
                        )}
                      </div>
                    );
                  })}
                <button className="primary_default_button cabinet_select_button" onClick={handleSubmit}>
                  Select
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CabinetPopup;
