import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

//styles
import "./App.css";
import "./UserStyle.css";
import "./defaultStyles.css";
import "./className.css";
// import "./themes/dark.css";

//pages
import WelcomePage from "./pages/welcome/WelcomePage";
import LoginPage from "./pages/login/LoginPage";
import PageDashboard from "./pages/PageDashboard";
import Page404 from "./pages/other/Page404";
import { ProcessBarSection } from "./lib";
import AppsList from "./pages/other/AppsList";
import ForgotPassword from "./pages/other/ForgotPassword";
import ResetPassword from "./pages/other/ResetPassword";
import ResetPasswordDone from "./pages/other/ResetPasswordDone";
import OTPScreen from "./pages/other/OTPScreen";
import PublicApps from "./pages/publicScreens/PublicApps";
import PublicPageList from "./pages/publicScreens/PublicPageList";
import SelectedPublicPage from "./pages/publicScreens/SelectedPublicPage";

//redux
import { useSelector, useDispatch } from "react-redux";
import { updateUserDataFunction } from "./redux/userview/userviewaction";
import { setAppsList } from "./redux/appview/appviewaction";

//axios
import axios from "axios";
import { getApiCallHeadersData } from "./utils/storageFunction";

//context
import ImagePreviewContext from "./applicationcontext/ImagePreviewContext";
import { FormHandlerContextProvider } from "./lib/formrendererlib/FormContext";
import { toastErrorMessageStyle } from "./utils/apiCallFunction";
import { useNetworkContext } from "./applicationcontext/NetWorkContext";

//routes
import { PUBLIC_PAGE_LIST_ROUTE, PUBLIC_PAGE_ROUTE } from "./routes/routes";
import CartScreenRenderer from "./pages/CartScreenRenderer";
import { PageHandlerContextProvider } from "./pagehandler/PageHandlerContext";

const App = () => {
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const [showIosInstallPrompt, setShowIosInstallPrompt] = useState(
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream && !window.navigator.standalone
  );
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { user, authenticate } = useSelector((state) => state.userviewstore);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isPublicPage = location.pathname.includes("/public");
  const { handleNetworkMode, networkMode } = useNetworkContext();

  //for validating usertoken and navigating according to it
  useEffect(() => {
    if (!authenticate && !user) {
      setIsLoading(true);
      if (!authenticate) dispatch(updateUserDataFunction());
    } else if (!user.loginFlag && !isPublicPage) {
      setIsLoading(false);
      navigate("/");
    }
    async function getApps() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOGIN_URL}/api/v1/applist`, {
          headers: getApiCallHeadersData()
        });
        dispatch(setAppsList(response?.data?.app_list));
      } catch (error) {
        toast.error(error?.response?.data?.message || "Apps Not Retrived", toastErrorMessageStyle());
        dispatch(setAppsList([]));
      }
    }
    if (authenticate && user) {
      getApps();
    }
    return () => {
      setIsLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate, user]);

  //install popup
  const installApp = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(() => {
        setShowInstallPrompt(false);
      });
    }
  };

  const removePopup = () => {
    setShowInstallPrompt(false);
  };

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setShowInstallPrompt(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
  }, []);

  useEffect(() => {
    handleNetworkMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <ProcessBarSection />;
  } else {
    return (
      <>
        {networkMode === "offline" && (
          <div className="offline_message_container">
            <p>You are offline</p>
          </div>
        )}
        {showInstallPrompt && (
          <div className="install-prompt">
            <p>Install app to your device for a better experience!</p>
            <div className="install-prompt-button-container">
              <button onClick={removePopup} className="secondary_default_button popup_button">
                Cancel
              </button>
              <button onClick={installApp} className="primary_default_button popup_button">
                Install
              </button>
            </div>
          </div>
        )}
        {showIosInstallPrompt && (
          <div className="ios-install-prompt">
            <p style={{ wordBreak: "break-word" }}>
              Please add this website to your home screen for easy access. Open Safari, tap the share icon, and select
              'Add to Home Screen'.
            </p>
            <div className="ios-install-prompt-button-container">
              <span className="material-symbols-outlined delete-icon" onClick={() => setShowIosInstallPrompt(false)}>
                close
              </span>
            </div>
          </div>
        )}
        <ImagePreviewContext>
          <FormHandlerContextProvider>
            <PageHandlerContextProvider>
              <Routes>
                <Route path="/" element={<WelcomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path={PUBLIC_PAGE_LIST_ROUTE} element={<PublicApps />} />
                <Route path={PUBLIC_PAGE_ROUTE + ":app"} element={<PublicPageList />} />
                <Route path={PUBLIC_PAGE_ROUTE + ":app/:page"} element={<SelectedPublicPage />} />
                <Route path="/forgotPassword" element={<ForgotPassword />} />
                <Route path="/resetPassword" element={<ResetPassword />} />
                <Route path="/resetSuccess" element={<ResetPasswordDone />} />
                <Route path="/otp" element={<OTPScreen />} />
                {authenticate && <Route path="/apps menu" element={<AppsList />} />}
                {authenticate && <Route path="/:pagename" element={<PageDashboard />} />}
                {authenticate && <Route path="/my cart/:cart" element={<CartScreenRenderer />} />}
                <Route path="/page404" element={<Page404 />} />
              </Routes>
            </PageHandlerContextProvider>
          </FormHandlerContextProvider>
          <Toaster position="bottom-center" reverseOrder={false} />
        </ImagePreviewContext>
      </>
    );
  }
};

export default App;
