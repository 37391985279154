import React, { useEffect, useState, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import logo from "../../assets/images/companyLogo.png";
import { getStorageItem } from "../../utils/storageFunction";
import { PUBLIC_PAGE_LIST_ROUTE } from "../../routes/routes";

import "./WelcomePage.css";

const WelcomePage = () => {
  const { authenticate, logincall } = useSelector((state) => state.userviewstore);
  const navigate = useNavigate();
  const [companyLogo, setCompanyLogo] = useState("");
  const [loader, setLoader] = useState(false);

  async function getCompanyLogo() {
    try {
      setLoader(true);
      const response = await fetch(`${process.env.REACT_APP_DETAILS_URL}/api/v1/web-tab-title/${window.location.host}`);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (data?.data?.title && data?.data?.favicon && data?.data?.app_logo) {
        setCompanyLogo(data?.data?.app_logo);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    if (authenticate && !logincall) {
      if (JSON.parse(getStorageItem("selectedApp"))?.id) {
        navigate("/home");
      } else {
        navigate("/apps menu");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useLayoutEffect(() => {
    getCompanyLogo();
  }, []);

  return (
    <>
      {loader ? (
        <div className="zino_renderer_loader_box">
          <span className="zino_renderer_loader"></span>
        </div>
      ) : (
        <div className="welcome_screen_container">
          <div className="welcome_logo_container">
            <img
              src={companyLogo ? `${process.env.REACT_APP_DETAILS_URL}/api/v1/public/${companyLogo}` : logo}
              alt="Logo"
              className="welcome_logo"
            />
          </div>
          <div className="welcome_screen_login_container">
            <button className="primary_default_button welcome_screen_login_button" onClick={() => navigate("/login")}>
              Login Here
            </button>
          </div>
          <div></div>
          <button onClick={() => navigate(PUBLIC_PAGE_LIST_ROUTE)} className="welcome_screen_guest_user_button">
            Guest User
          </button>
        </div>
      )}
    </>
  );
};

export default WelcomePage;
