import React from "react";

const CircularProgressBar = ({ percentage, itemConfig, value }) => {
  const style = {
    background: itemConfig?.color
      ? `conic-gradient(${itemConfig?.color} 0% ${percentage}%, #e0e0e0 ${percentage}% 100%)`
      : `conic-gradient(#4caf50 0% ${percentage}%, #e0e0e0 ${percentage}% 100%)`
  };

  return (
    <div className="linear_main_container">
      <div className="progress-circle" style={style}>
        <div className="progress-inner">
          <span className="progress-percentage" style={{ ...(itemConfig?.color && { color: itemConfig?.color }) }}>
            {value}
          </span>
        </div>
      </div>
      <span className="linear_title">{itemConfig?.title}</span>
    </div>
  );
};

export default CircularProgressBar;
