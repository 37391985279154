import axios from "axios";
import toast from "react-hot-toast";
import { getStorageItem, removeStorageItem, setStorageItem, removeTheme } from "../../utils/storageFunction";
import { checkApiCallLocalOrNot, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { setAppsList, setSelectedApp } from "../appview/appviewaction";

export const LOGIN_CALL = "LOGIN_CALL";
export const ADD_USER = "ADD_USER";
export const USER_AUTHENTICATE = "USER_AUTHENTICATE";
export const USER_TOKEN = "USER_TOKEN";
export const REMOVE_USER = "REMOVE_USER";

export const setUserData = (payload) => ({
  type: ADD_USER,
  payload
});

export const setUserToken = (payload) => ({
  type: USER_TOKEN,
  payload
});

export const setAuthenticate = (payload) => ({
  type: USER_AUTHENTICATE,
  payload
});

export const setLoginCall = (payload) => ({
  type: LOGIN_CALL,
  payload
});

export const removeUserData = (payload) => ({
  type: REMOVE_USER,
  payload
});

export const callUserLoginFunction = (loginData) => async (dispatch) => {
  try {
    dispatch(setLoginCall(true));
    let userData;
    let response = await axios.post(`${process.env.REACT_APP_LOGIN_URL}/api/v1/login`, loginData);
    let data = response.data;
    userData = data;
    if (userData) {
      setStorageItem("Zino_app_user", userData?.access_token);
      dispatch(updateUserDataFunction());
      dispatch(setAppsList(userData?.app_list));
    } else {
      toast.error("Invalid Credentials", toastErrorMessageStyle());
    }
  } catch (error) {
    toast.error(error?.response?.data?.message || "Invalid Credentials", toastErrorMessageStyle());
  } finally {
    dispatch(setLoginCall(false));
  }
};

export const updateUserDataFunction = () => async (dispatch) => {
  let resetUser = false;
  let token = getStorageItem("Zino_app_user");
  if (token) {
    const decode = JSON.parse(atob(token.split(".")[1]));
    if (decode.exp >= Math.floor(Date.now() / 1000)) {
      dispatch(setUserToken(`Bearer ${token}`));
      dispatch(
        setUserData({
          login_user_id: decode?.user_uuid,
          name: decode?.name || "Zino",
          email: decode?.email || "zino@getzino.com",
          exp_time: decode?.exp || Math.floor(Date.now() / 1000) + 86400,
          token: token,
          loginFlag: true
        })
      );
      dispatch(setAuthenticate(true));
    } else {
      resetUser = true;
    }
  } else {
    resetUser = true;
  }
  if (resetUser) {
    dispatch(removeUserData());
    removeStorageItem("", ["Zino_app_user", "Zino_app_route", "selectedApp"]);
  }
};

export const callUserLogoutFunction = () => async (dispatch) => {
  try {
    if (checkApiCallLocalOrNot() && window.location.host.includes("localhost:")) {
    } else {
      removeStorageItem("selectedApp");
      removeStorageItem("selected_module");
      dispatch(removeUserData());
      dispatch(setSelectedApp({}));
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_LOGIN_URL}/api/v1/logout`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorageItem("Zino_app_user")}`
        }
      });
      removeStorageItem("Zino_app_user");
    }
    removeTheme();
  } catch (error) {
    toast.error(error.response?.data?.error || "Please logout again", toastErrorMessageStyle());
  }
};

export const validateOtp = (phoneNumber, otpValue) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_LOGIN_URL}/api/v1/login/otp/${phoneNumber}/verify/${otpValue}`
    );
    setStorageItem("Zino_app_user", data?.access_token);
    dispatch(updateUserDataFunction());
    dispatch(setAppsList(data?.app_list));
  } catch (error) {
    toast.error(error?.response?.data?.message || "something went wrong", toastErrorMessageStyle());
  } finally {
    dispatch(setLoginCall(false));
  }
};
