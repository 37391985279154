import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setSelectedApp } from "../../redux/appview/appviewaction";
import { setStorageItem } from "../../utils/storageFunction";
import Header from "../../components/Header";
import AppCard from "../../components/appList/AppCard";

const AppsList = () => {
  const { appList } = useSelector((state) => state.appviewstore);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleApp(app) {
    dispatch(setSelectedApp(app));
    setStorageItem("selectedApp", JSON.stringify(app));
    navigate("/home");
  }

  return (
    <>
      <Header headerConfig={{}} />
      <div className="apps_container">
        {appList?.map((app, index) => (
          <AppCard key={index} app={app} callback={handleApp} />
        ))}
      </div>
    </>
  );
};

export default AppsList;
