const injectCSS = (css) => {
  let c0s1s0R2o2o3t = document.getElementById("load_extra_css");
  if (!c0s1s0R2o2o3t) return;
  c0s1s0R2o2o3t.innerHTML = "";
  let s2t0y2l3e = document.createElement("style");
  s2t0y2l3e.type = "text/css";
  s2t0y2l3e.textContent = css;
  c0s1s0R2o2o3t.appendChild(s2t0y2l3e);
  //   document.head.appendChild(el);
};

const injectJS = (js) => {
  let j2s0R2o5o0t = document.getElementById("load_extra_js");
  if (!j2s0R2o5o0t) return;
  j2s0R2o5o0t.innerHTML = "";
  let j0s1R0o3o2t5 = document.createElement("script");
  j0s1R0o3o2t5.type = "text/javascript";
  j0s1R0o3o2t5.defer = true;
  j0s1R0o3o2t5.textContent = js;
  j2s0R2o5o0t.appendChild(j0s1R0o3o2t5);
  //   document.head.appendChild(el);
};

const waitforsometime = (time = 5000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(`Promise resolved after ${time}ms`);
    }, time);
  });
};

export { injectCSS, injectJS, waitforsometime };
