import axios from "axios";
import toast from "react-hot-toast";
import { setHideComponentKeyInConfig } from "../../utils/updateConfigFunction";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData, removeStorageItem, setStorageItem, getStorageItem } from "../../utils/storageFunction";

export const DASHBOARD_CONFIG_APP_DATA = "DASHBOARD_CONFIG_APP_DATA";
export const DASHBOARD_CONFIG_ORG_DATA = "DASHBOARD_CONFIG_ORG_DATA";
export const WORKFLOW_APP_OBJECT_DATA = "WORKFLOW_APP_OBJECT_DATA";
export const ACTION_JOB_RES_DATA = "ACTION_JOB_RES_DATA";
export const ERROR_SHOW = "ERROR_SHOW";
export const NOTIFICATIONS_ARRAY = "NOTIFICATIONS_ARRAY";
export const APPS_LIST = "APPS_LIST";
export const SELECTED_APP = "SELECTED_APP";

export const setWorkflowAppObjectData = (payload) => ({
  type: WORKFLOW_APP_OBJECT_DATA,
  payload
});

export const setDashboardAppConfig = (payload) => ({
  type: DASHBOARD_CONFIG_APP_DATA,
  payload
});

export const setNotifications = (payload) => ({
  type: NOTIFICATIONS_ARRAY,
  payload
});

export const setDashboardOrganizationConfig = (payload) => ({
  type: DASHBOARD_CONFIG_ORG_DATA,
  payload
});

export const setActionJobResData = (payload) => ({
  type: ACTION_JOB_RES_DATA,
  payload
});

export const setErrorShow = (payload) => ({
  type: ERROR_SHOW,
  payload
});

//for multiple apps
export const setAppsList = (payload) => ({
  type: APPS_LIST,
  payload
});

export const setSelectedApp = (payload) => ({
  type: SELECTED_APP,
  payload
});

export const getDashboardConfigFunction = (page) => async (dispatch, getState) => {
  try {
    let cacheresponse = await getCacheResponse();

    dispatch(setDashboardAppConfig({}));

    let apiPayload = {
      data: "",
      function_name: "get_screen",
      params: {
        device: "mobile",
        name: page
      }
    };

    if (Object.keys(cacheresponse?.value || {}).length > 0) {
      apiPayload.params["token_data"] = cacheresponse?.value || {};
    }

    let { data, version } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, apiPayload, {
      headers: getApiCallHeadersData()
    });
    data = data.data;
    data.layout = await setHideComponentKeyInConfig(data.layout);

    //hard refresh if version changes
    const prevVerison = getStorageItem("version") || "";
    const newVersion = version?.pwa || "";
    if (prevVerison !== newVersion) {
      setStorageItem("currentScreen", page);
      window.location.reload(true);
      const currentScreen = getStorageItem("currentScreen");
      await dispatch(getDashboardConfigFunction(currentScreen));
      setStorageItem("version", version?.pwa);
      removeStorageItem("currentScreen");
    }

    // Load CSS
    const cssRoot = document.getElementById("cssroot");
    if (!cssRoot.innerHTML || page === "home") {
      const styleFlie = document.createElement("link");
      styleFlie.href = `${getApiCallLocalPath()}api/v1/assets/${data.theme_id}.css`;
      styleFlie.rel = "stylesheet";
      styleFlie.id = "stylesheetlink";
      cssRoot.innerHTML = "";
      cssRoot.appendChild(styleFlie);
    }
    // if (data?.is_access) {
    if (data?.page_name !== "applist") {
      let notificationCount = await dispatch(getNotificationsCountFunction());
      data["header"]["notifications_count"] = notificationCount || 0;
      const sampleObject = {
        header: data?.header || {},
        page: data?.layout || [],
        module_header: data?.module_header || [],
        is_access: data.is_access,
        page_name: data?.page_name,
        custom_css: data?.custom_css || "",
        custom_js: data?.custom_js || "",
        cacheresponse: cacheresponse || {}
      };
      dispatch(setDashboardAppConfig(sampleObject));
    } else {
      dispatch(setDashboardAppConfig({}));
      removeStorageItem("selectedApp");
      let { appList } = getState()?.appviewstore || [];
      if (appList?.length === 1) {
        window.open("page404", "_self");
      } else {
        toast.error("screen not found", toastErrorMessageStyle());
        window.history.back();
      }
    }
  } catch (error) {
    toast.error(
      error.response?.data?.message || error.response?.data.error || "App not found",
      toastErrorMessageStyle()
    );
    dispatch(setDashboardAppConfig({}));
    removeStorageItem("selectedApp");
    window.history.back();
  }
};

const getCacheResponse = async () => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (!token) {
      return {};
    }
    let cacheresponse = await axios.get(`${getApiCallLocalPath()}api/v1/cache/${token}`, {
      headers: getApiCallHeadersData()
    });

    if (cacheresponse?.data?.data) {
      cacheresponse = cacheresponse.data.data;
    } else {
      cacheresponse = {};
    }
    return cacheresponse;
  } catch (error) {
    return {};
  }
};

export const getSubPageConfigFunction = (page) => async (dispatch) => {
  dispatch(getDashboardConfigFunction(page));
};

export const getNotificationsCountFunction = () => async () => {
  try {
    let { data } = await axios.get(`${getApiCallLocalPath()}api/v1/todo/notificationCount`, {
      headers: getApiCallHeadersData()
    });

    return data.data;
  } catch (error) {
    return 0;
  }
};

export const getNotificationsFunction = (setIsLoading) => async (dispatch) => {
  const loadingHandler = (state) => {
    if (setIsLoading) {
      setIsLoading(state);
    }
  };

  loadingHandler(true);

  try {
    // Add notification api call
    let { data } = await axios.get(`${getApiCallLocalPath()}api/v1/todo/allitems`, {
      headers: getApiCallHeadersData()
    });
    dispatch(setNotifications(data?.data));
  } catch (error) {
    toast.error(
      error?.response?.data?.message || error?.response?.data?.error || "Notification Error",
      toastErrorMessageStyle()
    );
    dispatch(setNotifications([]));
  } finally {
    loadingHandler(false);
  }
};
