import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";

import { commonFunction } from "../utils/commonFunction";
import { convertNumbetToDate } from "../../utils/commonFunction";

const FFDateField = ({ component, currentConfigKey, namespace }) => {
  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];
  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || ""
  );
  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);

  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
  };

  useEffect(() => {
    if (inputValue === "" && component?.prefill_current) {
      validate(Date.now());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let minDate = "";
  if (component.block_past_date) {
    let nowDate = Date.now();
    minDate = convertNumbetToDate(nowDate, "date", "number");
  }
  let maxDate = "";
  if (component.block_future_date) {
    let nowDate = Date.now();
    maxDate = convertNumbetToDate(nowDate, "date", "number");
  }

  return (
    <div className="form_field_outer_box">
      <TextField
        id={component.id}
        type="date"
        fullWidth
        error={errors.length > 0}
        label={component.label}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={(e) => validate(e.target.value)}
        disabled={component.disable}
        required={component.validate.required}
        inputProps={{ min: minDate, max: maxDate }}
        size="small"
        sx={{
          "& .MuiInputLabel-root": {
            color: "var(--mui-label-color)"
          },
          "& .MuiInputLabel-root.Mui-error": {
            color: "var(--mui-label-error-color)" // color when there's an error
          },
          "& .MuiInputLabel-root.MuiInputLabel-shrink": {
            color: "var(--mui-label-shrink-color)" // color when the label is at the top (shrunk)
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderWidth: "var(--mui-border-width)",
              borderColor: "var(--mui-border-color)" // default border color
            },
            "&:hover fieldset": {
              borderWidth: "var(--mui-border-width)",
              borderColor: "var(--mui-border-color)" // border color on hover
            },
            "&.Mui-focused fieldset": {
              borderWidth: "var(--mui-border-width)",
              borderColor: "var(--mui-border-color)" // border color when focused
            },
            "&.Mui-disabled fieldset": {
              borderWidth: "var(--mui-border-width)",
              borderColor: "var(--mui-border-color)" // border color when focused
            },
            "&.Mui-error fieldset": {
              borderWidth: "var(--mui-border-width)",
              borderColor: "var(--mui-border-error-color)" // border color when there's an error
            }
          }
        }}
      />
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FFDateField;
