import React from "react";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <div className="otp_back_button_main_container">
      <div onClick={() => navigate(-1)} className="otp_back_button_container">
        <span className="material-symbols-outlined otp_back_icon">chevron_left</span>
      </div>
    </div>
  );
};

export default BackButton;
