import { legacy_createStore as createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import { appviewreducer } from "./appview/appviewreducer";
import { recordviewreducer } from "./recordview/recordviewreducer";
import { mapviewreducer } from "./mapview/mapviewreducer";
import { chartviewreducer } from "./chartview/chartviewreducer";
import { formviewreducer } from "./formview/formviewreducer";
import { userviewreducer } from "./userview/userviewreducer";
import { calendarviewreducer } from "./calendarview/calendarviewreducer";
import { detailsviewreducer } from "./detailsview/detailsviewreducer";
const enhancers = [];

const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
if (typeof devToolsExtension === "function") {
  enhancers.push(devToolsExtension());
}

const composedEnhancers = compose(applyMiddleware(thunk), ...enhancers);

const rootReducer = combineReducers({
  appviewstore: appviewreducer,
  recordviewstore: recordviewreducer,
  mapviewstore: mapviewreducer,
  chartviewstore: chartviewreducer,
  formviewstore: formviewreducer,
  userviewstore: userviewreducer,
  calendarviewstore: calendarviewreducer,
  detailsviewstore: detailsviewreducer
});

export const store = createStore(rootReducer, composedEnhancers);
