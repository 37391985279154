import React, { useState, useLayoutEffect } from "react";
import ScreenTabHeader from "./ScreenTabHeader";
import Pageview from "../../components/Pageview";

const ScreenTabRenderer = ({ configData = {}, jobParamsTokenConfig, functionObject }) => {
  const { headerData, bodyData = {} } = configData.config;
  const [selectedTab, setSelectedTab] = useState(null);

  useLayoutEffect(() => {
    if (!selectedTab) {
      let prevTab = "";
      for (let i = 0; i < headerData?.length; i++) {
        if (headerData[i].active) {
          prevTab = headerData?.[i]?.["id"];
        }
      }
      if (prevTab) {
        setSelectedTab(prevTab);
      } else {
        let id = headerData?.[0]?.["id"];
        if (headerData?.[0]) {
          headerData[0].active = true;
        }
        setSelectedTab(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerData]);

  return (
    <div>
      <ScreenTabHeader headerData={headerData} openTab={setSelectedTab} />
      {selectedTab && bodyData[selectedTab] ? (
        <Pageview
          pageConfig={bodyData[selectedTab]}
          jobParamsTokenConfig={jobParamsTokenConfig}
          functionObject={functionObject}
          parentType="tabview"
        />
      ) : (
        <div>Page data not found</div>
      )}
    </div>
  );
};

export default ScreenTabRenderer;
