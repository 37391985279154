import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterSelectField from "./FilterSelectField";
import { getFilterDataForAPICall } from "../../../../redux/chartview/chartviewaction";
import { useLocation } from "react-router-dom";
import DateRangePickerField from "./DateRangePickerField";

const FilterScreen = ({ namespace, setOpenFilterPopup, filterLayout = [] }) => {
  const [count, setCount] = useState(0);
  const chartFilterDataConfig = useSelector((state) => state.chartviewstore?.[`${namespace}_chartFilterDataForField`]);
  let chartLayout = useSelector((state) => state.chartviewstore?.[`${namespace}_chartDashboardDataConfig`]);
  const { filterData = {} } = chartFilterDataConfig;
  const location = useLocation();
  const screen_name = location.pathname;
  const dispatch = useDispatch();
  function handleResetFilters() {
    const response = window.confirm("Are you sure to reset filters");
    if (response) {
      const dataObject = chartFilterDataConfig?.filters;
      dataObject.filter_by = [];
      dispatch(getFilterDataForAPICall(namespace, dataObject, screen_name, chartLayout?.table_type));
      setCount((prev) => prev + 1);
    }
  }

  const handleCloseFilterPopup = () => {
    setOpenFilterPopup(false);
    document.body.style.overflow = "scroll";
  };

  return (
    <div className="chartFilterContainer">
      <header>
        <div className="chartFilterHeaderContainer">
          <div className="titleContainer">
            <span className="material-symbols-outlined">filter_alt</span>
            <span className="filterLabel">Filter</span>
          </div>
          <span
            className="material-symbols-outlined theme_pupup_section_closeBtn filter_close_icon"
            onClick={handleCloseFilterPopup}
          >
            close
          </span>
        </div>
      </header>
      <div className="chartFilterBodyContainer">
        {filterLayout?.length > 0 &&
          filterLayout?.map((filter, index) => {
            switch (filter?.field_type) {
              case "textfield":
              case "select":
              case "radio":
              case "email":
              case "learning-text":
              case "varchar":
                const options = filterData?.[filter?.id] || [];
                return (
                  <FilterSelectField
                    namespace={namespace}
                    key={index}
                    filter={filter}
                    reset={count}
                    options={options}
                  />
                );
              case "number":
                return <div>{filter?.field_type}</div>;
              case "datetime":
              case "date":
                return <DateRangePickerField namespace={namespace} key={index} filter={filter} reset={count} />;
              default:
                return <p key={index}>{filter?.field_type}</p>;
            }
          })}
      </div>
      <div className="chartFilterFooterContainer">
        <button
          className="primary_default_button"
          onClick={handleResetFilters}
          disabled={chartFilterDataConfig?.filters?.filter_by?.length > 0 ? false : true}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default FilterScreen;
