import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { commonFunction } from "../utils/commonFunction";

const FFSelectboxesField = ({ component, currentConfigKey, namespace }) => {
  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || {}
  );
  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || {});
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (flag, index, value) => {
    if (component.disable) {
      return;
    }
    let setValueObj = { ...inputValue };
    if (flag) {
      if (!setValueObj[index]) setValueObj[index] = value;
    } else {
      if (setValueObj[index]) delete setValueObj[index];
    }
    await commonFunction.callChackFiledFunction(namespace, setValueObj, currentConfigKey, component);
  };

  return (
    <div className="form_field_outer_box">
      <FormControl>
        <FormLabel
          required={component.validate.required}
          sx={{ color: "var(--mui-label-shrink-color)", fontSize: "14px" }}
        >
          {component.label}
        </FormLabel>
        {component.data.values.map((option, index) => {
          let checkFlag = false;
          if (inputValue?.[index]) checkFlag = true;

          return (
            <FormControlLabel
              key={`${component.id}_${index}_${component.type}_option`}
              disabled={component.disable}
              id={`${component.id}_${index}`}
              value={option.value}
              control={
                <Checkbox
                  onChange={(e) => validate(e.target.checked, index, option.value)}
                  checked={checkFlag}
                  sx={{
                    "&.MuiCheckbox-root": {
                      color: "#c1c4c9",
                      height: "28px"
                    },
                    "&.Mui-checked": {
                      color: "#3b7ced"
                    }
                  }}
                />
              }
              label={option.label}
              sx={{ color: "#10182B" }}
            />
          );
        })}
      </FormControl>

      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FFSelectboxesField;
