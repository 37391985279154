import React, { useEffect } from "react";
import "./PopupBoxSection.css";

const PopupBoxSection = ({ children, closePopup, showPopup = true, imagePopup }) => {
  useEffect(() => {
    let openCount = document.querySelectorAll(".popupBoxSectionIsOpen").length;
    if (openCount === 0) document.querySelector("body").style.overflow = "";
  });
  document.body.style.overflow = "hidden";

  if (showPopup) {
    return (
      <div
        style={{
          visibility: showPopup ? "visible" : "hidden",
          opacity: showPopup ? "1" : "0",
          zIndex: imagePopup ? 1000 : 999
        }}
        className={showPopup ? "popup_section_overlay popupBoxSectionIsOpen" : "popup_section_overlay"}
      >
        <div className="popup_section_popup" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    );
  } else {
    return null;
  }
};
export default PopupBoxSection;

const PopupBoxSectionSide = ({ children, closePopup, showPopup = true, side = "right" }) => {
  let style = {};
  if (side === "right") {
    style.right = 0;
  } else {
    style.left = 0;
  }

  useEffect(() => {
    let openCount = document.querySelectorAll(".popupBoxSectionIsOpen").length;
    if (openCount === 0) document.querySelector("body").style.overflow = "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  if (showPopup) {
    return (
      <div
        style={{
          visibility: showPopup ? "visible" : "hidden",
          opacity: showPopup ? "1" : "0",
          zIndex: 999
        }}
        className={showPopup ? "popup_section_overlay popupBoxSectionIsOpen" : "popup_section_overlay"}
      >
        <div className="popup_section_side_popup" onClick={(e) => e.stopPropagation()}>
          <div className="popup_section_content">{children}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export { PopupBoxSectionSide };
