import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { commonFunction } from "../utils/commonFunction";
import { getApiCallLocalPath } from "../../../utils/apiCallFunction";
import { form_images_store } from "../utils/store";
import CabinetPopup from "./CabinetPopup";

const FFFileField = ({ component, currentConfigKey, namespace }) => {
  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];
  const [showCabinent, setShowCabinent] = useState(false);
  const [apiCallFlag, setApiCallFalg] = useState(false);
  const [imageDataArray, setImageDataArray] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || []
  );
  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);

  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setImageDataArray(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || []);
    else setDefValFlag(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  let styleObj = {};
  if (errors.length > 0) {
    styleObj["border"] = "1px solid red";
    styleObj["color"] = "red";
  }

  if (component.disable) {
    styleObj["opacity"] = "0.4";
  }

  const deleteIconFromPreviewArray = (index) => {
    let imageDataArr = [...imageDataArray];

    imageDataArr.splice(index, 1);

    let ifErrorIs = [];
    if (component.fileMaxRange < imageDataArr.length) {
      ifErrorIs.push(`Select only ${component.fileMaxRange} files`);
    } else if (component.fileMinRange > imageDataArr.length) {
      if (component.validate.required) {
        // if (component.validate.required || (component.fileMinRange > 1 && imageDataArr.length === 0)) {
        ifErrorIs.push(`Select minimum ${component.fileMinRange} files`);
      }
    }

    let dataObject = {
      value: imageDataArr,
      errors: ifErrorIs
    };
    setImageDataArray(imageDataArr);
    validate(dataObject);
  };

  const onSelectFile = async (e) => {
    let files = e.target.files;
    files = Object.values(files);

    const imagesArray = [...imageDataArray];
    let promises = [];
    let ifErrorIs = [];
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      let selectedFile = files[i];
      let errorM = "";
      reader.readAsDataURL(selectedFile);
      let promise = new Promise((resolve) => {
        reader.onloadend = async () => {
          const compressedImageBase64 = await compressImage(reader.result);
          const byteString = atob(compressedImageBase64.split(",")[1]);
          const mimeString = compressedImageBase64.split(",")[0].split(":")[1].split(";")[0];
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let j = 0; j < byteString.length; j++) {
            ia[j] = byteString.charCodeAt(j);
          }
          const blob = new Blob([ab], { type: mimeString });
          const compressedFile = new File([blob], selectedFile.name, { type: mimeString });
          if (
            component.fileMaxSize * 1024 * 1024 < compressedFile.size ||
            component.fileMinSize * 1024 * 1024 > compressedFile.size
          ) {
            errorM = `File size should be between ${component.fileMinSize}MB to ${component.fileMaxSize}MB`;
          }
          imagesArray.push({
            url: "true",
            file: compressedFile,
            error: errorM,
            file_name: compressedFile.name,
            file_id: currentConfigKey + Date.now() + compressedFile.name
          });
          resolve();
        };
      });
      promises.push(promise);
    }
    await Promise.all(promises);
    setTimeout(() => {
      setImageDataArray(imagesArray);
    }, 300);

    let dataObject = {
      value: imagesArray,
      errors: ifErrorIs
    };
    validate(dataObject);
  };

  // get and check all validation
  const validate = async (value) => {
    setApiCallFalg(true);

    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);

    setApiCallFalg(false);
  };

  //function for compressedImage
  async function compressImage(base64String) {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = base64String;
      img.onload = async () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const aspectRatio = img.width / img.height;
        let newWidth, newHeight;
        if (img.width >= 1080 || img.height >= 1080) {
          if (img.width < img.height) {
            newWidth = 1080;
            newHeight = 1080 / aspectRatio;
          } else {
            newHeight = 1080;
            newWidth = 1080 * aspectRatio;
          }
        } else {
          newWidth = img.width;
          newHeight = img.height;
        }
        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        let resizedDataUrl;
        if (base64String.includes("data:image/jpeg")) {
          resizedDataUrl = canvas.toDataURL("image/jpeg");
        } else if (base64String.includes("data:image/png")) {
          resizedDataUrl = canvas.toDataURL("image/png");
        } else {
          resizedDataUrl = base64String;
        }
        resolve(resizedDataUrl);
      };
      img.onerror = (error) => resolve(base64String);
    });
  }

  const handleClick = (componentId) => {
    if (componentId) {
      document.getElementById(componentId).click();
    } else {
      setShowCabinent(true);
    }
  };

  //cabinet file changes
  const handleCabinetfileSelect = (imageArray) => {
    let newArray = [];
    for (let i = 0; i < imageArray?.length; i++) {
      const obj = {
        url: "true",
        id: imageArray[i]?.["id"],
        error: "",
        file_name: imageArray[i]?.["original_name"],
        file_id: currentConfigKey + Date.now() + imageArray[i]?.["original_name"]
      };
      newArray.push(obj);
    }
    setImageDataArray(newArray);
    let dataObject = {
      value: newArray,
      errors: []
    };
    validate(dataObject);
  };

  return (
    <>
      <div>
        <div className="image_field_container" style={{ ...styleObj, position: "relative" }}>
          <p className="image_field_label">{component?.label}</p>
          {imageDataArray.length > 0 ? (
            <div className="image_list_container">
              {imageDataArray?.map((preview, index) => {
                if (preview.url === "true" && !form_images_store[preview.file_id]) {
                  if (typeof preview.id === "number") {
                    uploadFile(preview.id, preview.file_id);
                  } else {
                    uploadFile(preview.file, preview.file_id);
                  }
                }
                return (
                  <div key={index}>
                    {component?.type === "image" ? (
                      <div className={preview.error ? "image_container image_error_container" : "image_container"}>
                        <span
                          className="material-symbols-outlined image_delete_icon"
                          onClick={() => deleteIconFromPreviewArray(index)}
                        >
                          delete
                        </span>
                        {preview?.id && typeof preview?.id === "number" ? (
                          <img
                            src={`${process.env.REACT_APP_CABINET_API}/api/v1/asset-file/default/${preview?.id}`}
                            alt="img"
                          />
                        ) : preview?.url === "true" ? (
                          <ReadFile file={preview?.file} dataObject={preview} />
                        ) : preview.url ? (
                          <img
                            src={form_images_store[preview.file_id] || preview?.compressedImage || preview?.url || ""}
                            alt="img"
                          />
                        ) : (
                          <img src={`${getApiCallLocalPath()}api/v1/assets/fileName/${preview.name}`} alt="img" />
                        )}
                      </div>
                    ) : (
                      <div className={preview.error ? "pdf_container pdf_error_container" : "pdf_container"}>
                        <span
                          className="material-symbols-outlined pdf_delete_icon"
                          onClick={() => deleteIconFromPreviewArray(index)}
                        >
                          delete
                        </span>
                        <p className="pdf_name">
                          <span className="material-symbols-outlined">description</span>
                          {preview.file_name || preview.original_name}
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
              {imageDataArray.length < Number(component?.fileMaxRange) && (
                <>
                  <input
                    type="file"
                    accept={component?.type === "image" ? "image/*" : "*"}
                    {...(component.from_camera ? { capture: true } : {})}
                    onChange={async (e) => await onSelectFile(e)}
                    style={{ display: "none" }}
                    id={component.id}
                    multiple={component.multiple || false}
                  />
                  <button
                    onClick={() => (imageDataArray[0]?.id ? handleClick() : handleClick(component?.id))}
                    className="secondary_default_button add_image_button"
                  >
                    <span className="material-symbols-outlined">
                      {component?.from_camera ? "add_a_photo" : imageDataArray[0]?.id ? "backup" : "upload"}
                    </span>
                  </button>
                </>
              )}
            </div>
          ) : (
            <>
              <input
                type="file"
                accept={component?.type === "image" ? "image/*" : "*"}
                onChange={onSelectFile}
                style={{ display: "none" }}
                id={component.id}
                multiple={component.multiple || false}
                {...(component.from_camera ? { capture: "environment" } : {})}
              />

              <div className="image_button file_button_container">
                <button onClick={() => handleClick(component?.id)} className="secondary_default_button image_button">
                  <span className="material-symbols-outlined">{component?.from_camera ? "add_a_photo" : "upload"}</span>
                  <p>Upload</p>
                </button>
                {!component?.from_camera && component?.show_cabinet && (
                  <button
                    onClick={() => handleClick()}
                    className="secondary_default_button image_button cabinet_button"
                  >
                    <span className="material-symbols-outlined">backup</span>
                    {/* <p>Cabinet</p> */}
                  </button>
                )}
              </div>
            </>
          )}

          {apiCallFlag && (
            <div style={{ position: "absolute", right: "40px", top: "15px", height: "10px" }}>
              <span className="loader-dot-show-hide"></span>
            </div>
          )}
        </div>

        {errors.length > 0 && (
          <>
            <div className="invalid_feedback">
              {errors.map((error, index) => (
                <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                  {error}
                </p>
              ))}
            </div>
          </>
        )}
      </div>
      {showCabinent && (
        <CabinetPopup
          setShowCabinent={setShowCabinent}
          handleCabinetfileSelect={handleCabinetfileSelect}
          component={component}
          imageDataArray={imageDataArray}
        />
      )}
    </>
  );
};

export default FFFileField;

const ReadFile = ({ file, dataObject }) => {
  const [previewURL, setPreviewURL] = useState("");
  if (file?.name) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      form_images_store[dataObject.file_id] = reader.result;
      setPreviewURL(reader.result);
    };

    return <img src={previewURL} alt="" />;
  } else {
    if (form_images_store[dataObject.file_id]) {
      return <img src={form_images_store[dataObject.file_id]} alt="" />;
    }
    return <img src={""} alt="" />;
  }
};

const uploadFile = (file, id) => {
  if (typeof file === "string" || typeof file === "number") {
    form_images_store[id] = file;
    return;
  }
  if (!file.name) return;
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = async () => {
    form_images_store[id] = reader.result;
  };
};
