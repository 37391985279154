import { RECORDSVIEW_DATA_CONFIG, RECORDSVIEW_LAYOUT_CONFIG, REMOVE_RECORDSVIEW_DATA_CONFIG } from "./recordviewaction";

// STATE
export const initState = {};

// REDUCER
export const recordviewreducer = (state = initState, { type, payload }) => {
  switch (type) {
    case RECORDSVIEW_DATA_CONFIG:
      return {
        ...state,
        [`${payload?.namespace}_recordsviewDataConfig`]: payload?.payloadData || {}
      };
    case RECORDSVIEW_LAYOUT_CONFIG:
      return {
        ...state,
        [`${payload?.namespace}_recordsviewLayoutConfig`]: payload?.payloadData || {}
      };
    case REMOVE_RECORDSVIEW_DATA_CONFIG:
      const newState = { ...state };
      delete newState[`${payload?.namespace}_recordsviewDataConfig`];
      return newState;
    default:
      return state;
  }
};
