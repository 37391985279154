import React, { useEffect, useState } from "react";
import { FormRenderer } from "../../lib/index";
import { createFormConfigandAddGridKeyInForm } from "../../lib/formrendererlib/utils/formUtilsFunction";
import axios from "axios";
import { getApiCallLocalPath, toastErrorMessageStyle, toastSuccessMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData, removeStorageItem } from "../../utils/storageFunction";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { getUpdatedParamsConfig } from "../../pagehandler/PageHandlerContext";
import { performTaskAction } from "../../utils/manageConfigFunction";
import GetwayRenderer from "../../pagehandler/GetwayRenderer";

const FormComponent = ({
  itemConfig,
  extraRowData = {},
  functionObject,
  jobParamsTokenConfig = {},
  callFrom = "outside_screen"
}) => {
  let itemConfigObject = itemConfig.config;
  const navigate = useNavigate();
  const { pagename } = useParams();
  const [formConfig, setFormConfig] = useState();
  const [lastActionConfig, setLastActionConfig] = useState();
  const [formConfigError, setFormConfigError] = useState();
  const [gatewayConfig, setGatewayConfig] = useState();

  const getFormConfigFunction = async (actionConfig, paramsConfigObject = {}, reqBodyData = {}) => {
    try {
      let configObject = {
        data: reqBodyData,
        function_name: actionConfig?.action_name || "",
        params: {
          ...(getUpdatedParamsConfig(paramsConfigObject, actionConfig?.action_name || "") || {}),
          front_end: {
            params_config: actionConfig?.params_config || {},
            action_config: actionConfig?.action_config || {}
          }
        }
      };
      let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, configObject, {
        headers: getApiCallHeadersData()
      });
      data = data.data;
      if (data.type === "form") {
        let formconfig = await createFormConfigandAddGridKeyInForm(
          data,
          data?.front_end?.params_config?.instance_id ||
            data?.form_response?._id ||
            data?.form_response?.id ||
            data?.form_response?.instance_id ||
            "",
          data?.front_end?.params_config?.callby_element_id || itemConfig?.id || ""
        );
        setLastActionConfig({
          action_config: actionConfig,
          params_config_object: paramsConfigObject,
          req_body_data: reqBodyData
        });
        setFormConfig(formconfig);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.response?.data?.error, toastErrorMessageStyle());
      setFormConfigError(error.response?.data?.message || error.response?.data?.error || "form not found");
    }
  };

  useEffect(() => {
    if (itemConfigObject.job_template) {
      let paramsConfig = itemConfigObject.params;
      if (jobParamsTokenConfig && Object.keys(jobParamsTokenConfig).length > 0) {
        if (callFrom === "screen") {
          if (jobParamsTokenConfig?.sm_id === paramsConfig?.sm_id) {
            paramsConfig = {
              ...jobParamsTokenConfig,
              ...(paramsConfig || {})
            };
          }
        } else if (callFrom === "outside_screen") {
          paramsConfig = {
            ...jobParamsTokenConfig,
            ...extraRowData,
            ...(paramsConfig || {})
          };
        }
      }
      let actionConfig = {
        action_name: itemConfigObject.job_template,
        action_config: {
          action_in: itemConfigObject.job_template,
          action_name: itemConfigObject.job_template,
          sm_id: null,
          instance_id: "",
          instance_ids: [],
          params: paramsConfig
        },
        params_config: paramsConfig
      };

      if (jobParamsTokenConfig && Object.keys(jobParamsTokenConfig).length > 0) {
        if (callFrom === "screen") {
          if (jobParamsTokenConfig?.sm_id === paramsConfig?.sm_id) {
            actionConfig.action_config = {
              ...jobParamsTokenConfig,
              ...(actionConfig?.action_config || {})
            };
          }
        } else if (callFrom === "outside_screen") {
          actionConfig.action_config = {
            ...jobParamsTokenConfig,
            ...extraRowData,
            ...(actionConfig?.action_config || {})
          };
        }
      }
      getFormConfigFunction(actionConfig, paramsConfig, extraRowData || {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitSubFormCallback = async (respons, form_local_key) => {
    try {
      let actionConfig = lastActionConfig.action_config;

      let function_name = "";
      if (actionConfig.action_name === "register_entity") {
        function_name = "register_entity_bg";
      } else if (actionConfig.action_name === "start_state_machine") {
        function_name = "start_state_machine_bg";
      } else {
        function_name = "perform_activity_bg";
      }

      let configObject = {
        data: respons?.data || {},
        function_name: function_name,
        params: {
          sm_id: formConfig?.sm_id || null,
          ...(getUpdatedParamsConfig(lastActionConfig?.params_config_object, function_name) || {}),
          front_end: {
            params_config: lastActionConfig?.action_config || {},
            action_config: lastActionConfig?.params_config_object || {}
          }
        }
      };
      if (extraRowData?.instance_ids) {
        configObject.params["instance_ids"] = extraRowData.instance_ids;
      }

      let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, configObject, {
        headers: getApiCallHeadersData()
      });
      let stopProcessBar = true;
      let response = data.data;
      for (let index = 0; index < response?.notification.length; index++) {
        const element = response.notification[index];
        if (element.error) {
          toast.error(element.message, toastErrorMessageStyle());
          stopProcessBar = false;
        } else {
          removeStorageItem(form_local_key?.local_storage_key);
          toast.success(element.message, toastSuccessMessageStyle());
        }
      }
      setFormConfig({ ...formConfig });

      if (response?.type === "navigate") {
        if (response?.navigate?.mobile_screen) {
          navigate(`/${response.navigate.mobile_screen}`);
        }
      } else if (response?.type === "payment_gateway") {
        setGatewayConfig(response);
      }
      if (stopProcessBar) {
        performTaskAction(itemConfigObject, "action_end", pagename);
      }
    } catch (error) {
      toast.error("Invalid Error", toastErrorMessageStyle());
    }
  };

  const handleCloseGateWay = () => {
    setGatewayConfig();
  };

  return (
    <>
      {formConfigError ? (
        <>
          <div className="popupSection_header">
            <span className="popupSection_header_display">{itemConfig?.config?.name}</span>
            <span></span>
          </div>
          <div
            style={{
              height: "100px",
              width: "100%",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <span style={{ color: "red", textAlign: "center", fontSize: "16px" }}>{formConfigError}</span>
          </div>
        </>
      ) : formConfig ? (
        <>
          <div className="popupSection_header">
            <span className="popupSection_header_display">{formConfig?.name || ""}</span>
            <span></span>
          </div>
          <FormRenderer
            apidata={{
              apicalldata: formConfig
            }}
            callbackfunction={{
              formsubmitgetresponscbfun: submitSubFormCallback
            }}
            namespace={itemConfig.id}
          />
        </>
      ) : (
        ""
      )}
      {gatewayConfig && (
        <>
          {gatewayConfig["gateway"] === "RAZORPAY" && (
            <GetwayRenderer
              getwayConfig={gatewayConfig}
              removeProcessTask={() => {
                handleCloseGateWay();
              }}
            />
          )}
          {gatewayConfig["gateway"] === "STRIPE" &&
            gatewayConfig["url"] &&
            stripeGetway(gatewayConfig, setGatewayConfig)}
        </>
      )}
    </>
  );
};

export default FormComponent;

const stripeGetway = (processTaskItem, setGatewayConfig) => {
  window.open(processTaskItem["url"], "_blank");
  setGatewayConfig();
};
