import React from "react";
import "./Component.css";

import { gridCommonFunction } from "../utils/gridCommonFunction";
import { JobActionRenderer, DetailsViewRender, DropdownActionRenderer, MapViewRender } from "../lib/index";

import FormComponent from "./appview/FormComponent";
import ChartComponent from "./appview/ChartComponent";

import ImageField from "./fields/ImageField";
import LabelField from "./fields/LabelField";
import CalenderRenderer from "../lib/calendarrendererlib/CalenderRenderer";
import RecordsViewRenderer from "../lib/newrecordsviewrendererlib/RecordsViewRenderer";
import ScreenTabRenderer from "../lib/screentabview/ScreenTabRenderer";
import ButtonGroupField from "./fields/ButtonGroupField";

const Pageview = ({ pageConfig, callFrom = "inside", jobParamsTokenConfig = {}, functionObject, parentType = "" }) => {
  let rowItemElements = [];
  let LayoutArray = [];
  for (let index = 0; index < pageConfig.length; index++) {
    let gridElement = pageConfig[index];
    LayoutArray.push(gridElement.grid);
    rowItemElements.push(gridElement.element_config);
  }
  let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(LayoutArray);

  if (callFrom === "outside" && Object.keys(jobParamsTokenConfig).length === 0) {
    return;
  }

  return (
    <div
      style={{ ...styleDataForGrid }}
      className={parentType === "tabview" ? "screenTabContainer" : "screenContainer"}
    >
      {rowItemElements?.map((colItem, colItemIndexIs) => {
        let styleObject = {
          gridArea: `zino-${colItem?.i || colItem.id}`
        };
        if (colItem.hide) {
          return undefined;
        } else {
          switch (colItem.type) {
            case "button":
              return (
                <div
                  className="app_entry_dashboard_itemS"
                  key={colItem.id + colItemIndexIs}
                  style={{ ...styleObject, padding: "12px 0px 0px 12px" }}
                >
                  <JobActionRenderer itemConfig={colItem} functionObject={functionObject} />
                </div>
              );
            case "button-group":
              return (
                <div className="app_entry_dashboard_itemS" key={colItem.id + colItemIndexIs} style={styleObject}>
                  <ButtonGroupField itemConfig={colItem} />
                </div>
              );
            case "dropdown":
              return (
                <div className="app_entry_dashboard_items" key={colItem.id + colItemIndexIs} style={styleObject}>
                  <DropdownActionRenderer itemConfig={colItem} functionObject={functionObject} />
                </div>
              );
            case "chart":
              return (
                <div
                  className="app_entry_dashboard_itemS"
                  key={colItem.id + colItemIndexIs}
                  style={{ ...styleObject, padding: "12px" }}
                >
                  <ChartComponent
                    itemConfig={colItem}
                    namespace={colItem.id}
                    jobParamsTokenConfig={jobParamsTokenConfig}
                    functionObject={functionObject}
                  />
                </div>
              );
            case "recordsview":
              return (
                <div
                  className="app_entry_dashboard_itemS"
                  key={colItem.id + colItemIndexIs}
                  style={{ ...styleObject, maxHeight: "100%", padding: "12px" }}
                >
                  <RecordsViewRenderer
                    itemConfig={colItem}
                    jobParamsTokenConfig={jobParamsTokenConfig}
                    functionObject={functionObject}
                  />
                </div>
              );
            case "detailsview":
              return (
                <div className="app_entry_dashboard_itemS" key={colItem.id + colItemIndexIs} style={{ ...styleObject }}>
                  <DetailsViewRender
                    itemConfig={colItem}
                    callFrom={callFrom}
                    jobParamsTokenConfig={jobParamsTokenConfig}
                    functionObject={functionObject}
                  />
                </div>
              );
            case "mapsview":
              return (
                <div className="app_entry_dashboard_itemS" key={colItem.id + colItemIndexIs} style={styleObject}>
                  <MapViewRender itemConfig={colItem} namespace={colItem.id} functionObject={functionObject} />
                </div>
              );
            case "html":
              return (
                <div
                  key={colItem.id + colItemIndexIs}
                  style={{ ...styleObject }}
                  dangerouslySetInnerHTML={{
                    __html: colItem?.content
                  }}
                ></div>
              );
            case "label":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <LabelField labelConfig={colItem} />
                </div>
              );
            case "image":
              return (
                <div key={colItem.id} style={styleObject}>
                  <ImageField imgConfig={colItem} />
                </div>
              );
            case "form":
              return (
                <div
                  className="app_entry_dashboard_itemS"
                  key={colItem.id + colItemIndexIs}
                  style={{ ...styleObject, padding: "0px 12px" }}
                >
                  <FormComponent
                    itemConfig={colItem}
                    functionObject={functionObject}
                    extraRowData={jobParamsTokenConfig || {}}
                    jobParamsTokenConfig={jobParamsTokenConfig}
                    callFrom="screen"
                  />
                </div>
              );
            case "empty":
              return <div key={colItem.id} style={styleObject}></div>;
            case "spacer":
              return (
                <div
                  key={colItem.id + colItemIndexIs}
                  style={{ ...styleObject, height: `${colItem?.height || 40}px` }}
                ></div>
              );
            case "calendar":
              return (
                <div
                  className="app_entry_dashboard_itemS"
                  key={colItem.id + colItemIndexIs}
                  style={{ ...styleObject, padding: "0px 12px" }}
                >
                  <CalenderRenderer configData={colItem} functionObject={functionObject} />
                </div>
              );
            case "tabview":
              return (
                <div className="app_entry_dashboard_itemS" key={colItem.id + colItemIndexIs} style={styleObject}>
                  <ScreenTabRenderer
                    configData={colItem}
                    jobParamsTokenConfig={jobParamsTokenConfig}
                    functionObject={functionObject}
                  />
                </div>
              );
            default:
              return (
                <div className="app_entry_dashboard_itemS" key={colItem.id + colItemIndexIs} style={styleObject}>
                  {colItem.type}
                </div>
              );
          }
        }
      })}
    </div>
  );
};

export default Pageview;
