import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as MapIcon } from "../../assets/icons/MapIcon.svg";

const MapPopup = ({ lat, lng }) => {
  const [showMap, setShowMap] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const mapRef = useRef(null);
  const isInitialRender = useRef(true);

  let mapRoot = document.getElementById("maproot");

  let script = document.getElementById("mapPopup");

  if (showMap && !script && isInitialRender.current) {
    setIsLoading(true);

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${"AIzaSyDMWCLgNVsigqHdgwgdV0yurVhy6n5dnb0"}&v=weekly&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      setIsLoading(false);
    };

    script.id = "mapPopup";

    mapRoot.appendChild(script);
    isInitialRender.current = false;
  }

  useEffect(() => {
    if (mapRef.current && lat && lng && !isLoading && showMap) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: lat, lng: lng },
        zoom: 16
      });

      new window.google.maps.Marker({
        position: { lat: lat, lng: lng },
        map: map
      });
    }
  }, [lat, lng, isLoading, showMap]);

  const closeBtnHandler = () => {
    setShowMap(false);

    if (script) {
      mapRoot.removeChild(script);
    }

    isInitialRender.current = true;
  };

  useEffect(() => {
    if (showMap) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showMap]);

  const handleClick = (e) => {
    e.stopPropagation();
    setShowMap(true);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {lat && lng && <MapIcon cursor="pointer" width={"15px"} onClick={(e) => handleClick(e)} />}
      {showMap ? (
        <div className="popup_outer_container">
          <div className="popup_inner_container">
            <div className="popup_header_container">
              <p className="popup_title">Location</p>
              <span className="material-symbols-outlined popup_close_icon" onClick={closeBtnHandler}>
                close
              </span>
            </div>
            <div className="popup_body_container">
              <div id="map_div" style={{ width: "100%", height: "400px", overflow: "scroll" }} ref={mapRef}></div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MapPopup;
