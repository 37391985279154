import { createContext, useContext, useState } from "react";

const NetworkContext = createContext();
export const useNetworkContext = () => useContext(NetworkContext);

export const NetworkContextProvider = ({ children }) => {
  const [networkMode, setNetworkMode] = useState("online");
  const handleNetworkMode = () => {
    if (navigator.onLine) {
      setNetworkMode("online");
    } else {
      setNetworkMode("offline");
    }
  };

  return <NetworkContext.Provider value={{ networkMode, handleNetworkMode }}>{children}</NetworkContext.Provider>;
};
