// import moment from "moment";
import React from "react";

const CustomToolbar = (props) => {
  //for shifting to other date
  function handleNavigate(type) {
    props.onNavigate(type);
  }

  return (
    <>
      <div className="calendar-header">
        <div className="calendar-header-child">
          <p className="calendar-label">{props.label}</p>
          <div className="calendar-header-navigation-container">
            <span className="material-symbols-outlined icon-styles" onClick={() => handleNavigate("PREV")}>
              chevron_left
            </span>
            <span className="material-symbols-outlined icon-styles" onClick={() => handleNavigate("NEXT")}>
              chevron_right
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomToolbar;
