import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setRecordsViewDataConfig, setRecordsViewLayoutConfig } from "../../redux/recordview/recordviewaction";
import RecordsViewLayout from "../newrecordsviewrendererlib/RecordsViewLayout";
const ActivityRecordsViewRenderer = ({ recordData, recordLayout, jobParamsConfig, functionObject }) => {
  const dispatch = useDispatch();
  const getRecordviewData = () => {
    dispatch(setRecordsViewLayoutConfig(recordLayout?.id, recordLayout?.config));
    try {
      for (let i = 0; i < recordData?.length; i++) {
        if (recordData[i]?.id === recordLayout?.id) {
          dispatch(setRecordsViewDataConfig(recordLayout?.id, recordData[0]));
        }
      }
    } catch (error) {
      dispatch(setRecordsViewDataConfig(recordLayout?.id));
    }
  };

  useEffect(() => {
    getRecordviewData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordData, recordLayout]);

  return (
    <div>
      <RecordsViewLayout namespace={recordLayout?.id} />
    </div>
  );
};

export default ActivityRecordsViewRenderer;
