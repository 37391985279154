import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAxios from "../../hooks/useAxios";
import BackButton from "../../components/buttons/BackButton";
import Loader from "../../components/Loader";
import { PUBLIC_PAGE_ROUTE } from "../../routes/routes";
import AppCard from "../../components/appList/AppCard";

import "./PublicPage.css";
import { removeStorageItem, setStorageItem } from "../../utils/storageFunction";
import { ZINO_PUBLIC_DOMAIN_MOB } from "../../Constants/LocStorageKeys";

const PublicApps = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [appList, setAppList] = useState([]);

  const { axiosInstance, handleError } = useAxios(process.env.REACT_APP_LOGIN_URL);
  const navigate = useNavigate();

  const getAppList = async () => {
    try {
      setIsLoading(true);

      const { app_list } = await axiosInstance.get("/guest/applist");

      setAppList(app_list);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    removeStorageItem(ZINO_PUBLIC_DOMAIN_MOB);

    getAppList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectPublicPageHandler = (appDetails) => {
    setStorageItem(ZINO_PUBLIC_DOMAIN_MOB, `${appDetails.domain_mobile}`);

    navigate(PUBLIC_PAGE_ROUTE + appDetails.id, { state: { appDetails: appDetails } });
  };

  let content;

  if (isLoading) {
    content = <Loader />;
  } else if (appList.length > 0) {
    content = appList.map((appDetails) => (
      <AppCard key={appDetails.id} app={appDetails} callback={selectPublicPageHandler} />
    ));
  } else {
    content = null;
  }

  return (
    <>
      <div className="header_container" style={{ padding: "0px", gap: "0px" }}>
        <BackButton />
        <h4>Apps</h4>
      </div>

      <div className="public_page_container"> {content}</div>
    </>
  );
};

export default PublicApps;
