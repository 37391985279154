import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FilterSelectFieldLV from "./FilterSelectFieldLV";
import NumberPicker from "./NumberPicker";
import DateRangePickerField from "./DateRangePickerField";
import { createRecordsViewPayload } from "../../../../redux/recordview/recordviewaction";

import { useLocation } from "react-router-dom";
import { usePageHandlerContext } from "../../../../pagehandler/PageHandlerContext";

const FilterScreen = ({ namespace, setOpenFilterPopup, filterCount }) => {
  const location = useLocation();
  const screen_name = location.pathname;
  const { cacheProcessData } = usePageHandlerContext();
  const storeData = useSelector((state) => state.recordviewstore);
  const recordsviewLayoutConfig = storeData?.[`${namespace}_recordsviewLayoutConfig`];
  const recordsviewDataConfig = storeData?.[`${namespace}_recordsviewDataConfig`];

  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  let filterDataArray = recordsviewDataConfig?.facets;
  let filterKeyArray = [];
  for (let index = 0; index < filterDataArray?.length; index++) {
    const element = filterDataArray[index]?.id;
    filterKeyArray[index] = element;
  }

  function handleResetFilters() {
    const response = window.confirm("Are you sure to reset filters");
    if (response) {
      dispatch(
        createRecordsViewPayload({
          namespace: namespace,
          layoutConfig: recordsviewLayoutConfig,
          filter_by: [],
          search_term: "",
          page: 1,
          per_page: 10,
          screen_name: screen_name,
          cacheProcessData: cacheProcessData
        })
      );
      setCount((prev) => prev + 1);
    }
  }

  const handleCloseFilterPopup = () => {
    setOpenFilterPopup(false);
    document.body.style.overflow = "scroll";
  };

  return (
    <div className="recordFilterContainer theme_record_filter_container">
      <header>
        <div className="recordFilterHeaderContainer theme_recordFilterHeaderContainer">
          <div className="titleContainer">
            <span className="material-symbols-outlined">filter_alt</span>
            <p className="filerLabel">Filter</p>
          </div>
          <span
            className="material-symbols-outlined theme_pupup_section_closeBtn filter_close_icon"
            onClick={handleCloseFilterPopup}
          >
            close
          </span>
        </div>
      </header>
      <div className="recordFilterBodyContainer">
        {recordsviewLayoutConfig?.layout?.onscreen_filters?.map((filter, index) => {
          let dataIndex = filterKeyArray.indexOf(filter.id);
          let options = filterDataArray?.[dataIndex]?.facet_values || [];
          switch (filter?.field_type) {
            case "textfield":
            case "select":
            case "radio":
            case "email":
            case "learning-text":
            case "varchar":
              return (
                <FilterSelectFieldLV
                  namespace={namespace}
                  filter={filter}
                  options={options}
                  key={index}
                  reset={count}
                />
              );
            case "number":
              return <NumberPicker key={index} namespace={namespace} filter={filter} reset={count} />;
            case "date":
            case "datetime":
              return <DateRangePickerField namespace={namespace} key={index} filter={filter} reset={count} />;
            default:
              return <p key={index}>{filter?.data_type}</p>;
          }
        })}
      </div>
      <div className="recordFilterFooterContainer">
        <button
          className="primary_default_button"
          onClick={handleResetFilters}
          disabled={filterCount > 0 ? false : true}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default FilterScreen;
