import React, { useState } from "react";
import RememberImage from "../../assets/images/rememberimage.png";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { toastErrorMessageStyle } from "../../utils/apiCallFunction";
import axios from "axios";

const ForgotPassword = () => {
  const [forgotEmail, setForgotEmail] = useState("");
  const navigate = useNavigate();

  async function handleSendOtp() {
    if (forgotEmail.includes("@")) {
      try {
        await axios.put(`${process.env.REACT_APP_LOGIN_URL}/api/v1/forgetPassword`, {
          email: forgotEmail
        });
        navigate("/resetPassword", { state: { email: forgotEmail } });
      } catch (error) {
        toast.error("Enter Valid Credentials", toastErrorMessageStyle());
      }
    } else {
      toast.error("Enter Valid Credentials", toastErrorMessageStyle());
    }
  }

  return (
    <div className="forgotScreenContainer">
      <div className="forgotBackContainer">
        <div className="forgotBackButtonContainer" onClick={() => navigate("/login")}>
          <span className="material-symbols-outlined back_icon">arrow_back_ios</span>
          <p className="back_value">Go back</p>
        </div>
      </div>
      <div className="forgotImageContainer">
        <img src={RememberImage} alt="img" />
      </div>
      <div className="forgotFieldsContainer">
        <p className="forgot_label">Enter your email to reset password</p>
        <input
          className="forgot_input_field"
          placeholder="Enter here"
          value={forgotEmail}
          onChange={({ target: { value } }) => setForgotEmail(value)}
        />
        <div className="forgot_button_container">
          <button className="primary_default_button forgot_button" onClick={handleSendOtp}>
            Send code
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
