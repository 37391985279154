import { getStorageItem } from "./storageFunction";

//for test local json files return true
const checkApiCallLocalOrNot = () => {
  return false;
};

//for checking the host
const getApiCallLocalPath = () => {
  const selectedApp = JSON.parse(getStorageItem("selectedApp"));
  let hostName = "/";
  hostName = `${selectedApp?.domain_mobile}/`;
  return hostName;
};

//for sending toast error style
const toastErrorMessageStyle = () => {
  return {
    style: {
      border: "1px solid red",
      color: "red",
      fontSize: "12px"
    },
    iconTheme: {
      primary: "red",
      secondary: "#FFFAEE"
    },
    duration: 2000
  };
};

const toastSuccessMessageStyle = () => {
  return {
    style: {
      border: "1px solid green",
      color: "green",
      fontSize: "12px"
    },
    iconTheme: {
      primary: "green",
      secondary: "#FFFAEE"
    },
    duration: 2000
  };
};

export { checkApiCallLocalOrNot, getApiCallLocalPath, toastErrorMessageStyle, toastSuccessMessageStyle };
window.getApiCallLocalPath = getApiCallLocalPath;
