import React, { useEffect, useState, useLayoutEffect } from "react";

import { useNavigate } from "react-router-dom";
import { callUserLoginFunction } from "../../redux/userview/userviewaction";
import { useDispatch, useSelector } from "react-redux";
import { getStorageItem, setStorageItem } from "../../utils/storageFunction";
import toast from "react-hot-toast";
import { toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { setSelectedApp } from "../../redux/appview/appviewaction";

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authenticate, logincall } = useSelector((state) => state.userviewstore);
  const { selectedApp, appList } = useSelector((state) => state.appviewstore);
  const [loginCredential, setLoginCredential] = useState({
    email: "",
    password: ""
  });
  const [showPassword, setShowPassword] = useState(false);
  const [companyLogo, setCompanyLogo] = useState("");
  const [loader, setLoader] = useState(false);

  const handleLoginData = (e) => {
    setLoginCredential({
      ...loginCredential,
      [e.target.name]: e.target.value
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (loginCredential.email && loginCredential.password) {
      dispatch(callUserLoginFunction(loginCredential));
    } else {
      toast.error("Please Enter valid Credentials", toastErrorMessageStyle());
    }
  };

  useEffect(() => {
    if (authenticate && !logincall && getStorageItem("Zino_app_user")) {
      if (selectedApp?.id) {
        navigate("/home");
      } else if (appList?.length === 1 && appList[0]?.status === "live") {
        dispatch(setSelectedApp(appList[0]));
        setStorageItem("selectedApp", JSON.stringify(appList[0]));
        navigate("/home");
      } else if (appList?.length !== 1) {
        navigate("/apps menu");
      } else {
        window.open("/page404", "_self");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate, logincall]);

  async function getCompanyLogo() {
    try {
      setLoader(true);
      const response = await fetch(`${process.env.REACT_APP_DETAILS_URL}/api/v1/web-tab-title/${window.location.host}`);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      if (data?.data?.title && data?.data?.favicon && data?.data?.login_image) {
        setCompanyLogo(data?.data?.login_image);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    } finally {
      setLoader(false);
    }
  }

  useLayoutEffect(() => {
    getCompanyLogo();
  }, []);

  if (loader) {
    return (
      <div className="zino_renderer_loader_box">
        <span className="zino_renderer_loader"></span>
      </div>
    );
  }
  return (
    <div className="login_container">
      <div className="login_logo_container">
        <img
          src={
            companyLogo
              ? `${process.env.REACT_APP_DETAILS_URL}/api/v1/public/${companyLogo}`
              : require("../../assets/images/loginimage.png")
          }
          alt="Company Logo"
          className="login_logo"
        />
      </div>
      <div className="login_input_container">
        <div className="login_input_group">
          <label className="login_input_label">Email</label>
          <input
            type="email"
            name="email"
            placeholder="Enter your mail-id"
            className="login_input_field"
            autoComplete="on"
            id="username"
            value={loginCredential.email}
            onChange={handleLoginData}
          />
        </div>
        <div className="login_input_group">
          <label className="login_input_label">Password</label>
          <div className="login_input_pass">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Enter your password"
              className="login_input_field"
              autoComplete="off"
              value={loginCredential.password}
              onChange={handleLoginData}
            />
            <span className="material-symbols-outlined password_icon" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? "visibility_off" : "visibility"}
            </span>
          </div>
          <div className="passoword_container">
            <p className="password_label" onClick={() => navigate("/forgotPassword")}>
              Forgot Password?
            </p>
          </div>
        </div>
      </div>
      <div className="login_button_conntainer">
        <button className="primary_default_button login_button" onClick={handleLogin} disabled={logincall}>
          Sign in
        </button>
        <p className="login_otp_button" onClick={() => navigate("/otp")}>
          Log in via OTP
        </p>
      </div>
    </div>
  );
}

export default LoginPage;
