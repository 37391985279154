import React, { useEffect, useRef, useState } from "react";
import { commonFunction } from "../../utils/commonFunction";
import { ExpandLessIconSvg, ExpandMoreIconSvg } from "../../../zinoIcon";

export default function FilterMSelectFieldLV({ filter, namespace, options }) {
  const [popupShow, setPopupShow] = useState(false);
  const [option, setOption] = useState([]);

  const handleChange = (flag, value) => {
    let valueArray = [...option];
    if (flag) {
      valueArray.push(value);
    } else {
      let index = valueArray.indexOf(value);
      valueArray.splice(index, 1);
    }
    setOption(valueArray);

    if (valueArray.length > 0) {
      commonFunction.callGetMapviewTableDataByFilter(namespace, [
        filter.data_key,
        "filter_by",
        {
          id: filter.id,
          operator: "in",
          text_array: valueArray
        }
      ]);
    } else {
      commonFunction.callGetMapviewTableDataByFilter(namespace, [filter.data_key, "filter_by", ""]);
    }
  };

  // Close popup on side click
  const isClickOutside = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isClickOutside.current && !isClickOutside.current.contains(event.target)) {
        setPopupShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="mapview_filter_field_outerBox theme_mapview_filter_field_outerBox" ref={isClickOutside}>
      <span className="mapview_filter_field_exp" onClick={() => setPopupShow(!popupShow)}>
        <span className="title">{filter.display}</span>
        {option.length > 0 ? <span>{option.join()}</span> : <span className="exp">All</span>}
        {popupShow ? <ExpandLessIconSvg /> : <ExpandMoreIconSvg />}
      </span>
      {popupShow && (
        <div className="mapview_filter_field" style={{ minWidth: "100%" }}>
          <div className="mapview_filter_field_selectBox">
            <div
              className="mapview_filter_field_selectItem"
              onClick={() => {
                setOption([]);
              }}
            >
              All
            </div>
            {options.map((optionItem, index) => {
              let checked = option.includes(optionItem.value);
              return (
                <div key={index} className="mapview_filter_field_selectItem">
                  <input
                    type="checkbox"
                    defaultChecked={checked}
                    value={optionItem.value}
                    onClick={() => {
                      handleChange(!checked, optionItem.value);
                    }}
                  />
                  <span>{optionItem.value}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

// count: 451, highlighted: 'Female', value: 'Female'
