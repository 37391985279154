import React, { useLayoutEffect, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { commonFunction } from "../utils/commonFunction";
import { Paper } from "@mui/material";

const FFLearningTagsField = ({ component, currentConfigKey, namespace }) => {
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];
  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || []
  );

  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);

  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || []);
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  function removeEmptyLabel(objects) {
    return objects.filter((obj) => {
      if (typeof obj === "string") {
        return obj?.trim() !== "";
      }
      return false;
    });
  }

  const [textInput, setTextInput] = useState("");
  const [options, setOptions] = useState(removeEmptyLabel([...component.data.values]));
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [open, setOpen] = useState(false);

  if (component?.sort) {
    options.sort((a, b) => a?.label?.toLowerCase()?.localeCompare(b?.label?.toLowerCase()));
  }
  const validate = async (value) => {
    if (typeof value === "undefined") return;
    setInputValue(value);
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component, false);
  };

  useEffect(() => {
    setFilteredOptions(options.filter((option) => !inputValue.includes(option)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter" && textInput.trim() !== "") {
      const newValue = textInput.trim();
      if (component["allow_new"]) {
        validate([...inputValue, newValue]);
        setTextInput("");
      } else {
        if (options.includes(newValue)) {
          validate([...inputValue, newValue]);
          setTextInput("");
        }
      }
    }
  };

  const handleInput = (event, value) => {
    const newValue = value.filter((value) => value !== undefined); // Extracting values and filtering out undefined values
    const filteredValue = newValue.filter((option) => !inputValue.includes(option)); // Filtering out already existing options
    if (filteredValue.length !== 0) {
      validate([...inputValue, ...filteredValue]);
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    const temp = inputValue.filter((tag) => tag !== tagToDelete);
    validate(temp);
  };

  useEffect(() => {
    setOptions(removeEmptyLabel([...component?.data?.values]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="form_field_outer_box">
      <Stack spacing={2} width={"100%"}>
        <Autocomplete
          open={open}
          onFocus={() => setOpen(true)}
          fullWidth
          multiple
          id={component.id}
          options={filteredOptions}
          getOptionLabel={(option) => {
            return option || JSON.stringify(option);
          }}
          freeSolo
          value={inputValue}
          onChange={(event, newValue) => {
            handleInput(event, newValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                label={option}
                {...getTagProps({ index })}
                onDelete={() => handleDeleteTag(option)}
                style={{ marginRight: "2px" }}
              />
            ))
          }
          autoComplete
          includeInputInList
          renderInput={(params) => (
            <TextField
              {...params}
              label={component?.label}
              value={textInput}
              onChange={(event) => setTextInput(event.target.value)}
              onKeyDown={handleInputKeyDown}
              fullWidth
              multiline
              size="small"
              sx={{
                "& .MuiInputLabel-root": {
                  color: "var(--mui-label-color)"
                },
                "& .MuiInputLabel-root.Mui-error": {
                  color: "var(--mui-label-error-color)" // color when there's an error
                },
                "& .MuiInputLabel-root.MuiInputLabel-shrink": {
                  color: "var(--mui-label-shrink-color)" // color when the label is at the top (shrunk)
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-color)" // default border color
                  },
                  "&:hover fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-color)" // border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-color)" // border color when focused
                  },
                  "&.Mui-disabled fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-color)" // border color when focused
                  },
                  "&.Mui-error fieldset": {
                    borderWidth: "var(--mui-border-width)",
                    borderColor: "var(--mui-border-error-color)" // border color when there's an error
                  }
                }
              }}
            />
          )}
          filterOptions={(options, { inputValue }) => {
            if (!component.show_default_option && inputValue.trim() === "") {
              return [];
            }
            const inputValues = inputValue.trim().toLowerCase().split(" ");
            return options.filter((option) => {
              const labelWords = option.toLowerCase().split(" ");
              const firstWord = labelWords[0];

              return inputValues.every((val, index) => {
                if (index === 0) {
                  return firstWord.startsWith(val);
                } else {
                  return labelWords.slice(1).some((word) => word.startsWith(val));
                }
              });
            });
          }}
          onBlur={() => setOpen(false)}
          ListboxProps={{ sx: { fontSize: "14px" } }}
          PaperComponent={({ children }) => <Paper style={{ maxHeight: 200, overflow: "auto" }}>{children}</Paper>}
        />
      </Stack>
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id || component._id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FFLearningTagsField;
