import {
  getFormFieldsStoreObject,
  getFormShowHideKey,
  getFormShowKey,
  setFormShowHideKey
} from "../../lib/formrendererlib/utils/formUtilsFunction";
import { formDataPrefill } from "../../lib/formrendererlib/utils/prefillData";
import { removeStorageItem } from "../../utils/storageFunction";

// ACTION TYPE
export const FORM_DATA = "FORM_DATA";
export const FORM_CONFIG_DATA = "FORM_CONFIG_DATA";
export const FORM_CUSTOM_FUN_DATA = "FORM_CUSTOM_FUN_DATA";
export const FORM_MASTER_DATA = "FORM_MASTER_DATA";
export const FORM_PAGE_NUMBER = "FORM_PAGE_NUMBER";
export const FORM_PAGE_ERROR_COLOR = "FORM_PAGE_ERROR_COLOR";
export const FORM_FIELD_AND_KEY_DATA = "FORM_FIELD_AND_KEY_DATA";
export const FORM_DATA_DELETE = "FORM_DATA_DELETE";
export const FORM_PRE_FILL_DATA = "FORM_PRE_FILL_DATA";
export const FORM_ON_SUBMIT_FUNCTION = "FORM_ON_SUBMIT_FUNCTION";
export const FORM_OPTIONS_DATA = "FORM_OPTIONS_DATA";

// ACTIONS
export const updateFormData = (namespace, payloaddata) => {
  if (Object.keys(payloaddata).length > 0) {
    return {
      type: FORM_DATA,
      payload: { namespace, payloaddata }
    };
  } else {
    return {
      type: FORM_PAGE_NUMBER,
      payload: { namespace, payloaddata: 0 }
    };
  }
};

export const getFormConfigData = (namespace, payloaddata) => ({
  type: FORM_CONFIG_DATA,
  payload: { namespace, payloaddata }
});

export const setformMasterData = (namespace, payloaddata) => ({
  type: FORM_MASTER_DATA,
  payload: { namespace, payloaddata }
});

export const setformCustomFunData = (namespace, payloaddata) => ({
  type: FORM_CUSTOM_FUN_DATA,
  payload: { namespace, payloaddata }
});

export const setFormPageNumber = (namespace, payloaddata) => ({
  type: FORM_PAGE_NUMBER,
  payload: { namespace, payloaddata }
});

export const setFormPageErrorColor = (namespace, payloaddata) => ({
  type: FORM_PAGE_ERROR_COLOR,
  payload: { namespace, payloaddata }
});

export const setFormFieldKeyAndType = (namespace, payloaddata) => ({
  type: FORM_FIELD_AND_KEY_DATA,
  payload: { namespace, payloaddata }
});

export const setFormPreFillData = (namespace, payloaddata) => ({
  type: FORM_PRE_FILL_DATA,
  payload: { namespace, payloaddata }
});

export const setFormOptionsData = (namespace, payloaddata) => ({
  type: FORM_OPTIONS_DATA,
  payload: { namespace, payloaddata }
});

export const setFormOnSubmitData = (namespace, payloaddata) => ({
  type: FORM_ON_SUBMIT_FUNCTION,
  payload: { namespace, payloaddata }
});

export const deleteFormDataFunction = (namespace, payloaddata) => ({
  type: FORM_DATA_DELETE,
  payload: { namespace, payloaddata }
});

export const getFormConfig = (namespace, apidata) => async (dispatch) => {
  let { apicallflag, apicalldata } = apidata;
  let config,
    dataConfig = {},
    customFunctions,
    onSubmitFunction;
  if (apicallflag) {
    // config = await axios(inputapi);
    // config = config.data.data;
  } else {
    config = apicalldata;

    dispatch(resetFormRenderConfig(namespace));
  }

  if (config) {
    // Form master data config
    let masterDataArray = config?.master_data || [];
    for (let index = 0; index < masterDataArray.length; index++) {
      const element = masterDataArray[index];
      if (element.type === "learning_text") {
        dataConfig[element.id] = element["learning_master_data"];
      } else if (element.type === "custom") {
        dataConfig[element.id] = element["custom_master_data"];
      } else if (element.type === "key_value") {
        dataConfig[element.id] = element["custom_master_data"];
      }
    }

    // Form custom function config
    customFunctions = config?.custom_functions || [];

    onSubmitFunction = config?.onsubmit_custom_functions;
    // Form data config
    if (config.sm_id === "appuser") {
      config.sm_id = "";
    }

    config.setting["title"] = config.name;
    config.setting["id"] = config.id;
    config.setting["local_storage_key"] = config.local_storage_key;
    config.setting["submit_button"] = config.submit_button;
    config.setting["sm_id"] = config.sm_id;
    config.setting["sm_activity_id"] = config.sm_activity_id;
    config.setting["activity_id"] = config.activity_id;
    config.setting["entity_id"] = config.entity_id;
    config.setting["instance_id"] = config.instance_id;
    config.setting["reset_button"] = config.reset_button;
    config = config.setting;
    // get config from config file
    let formsArray = config.sections;
    let formItemsShowArray = [];

    let formFieldKeyAndType = getFormFieldsStoreObject();
    // show and disable key adding in form config
    for (let formsIndex = formsArray.length - 1; formsIndex >= 0; formsIndex--) {
      formsArray[formsIndex].id = formsArray[formsIndex].key;
      const formArray = formsArray[formsIndex].components;
      for (let formIndex = formArray.length - 1; formIndex >= 0; formIndex--) {
        const rowArray = formArray[formIndex].elements;
        getFormShowKey(formItemsShowArray, rowArray, formFieldKeyAndType);
      }
    }
    // show and disable key adding in form config
    for (let formsIndex = formsArray.length - 1; formsIndex >= 0; formsIndex--) {
      const formArray = formsArray[formsIndex].components;
      for (let formIndex = formArray.length - 1; formIndex >= 0; formIndex--) {
        const rowArray = formArray[formIndex].elements;
        getFormShowHideKey(formItemsShowArray, rowArray, dataConfig, namespace);
      }
    }

    // change show value true -> false
    for (let formsIndex = formsArray.length - 1; formsIndex >= 0; formsIndex--) {
      const formArray = formsArray[formsIndex].components;
      for (let formIndex = formArray.length - 1; formIndex >= 0; formIndex--) {
        const rowArray = formArray[formIndex].elements;
        setFormShowHideKey(formItemsShowArray, rowArray, dataConfig);
      }
    }

    // change array to object
    // for (let formsIndex = 0; formsIndex < formsArray.length ; formsIndex++) {
    //     formsArray[formsIndex].components = await changeObjectArrayToObjectFun(formsArray[formsIndex].components)
    // }

    // update in redux
    let formFillData = formDataPrefill(apicalldata?.formprefilldata || {}, {}, config, "outside", dataConfig);
    dispatch(updateFormData(namespace, formFillData));

    dispatch(getFormConfigData(namespace, config));
    dispatch(setformMasterData(namespace, dataConfig));
    dispatch(setformCustomFunData(namespace, customFunctions));
    dispatch(setFormOnSubmitData(namespace, onSubmitFunction));
    dispatch(setFormPageNumber(namespace, 0));
    dispatch(setFormFieldKeyAndType(namespace, formFieldKeyAndType));
    dispatch(setFormPreFillData(namespace, apicalldata.formprefilldata));

    // let formFillData = JSON.parse(getStorageItem(config.local_storage_key));
    // if (formFillData) {
    //   dispatch(updateFormData(namespace, formFillData["form_data"]));
    //   dispatch(getFormConfigData(namespace, formFillData["form_config_data"]));
    //   dispatch(setFormPageErrorColor(namespace, formFillData["form_error_color"]));
    //   dispatch(setFormPageNumber(namespace, formFillData["form_fill_page"]));
    //   dispatch(setformMasterData(namespace, formFillData["form_master_data"]));
    //   dispatch(setformCustomFunData(namespace, formFillData["form_custom_fun_data"]));
    // }
  }
};

export const resetFormRenderConfig = (namespace) => async (dispatch) => {
  // dispatch(getFormConfigData(namespace, {}));
  // dispatch(setformMasterData(namespace, {}));
  dispatch(updateFormData(namespace, {}));
  dispatch(setFormPageErrorColor(namespace, {}));
  dispatch(setFormPageNumber(namespace, 0));
};

export const resetFormLocalStorageData =
  (namespace, flag = true) =>
  async (dispatch) => {
    removeStorageItem(namespace);

    if (flag) dispatch(resetFormRenderConfig(namespace));
  };
