import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createRecordsViewPayload } from "../../../../redux/recordview/recordviewaction";

import { useLocation } from "react-router-dom";
import { usePageHandlerContext } from "../../../../pagehandler/PageHandlerContext";

const NumberPicker = ({ namespace, filter, reset }) => {
  const location = useLocation();
  const screen_name = location.pathname;
  const { cacheProcessData } = usePageHandlerContext();
  const [option, setOption] = useState({
    minValue: 0,
    maxValue: 0
  });

  const storeData = useSelector((state) => state.recordviewstore);
  const recordsviewPayloadConfig = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}`));
  const recordsviewLayoutConfig = storeData?.[`${namespace}_recordsviewLayoutConfig`];
  let previousFilters = recordsviewPayloadConfig?.params?.search_query?.filter_by;
  let previousSearch = recordsviewPayloadConfig?.params?.search_query?.search_term;
  let previousPage = recordsviewPayloadConfig?.params?.search_query?.page || 1;
  let previousPerPage = recordsviewPayloadConfig?.params?.search_query?.per_page || 10;

  const dispatch = useDispatch();

  function handleValue(options, prevFilters, prevSearch) {
    let tempObj = {
      id: filter?.id,
      operator: "between",
      text_value: "",
      text_array: "",
      number_value: "",
      number_range: [parseInt(options.minValue), parseInt(options.maxValue)],
      human_date: "",
      custom_date_range: ""
    };
    prevFilters.push(tempObj);
    let updatedFilters = prevFilters?.filter((prevFilter) => prevFilter?.id !== filter.id);
    updatedFilters.push(tempObj);
    if (options.maxValue >= options.minValue && options.maxValue > 0) {
      dispatch(
        createRecordsViewPayload({
          namespace: namespace,
          layoutConfig: recordsviewLayoutConfig,
          filter_by: updatedFilters,
          search_term: prevSearch,
          page: previousPage,
          per_page: previousPerPage,
          screen_name: screen_name,
          cacheProcessData: cacheProcessData
        })
      );
    } else if (options.minValue === 0 && options.maxValue === 0) {
      let removeFilters = prevFilters?.filter((prevFilter) => prevFilter?.id !== filter.id);
      dispatch(
        createRecordsViewPayload({
          namespace: namespace,
          layoutConfig: recordsviewLayoutConfig,
          filter_by: removeFilters,
          search_term: prevSearch,
          page: previousPage,
          per_page: previousPerPage,
          screen_name: screen_name,
          cacheProcessData: cacheProcessData
        })
      );
    }
  }

  const apiCallAndGetDataDebounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  useEffect(() => {
    if (previousFilters?.length !== 0) {
      previousFilters?.forEach((prevFilter) => {
        if (prevFilter?.id === filter?.id) {
          let prevOptions = option;
          prevOptions.minValue = parseInt(prevFilter?.number_range[0]);
          prevOptions.maxValue = parseInt(prevFilter?.number_range[1]);
          setOption((prev) => ({ ...prev, minValue: prevOptions.minValue, maxValue: prevOptions.maxValue }));
        }
      });
    } else {
      setOption({
        minValue: 0,
        maxValue: 0
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiCallAndGetDataOptimizedFun = useCallback(apiCallAndGetDataDebounce(handleValue), []);

  return (
    <div className="filterItemContainer">
      <span className="title">{filter.custom_label || filter.display}</span>
      <div className="recordview_filter_field_outerBox theme_recordview_filter_field_outerBox">
        <input
          type="number"
          className="numberFilterInput theme_numberFilterInput"
          value={option.minValue}
          onChange={({ target: { value } }) => {
            let prevOptions = { ...option };
            prevOptions.minValue = parseInt(value) || 0;
            setOption((prev) => ({ ...prev, minValue: parseInt(value) || 0 }));
            apiCallAndGetDataOptimizedFun(prevOptions, previousFilters, previousSearch);
          }}
        />
        <p className="tempGap theme_tempGap">To</p>
        <input
          type="number"
          className="numberFilterInput theme_numberFilterInput"
          value={option.maxValue}
          onChange={({ target: { value } }) => {
            let prevOptions = { ...option };
            prevOptions.maxValue = parseInt(value) || 0;
            setOption((prev) => ({ ...prev, maxValue: parseInt(value) || 0 }));
            apiCallAndGetDataOptimizedFun(prevOptions, previousFilters, previousSearch);
          }}
        />
      </div>
    </div>
  );
};

export default NumberPicker;
