import React, { useState } from "react";
import "../Component.css";
import { JobActionRenderer } from "../../lib";
import { usePageHandlerContext } from "../../pagehandler/PageHandlerContext";

const ButtonGroupField = ({ itemConfig, reqData = {}, jobParamsConfig = {} }) => {
  const [showButtonList, setShowButtonList] = useState(false);
  const { createNewTask, cacheProcessData } = usePageHandlerContext();

  const handleMainButton = () => {
    if (itemConfig?.button_list?.length === 1) {
      let singleButtonItemConfig = itemConfig?.button_list[0];
      let element_config = {
        element_id: singleButtonItemConfig.id,
        action_start: singleButtonItemConfig.action_start,
        action_end: singleButtonItemConfig.action_end
      };
      let action_config = {
        action_in:
          singleButtonItemConfig?.job_template || singleButtonItemConfig?.action_in || singleButtonItemConfig.action,
        navigate_to: singleButtonItemConfig?.params?.name || singleButtonItemConfig.navigate_to,
        action_data: singleButtonItemConfig?.action_data,
        action_name: singleButtonItemConfig?.job_template || singleButtonItemConfig?.action_data?.job_name,
        sm_id:
          jobParamsConfig?.sm_id ||
          singleButtonItemConfig.data_id ||
          singleButtonItemConfig?.action_data?.sm_id ||
          cacheProcessData?.value?.sm_id ||
          null,
        instance_id: jobParamsConfig?.instance_id || null,
        instance_ids: jobParamsConfig?.instance_ids || null,
        activity_id: singleButtonItemConfig?.action_data?.activity_id,
        data_source: singleButtonItemConfig?.data_source,
        data_source_type: singleButtonItemConfig?.data_type,
        params: singleButtonItemConfig?.params || {}
      };
      if (Object.keys(reqData).length === 0 && Object.keys(cacheProcessData?.value || {}).length > 0) {
        reqData = cacheProcessData?.value;
      }

      if (["send_email_bg", "send_sms_bg"].includes(action_config.action_name)) {
        reqData = {};
      } else if (["perform_activity_ai"].includes(action_config.action_name)) {
        reqData = { prompt: "" };
      } else {
        reqData = { ...reqData, sm_id: action_config.sm_id };
      }
      createNewTask(element_config, action_config, reqData);
    } else {
      setShowButtonList(!showButtonList);
    }
  };

  if (itemConfig?.button_list?.length > 0) {
    return (
      <div className="floating_button_container">
        {showButtonList && itemConfig?.button_list?.length > 0 && (
          <div className="floating_button_group">
            {itemConfig?.button_list?.map((button, index) => {
              return (
                <JobActionRenderer
                  key={index}
                  jobParamsConfig={jobParamsConfig}
                  itemConfig={button}
                  reqData={reqData}
                  button_className={"floating_button_list"}
                />
              );
            })}
          </div>
        )}
        <button
          className={`${itemConfig?.button_list?.length === 1 ? "static_floating_button" : "floating_button"} ${
            showButtonList ? "floating_active_button" : ""
          }`}
          onClick={handleMainButton}
        >
          <span className="material-symbols-outlined">
            {itemConfig?.button_list?.length === 1 && itemConfig?.button_list[0]?.icon
              ? itemConfig?.button_list[0]?.icon?.split(" ")[0]
              : "add"}
          </span>
        </button>
      </div>
    );
  } else return null;
};

export default ButtonGroupField;
