import React, { useEffect, useState } from "react";
import { getDataFromDataObjectByKey } from "../../utils/updateConfigFunction";
import CircularProgressBar from "./CircularProgressBar";

const ProgressBar = ({ itemConfig = {}, data = {} }) => {
  const [percentage, setPercentage] = useState(0);
  const [displayValue, setDisplayValue] = useState(0);
  const linearStyle = {
    width: `${percentage}%`,
    background: itemConfig?.color ? itemConfig?.color : "var(--primary-background-color)"
  };

  const generatePercentage = (minvalue, maxvalue) => {
    if (!minvalue || !maxvalue || typeof minvalue !== "number" || typeof maxvalue !== "number") {
      setPercentage(0);
      if (itemConfig?.format === "percentage" || !itemConfig?.format) {
        setDisplayValue("0%");
      } else if (itemConfig?.format === "count") {
        setDisplayValue("0/0");
      }
    } else {
      let percent = (minvalue / maxvalue) * 100;
      if (percent > 100) {
        setPercentage(100);
      } else {
        percent = parseFloat(percent.toFixed(2));
        setPercentage(percent);
      }
      if (itemConfig?.format === "percentage" || !itemConfig?.format) {
        if (percent > 100) {
          setDisplayValue("100%");
        } else {
          percent = parseFloat(percent.toFixed(2));
          setDisplayValue(`${percent}%`);
        }
      } else if (itemConfig?.format === "count") {
        setDisplayValue(`${minvalue}/${maxvalue}`);
      }
    }
  };

  useEffect(() => {
    let minData = [];
    getDataFromDataObjectByKey(data, minData, `${itemConfig?.completed_value?.id}`);
    const minValue = minData[0] || 0;
    let maxData = [];
    getDataFromDataObjectByKey(data, maxData, `${itemConfig?.total_value?.id}`);
    const maxValue = maxData[0] || 0;
    generatePercentage(minValue, maxValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {itemConfig?.view_type === "linear" ? (
        <div className="linear_main_container">
          <div className="linear_graph_container">
            <div className="linear_bar">
              <div className="linear_fill" style={linearStyle}></div>
            </div>
            <span className="linear_value">{displayValue}</span>
          </div>
          <span className="linear_title">{itemConfig?.title}</span>
        </div>
      ) : (
        <div>
          <CircularProgressBar percentage={percentage} itemConfig={itemConfig} value={displayValue} />
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
