import { currencySymbolsListObject } from "./currencySymbols";

const convertNumbetToTime = (number, format = "am/pm") => {
  let time;
  let dateNow;
  if (isNaN(number)) {
    const startWithPattern = new RegExp(`^${"2000-01-"}.*$`, "i");
    if (startWithPattern.test(number)) {
      dateNow = new Date(number);
    } else {
      dateNow = new Date("2000-01-01T" + number);
    }
  } else {
    if (parseInt(number) < 86401) {
      let totalMinutes = Math.floor(number / 60);
      let hours = Math.floor(totalMinutes / 60);
      let minutes = totalMinutes % 60;

      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      dateNow = new Date("2000-01-01T" + hours + ":" + minutes);
    } else if (parseInt(number) > 9999999999) {
      dateNow = new Date(parseInt(number));
    } else {
      dateNow = new Date(parseInt(number) * 1000);
    }
  }

  let hours = dateNow.getHours();
  let minutes = dateNow.getMinutes();
  if (format === "am/pm") {
    if (hours >= 12) {
      hours = hours - 12;
      if (hours === 0) {
        hours = 12;
      }
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      time = hours + ":" + minutes + " PM";
    } else {
      if (hours === 0) {
        hours = 12;
      }
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      time = hours + ":" + minutes + " AM";
    }
  } else {
    let hours = dateNow.getHours();
    let minutes = dateNow.getMinutes();
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    time = hours + ":" + minutes;
  }
  if (time.includes("NaN")) {
    return "-";
  } else {
    return time;
  }
  // return dateNow.toLocaleTimeString();
};

const convertNumbetToDate = (
  number,
  format = "date",
  monthFormat = "string",
  dateFormat = ["yyyy", "mm", "dd"],
  spaceStyle = "-"
) => {
  let date = "";
  let dateNow;
  if (isNaN(number)) {
    dateNow = new Date(number);
  } else {
    if (parseInt(number) > 9999999999) {
      dateNow = new Date(parseInt(number));
    } else {
      dateNow = new Date(parseInt(number) * 1000);
    }
  }
  let day = dateNow.getDate();
  let monthIndex = dateNow.getMonth();
  let year = dateNow.getFullYear();

  let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let month = monthIndex;
  if (monthFormat === "string") {
    month = monthNames[monthIndex];
  } else {
    month = month + 1;
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
  }
  for (let i = 0; i < dateFormat.length; i++) {
    const element = dateFormat[i];
    if (i > 0) {
      date += spaceStyle;
    }
    if (element === "yyyy") {
      date += year;
    } else if (element === "mm") {
      date += month;
    } else if (element === "dd") {
      date += day;
    }
  }

  if (format === "datetime") {
    // date += " " + dateNow.toLocaleTimeString();
    let hours = dateNow.getHours();
    let minutes = dateNow.getMinutes();
    if (hours >= 12) {
      hours = hours - 12;
      if (hours === 0) {
        hours = 12;
      }
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      date += " " + hours + ":" + minutes + " PM";
    } else {
      if (hours === 0) {
        hours = 12;
      }
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      date += " " + hours + ":" + minutes + " AM";
    }
  }
  return date;
};

const stringToJSONChange = (value) => {
  try {
    if (!value || !value.trim()) {
      return {};
    }
    return JSON.parse(value);
  } catch (error) {
    return {};
  }
};

function formatTimestamp(timestamp, type = "datetime", format = "DD-MM-YYYY HH:MM AM/PM") {
  const date = new Date(timestamp);

  const day = date.getDate();
  const month = date.getMonth(); // 0-indexed, so January is 0
  const year = date.getFullYear();
  const shortYear = year % 100;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const amPm = hours >= 12 ? "PM" : "AM";
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const padZero = (num) => num.toString().padStart(2, "0");

  if (type === "time") {
    switch (format) {
      case "HH:MM":
        return `${padZero(hours)}:${padZero(minutes)}`;
      case "HH:MM AM/PM":
        return `${padZero(formattedHours)}:${padZero(minutes)} ${amPm}`;
      case "HH:MM:SS":
        return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
      case "HH:MM:SS AM/PM":
        return `${padZero(formattedHours)}:${padZero(minutes)}:${padZero(seconds)} ${amPm}`;
      case "Ago":
        return timeAgo(date);
      default:
        return `${padZero(formattedHours)}:${padZero(minutes)} ${amPm}`;
    }
  } else if (type === "date") {
    switch (format) {
      case "DD-MM-YYYY":
        return `${padZero(day)}-${padZero(month + 1)}-${year}`;
      case "DD-MM-YY":
        return `${padZero(day)}-${padZero(month + 1)}-${padZero(shortYear)}`;
      case "MM-DD-YYYY":
        return `${padZero(month + 1)}-${padZero(day)}-${year}`;
      case "MM-DD-YY":
        return `${padZero(month + 1)}-${padZero(day)}-${padZero(shortYear)}`;
      case "YYYY-MM-DD":
        return `${year}-${padZero(month + 1)}-${padZero(day)}`;
      case "YY-MM-DD":
        return `${padZero(shortYear)}-${padZero(month + 1)}-${padZero(day)}`;
      case "DD-MON-YYYY":
        return `${padZero(day)}-${monthNames[month]}-${year}`;
      case "DD-MON-YY":
        return `${padZero(day)}-${monthNames[month]}-${padZero(shortYear)}`;
      case "DD MON":
        return `${padZero(day)} ${monthNames[month]}`;
      case "MON DD YY":
        return `${monthNames[month]} ${padZero(day)} ${padZero(shortYear)}`;
      case "MON DD YYYY":
        return `${monthNames[month]} ${padZero(day)} ${year}`;
      case "MON YY":
        return `${monthNames[month]} ${padZero(shortYear)}`;
      case "MON YYYY":
        return `${monthNames[month]} ${year}`;
      case "Ago":
        return dateAgo(date);
      default:
        return `${padZero(day)}-${padZero(month + 1)}-${year}`;
    }
  } else if (type === "datetime") {
    switch (format) {
      case "DD-MM-YYYY HH:MM":
        return `${padZero(day)}-${padZero(month + 1)}-${year} ${padZero(hours)}:${padZero(minutes)}`;
      case "DD-MM-YYYY HH:MM AM/PM":
        return `${padZero(day)}-${padZero(month + 1)}-${year} ${padZero(formattedHours)}:${padZero(minutes)} ${amPm}`;
      case "DD-MM-YY HH:MM":
        return `${padZero(day)}-${padZero(month + 1)}-${padZero(shortYear)} ${padZero(hours)}:${padZero(minutes)}`;
      case "DD-MM-YY HH:MM AM/PM":
        return `${padZero(day)}-${padZero(month + 1)}-${padZero(shortYear)} ${padZero(formattedHours)}:${padZero(
          minutes
        )} ${amPm}`;
      case "DD MON YY HH:MM":
        return `${padZero(day)} ${monthNames[month]} ${padZero(shortYear)} ${padZero(hours)}:${padZero(minutes)}`;
      case "DD MON YY HH:MM AM/PM":
        return `${padZero(day)} ${monthNames[month]} ${padZero(shortYear)} ${padZero(formattedHours)}:${padZero(
          minutes
        )} ${amPm}`;
      case "DD-MON-YY HH:MM":
        return `${padZero(day)}-${monthNames[month]}-${padZero(shortYear)} ${padZero(hours)}:${padZero(minutes)}`;
      case "DD-MON-YY HH:MM AM/PM":
        return `${padZero(day)}-${monthNames[month]}-${padZero(shortYear)} ${padZero(formattedHours)}:${padZero(
          minutes
        )} ${amPm}`;
      case "DD-MON-YYYY HH:MM":
        return `${padZero(day)}-${monthNames[month]}-${year} ${padZero(hours)}:${padZero(minutes)}`;
      case "DD-MON-YYYY HH:MM AM/PM":
        return `${padZero(day)}-${monthNames[month]}-${year} ${padZero(formattedHours)}:${padZero(minutes)} ${amPm}`;
      case "MON YY HH:MM":
        return `${monthNames[month]} ${padZero(shortYear)} ${padZero(hours)}:${padZero(minutes)}`;
      case "MON YY HH:MM AM/PM":
        return `${monthNames[month]} ${padZero(shortYear)} ${padZero(formattedHours)}:${padZero(minutes)} ${amPm}`;
      case "MON YYYY HH:MM":
        return `${monthNames[month]} ${year} ${padZero(hours)}:${padZero(minutes)}`;
      case "MON YYYY HH:MM AM/PM":
        return `${monthNames[month]} ${year} ${padZero(formattedHours)}:${padZero(minutes)} ${amPm}`;
      case "DD MON HH:MM":
        return `${padZero(day)} ${monthNames[month]} ${padZero(hours)}:${padZero(minutes)}`;
      case "DD MON HH:MM AM/PM":
        return `${padZero(day)} ${monthNames[month]} ${padZero(formattedHours)}:${padZero(minutes)} ${amPm}`;
      case "Ago":
        return dateTimeAgo(date);
      default:
        return `${padZero(day)}-${padZero(month + 1)}-${year} ${padZero(formattedHours)}:${padZero(minutes)} ${amPm}`;
    }
  } else {
    return timestamp;
  }
}

function timeAgo(date) {
  const now = new Date();

  // Extract hours, minutes, and seconds from both times
  const hoursDiff = now.getHours() - date.getHours();
  const minutesDiff = now.getMinutes() - date.getMinutes();
  const secondsDiff = now.getSeconds() - date.getSeconds();

  // Calculate total difference in seconds
  let totalSeconds = hoursDiff * 3600 + minutesDiff * 60 + secondsDiff;

  if (totalSeconds < 0) {
    totalSeconds += 24 * 3600; // Adjust for time differences spanning past midnight
  }

  // Convert the total seconds difference into a readable format
  if (totalSeconds >= 3600) {
    const hoursAgo = Math.floor(totalSeconds / 3600);
    return `${hoursAgo} hours ago`;
  } else if (totalSeconds >= 60) {
    const minutesAgo = Math.floor(totalSeconds / 60);
    return `${minutesAgo} minutes ago`;
  } else {
    return `${totalSeconds} seconds ago`;
  }
}

function dateAgo(date) {
  const now = new Date();

  const daysDiff = Math.floor((now - date) / (1000 * 60 * 60 * 24));

  if (daysDiff >= 365) {
    const yearsAgo = Math.floor(daysDiff / 365);
    return yearsAgo > 1 ? `${yearsAgo} years ago` : "1 year ago";
  } else if (daysDiff >= 30) {
    const monthsAgo = Math.floor(daysDiff / 30);
    return monthsAgo > 1 ? `${monthsAgo} months ago` : "1 month ago";
  } else if (daysDiff >= 1) {
    return daysDiff > 1 ? `${daysDiff} days ago` : "1 day ago";
  } else {
    return "Today";
  }
}

function dateTimeAgo(date) {
  const now = new Date();

  const daysDiff = Math.floor((now - date) / (1000 * 60 * 60 * 24));
  const hoursDiff = Math.floor((now - date) / (1000 * 60 * 60));
  const minutesDiff = Math.floor((now - date) / (1000 * 60));

  if (daysDiff >= 365) {
    const yearsAgo = Math.floor(daysDiff / 365);
    return yearsAgo > 1 ? `${yearsAgo} years ago` : "1 year ago";
  } else if (daysDiff >= 1) {
    return daysDiff > 1 ? `${daysDiff} days ago` : "1 day ago";
  } else if (hoursDiff >= 1) {
    return hoursDiff > 1 ? `${hoursDiff} hours ago` : "1 hour ago";
  } else if (minutesDiff >= 1) {
    return minutesDiff > 1 ? `${minutesDiff} minutes ago` : "1 minute ago";
  } else {
    return "Just now";
  }
}

function updateNumberFieldStringFormat(value, format, currencyCode) {
  // Ensure the value is a number
  if (typeof value !== "number") {
    if (typeof value === "string" && !isNaN(value)) {
      value = parseFloat(value);
    } else {
      return;
    }
  }

  // Determine the currency symbol based on the provided currency code
  const currencySymbol = currencySymbolsListObject[currencyCode] || "";

  // Determine the format
  let formattedValue = "";
  if (currencySymbol) {
    formattedValue = `${currencySymbol} `;
  }
  switch (format) {
    case "currency":
      formattedValue += `${value.toFixed(2)}`;
      break;
    case "currency_format":
      if (currencyCode === "INR") {
        // Special formatting for Indian currency
        formattedValue += `${indianNumberFormat(value.toFixed(2))}`;
      } else {
        formattedValue += `${value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`;
      }
      break;
    case "dollar":
      formattedValue = `${currencySymbolsListObject["USD"]} ${value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`;
      break;
    case "rupee":
      formattedValue = `${currencySymbolsListObject["INR"]} ${indianNumberFormat(value.toFixed(2))}`;
      break;
    case "accounting":
      if (value >= 0) {
        formattedValue += `${value.toFixed(2)}`;
      } else {
        if (currencySymbol) {
          formattedValue = `(${currencySymbol} ${Math.abs(value).toFixed(2)})`;
        } else {
          formattedValue = `(${Math.abs(value).toFixed(2)})`;
        }
      }
      break;
    case "percentage":
      formattedValue += `${value}%`;
      break;
    case "percentage_decimals":
      formattedValue += `${value.toFixed(2)}%`;
      break;
    case "number":
      formattedValue += parseInt(value);
      break;
    case "number_decimals":
      formattedValue += value.toFixed(2);
      break;
    case "number_format":
      formattedValue += value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      break;
    case "scientific":
      formattedValue += value.toExponential(2);
      break;
    case "fraction":
      formattedValue += `${Math.floor(value)} ${Math.round((value - Math.floor(value)) * 100)}/100`;
      break;
    case "rounded":
      formattedValue += Math.round(value).toLocaleString();
      break;
    case "international_numeral_format":
      if (value >= 1_000_000_000_000_000) {
        // Quadrillion
        formattedValue += `${formatDecimals(value / 1_000_000_000_000_000, 2)} Q`;
      } else if (value >= 1_000_000_000_000) {
        // Trillion
        formattedValue += `${formatDecimals(value / 1_000_000_000_000, 2)} T`;
      } else if (value >= 1_000_000_000) {
        // Billion
        formattedValue += `${formatDecimals(value / 1_000_000_000, 2)} B`;
      } else if (value >= 1_000_000) {
        // Million
        formattedValue += `${formatDecimals(value / 1_000_000, 2)} M`;
      } else if (value >= 1_000) {
        // Thousand
        formattedValue += `${formatDecimals(value / 1_000, 2)} K`;
      } else {
        formattedValue += value.toString();
      }
      break;
    case "international_numeral_m_format":
      formattedValue += `${formatDecimals(value / 1_000_000, 2)} M`;
      break;
    case "international_numeral_b_format":
      formattedValue += `${formatDecimals(value / 1_000_000_000, 2)} B`;
      break;
    case "international_numeral_t_format":
      formattedValue += `${formatDecimals(value / 1_000_000_000_000, 2)} T`;
      break;
    case "international_numeral_q_format":
      formattedValue += `${formatDecimals(value / 1_000_000_000_000_000, 2)} Q`;
      break;

    case "indian_numeral_format":
      if (value >= 1_00_00_000_00_00_000) {
        // Crore Crore (100 Trillion in Indian system)
        formattedValue += `${formatDecimals(value / 1_00_00_000_00_00_000, 2)} Cr Cr`;
      } else if (value >= 1_00_000_00_00_000) {
        // Lakh Crore (1 Trillion in Indian system)
        formattedValue += `${formatDecimals(value / 1_00_000_00_00_000, 2)} Lac Cr`;
      } else if (value >= 1_000_00_00_000) {
        // Thousand Crore
        formattedValue += `${formatDecimals(value / 1_000_00_00_000, 2)} K Cr`;
      } else if (value >= 1_00_00_000) {
        // Crore
        formattedValue += `${formatDecimals(value / 1_00_00_000, 2)} Cr`;
      } else if (value >= 1_00_000) {
        // Lakh
        formattedValue += `${formatDecimals(value / 1_00_000, 2)} Lac`;
      } else if (value >= 1_000) {
        // Thousand
        formattedValue += `${formatDecimals(value / 1_000, 2)} K`;
      } else {
        formattedValue += value.toString();
      }
      break;
    case "indian_numeral_k_format":
      formattedValue += `${formatDecimals(value / 1_000, 2)} K`;
      break;
    case "indian_numeral_l_format":
      formattedValue += `${formatDecimals(value / 1_00_000, 2)} Lac`;
      break;
    case "indian_numeral_cr_format":
      formattedValue += `${formatDecimals(value / 1_00_00_000, 2)} Cr`;
      break;

    default:
      formattedValue += value.toString();
      break;
  }

  return formattedValue;
}

function indianNumberFormat(number) {
  const [integerPart, decimalPart] = number.split(".");
  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);
  let formattedNumber = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThreeDigits;
  if (formattedNumber[0] === ",") {
    formattedNumber = formattedNumber.slice(1);
  }
  return decimalPart ? formattedNumber + "." + decimalPart : formattedNumber;
}

// Function to format the number to the desired decimal places without rounding up
function formatDecimals(number, decimals) {
  const factor = Math.pow(10, decimals);
  return Math.floor(number * factor) / factor;
}

export { convertNumbetToTime, convertNumbetToDate, stringToJSONChange, formatTimestamp, updateNumberFieldStringFormat };
