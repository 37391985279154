import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";

function ChartField({ config }) {
  HC_exporting(Highcharts);
  const chartRef = useRef(null);
  useEffect(() => {
    if (chartRef.current) {
      Highcharts.chart(chartRef.current, config);
    }
  }, [config]);
  return <div ref={chartRef} />;
}
function SparkChartField({ config }) {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      Highcharts.chart(chartRef.current, config);
    }
  }, [config]);
  return <div ref={chartRef} />;
}

export { SparkChartField };
export default ChartField;
