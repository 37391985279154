import { setDashboardAppConfig } from "../redux/appview/appviewaction";
import { getFilterDataForAPICall } from "../redux/chartview/chartviewaction";
import { getMapviewTableData } from "../redux/mapview/mapviewaction";

import { createRecordsViewPayload } from "../redux/recordview/recordviewaction";
import { getCalendarData, getCalendarFilterData } from "../redux/calendarview/calendarviewaction";
import { store } from "../redux/store";
import { setHideComponentKeyInConfig } from "./updateConfigFunction";
import { fetchDetailsView } from "../redux/detailsview/detailsviewaction";

const performTaskAction = async (taskObject, task_action_type, screen_name, jobParamsTokenConfig) => {
  if (!taskObject[task_action_type]) return;

  let storeData = store.getState()["appviewstore"];
  let pageLayoutConfig = JSON.parse(JSON.stringify(storeData.dashboardAppConfig));
  pageLayoutConfig.page = await setHideComponentKeyInConfig(
    pageLayoutConfig.page,
    false,
    taskObject[task_action_type].hide || [],
    taskObject[task_action_type].show || []
  );

  await refreshComponentKeyInConfig(
    pageLayoutConfig.page,
    taskObject[task_action_type].refresh || [],
    screen_name,
    jobParamsTokenConfig
  );
  store.dispatch(setDashboardAppConfig(pageLayoutConfig));
};

const refreshComponentKeyInConfig = async (pageLayout, refreshKeyArray, screen_name, jobParamsTokenConfig) => {
  for (let elementIndex = 0; elementIndex < pageLayout?.length; elementIndex++) {
    const element = pageLayout[elementIndex].element_config;
    if (refreshKeyArray.includes(element.id)) {
      if (["recordsview", "listview"].includes(element.type)) {
        refreshRecordView(element.id, screen_name, jobParamsTokenConfig);
      } else if (["mapsview"].includes(element.type)) {
        refreshMapView(element.id);
      } else if (["chart"].includes(element.type, screen_name)) {
        refreshChartView(element.id);
      } else if (["calendar"].includes(element.type)) {
        refreshCalendar(element.id, screen_name);
      } else if (["detailsview"].includes(element.type)) {
        refreshDetailsView(element.id, jobParamsTokenConfig);
      }
    }
    if (["tabview"].includes(element.type)) {
      let headerData = element?.config?.headerData || [];
      let bodyData = element?.config?.bodyData || {};

      for (let tabIndex = 0; tabIndex < headerData.length; tabIndex++) {
        const tabElement = headerData[tabIndex];
        if (bodyData?.[tabElement?.id]) {
          await refreshComponentKeyInConfig(
            bodyData[tabElement.id],
            refreshKeyArray,
            screen_name,
            jobParamsTokenConfig
          );
        }
      }
    }
  }
};

const refreshCalendar = async (namespace, screen_name) => {
  let storeData = store.getState()["calendarviewstore"];
  let calendarViewLayoutData = storeData?.[`${namespace}_calendarLayoutData`];
  let calendarViewEventspayloadData = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}_event`));
  let calendarViewFilterpayloadData = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}_filters`));
  if (typeof calendarViewLayoutData === "undefined") {
    return;
  }

  await store.dispatch(
    getCalendarData({
      namespace: namespace,
      facet_by: [calendarViewLayoutData?.data?.subject_field?.id],
      filter_by: calendarViewEventspayloadData?.params?.search_query?.filter_by || []
    })
  );
  await store.dispatch(
    getCalendarFilterData({
      namespace: namespace,
      filter_config: calendarViewLayoutData?.data?.subject_filter,
      filter_by: calendarViewFilterpayloadData?.params?.search_query?.filter_by || [],
      facet_by: [calendarViewLayoutData?.data?.subject_field?.id?.split(".")[1]]
    })
  );
};

const refreshRecordView = async (namespace, screen_name, cacheProcessData) => {
  let recordsviewPayloadConfig = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}`));
  let recordsviewLayoutConfig = store.getState()["recordviewstore"][`${namespace}_recordsviewLayoutConfig`];
  if (typeof recordsviewLayoutConfig === "undefined") {
    return;
  }

  await store.dispatch(
    createRecordsViewPayload({
      namespace: namespace,
      layoutConfig: recordsviewLayoutConfig,
      filter_by: recordsviewPayloadConfig?.params?.search_query?.filter_by || [],
      search_term: recordsviewPayloadConfig?.params?.search_query?.search_term || "",
      page: recordsviewPayloadConfig?.params?.search_query?.page || 1,
      per_page: recordsviewPayloadConfig?.params?.search_query?.per_page || 10,
      screen_name: screen_name,
      cacheProcessData: cacheProcessData
    })
  );
};

const refreshMapView = async (namespace) => {
  let mapviewApiConfig = store.getState()["mapviewstore"][`${namespace}_mapviewApiConfig`] || {};
  let mapviewTableFPayloadData = store.getState()["mapviewstore"][`${namespace}_mapviewTableFPayloadData`];
  if (typeof mapviewApiConfig === "undefined" || typeof mapviewTableFPayloadData === "undefined") {
    return;
  }

  let mapviewTableFPayload = JSON.parse(JSON.stringify(mapviewTableFPayloadData));
  await store.dispatch(getMapviewTableData(namespace, mapviewApiConfig, mapviewTableFPayload));
};

const refreshChartView = async (namespace, screen_name) => {
  let chartLayout = store.getState()?.chartviewstore[`${namespace}_chartDashboardDataConfig`];
  if (typeof chartLayout === "undefined") {
    return;
  }
  let filterDataForAPICall = store.getState()?.chartviewstore[`${namespace}_chartFilterDataForAPICall`] || [];
  await store.dispatch(getFilterDataForAPICall(namespace, filterDataForAPICall, screen_name, chartLayout?.table_type));
};

const refreshDetailsView = async (namespace, jobParamsTokenConfig) => {
  await store.dispatch(fetchDetailsView(namespace, jobParamsTokenConfig));
};

export { performTaskAction };
