import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Chip, FormControl, FormLabel, Grid } from "@mui/material";
import { commonFunction } from "../utils/commonFunction";

const FFSelectChipsField = ({ component, currentConfigKey, namespace }) => {
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || []
  );

  function removeEmptyLabel(objects) {
    return objects.filter((obj) => obj.label.trim() !== "");
  }

  let options = removeEmptyLabel([...component.data.values]);

  if (component?.sort) {
    options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || []);
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  const filterFun = (inputValue, value) => {
    if (!inputValue.includes(value)) {
      return [...inputValue, value];
    } else {
      return inputValue.filter((item) => item !== value);
    }
  };
  const handleClick = (value) => {
    if (component.disable) {
      return;
    }
    let newData = JSON.parse(JSON.stringify(filterFun(inputValue, value)));
    setInputValue(newData);
    validate(newData);
  };

  const validate = async (value) => {
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component, false);
  };

  return (
    <div className="form_field_outer_box">
      <FormControl>
        <FormLabel>{component.label}</FormLabel>
        <Grid container spacing={1}>
          {options.map((option, index) => {
            return (
              <Grid item key={index}>
                <Chip
                  label={option.label}
                  onClick={() => handleClick(option.value)}
                  variant={inputValue.includes(option.value) ? "filled" : "outlined"}
                  color={inputValue.includes(option.value) ? "primary" : "default"}
                />
              </Grid>
            );
          })}
        </Grid>
      </FormControl>

      {errors.length > 0 && (
        <div className="invalid_feedback">
          {errors.map((error, index) => (
            <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
              {error}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default FFSelectChipsField;
