import React, { useLayoutEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { returnData } from "../utils/commonFunction";
import { usePageHandlerContext } from "../../../pagehandler/PageHandlerContext";
import { store } from "../../../redux/store";
//custom components
import CustomToolbar from "../CustomCalendarBlock/CustomToolbar";
import CalendarSubjectFilter from "../CalendarFilterBlock/CalendarSubjectFilter";
import {
  getCalendarData,
  getCalendarFilterData,
  setCalenderResourceConfig
} from "../../../redux/calendarview/calendarviewaction";
import CalendarEntityFilter from "../CalendarFilterBlock/CalendarEntityFilter";

import { useLocation } from "react-router-dom";

const CalendarBlock = ({ namespace }) => {
  const location = useLocation();
  const screen_name = location.pathname;

  const localizer = momentLocalizer(moment);

  const storeData = useSelector((state) => state.calendarviewstore);
  let calendarViewLayoutData = storeData?.[`${namespace}_calendarLayoutData`];
  let calendarViewEventsData = storeData?.[`${namespace}_calendarEventsData`];
  let calendarViewFilterData = storeData?.[`${namespace}_calendarFilterData`];
  let calendarViewResourceData = storeData?.[`${namespace}_calendarResourceData`];
  let calendarViewEventspayloadData = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}_event`));
  let calendarViewFilterPayloadData = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}_filters`));

  const [showFilters, setShowFilters] = useState(false);

  const { createNewTask } = usePageHandlerContext();

  //states
  const [eventsJson, setEventsJson] = useState([]);
  const [selectedResource, setSelectedResource] = useState([]);

  //for creating resource Config
  function createResourceConfig() {
    let tempResourceJson = [];
    let value = calendarViewEventspayloadData?.params?.search_query?.filter_by?.[0]?.text_value;
    let resourceId = calendarViewLayoutData?.data?.subject_field?.id?.split(".")[1];
    switch (calendarViewLayoutData?.data?.filter_type) {
      case "entity":
        calendarViewFilterData?.documents?.forEach((document) => {
          let documentObj = {
            id: document?.id,
            value: document?.[resourceId]
          };
          tempResourceJson.push(documentObj);
        });
        store.dispatch(setCalenderResourceConfig(namespace, tempResourceJson));
        if (value) {
          tempResourceJson?.forEach((resource) => {
            if (resource?.id === value) {
              setSelectedResource([resource]);
            }
          });
        }
        break;
      case "user":
        calendarViewFilterData?.users?.users?.forEach((obj) => {
          let tempObj = {
            id: obj.id,
            value: obj?.[resourceId]
          };
          tempResourceJson.push(tempObj);
        });
        store.dispatch(setCalenderResourceConfig(namespace, tempResourceJson));
        if (value) {
          tempResourceJson?.forEach((resource) => {
            if (resource?.id === value) {
              setSelectedResource([resource]);
            }
          });
        }
        break;
      default:
        break;
    }
  }

  //for event resourceId
  function handleResourceId(id, data) {
    let resourceId = "";
    if (calendarViewLayoutData?.data?.filter_type === "user") {
      resourceId = returnData("id", data[id?.split(".")[0]]);
    } else if (calendarViewLayoutData?.data?.filter_type === "entity") {
      resourceId = returnData("id", data[id.split(".")[0]]);
    }
    return resourceId;
  }

  // for creating events config
  function createEventsConfig() {
    let calendarEventFormatArray = [];
    for (let i = 0; i < calendarViewEventsData?.length; i++) {
      let tempEvent = {
        id: calendarViewEventsData[i]?.id,
        event_title: returnData(calendarViewLayoutData.data.event_title_field, calendarViewEventsData[i]),
        start_datetime_field: returnData(calendarViewLayoutData.data.start_datetime_field, calendarViewEventsData[i]),
        end_datetime_field: returnData(calendarViewLayoutData.data.end_datetime_field, calendarViewEventsData[i]),
        duration_field: returnData(calendarViewLayoutData.data.duration_field, calendarViewEventsData[i]),
        start_date_field: returnData(calendarViewLayoutData.data.start_date_field, calendarViewEventsData[i]),
        start_time_field: returnData(calendarViewLayoutData.data.start_time_field, calendarViewEventsData[i]),
        end_date_field: returnData(calendarViewLayoutData.data.end_date_field, calendarViewEventsData[i]),
        end_time_field: returnData(calendarViewLayoutData.data.end_time_field, calendarViewEventsData[i]),
        resourceId: handleResourceId(calendarViewLayoutData.data.subject_field?.id, calendarViewEventsData[i])
      };
      calendarEventFormatArray.push(tempEvent);
    }
    setEventsJson(calendarEventFormatArray);
  }

  //returns events start time
  function handleStartDateTime(event) {
    if (event?.start_datetime_field) {
      const startDateTime = new Date(event?.start_datetime_field);
      return startDateTime;
    } else if (event.start_date_field) {
      // Extract the date components from startDate
      const startDate = new Date(event?.start_date_field);
      const startDateYear = startDate.getFullYear();
      const startDateMonth = startDate.getMonth();
      const startDateDay = startDate.getDate();

      // Extract the time components from startTime
      const startTime = new Date(event?.start_time_field);
      const startTimeHours = startTime.getHours();
      const startTimeMinutes = startTime.getMinutes();
      const startTimeSeconds = startTime.getSeconds();

      // Combine the date and time components into a new Date object
      const startDateTime = new Date(
        startDateYear,
        startDateMonth,
        startDateDay,
        startTimeHours,
        startTimeMinutes,
        startTimeSeconds
      );
      return startDateTime;
    } else {
      const startDateTime = new Date();
      return startDateTime;
    }
  }

  //returns events end time
  function handleEndDateTime(event) {
    const startDateValue = handleStartDateTime(event);
    let endDateTime = new Date(startDateValue).setMinutes(calendarViewLayoutData?.data?.time_slot_duration);
    if (event.end_datetime_field) {
      endDateTime = new Date(event.end_datetime_field);
    } else if (event.duration_field) {
      endDateTime = new Date(startDateValue);
      endDateTime.setMinutes(endDateTime.getMinutes() + event.duration_field);
    } else if (event.end_date_field && event.end_time_field) {
      // Extract the date components from startDate
      const endDate = new Date(event?.end_date_field);
      const endDateYear = endDate.getFullYear();
      const endDateMonth = endDate.getMonth();
      const endDateDay = endDate.getDate();

      // Extract the time components from startTime
      const endTime = new Date(event?.end_time_field);
      const endTimeHours = endTime.getHours();
      const endTimeMinutes = endTime.getMinutes();
      const endTimeSeconds = endTime.getSeconds();

      // Combine the date and time components into a new Date object
      endDateTime = new Date(endDateYear, endDateMonth, endDateDay, endTimeHours, endTimeMinutes, endTimeSeconds);
    }
    return endDateTime;
  }

  //returns event title
  function handleEventTitle(event) {
    return event.event_title;
  }

  const components = {
    toolbar: (props) => CustomToolbar(props, namespace)
  };

  //for handling start and end time
  function handleTime(time) {
    // Example time = "09:00"
    const parsedTime = moment(time, "HH:mm");

    // Creating a new Date object with the desired hours, minutes, and seconds
    const newDate = new Date();
    newDate.setHours(parsedTime.hours(), parsedTime.minutes(), 0);
    return newDate;
  }

  //Adding new Event on slot clicking
  const handleSelect = (props) => {
    function returnTimeStamp(time, type) {
      if (type === "date-time") {
        const timestamp = new Date(time).getTime();
        const secondsTimestamp = timestamp / 1000;
        return secondsTimestamp;
      } else if (type === "date") {
        const newTime = new Date(time).setHours(0, 0, 0, 0);
        const timestamp = new Date(newTime).getTime();
        const secondsTimestamp = timestamp / 1000;
        return secondsTimestamp;
      } else if (type === "time") {
        const DefaultTime = new Date(time).setHours(0, 0, 0, 0);
        const timeDifference = time.getTime() - new Date(DefaultTime).getTime();
        const seconds = Math.floor(timeDifference / 1000);
        return seconds;
      }
    }

    function returnSubjectField(props) {
      if (calendarViewLayoutData?.data?.filter_type === "user") {
        const userDetails = calendarViewFilterData?.users?.users?.find((user) => user?.id === props.resourceId);
        if (userDetails) {
          return userDetails;
        } else {
          return "";
        }
      } else if (calendarViewLayoutData?.data?.filter_type === "entity") {
        let tempArray = calendarViewFilterData?.documents?.find((opt) => opt?.id === props.resourceId);
        return tempArray;
      } else {
        return "";
      }
    }

    let prefill_data = {
      subject_field: returnSubjectField(props),
      start_date_time: returnTimeStamp(props.start, "date-time"),
      end_date_time: returnTimeStamp(props.end, "date-time"),
      duration: (props.slots.length - 1) * calendarViewLayoutData?.data?.time_slot_duration,
      start_date: returnTimeStamp(props.start, "date"),
      end_date: returnTimeStamp(props.end, "date"),
      start_time: returnTimeStamp(props.start, "time"),
      end_time: returnTimeStamp(props.end, "time")
    };

    let element_config = {
      element_id: namespace,
      action_start: {
        hide: [],
        refresh: [],
        show: []
      },
      action_end: {
        hide: [],
        refresh: [namespace],
        show: []
      }
    };

    let action_config = {
      action_in: "start_state_machine",
      navigate_to: "",
      action_data: "",
      action_name: "start_state_machine",
      sm_id: "",
      activity_id: "",
      data_source: "",
      data_source_type: "",
      params: { sm_id: calendarViewLayoutData?.table_id }
    };
    createNewTask(element_config, action_config, { prefill_data: prefill_data });
  };

  //event click
  function handleEvent(event) {
    if (
      calendarViewLayoutData?.data?.add_event_config?.job_template === "show_detailsview_popup" &&
      calendarViewLayoutData?.data?.add_event_config?.params
    ) {
      const element_config = {
        element_id: namespace,
        callback_function: [],
        callback_function_after: [undefined],
        action_start: {
          hide: [],
          refresh: [],
          show: []
        },
        action_end: {
          hide: [],
          refresh: [],
          show: []
        }
      };
      const action_config = {
        action_in: "show_detailsview_popup",
        action_name: "show_detailsview_popup",
        instance_id: event?.id,
        params: calendarViewLayoutData?.data?.add_event_config?.params || {},
        sm_id: calendarViewLayoutData?.table_id
      };
      let tempData = calendarViewEventsData?.find((table) => table?.id === event?.id);
      tempData.sm_id = calendarViewLayoutData?.table_id;
      tempData.instance_id = event?.id;
      const reqData = { data: tempData, instance_id: event?.id };
      createNewTask(element_config, action_config, reqData.data);
    }
  }

  function handleSubjectChange(value) {
    if (value) {
      calendarViewResourceData?.forEach((resource) => {
        if (resource?.id === value) {
          setSelectedResource([resource]);
          let temp_filter = {
            id: `${calendarViewLayoutData?.data?.subject_field?.id?.split(".")[0]}.id`,
            operator: "is",
            text_value: value
          };
          store.dispatch(
            getCalendarData({
              namespace: namespace,
              facet_by: [calendarViewLayoutData?.data?.subject_field?.id],
              filter_by: [temp_filter],
              screen_name: screen_name
            })
          );
        }
      });
    } else {
      setSelectedResource([]);
      store.dispatch(
        getCalendarData({
          namespace: namespace,
          filter_by: [],
          facet_by: [],
          screen_name: screen_name
        })
      );
    }
  }

  function DemoLabel() {
    switch (calendarViewLayoutData?.data?.filter_type) {
      case "entity":
        return calendarViewLayoutData?.data?.subject_field?.display;
      case "user":
        return "subject";
      default:
        return null;
    }
  }

  //for reset all filters except subject_field
  function handleResetFilters() {
    const response = window.confirm("Are You sure to Reset All Filters");
    if (response) {
      store.dispatch(
        getCalendarFilterData({
          namespace: namespace,
          filter_config: calendarViewLayoutData?.data?.subject_filter,
          filter_by: [],
          facet_by: [calendarViewLayoutData?.data?.subject_field?.id?.split(".")[1]],
          screen_name: screen_name
        })
      );
    }
  }

  useLayoutEffect(() => {
    createResourceConfig();
    createEventsConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarViewFilterData, calendarViewEventsData]);

  return (
    <>
      <div className="calendarFilterHeader">
        <CalendarSubjectFilter namespace={namespace} onSubjectChange={handleSubjectChange} />
        {calendarViewLayoutData?.data?.subject_filter?.filters?.length !== 0 && (
          <button className="icon-container" onClick={() => setShowFilters(true)}>
            <span className="material-symbols-outlined icon-styles">filter_alt</span>
            {calendarViewFilterPayloadData?.params?.search_query?.filter_by?.length !== 0 && (
              <p className="filter_value">{calendarViewFilterPayloadData?.params?.search_query?.filter_by?.length}</p>
            )}
          </button>
        )}
      </div>
      {calendarViewResourceData?.length !== 0 && selectedResource?.length !== 0 ? (
        <div style={{ height: "100vh" }}>
          <Calendar
            localizer={localizer}
            components={components}
            events={eventsJson}
            startAccessor={(event) => handleStartDateTime(event)} //for event start time
            endAccessor={(event) => handleEndDateTime(event)} //for event end time
            titleAccessor={(event) => handleEventTitle(event)} //for event title
            defaultView="day"
            min={handleTime(calendarViewLayoutData?.data?.time_start)}
            max={handleTime(calendarViewLayoutData?.data?.time_end)}
            timeslots={1}
            step={calendarViewLayoutData?.data?.time_slot_duration}
            resources={selectedResource}
            resourceIdAccessor={(obj) => obj.id}
            resourceTitleAccessor={(obj) => obj.value}
            selectable
            onSelectSlot={handleSelect}
            onSelectEvent={handleEvent}
          />
        </div>
      ) : (
        <div style={{ height: "100vh" }} className="calendar-demo-block">
          <Calendar
            localizer={localizer}
            components={components}
            defaultView="day"
            min={handleTime(calendarViewLayoutData?.data?.time_start)}
            max={handleTime(calendarViewLayoutData?.data?.time_end)}
            timeslots={1}
            step={calendarViewLayoutData?.data?.time_slot_duration}
          />
          <div className="calendar-demo-block-child">
            <p>Please select {DemoLabel()}</p>
          </div>
        </div>
      )}
      {showFilters && (
        <div className="filterScreenContainer">
          <div className="filterScreen">
            <div className="filterScreenHeader">
              <p className="filterScreenTitle">Filters</p>
              <span className="material-symbols-outlined" onClick={() => setShowFilters(false)}>
                close
              </span>
            </div>
            <div className="filterScreenBody">
              <CalendarEntityFilter namespace={namespace} />
            </div>
            <div className="filterScreenFooter">
              <button className="primary_default_button" onClick={handleResetFilters}>
                Reset
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CalendarBlock;
