import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { callUserLogoutFunction } from "../redux/userview/userviewaction";
import { getApiCallHeadersData, removeStorageItem, removeTheme } from "../utils/storageFunction";
import axios from "axios";
import { getNotificationsFunction, setAppsList, setSelectedApp } from "../redux/appview/appviewaction";
import IconRender from "./IconRender";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FormHelperText from "@mui/material/FormHelperText";
import toast from "react-hot-toast";
import { toastErrorMessageStyle, toastSuccessMessageStyle } from "../utils/apiCallFunction";
import NotificationPopup from "./NotificationPopup";
import { setDashboardAppConfig } from "../redux/appview/appviewaction";
import { injectCSS, injectJS } from "../utils/loadfiles";
import { useCartContext } from "../applicationcontext/CartContext";
import ModulesPopup from "./ModulesPopup";

const Header = ({ headerConfig, setSelectedFooterModule }) => {
  const { user } = useSelector((state) => state.userviewstore);
  const { selectedApp, appList, dashboardAppConfig } = useSelector((state) => state.appviewstore);
  const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [showModulesPopup, setShowModulesPopup] = useState(false);
  const [showCartOptions, setShowCartOptions] = useState(false);
  const { cartLayout, fetchCart } = useCartContext();
  const selected_module = localStorage.getItem("selected_module") || "";
  const [selectedModule, setSelectedModule] = useState({});

  const dispatch = useDispatch();
  const { pagename } = useParams();
  const navigate = useNavigate();

  const handleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const handleMenu = (screen) => {
    navigate(`/${screen}`);
    setTimeout(() => {
      setShowDrawer(!showDrawer);
    }, 300);
    setActiveScreen(screen);
  };

  const handleChildClick = (e) => {
    e.stopPropagation();
  };

  const handleLogout = () => {
    dispatch(callUserLogoutFunction(user.token));
    dispatch(setDashboardAppConfig({}));
    injectJS("");
    injectCSS("");
    navigate("/");
  };

  const handleApps = async () => {
    const headers = getApiCallHeadersData();
    try {
      const response = await axios.get(`${process.env.REACT_APP_LOGIN_URL}/api/v1/applist`, { headers });
      dispatch(setAppsList(response.data.app_list));
      removeTheme();
      removeStorageItem("selectedApp");
      removeStorageItem("selected_module");
      dispatch(setSelectedApp({}));
      dispatch(setDashboardAppConfig({}));
      navigate("/apps menu");
      injectJS("");
      injectCSS("");
      fetchCart();
    } catch (error) {
      toast.error(error?.response?.data?.message || "Login Again", toastErrorMessageStyle());
    }
    handleDrawer();
  };

  const [showDrawer, setShowDrawer] = useState(false);
  const [activeScreen, setActiveScreen] = useState("home");

  //change Password function
  const handleShowChangePasswordPopup = () => {
    setShowDrawer(false);
    setShowChangePasswordPopup(true);
  };

  //notification popup
  const handleShowNotificationPopup = () => {
    setShowDrawer(false);
    setShowNotificationPopup(true);
    dispatch(getNotificationsFunction());
  };

  const handleShowModules = () => {
    setShowDrawer(false);
    setShowModulesPopup(true);
  };

  useEffect(() => {
    let temp = window.location.pathname.slice(1);
    temp = temp.charAt(0).toUpperCase() + temp.slice(1);
    const decodedString = decodeURIComponent(temp.replace(/\+/g, " "));
    setActiveScreen(decodedString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagename]);

  useEffect(() => {
    if (showDrawer === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showDrawer]);

  useEffect(() => {
    let modules_array = dashboardAppConfig?.module_header || [];
    let seleced_module_object = modules_array?.find((module) => module?.name === selected_module) || {};
    setSelectedModule(seleced_module_object);
    if (Object?.keys(seleced_module_object)?.length > 0) {
      setSelectedFooterModule(seleced_module_object?.config?.menu_bottom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected_module]);
  return (
    <div className="header_container ">
      <div className="header_menu_bar_container">
        <span className="material-symbols-outlined header_menu_bar_icon theme_menu_icon" onClick={handleDrawer}>
          menu
        </span>
      </div>
      <div className="header_title_container">
        <p className="header_title">{activeScreen}</p>
        {cartLayout?.length > 0 && (
          <span className="material-symbols-outlined cart_icon" onClick={() => setShowCartOptions(!showCartOptions)}>
            shopping_cart
          </span>
        )}
        {showCartOptions && (
          <div className="cart_list">
            {cartLayout?.map((cart, index) => (
              <span key={index} onClick={() => navigate(`/my cart/${cart?.page_name}`, { state: { cart } })}>
                {cart?.page_name}
              </span>
            ))}
          </div>
        )}
      </div>
      {showDrawer && (
        <div className="header_drawer_background_container" onClick={() => setShowDrawer(false)}>
          <div className="header_drawer_content_container theme_popup_navbar_container" onClick={handleChildClick}>
            {Object.keys(selectedApp).length === 0 ? (
              <>
                <div className="header_drawer_profile_menu_container">
                  <div className="header_drawer_profile_container">
                    <div className="header_drawer_profile_logo_container">
                      <span className="header_drawer_profile_logo material-symbols-outlined">person</span>
                    </div>
                    <div className="header_drawer_profile_details_container">
                      <p className="header_drawer_profile_name theme_user_name">{user?.name}</p>
                    </div>
                  </div>
                  <hr className="header_drawer_profile_break_line theme_header_bottom_line" />
                  <div className="header_drawer_profile_menu_list_container">
                    <div className="header_drawer_profile_menu">
                      <div className="logout_logo_container">
                        <span className="material-symbols-outlined logout_logo">mail</span>
                      </div>
                      <p className="header_drawer_profile_menu_name theme_user_detail">{user?.email}</p>
                    </div>
                    <div className="header_drawer_profile_menu" onClick={handleShowChangePasswordPopup}>
                      <div className="logout_logo_container">
                        <span className="material-symbols-outlined logout_logo">password</span>
                      </div>
                      <p className="header_drawer_profile_menu_name theme_user_detail">Change Password</p>
                    </div>
                    <div className="header_drawer_profile_menu" onClick={handleLogout}>
                      <div className="logout_logo_container">
                        <span className="material-symbols-outlined logout_logo">logout</span>
                      </div>
                      <p className="header_drawer_profile_menu_name theme_user_detail">Logout</p>
                    </div>
                    <div className="header_drawer_profile_menu">
                      <div className="logout_logo_container">
                        <span className="material-symbols-outlined logout_logo">manufacturing</span>
                      </div>
                      <p className="header_drawer_profile_menu_name theme_user_detail">
                        Version - ${process.env.REACT_APP_VERSION}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="header_drawer_app_content_container">
                <div className="header_drawer_combine_container">
                  <div className="header_drawer_app_container">
                    <div className="header_drawer_app_icon_container">
                      <img
                        src={
                          selectedApp.logo
                            ? `${process.env.REACT_APP_IMAGE_HOST}/api/v1/public/${selectedApp.logo}`
                            : require("../assets/icons/app_icon.png")
                        }
                        alt="userProfile"
                        className="header_drawer_app_icon"
                      />
                    </div>
                    <div className="header_drawer_app_details_container" onClick={() => window.location.reload()}>
                      <p className="header_drawer_app_name">{selectedApp?.name}</p>
                    </div>
                  </div>
                  <hr className="header_drawer_profile_break_line theme_header_bottom_line" />
                  <div className="header_drawer_menu_container">
                    {Object?.keys(selectedModule)?.length > 0
                      ? selectedModule?.config?.menu?.map((item, index) =>
                          item?.type === "page" ? (
                            <button
                              className={
                                activeScreen.toLowerCase() === item.display.toLowerCase()
                                  ? "header_drawer_menu header_drawer_menu_active_screen theme_menu_active_screen"
                                  : "header_drawer_menu theme_nav_menu_item"
                              }
                              onClick={() => handleMenu(item.display)}
                              key={index}
                            >
                              {item?.icon && <IconRender iconValue={item.icon} />}
                              {item.display === "home" ? "Home" : item.display}
                            </button>
                          ) : item?.type === "dropdown" && item?.dropdown?.length > 0 ? (
                            <DropdownMenu
                              dropdownMenu={item}
                              onPageClick={handleMenu}
                              activeScreen={activeScreen}
                              key={index}
                            />
                          ) : (
                            <></>
                          )
                        )
                      : headerConfig?.menu?.map((item, index) =>
                          item?.type === "page" ? (
                            <button
                              className={
                                activeScreen.toLowerCase() === item.display.toLowerCase()
                                  ? "header_drawer_menu header_drawer_menu_active_screen theme_menu_active_screen"
                                  : "header_drawer_menu theme_nav_menu_item"
                              }
                              onClick={() => handleMenu(item.display)}
                              key={index}
                            >
                              {item?.icon && <IconRender iconValue={item.icon} />}
                              {item.display === "home" ? "Home" : item.display}
                            </button>
                          ) : item?.type === "dropdown" && item?.dropdown?.length > 0 ? (
                            <DropdownMenu
                              dropdownMenu={item}
                              onPageClick={handleMenu}
                              activeScreen={activeScreen}
                              key={index}
                            />
                          ) : (
                            <></>
                          )
                        )}
                  </div>
                </div>
                <div className="header_drawer_profile_menu_container">
                  <div className="header_drawer_profile_container">
                    <div className="header_drawer_profile_logo_container">
                      <span className="header_drawer_profile_logo material-symbols-outlined">person</span>
                    </div>
                    <div className="header_drawer_profile_details_container">
                      <p className="header_drawer_profile_name theme_user_detail">{user?.name}</p>
                      <p className="header_drawer_profile_mail theme_user_detail">{user?.email}</p>
                    </div>
                  </div>
                  <hr className="header_drawer_profile_break_line theme_header_bottom_line" />
                  <div className="header_drawer_profile_menu_list_container">
                    <div className="header_drawer_profile_menu" onClick={handleShowNotificationPopup}>
                      <div className="logout_logo_container">
                        <span className="material-symbols-outlined logout_logo">notifications</span>
                        {dashboardAppConfig?.header?.notifications_count > 0 && (
                          <span className="notification_count">{dashboardAppConfig?.header?.notifications_count}</span>
                        )}
                      </div>
                      <p className="header_drawer_profile_menu_name theme_user_detail">Notifications</p>
                    </div>
                    {dashboardAppConfig?.module_header?.length > 0 && (
                      <div className="header_drawer_profile_menu" onClick={handleShowModules}>
                        <div className="logout_logo_container">
                          <span className="material-symbols-outlined logout_logo">toc</span>
                        </div>
                        <span
                          className="header_drawer_profile_menu_name theme_user_detail"
                          style={{ wordBreak: "keep-all" }}
                        >
                          Modules
                        </span>
                        {selectedModule?.name && <span className="selected_module_name">{selectedModule?.name}</span>}
                      </div>
                    )}
                    {appList?.length > 1 && (
                      <div className="header_drawer_profile_menu" onClick={handleApps}>
                        <div className="logout_logo_container">
                          <span className="material-symbols-outlined logout_logo">reply</span>
                        </div>
                        <p className="header_drawer_profile_menu_name theme_user_detail">Apps Menu</p>
                      </div>
                    )}
                    <div className="header_drawer_profile_menu" onClick={handleLogout}>
                      <div className="logout_logo_container">
                        <span className="material-symbols-outlined logout_logo">logout</span>
                      </div>
                      <p className="header_drawer_profile_menu_name theme_user_detail">Logout</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {showChangePasswordPopup && <ChangePasswordPopup setShowChangePasswordPopup={setShowChangePasswordPopup} />}
      {showNotificationPopup && <NotificationPopup setShowNotificationPopup={setShowNotificationPopup} />}
      {showModulesPopup && (
        <ModulesPopup
          setShowModulesPopup={setShowModulesPopup}
          setSelectedModule={setSelectedModule}
          setSelectedFooterModule={setSelectedFooterModule}
          selectedModule={selectedModule}
        />
      )}
    </div>
  );
};

export default Header;

function DropdownMenu({ dropdownMenu = {}, onPageClick, activeScreen }) {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div onClick={() => setShowDropdown(!showDropdown)} className="header_dropdown_container">
      <div className={`header_dropdown ${showDropdown ? "highlight_header_dropdown" : ""}`}>
        <div className="header_dropdown_value">
          {dropdownMenu?.icon && <IconRender iconValue={dropdownMenu.icon} />}
          {dropdownMenu.display === "home" ? "Home" : dropdownMenu.display}
        </div>
        <IconRender iconValue={showDropdown ? "keyboard_arrow_up" : "keyboard_arrow_down"} />
      </div>
      {showDropdown && (
        <div className="dropdown_menu_container">
          {dropdownMenu?.dropdown?.length > 0 &&
            dropdownMenu?.dropdown?.map((item, index) => (
              <button
                className={
                  activeScreen.toLowerCase() === item.display.toLowerCase()
                    ? "header_drawer_menu header_drawer_menu_active_screen theme_menu_active_screen"
                    : "header_drawer_menu theme_nav_menu_item"
                }
                onClick={() => onPageClick(item.display)}
                key={index}
              >
                {item?.icon && <IconRender iconValue={item.icon} />}
                {item.display === "home" ? "Home" : item.display}
              </button>
            ))}
        </div>
      )}
    </div>
  );
}

function ChangePasswordPopup({ setShowChangePasswordPopup }) {
  const [showPassword, setShowPassword] = useState({ existing: false, new: false, reenter: false });
  const [passwordsData, setPasswordsData] = useState({ existing: "", new: "", reenter: "" });
  const [errorState, setErrorState] = useState({ existing: false, new: false, reenter: false });

  const handleClickShowPassword = (key) => () => {
    setShowPassword((prev) => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleErrors = () => {
    let tempErrors = JSON.parse(JSON.stringify(errorState));

    setErrorState({
      existing: passwordsData?.existing.length < 4,
      new: passwordsData?.new.length < 4,
      reenter: passwordsData?.new !== passwordsData?.reenter
    });
    tempErrors = {
      existing: passwordsData?.existing.length < 4,
      new: passwordsData?.new.length < 4,
      reenter: passwordsData?.new !== passwordsData?.reenter
    };
    return tempErrors;
  };

  const handleChange = (key) => (event) => {
    setPasswordsData((prev) => ({
      ...prev,
      [key]: event.target.value
    }));
  };

  const handleChangePassword = async () => {
    const tempErrors = handleErrors();
    if (!tempErrors?.existing && !tempErrors?.new && !tempErrors?.reenter) {
      try {
        await axios.put(
          `${process.env.REACT_APP_LOGIN_URL}/api/v1/loginPassword`,
          {
            existing_password: passwordsData?.existing,
            new_password: passwordsData?.new
          },
          { headers: getApiCallHeadersData() }
        );
        toast.success("Password updated successfully", toastSuccessMessageStyle());
        setShowChangePasswordPopup(false);
      } catch (error) {
        setErrorState((prev) => ({
          ...prev,
          existing: error?.response?.data?.message
        }));
      }
    }
  };

  return (
    <div className="popup_outer_container">
      <div className="popup_inner_container">
        <div className="popup_header_container">
          <p className="popup_title">Change Password</p>
          <span
            className="material-symbols-outlined popup_close_icon"
            onClick={() => setShowChangePasswordPopup(false)}
          >
            close
          </span>
        </div>
        <div className="popup_body_container">
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FormControl sx={{ m: 1 }} size="small" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password" style={{ fontSize: "14px" }}>
                Existing Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword.existing ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword("existing")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="small"
                    >
                      {showPassword.existing ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Existing Password"
                value={passwordsData.existing}
                onChange={handleChange("existing")}
                error={errorState?.existing}
                inputProps={{
                  style: { fontSize: "14px" }
                }}
              />
              {errorState?.existing && (
                <FormHelperText error={true}>
                  {errorState?.existing === true ? "Enter valid Password" : errorState?.existing}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl sx={{ m: 1 }} size="small" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password" style={{ fontSize: "14px" }}>
                New Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword.new ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword("new")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="small"
                    >
                      {showPassword.new ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="New Password"
                value={passwordsData.new}
                onChange={handleChange("new")}
                error={errorState?.new}
                inputProps={{
                  style: { fontSize: "14px" }
                }}
              />
              {errorState?.new && <FormHelperText error={true}>Password atleast contains 4 charcters</FormHelperText>}
            </FormControl>
            <FormControl sx={{ m: 1 }} size="small" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password" style={{ fontSize: "14px" }}>
                Re-enter New Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword.reenter ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword("reenter")}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="small"
                    >
                      {showPassword.reenter ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Re-enter New Password"
                value={passwordsData.reenter}
                onChange={handleChange("reenter")}
                error={errorState?.reenter}
                inputProps={{
                  style: { fontSize: "14px" }
                }}
              />
              {errorState?.reenter && <FormHelperText error={true}>Passwords mismatch!</FormHelperText>}
            </FormControl>
            <div className="button_container">
              <button className="primary_default_button" onClick={handleChangePassword}>
                Change Password
              </button>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
}
