import React from "react";

import "./CardRenderer.css";

import { gridCommonFunction } from "../../utils/gridCommonFunction";
import CardItems from "./CardItems";
import { stringToJSONChange } from "../../utils/commonFunction";

const CardRenderer = ({
  configData,
  repeatCard,
  detailsViewData,
  layoutDataLineHeight = 40,
  activityCardsConfig,
  detailsViewDataActivityCard,
  detailsViewDataTimeline,
  detailsViewDataActivitiesHistory,
  jobParamsConfig,
  inline_style,
  detailsViewDataOrg,
  functionObject,
  custom_css,
  detailsViewItemConfig
}) => {
  let layoutConfig = configData.layouts;
  let dataConfig = detailsViewData;
  let bodyClassName = configData?.parent_className || "";
  let rowItemElements = [];
  let LayoutArray = [];
  for (let index = 0; index < layoutConfig.length; index++) {
    let gridElement = layoutConfig[index];
    LayoutArray.push(gridElement.grid);
    rowItemElements.push(gridElement.element_config);
  }
  let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(LayoutArray);
  const custom_css_value = stringToJSONChange(custom_css);
  if (repeatCard) {
    return (
      <>
        {dataConfig.map((cardData, cardIndex) => {
          return (
            <div
              key={cardIndex}
              className={bodyClassName}
              style={{ ...styleDataForGrid, ...inline_style, ...custom_css_value }}
            >
              {rowItemElements.map((colItem, colItemIndex) => {
                return (
                  <CardItems
                    key={colItemIndex}
                    colItem={colItem}
                    dataConfig={cardData}
                    layoutDataLineHeight={layoutDataLineHeight}
                    activityCardsConfig={activityCardsConfig}
                    detailsViewDataActivityCard={detailsViewDataActivityCard}
                    detailsViewDataTimeline={detailsViewDataTimeline}
                    detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
                    jobParamsConfig={jobParamsConfig}
                    detailsViewDataOrg={detailsViewDataOrg}
                    functionObject={functionObject}
                    detailsViewItemConfig={detailsViewItemConfig}
                  />
                );
              })}
            </div>
          );
        })}
      </>
    );
  } else {
    return (
      <div className={bodyClassName} style={{ ...styleDataForGrid, ...inline_style, ...custom_css_value }}>
        {rowItemElements.map((colItem, colItemIndex) => {
          return (
            <CardItems
              key={colItemIndex}
              colItem={colItem}
              dataConfig={dataConfig}
              layoutDataLineHeight={layoutDataLineHeight}
              activityCardsConfig={activityCardsConfig}
              detailsViewDataActivityCard={detailsViewDataActivityCard}
              detailsViewDataTimeline={detailsViewDataTimeline}
              detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
              jobParamsConfig={jobParamsConfig}
              detailsViewDataOrg={detailsViewDataOrg}
              functionObject={functionObject}
              detailsViewItemConfig={detailsViewItemConfig}
            />
          );
        })}
      </div>
    );
  }
};

export default CardRenderer;
