import React from "react";
import { store } from "../../../redux/store";
import CalendarDropDownFilter from "./CalendarDropDownFilter";

const CalendarEntityFilter = ({ namespace }) => {
  const calendarViewLayoutData = store.getState()["calendarviewstore"]?.[`${namespace}_calendarLayoutData`];
  return (
    <>
      {calendarViewLayoutData?.data?.subject_filter?.filters?.map((filter, index) => {
        switch (filter?.field_type) {
          case "select":
          case "textfield":
          case "email":
          case "radio":
          case "learning-text":
            return <CalendarDropDownFilter namespace={namespace} filter={filter} key={index} />;
          case "checkbox":
            return <CalendarDropDownFilter namespace={namespace} filter={filter} key={index} />;
          default:
            return <p>{filter?.field_type}</p>;
        }
      })}
    </>
  );
};

export default CalendarEntityFilter;
