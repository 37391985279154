import React from "react";
import { useNavigate } from "react-router-dom";
import ResetDoneImage from "../../assets/images/resetdoneimage.png";

const ResetPasswordDone = () => {
  const navigate = useNavigate();

  return (
    <div className="resetDoneScreenContainer">
      <div className="resetDoneImageContainer">
        <img src={ResetDoneImage} alt="img" />
      </div>
      <div className="resetDoneFieldsContainer">
        <p className="resetDoneLabel">Password reset successfully</p>
        <div className="resetDoneButtonContainer">
          <button className="primary_default_button resetDoneButton" onClick={() => navigate("/login")}>
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordDone;
