import React from "react";
import "./ProgressBarRenderer.css";
import LinearProgressBar from "./components/LinearProgressBar";
import CircularProgressbar from "./components/CircularProgressbar";

const ProgressBarRenderer = ({ itemConfig = {}, progressBarData = {} }) => {
  switch (itemConfig?.config?.progress_type) {
    case "linear":
      return <LinearProgressBar itemConfig={itemConfig} progressBarData={progressBarData} />;
    case "circular":
      return <CircularProgressbar itemConfig={itemConfig} progressBarData={progressBarData} />;
    default:
      return <LinearProgressBar itemConfig={itemConfig} progressBarData={progressBarData} />;
  }
};

export default ProgressBarRenderer;
