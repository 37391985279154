import React from "react";
import { stringToJSONChange } from "../../utils/commonFunction";
import IconRender from "../../../components/IconRender";

const LinearProgressBar = ({ itemConfig = {}, progressBarData = {} }) => {
  const ProgressBarArray = stringToJSONChange(progressBarData?.data) || [];

  return (
    <div className="progress_bar_container">
      {ProgressBarArray?.length > 0 &&
        ProgressBarArray?.map((eachStatus, index) => {
          const styleObject = {
            color: eachStatus?.color,
            backgroundColor: eachStatus?.bg_color,
            borderColor: eachStatus?.bg_color
          };
          return (
            <div key={index} className="default_progress_bar">
              {eachStatus?.icon && (
                <span style={styleObject} className="default_progress_icon">
                  <IconRender iconValue={eachStatus?.icon} />
                </span>
              )}
              <span className="default_progress_text">{eachStatus?.text}</span>
              {index < ProgressBarArray.length - 1 && (
                <div className="progress_connector" style={{ backgroundColor: eachStatus?.bg_color }} />
              )}
            </div>
          );
        })}
    </div>
  );
};

export default LinearProgressBar;
