import React, { useEffect, useState } from "react";
import SearchBarFilter from "./components/filters/SearchBarFilter";
import RecordField from "./components/records/RecordField";
import { useSelector } from "react-redux";
import RecordHeader from "./components/records/RecordHeader";
import FilterScreen from "./components/filters/FilterScreen";
import { useLocation } from "react-router-dom";
import RecordPageNavigation from "./components/records/RecordPageNavigation";
import TilesRenderer from "../tileslib/TilesRenderer";
import TabScreenFilters from "./components/filters/TabScreenFilters";
import TabScreenDateFilter from "./components/filters/TabScreenDateFilter";

const RecordsViewLayout = ({ namespace }) => {
  const storeData = useSelector((state) => state.recordviewstore);
  const recordsviewLayoutConfig = storeData?.[`${namespace}_recordsviewLayoutConfig`];
  const recordsViewDataConfig = storeData?.[`${namespace}_recordsviewDataConfig`];
  const location = useLocation();
  const screen_name = location.pathname;
  const recordsviewPayloadConfig = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}`));
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [filterCount, setFilterCount] = useState(0);
  const appviewstoredata = useSelector((state) => state.appviewstore);
  const dashboardAppConfig = appviewstoredata.dashboardAppConfig;

  useEffect(() => {
    let length = recordsviewPayloadConfig ? recordsviewPayloadConfig?.params?.search_query?.filter_by?.length : 0;
    setFilterCount(length);
  }, [recordsviewPayloadConfig]);

  const handleFilterPopup = () => {
    setOpenFilterPopup(true);
    document.body.style.overflow = "hidden";
  };

  return (
    <>
      {recordsviewLayoutConfig?.layout?.tiles?.length > 0 && (
        <TilesRenderer
          tilesLayoutConfig={recordsviewLayoutConfig?.layout?.tiles}
          tilesDataConfig={recordsViewDataConfig?.tiles}
        />
      )}
      <div
        className="recordViewContainer"
        style={{ marginBottom: `${dashboardAppConfig?.header?.menu_bottom?.length > 0 ? "62px" : "46px"}` }}
      >
        {["textfield", "email", "select", "radio", "learning-text", "varchar"]?.includes(
          recordsviewLayoutConfig?.layout?.filter_layout?.filter_field?.field_type
        ) && (
          <div className="recordViewTabContainer">
            <TabScreenFilters namespace={namespace} />
          </div>
        )}

        {["date", "datetime"]?.includes(recordsviewLayoutConfig?.layout?.filter_layout?.filter_field?.field_type) && (
          <div className="recordViewTabContainer">
            <TabScreenDateFilter namespace={namespace} />
          </div>
        )}

        <div className="recordViewSearchContainer">
          {recordsviewLayoutConfig?.layout?.search && <SearchBarFilter namespace={namespace} />}
          {recordsviewLayoutConfig?.layout?.onscreen_filters?.length !== 0 && (
            <div className="recordFilterIconContainer" onClick={handleFilterPopup}>
              <span className="material-symbols-outlined recordFilterIcon">filter_alt</span>
              {filterCount !== 0 && (
                <div className="filterCount">
                  <p className="filterCountValue">{filterCount}</p>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="recordViewBodyContainer">
          <RecordHeader
            namespace={namespace}
            selectedRecords={selectedRecords}
            setSelectedRecords={setSelectedRecords}
          />
          <RecordField
            namespace={namespace}
            selectedRecords={selectedRecords}
            setSelectedRecords={setSelectedRecords}
          />
        </div>
      </div>
      {openFilterPopup && (
        <div className="filterscreenPopup">
          <FilterScreen namespace={namespace} setOpenFilterPopup={setOpenFilterPopup} filterCount={filterCount} />
        </div>
      )}
      <div
        className="recordsViewBottomBarContainer"
        style={{
          bottom:
            dashboardAppConfig?.header?.menu_bottom !== null && dashboardAppConfig?.header?.menu_bottom?.length !== 0
              ? "48px"
              : 0
        }}
      >
        <RecordPageNavigation namespace={namespace} />
      </div>
    </>
  );
};

export default RecordsViewLayout;
