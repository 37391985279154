import React from "react";

import { gridCommonFunction } from "../../utils/gridCommonFunction";
import { JobActionRenderer, DropdownActionRenderer } from "../../lib/index";
import { FSIconRenderer } from "../../lib/zinoIcon";
import PublicFormComponent from "./PublicFormComponent";

import "../Component.css";
import "./PublicComponents.css";

const PublicPageView = ({ pageConfig }) => {
  let rowItemElements = [];
  let layoutArray = [];

  pageConfig.forEach((config) => {
    layoutArray.push(config.grid);
    rowItemElements.push(config.element_config);
  });

  let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(layoutArray);

  const elementsHandler = (colItem, colItemIndexIs) => {
    let styleObject = {
      gridArea: `zino-${colItem?.i || colItem.id}`
    };

    let class_name = "";

    if (colItem.className) {
      if (typeof colItem.className === "object") {
        class_name = Object.values(colItem.className).join(" ");
      } else {
        class_name = colItem.className;
      }
    }

    if (colItem.hide) {
      return undefined;
    } else {
      return getColItem(colItem, colItemIndexIs, styleObject, class_name);
    }
  };

  return (
    <div style={{ ...styleDataForGrid, padding: "48x 48px 0 48px" }} className="pageView_container">
      {rowItemElements.map((colItem, colItemIndexIs) => {
        return elementsHandler(colItem, colItemIndexIs);
      })}
    </div>
  );
};

const getColItem = (colItem, colItemIndexIs, styleObject, class_name) => {
  switch (colItem.type) {
    case "button":
      return (
        <div key={colItem.id + colItemIndexIs} style={{ ...styleObject, padding: "10px 0px" }}>
          <JobActionRenderer itemConfig={colItem} />
        </div>
      );

    case "dropdown":
      return (
        <div key={colItem.id + colItemIndexIs} style={{ ...styleObject, padding: "20px" }}>
          <DropdownActionRenderer itemConfig={colItem} />
        </div>
      );

    case "html":
      return (
        <div
          key={colItem.id + colItemIndexIs}
          style={{ ...styleObject, padding: "20px" }}
          dangerouslySetInnerHTML={{
            __html: colItem?.content
          }}
        ></div>
      );

    case "label":
      return (
        <div
          className={`${class_name} block_label`}
          key={colItem.id + colItemIndexIs}
          style={{
            ...styleObject,
            padding: "20px",
            display: "flex",
            alignItems: "center",
            ...(colItem.inline_style || {})
          }}
        >
          <FSIconRenderer icon={colItem?.icon || ""} />
          {colItem.name}
        </div>
      );

    case "image":
      return (
        <div key={colItem.id + colItemIndexIs} style={{ ...styleObject, padding: "20px" }}>
          <img
            src={
              colItem.repo_source
                ? `${process.env.REACT_APP_IMAGE_HOST}/api/v1/public/${colItem.repo_source}`
                : colItem.source
            }
            alt={colItem.name}
            className={class_name}
            style={{ aspectRatio: "16/9", height: "auto", width: "100%", ...(colItem.inline_style || {}) }}
          />
        </div>
      );

    case "publicform":
      return (
        <div key={colItem.id + colItemIndexIs} style={styleObject}>
          <PublicFormComponent itemConfig={colItem} />
        </div>
      );

    case "empty":
      return <div key={colItem.id + colItemIndexIs} style={styleObject}></div>;

    case "spacer":
      return (
        <div key={colItem.id + colItemIndexIs} style={{ ...styleObject, height: `${colItem?.height || 40}px` }}></div>
      );

    default:
      return (
        <div key={colItem.id + colItemIndexIs} style={styleObject}>
          {colItem.type}
        </div>
      );
  }
};

export default PublicPageView;
