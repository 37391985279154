import React from "react";

const ImageField = ({ imgConfig = {} }) => {
  let class_name = ""; //classnames coming from builder for that element
  if (imgConfig.className) {
    if (typeof imgConfig.className === "object") {
      class_name = Object.values(imgConfig.className).join(" ");
    } else {
      class_name = imgConfig.className;
    }
  }

  return (
    <img
      src={
        imgConfig?.repo_source
          ? `${process.env.REACT_APP_IMAGE_HOST}/api/v1/public/${imgConfig?.repo_source}`
          : imgConfig.source
      }
      alt={imgConfig.name}
      className={`${class_name} default_image`}
    />
  );
};

export default ImageField;
