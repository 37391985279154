import React, { useEffect } from "react";
import Page_404_img from "../../assets/images/Page 404.jpg";
import "../Pagestyle.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { callUserLogoutFunction } from "../../redux/userview/userviewaction";
import { getStorageItem } from "../../utils/storageFunction";

const Page404 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_token = getStorageItem("Zino_app_user");

  useEffect(() => {
    if (user_token) dispatch(callUserLogoutFunction(user_token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page404Container">
      <div className="page404ImageContainer">
        <img src={Page_404_img} alt="Screen Not found" className="page404Image" />
      </div>
      <div className="page404ContentContainer">
        <span className="page404Content">No Access</span>
        <button className="primary_default_button page404Button" onClick={() => navigate("/login")}>
          Back to Login
        </button>
      </div>
    </div>
  );
};

export default Page404;
