import React, { useEffect, useState, useRef } from "react";
import "../../Components.css";
import { useSelector, useDispatch } from "react-redux";
import { usePageHandlerContext } from "../../../../pagehandler/PageHandlerContext";
import { useLocation } from "react-router-dom";
import { createRecordsViewPayload } from "../../../../redux/recordview/recordviewaction";

const TabScreenFilters = ({ namespace }) => {
  const { cacheProcessData } = usePageHandlerContext();
  const location = useLocation();
  const screen_name = location.pathname;
  const [selectedTab, setSelectedTab] = useState("");
  const storeData = useSelector((state) => state.recordviewstore);
  const recordsviewPayloadConfig = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}`));
  const recordsviewLayoutConfig = storeData?.[`${namespace}_recordsviewLayoutConfig`];
  const recordsviewDataConfig = storeData?.[`${namespace}_recordsviewDataConfig`];
  const selectedFieldRef = useRef(null);

  const dispatch = useDispatch();
  const [tabId, setTabId] = useState("");
  const [tabViewFacets, setTabViewFacets] = useState([]);

  useEffect(() => {
    let facetArray = [];
    setTabId(recordsviewLayoutConfig?.layout?.filter_layout?.filter_field?.id);
    recordsviewDataConfig?.facets?.forEach((facet) => {
      if (facet?.id === recordsviewLayoutConfig?.layout?.filter_layout?.filter_field?.id) {
        setTabViewFacets(facet?.facet_values);
        facetArray = facet?.facet_values;
      }
    });
    if (!selectedTab) {
      let previousFilters = recordsviewPayloadConfig?.params?.search_query?.filter_by || [];
      let prevTabValue = "";
      previousFilters?.forEach((filter) => {
        if (filter?.id === recordsviewLayoutConfig?.layout?.filter_layout?.filter_field?.id) {
          prevTabValue = filter?.text_value || "";
        }
      });
      if (prevTabValue) {
        setSelectedTab(prevTabValue);
      } else {
        if (facetArray[0]?.value) {
          handleTabClick(facetArray[0]?.value);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsviewDataConfig]);

  const handleTabClick = (value) => {
    setSelectedTab(value);
    let previousFilters = recordsviewPayloadConfig?.params?.search_query?.filter_by || [];
    let previousSearch = recordsviewPayloadConfig?.params?.search_query?.search_term || "";
    let tempFilterObj = {
      id: tabId,
      operator: "is",
      text_value: value,
      text_array: "",
      number_value: "",
      number_range: "",
      human_date: "",
      custom_date_range: ""
    };
    let updatedFilters = previousFilters
      ?.filter((prevFilter) => prevFilter?.id)
      ?.filter((prevFilter) => prevFilter?.id !== tabId);
    updatedFilters.push(tempFilterObj);
    dispatch(
      createRecordsViewPayload({
        namespace: namespace,
        layoutConfig: recordsviewLayoutConfig,
        filter_by: updatedFilters,
        search_term: previousSearch,
        page: 1,
        per_page: 10,
        screen_name: screen_name,
        cacheProcessData: cacheProcessData
      })
    );
  };

  function handleGridHeader() {
    let gridTemplateColumns = "";
    for (let i = 0; i < tabViewFacets?.length; i++) {
      gridTemplateColumns += "1fr ";
    }
    return gridTemplateColumns;
  }

  useEffect(() => {
    if (selectedFieldRef.current) {
      selectedFieldRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [selectedTab]);

  return (
    <>
      {tabViewFacets?.length > 0 ? (
        <div className="filterTabContainer" style={{ display: "grid", gridTemplateColumns: handleGridHeader() }}>
          {tabViewFacets
            ?.filter((option) => option?.value)
            ?.map((option, optionIndex) => (
              <div
                className={
                  option?.value === selectedTab
                    ? "filterTabMainContainer filterSelectedOption"
                    : "filterTabMainContainer"
                }
                ref={option?.value === selectedTab ? selectedFieldRef : null}
                key={optionIndex}
              >
                <div onClick={() => handleTabClick(option?.value)} className="filterTabOption">
                  <span>{option?.value}</span>
                </div>

                <hr className={optionIndex === tabViewFacets.length - 1 ? "filterLasthr" : null} />
              </div>
            ))}
        </div>
      ) : null}
    </>
  );
};

export default TabScreenFilters;
