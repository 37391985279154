import React, { useLayoutEffect, useState } from "react";
import "./TabViewRenderer.css";
import { TabHead } from "./TabHead";
import DetailsViewItem from "../detailsviewlib/DetailsViewItem";

const TabViewRenderer = ({
  configData = {},
  detailsViewData,
  detailsViewDataTimeline,
  activityCardsConfig,
  detailsViewDataActivityCard,
  detailsViewDataActivitiesHistory,
  styleConfig = {},
  jobParamsConfig,
  detailsViewDataOrg,
  functionObject,
  detailsViewItemConfig
}) => {
  const { headerData, bodyData = {} } = configData;
  const [selectedTab, setSelectedTab] = useState(null);

  //classnames coming from builder for that element
  let class_name = "";
  if (styleConfig) {
    if (typeof styleConfig === "object") {
      class_name = Object.values(styleConfig).join(" ");
    } else {
      class_name = styleConfig;
    }
  }

  useLayoutEffect(() => {
    if (!selectedTab) {
      let prevTab = "";
      for (let i = 0; i < headerData?.length; i++) {
        if (headerData[i].active) {
          prevTab = headerData?.[i]?.["id"];
        }
      }
      if (prevTab) {
        setSelectedTab(prevTab);
      } else {
        let id = headerData?.[0]?.["id"];
        if (headerData?.[0]) {
          headerData[0].active = true;
        }
        setSelectedTab(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerData]);

  return (
    <div className="details_block_outer">
      <div className={"tabview_renderer_outerbox static_field_blocks"}>
        {headerData ? (
          <TabHead headerData={headerData} openTab={setSelectedTab} />
        ) : (
          <div>Tab Header not available</div>
        )}
        {selectedTab && bodyData[selectedTab] ? (
          <div className={class_name}>
            <DetailsViewItem
              itemConfig={bodyData[selectedTab]}
              detailsViewDataFormData={detailsViewData}
              detailsViewDataTimeline={detailsViewDataTimeline}
              activityCardsConfig={activityCardsConfig}
              detailsViewDataActivityCard={detailsViewDataActivityCard}
              detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
              jobParamsConfig={jobParamsConfig}
              detailsViewDataOrg={detailsViewDataOrg}
              functionObject={functionObject}
              detailsViewItemConfig={detailsViewItemConfig}
            />
          </div>
        ) : (
          <div>Page data not found</div>
        )}
      </div>
    </div>
  );
};

export default TabViewRenderer;
