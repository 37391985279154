import React, { useEffect, useLayoutEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const CalendarSubjectFilter = ({ namespace, onSubjectChange }) => {
  const location = useLocation();
  const screen_name = location.pathname;

  const [selectedSubject, setSelectedSubject] = useState("");
  const [optionsArray, setOptionsArray] = useState([]);
  const storeData = useSelector((state) => state.calendarviewstore);
  let calendarViewFilterData = storeData?.[`${namespace}_calendarFilterData`];
  let calendarViewLayoutData = storeData?.[`${namespace}_calendarLayoutData`];
  let calendarViewEventspayloadData = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}_event`));

  function handleSubjectChange(value) {
    setSelectedSubject(value);
    onSubjectChange(value);
  }

  function handleOptions() {
    let tempOptionArray = [];
    if (calendarViewLayoutData?.data?.filter_type === "user") {
      calendarViewFilterData?.users?.users?.forEach((user) => {
        let userObject = {};
        userObject.id = user?.id;
        // userObject.label = user.name;
        userObject.label = user?.[calendarViewLayoutData?.data?.subject_field?.id?.split(".")[1]];
        tempOptionArray.push(userObject);
      });
    } else if (calendarViewLayoutData?.data?.filter_type === "entity") {
      calendarViewFilterData?.documents?.forEach((document) => {
        let documentObject = {};
        documentObject.id = document?.id;
        documentObject.label = document?.[calendarViewLayoutData?.data?.subject_field?.id?.split(".")[1]];
        tempOptionArray.push(documentObject);
      });
    }
    setOptionsArray(tempOptionArray);
  }

  useLayoutEffect(() => {
    handleOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarViewFilterData]);

  useEffect(() => {
    let value = calendarViewEventspayloadData?.params?.search_query?.filter_by?.[0]?.text_value;
    if (value) {
      setSelectedSubject(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <FormControl sx={{ minWidth: 120 }} size="small">
      <InputLabel sx={{ fontSize: 14 }}>{calendarViewLayoutData?.data?.subject_field?.display}</InputLabel>
      <Select
        value={selectedSubject}
        label={calendarViewLayoutData?.data?.subject_field?.display}
        onChange={({ target: { value } }) => handleSubjectChange(value)}
        autoWidth
        sx={{ fontSize: 14 }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200
            }
          }
        }}
      >
        <MenuItem value="" sx={{ fontSize: 12, minHeight: 0 }}>
          <em>None</em>
        </MenuItem>
        {optionsArray?.map((option, index) => (
          <MenuItem key={index} value={option?.id} sx={{ fontSize: 12, minHeight: 0 }}>
            {option?.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CalendarSubjectFilter;
