import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { usePageHandlerContext } from "../../../../pagehandler/PageHandlerContext";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../../../utils/apiCallFunction";
// import { ExpandLessIconSvg, ExpandMoreIconSvg } from "../../../zinoIcon";
import { getApiCallHeadersData } from "../../../../utils/storageFunction";
import IconRender from "../../../../components/IconRender";

const DropdownAction = ({ itemConfig, itemType, reqData, callBackFunction }) => {
  const [popupShow, setPopupShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState(itemConfig?.selected_value || "");
  const [optionList, setOptionList] = useState([]);

  const { createNewTask } = usePageHandlerContext();

  const setOptionEmpty = () => {
    setOption("");
  };

  const startNewTask = (optionValue) => {
    let element_config = {
      element_id: itemConfig.id,
      action_start: itemConfig.action_start,
      action_end: itemConfig?.action_end
        ? itemConfig.action_end
        : { refresh: itemConfig.refresh_block ? [itemConfig.namespace] : [] },
      callback_function: [setOptionEmpty],
      callback_function_after: [callBackFunction]
    };
    let action_config = {
      action_in: itemConfig?.job_template || itemConfig?.action_in || itemConfig.action,
      navigate_to: itemConfig?.params?.name || itemConfig.navigate_to,
      action_data: itemConfig?.action_data,
      sm_id: itemConfig?.data_id || itemConfig?.action_data?.sm_id,
      activity_id: itemConfig?.action_data?.activity_id,
      data_source: itemConfig?.data_source,
      data_source_type: itemConfig?.data_type,
      params: itemConfig?.params || {}
    };

    if (itemConfig?.job_template) {
      if (itemConfig.job_template === "get_activities") {
        action_config["action_name"] = "perform_activity";
        action_config["activity_id"] = optionValue;
      } else if (itemConfig.job_template === "perform_transition") {
        let source_activity = "";
        for (let index = 0; index < optionList?.data.length; index++) {
          const element = optionList.data[index];
          if (element.id === optionValue) {
            source_activity = element.source_activity;
            break;
          }
        }
        action_config["activity_id"] = source_activity;
        action_config["action_name"] = "perform_activity";
        action_config["state_id"] = optionValue;
      } else {
        action_config["action_name"] = itemConfig.job_template;
      }

      if (itemType === "single") {
        action_config["instance_id"] = reqData.instance_id;
        action_config["instance_ids"] = null;
      } else if (itemType === "multi") {
        action_config["instance_ids"] = reqData.instance_ids;
        action_config["instance_id"] = null;
        // if (itemConfig.data_type !== "get_activities") {
        //   // action_config["action_name"] = "do_state_transition_bulk";
        //   action_config["instance_id"] = reqData.instance_ids;
        // }
      }
    } else {
      return undefined;
    }
    reqData.data = { ...reqData.data, sm_id: action_config.sm_id };
    createNewTask(element_config, action_config, reqData.data);
  };

  const handleChange = (value) => {
    setOption(value);
    if (itemConfig.action_call_onchange && value) {
      startNewTask(value);
    }
    setPopupShow(false);
  };

  // const handleJobCallFunction = (e) => {
  //   e.stopPropagation();
  //   if (option) startNewTask(option);
  // };

  // Close popup on side click
  const isClickOutside = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isClickOutside.current && !isClickOutside.current.contains(event.target)) {
        setPopupShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const apiCallFunction = async () => {
    try {
      setLoading(true);
      let function_name = itemConfig?.job_template;
      let configObject = {
        data: {},
        function_name: function_name,
        params: {
          sm_id: itemConfig.data_id,
          instance_id: ""
        }
      };
      if (itemType === "single") {
        configObject.params["instance_id"] = reqData.instance_id || null;
      } else if (itemType === "multi") {
        configObject.params["instance_ids"] = reqData?.instance_ids || null;
      }
      let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, configObject, {
        headers: getApiCallHeadersData()
      });
      let object = {};
      for (let index = 0; index < data.data.length; index++) {
        const element = data.data[index];
        object[element.id] = element.name;
      }
      setOptionList({ data: data?.data || [], value: object });
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (popupShow) {
      callOnclickDropdown();
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupShow]);

  useEffect(() => {
    if (option && itemConfig.action_call_onchange) startNewTask(option);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  const callOnclickDropdown = () => {
    if (optionList.length === 0) apiCallFunction();
  };

  // let buttonClass = itemConfig.disabled ? "zino_btn_disabled" : "secondary_default_button";
  return (
    <>
      {/* <div
        className="dropdown_action_field_outerBox"
        ref={isClickOutside}
        style={{
          padding: itemConfig.action_call_onchange ? "9px 14px" : "0px",
          borderColor: itemConfig.disabled ? "#e4e7ec" : "#4a1fb8"
        }}
      >
        {!itemConfig.action_call_onchange && (
          <button onClick={handleJobCallFunction} className={buttonClass + " dropdown_action_button"}>
            {itemConfig.name}
          </button>
        )}
        <span
          className="dropdown_action_field_exp"
          onClick={() => setPopupShow(!itemConfig.disabled && !popupShow)}
          style={{ border: itemConfig.action_call_onchange ? "none" : "" }}
        >
          {itemConfig.action_call_onchange && <span className="dropdown_action_title">{itemConfig.name}</span>}
          {option ? <span>{optionList.value[option]}</span> : <span className="dropdown_action_exp">--</span>}
          {popupShow ? <ExpandLessIconSvg /> : <ExpandMoreIconSvg />}
        </span>
        {popupShow && (
          <div className="dropdown_action_field">
            <div className="dropdown_action_field_selectBox">
              {loading ? (
                <div className="zino_renderer_loader_box" style={{ height: "50px" }}>
                  <span className="zino_renderer_line_loader"></span>
                </div>
              ) : (
                <div
                  className="dropdown_action_field_selectItem"
                  onClick={() => {
                    handleChange("");
                  }}
                >
                  --
                </div>
              )}

              {itemConfig?.data?.map((optionItem, index) => {
                return (
                  <div
                    key={index}
                    className="dropdown_action_field_selectItem"
                    onClick={() => {
                      handleChange(optionItem.value);
                    }}
                  >
                    {optionItem.label}
                  </div>
                );
              })}

              {optionList?.data?.map((optionItem, index) => {
                return (
                  <div
                    key={index}
                    className="dropdown_action_field_selectItem"
                    onClick={() => {
                      handleChange(optionItem.id);
                    }}
                  >
                    {optionItem.name}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div> */}
      <div>
        <button
          className="secondary_default_button"
          disabled={itemConfig?.disabled ? true : false}
          onClick={() => setPopupShow(true)}
        >
          {itemConfig?.icon && <IconRender iconValue={itemConfig?.icon} />}
          {itemConfig?.name}
          <span className="material-symbols-outlined">more_vert</span>
        </button>
        {popupShow && (
          <div className="dropdown_bottom_container">
            <div className="dropdown_bottom_child">
              <div className="dropdown_bottom_header">
                <p className="dropdown_bottom_label">
                  <span className="material-symbols-outlined">arrow_split</span>
                  Choose action
                </p>
                <span className="material-symbols-outlined" onClick={() => setPopupShow(false)}>
                  close
                </span>
              </div>
              <div className="dropdown_bottom_body">
                {loading ? (
                  <div className="dropdown_bottom_loader">
                    <span className="loader"></span>
                  </div>
                ) : (
                  <div className="dropdown_bottom_option_container">
                    {itemConfig?.data?.map((optionItem, index) => {
                      return (
                        <div
                          key={index}
                          className="dropdown_bottom_option"
                          onClick={() => {
                            handleChange(optionItem.value);
                          }}
                        >
                          {optionItem.label}
                        </div>
                      );
                    })}
                    {optionList?.data?.map((optionItem, index) => {
                      return (
                        <div
                          key={index}
                          className="dropdown_bottom_option"
                          onClick={() => {
                            handleChange(optionItem.id);
                          }}
                        >
                          {optionItem.name}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DropdownAction;
