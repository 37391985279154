import React, { useEffect, useState } from "react";
import axios from "axios";
import { getApiCallLocalPath, toastErrorMessageStyle, toastSuccessMessageStyle } from "../utils/apiCallFunction";
import { getApiCallHeadersData } from "../utils/storageFunction";
import toast from "react-hot-toast";

const GetwayRenderer = ({ getwayConfig, removeProcessTask }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [allreadyOpen, setAllreadyOpen] = useState(true);

  useEffect(() => {
    let mapRoot = document.getElementById("checkoutroot");
    if (!mapRoot.innerHTML) {
      const script = document.createElement("script");
      script.src = `/file/rozor_checkout.js`;
      script.onload = () => {
        setShowPopup(true);
      };
      mapRoot.appendChild(script);
    } else {
      setShowPopup(true);
    }
  }, []);

  useEffect(() => {
    if (showPopup && allreadyOpen) {
      setAllreadyOpen(false);
      if (!getwayConfig?.session_id || !getwayConfig?.client_key) {
        return;
      }

      var options = {
        key: getwayConfig.client_key,
        order_id: getwayConfig.session_id,
        handler: async function (response) {
          try {
            let { data } = await axios.post(
              `${getApiCallLocalPath()}api/v1/payment/verify`,
              {
                payment_id: response.razorpay_payment_id,
                order_id: response.razorpay_order_id,
                signature: response.razorpay_signature
              },
              {
                headers: getApiCallHeadersData()
              }
            );
            data = data?.data || {};
            if (typeof data.error === "boolean" && !data.error) {
              toast.success(data?.message || "", toastSuccessMessageStyle());
            } else {
              toast.error(data?.message || "", toastErrorMessageStyle());
            }
          } catch (error) {
            toast.error(error.response?.data?.message || error.response?.data?.error, toastErrorMessageStyle());
          } finally {
            removeProcessTask(getwayConfig.front_end.action_config.task_id, true);
          }
        },
        // User Data
        // prefill: {
        //   name: "",
        //   email: "",
        //   contact: ""
        // },
        modal: {
          ondismiss: function () {
            if (getwayConfig?.front_end?.action_config?.task_id) {
              removeProcessTask(getwayConfig.front_end.action_config.task_id, true);
            } else {
              removeProcessTask();
            }
            // close payment popup
          }
        }
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopup]);

  return <></>;
};

export default GetwayRenderer;
