import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

import { commonFunction } from "../utils/commonFunction";

const FFRadioField = ({ component, currentConfigKey, namespace }) => {
  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || ""
  );
  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
  };

  const handleChipClick = (value) => {
    if (value === inputValue) {
      setInputValue("");
      validate("");
    } else {
      setInputValue(value);
      validate(value);
    }
  };

  return (
    <div className="form_field_outer_box">
      <FormControl>
        <FormLabel
          required={component.validate.required}
          sx={{ color: "var(--mui-label-shrink-color)", fontSize: "14px" }}
        >
          {component.label}
        </FormLabel>
        {component?.show_chips_format ? (
          <Box>
            {component.data.values.map((option, index) => (
              <Chip
                key={`${component.id}_${index}_${component.type}_chip`}
                label={option.label}
                clickable
                variant={inputValue.includes(option.value) ? "filled" : "outlined"}
                color={inputValue === option.value ? "primary" : "default"}
                onClick={() => handleChipClick(option.value)}
                disabled={component.disable}
                sx={{ margin: "0 4px 4px 0" }}
              />
            ))}
          </Box>
        ) : (
          <RadioGroup
            aria-labelledby="form-radio-buttons-group-label"
            value={inputValue}
            name="radio-buttons-group"
            row={component?.isHorizontal}
          >
            {component.data.values.map((option, index) => (
              <FormControlLabel
                key={`${component.id}_${index}_${component.type}_option`}
                disabled={component.disable}
                id={`${component.id}_${index}`}
                value={option.value}
                control={<Radio onClick={(e) => validate(option.value)} sx={{ color: "#c1c4c9" }} />}
                label={option.label}
                sx={{ color: "#10182B", height: "28px" }}
              />
            ))}
          </RadioGroup>
        )}
      </FormControl>

      {errors.length > 0 && (
        <div className="invalid_feedback">
          {errors.map((error, index) => (
            <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
              {error}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default FFRadioField;
