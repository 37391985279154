import React, { createContext, useContext, useEffect, useState } from "react";
import { getApiCallLocalPath } from "../utils/apiCallFunction";

const ImagePreviewContextApi = createContext();

const useImagePreviewContext = () => useContext(ImagePreviewContextApi);

const ImagePreviewContext = ({ children }) => {
  const [openImagePopup, setOpenImagePopup] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);

  const showPopupImagePreview = (imageObj) => {
    setOpenImagePopup(imageObj);
    setIsPopupOpen(true);
  };

  const hidePopupImagePreview = () => {
    setIsPopupOpen(false);
    setOpenImagePopup("");
    document.body.style.overflow = "scroll";
  };

  const handleZoom = (event) => {
    const zoomSpeed = 0.005; // Adjust the speed of zooming
    const newZoomLevel = zoomLevel - event.deltaY * zoomSpeed;

    // Limit the zoom level to a reasonable range (e.g., between 0.5 and 2)
    setZoomLevel(Math.min(Math.max(newZoomLevel, 0.5), 2));
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <ImagePreviewContextApi.Provider value={{ showPopupImagePreview }}>
      {isPopupOpen && (
        <div className="popup_outer_container">
          <div className="popup_inner_container">
            <div className="popup_header_container">
              <p className="popup_title">{openImagePopup?.original_name}</p>
              <span className="material-symbols-outlined popup_close_icon" onClick={hidePopupImagePreview}>
                close
              </span>
            </div>
            <div
              className="popup_body_container"
              onWheel={handleZoom}
              style={{
                touchAction: "manipulation",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxHeight: "60vh"
              }}
            >
              <img
                src={`${getApiCallLocalPath()}api/v1/assets/fileName/${openImagePopup.name}?original=true`}
                alt={openImagePopup.original_name}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  transform: `scale(${zoomLevel})`,
                  transformOrigin: "0 0"
                }}
              />
            </div>
          </div>
        </div>
      )}
      {children}
    </ImagePreviewContextApi.Provider>
  );
};

export default ImagePreviewContext;
export { useImagePreviewContext };
