import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createRecordsViewPayload } from "../../../../redux/recordview/recordviewaction";

import { useLocation } from "react-router-dom";
import { usePageHandlerContext } from "../../../../pagehandler/PageHandlerContext";

const SearchBarFilter = ({ namespace }) => {
  const location = useLocation();
  const screen_name = location.pathname;
  const { cacheProcessData } = usePageHandlerContext();
  const storeData = useSelector((state) => state.recordviewstore);
  const recordsviewPayloadConfig = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}`));
  const recordsviewLayoutConfig = storeData?.[`${namespace}_recordsviewLayoutConfig`];

  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");

  const apiCallAndGetDataDebounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  let previousFilters = recordsviewPayloadConfig?.params?.search_query?.filter_by;
  let previousPerPage = recordsviewPayloadConfig?.params?.search_query?.per_page || 10;

  function handleSearchQuery(value, prevFilters, prevPerPage, cacheProcessData) {
    dispatch(
      createRecordsViewPayload({
        namespace: namespace,
        layoutConfig: recordsviewLayoutConfig,
        search_term: value,
        filter_by: prevFilters,
        page: 1,
        per_page: prevPerPage,
        screen_name: screen_name,
        cacheProcessData: cacheProcessData
      })
    );
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiCallAndGetDataOptimizedFun = useCallback(apiCallAndGetDataDebounce(handleSearchQuery), []);

  return (
    <div className="recordSearchFilterContainer theme_record_searchBar_outer">
      <span className="material-symbols-outlined recordSearchIcon">search</span>
      <input
        type="text"
        placeholder="Enter search term"
        className="recordSearchFilterInput "
        onChange={({ target: { value } }) => {
          setSearchValue(value);
          apiCallAndGetDataOptimizedFun(value, previousFilters, previousPerPage, cacheProcessData);
        }}
        value={searchValue}
      />
      {searchValue && (
        <span
          className="material-symbols-outlined recordSearchIcon recordCloseIcon"
          onClick={() => {
            setSearchValue("");
            apiCallAndGetDataOptimizedFun("", previousFilters, previousPerPage, cacheProcessData);
          }}
        >
          close
        </span>
      )}
    </div>
  );
};

export default SearchBarFilter;
