import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilterDataForAPICall } from "../../../../redux/chartview/chartviewaction";
import { useLocation } from "react-router-dom";

const FilterSelectField = ({ namespace = "", filter = {}, options = [], reset }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [popupShow, setPopupShow] = useState(false);
  const [multiSelectedOptions, setMultiSelectedOptions] = useState([]);
  const storeData = useSelector((state) => state.chartviewstore);
  let filterDataForField = storeData?.[`${namespace}_chartFilterDataForField`] || {};
  let chartLayout = storeData?.[`${namespace}_chartDashboardDataConfig`];
  const isClickOutside = useRef();
  const dispatch = useDispatch();
  function handleDropdownClick() {
    setPopupShow(!popupShow);
  }
  const location = useLocation();
  const screen_name = location.pathname;
  // Function to handle the checkbox change event
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setMultiSelectedOptions([...multiSelectedOptions, value]);
    } else {
      setMultiSelectedOptions(multiSelectedOptions.filter((item) => item !== value));
    }
  };

  const handleRemoveSpecficOption = (event, option) => {
    event.stopPropagation();
    let newOptions = selectedOptions.filter((item) => item !== option);
    setMultiSelectedOptions(newOptions);
    handleDropDownFilterChanges(newOptions);
  };

  const handleDropDownFilterChanges = (value) => {
    setPopupShow(false);
    setSelectedOptions(value);
    let dataFilter = { ...filterDataForField.filters };
    if (!dataFilter.filter_by) {
      dataFilter["filter_by"] = [];
    }
    let dataFilterObject = dataFilter.filter_by;
    let test = dataFilterObject?.filter((prevfilter) => prevfilter?.id !== filter?.id);
    if (value?.length === 0) {
      dataFilterObject = test;
    } else {
      dataFilterObject = test;
      dataFilterObject.push({ id: filter?.id, operator: "in", text_array: value });
    }
    dataFilter.filter_by = dataFilterObject;
    dispatch(getFilterDataForAPICall(namespace, dataFilter, screen_name, chartLayout?.table_type));
  };

  // Close popup on side click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isClickOutside.current && !isClickOutside.current.contains(event.target)) {
        setPopupShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //for previous options
  useEffect(() => {
    let previousFilters = filterDataForField?.filters?.filter_by;
    if (previousFilters?.length !== 0) {
      previousFilters?.forEach((prevfilter) => {
        if (prevfilter?.id === filter?.id) {
          setSelectedOptions(prevfilter?.text_array);
          setMultiSelectedOptions(prevfilter?.text_array);
        }
      });
    } else {
      setSelectedOptions([]);
      setMultiSelectedOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  return (
    <div className="filter_item_container">
      <span className="filter_item_label">{filter?.custom_label || filter?.display}</span>
      <div className="filter_dropdown_container" onClick={handleDropdownClick}>
        <div className="filter_dropdown_content">
          {filter?.is_multi_select ? (
            <>
              {selectedOptions?.length === 0 ? (
                <span className="filter_default_option">All</span>
              ) : (
                <div className="filter_selected_option_container">
                  {selectedOptions?.map((selectedOption, index) => (
                    <div className="filter_selected_option">
                      <span className="filter_selected_value" key={index}>
                        {selectedOption}
                      </span>
                      <span
                        className="material-symbols-outlined selected_close_icon"
                        onClick={(e) => handleRemoveSpecficOption(e, selectedOption)}
                      >
                        close
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <span className="filter_default_option">{selectedOptions?.length === 0 ? "All" : selectedOptions[0]}</span>
          )}
        </div>
        <div className="filter_dropdown_icon_container">
          <span className="material-symbols-outlined">{popupShow ? "keyboard_arrow_up" : "keyboard_arrow_down"}</span>
        </div>
      </div>
      {popupShow && (
        <>
          {filter?.is_multi_select ? (
            <div className="filter_dropdown_menu_container" ref={isClickOutside}>
              <div className="filter_dropdown_menu_list">
                {options.map((optionItem, index) => {
                  return (
                    <label className="filter_dropdown_menu_multi" key={index}>
                      <input
                        type="checkbox"
                        value={optionItem.value}
                        checked={multiSelectedOptions.includes(optionItem.value)}
                        onChange={handleCheckboxChange}
                      />
                      <span>{optionItem.value}</span>
                    </label>
                  );
                })}
              </div>
              <div className="filter_button_container">
                <button className="secondary_default_button" onClick={() => handleDropDownFilterChanges([])}>
                  Clear
                </button>
                <button
                  className="primary_default_button"
                  onClick={() => handleDropDownFilterChanges(multiSelectedOptions)}
                >
                  Apply
                </button>
              </div>
            </div>
          ) : (
            <div className="filter_dropdown_menu_container" ref={isClickOutside}>
              <div className="filter_dropdown_menu_list">
                <div
                  className="filter_dropdown_menu"
                  onClick={() => {
                    handleDropDownFilterChanges([]);
                  }}
                >
                  All
                </div>
                {options.map((optionItem, index) => {
                  return (
                    <div
                      key={index}
                      className="filter_dropdown_menu"
                      onClick={() => {
                        handleDropDownFilterChanges([optionItem.value]);
                      }}
                    >
                      {optionItem.value}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FilterSelectField;
