import React from "react";
import JobAction from "../actions/JobAction";
import { getApiCallLocalPath } from "../../../../utils/apiCallFunction";

import file_save from "../../../../assets/images/downloadfileicon.png";
import IconRender from "../../../../components/IconRender";
import { useImagePreviewContext } from "../../../../applicationcontext/ImagePreviewContext";
import { convertNumbetToDate, convertNumbetToTime } from "../../../utils/commonFunction";
import { getDataFromDataObjectByKey } from "../../../../utils/updateConfigFunction";
import MapPopup from "../../../../components/Map/MapPopup";

const RecordDataViewerField = ({ itemIs, index, namespace, record, table_id }) => {
  // let styleObject = {};
  const { showPopupImagePreview } = useImagePreviewContext();
  let colorIf = {};

  if (itemIs?.config?.colorif) {
    for (let index = 0; index < itemIs.config.colorif.length; index++) {
      const element = itemIs.config.colorif[index];
      colorIf[element.value.toLowerCase()] = element.color;
    }
  }
  let dataIs = [];

  function getClassNames() {
    if (itemIs?.className && Object.values(itemIs?.className).length !== 0) {
      const classNamesArray = Object.values(itemIs?.className);
      const className = classNamesArray.join(" ");
      return className;
    }
    return "";
  }
  if (itemIs?.field_type === "lookup-sm") {
    getDataFromDataObjectByKey(record, dataIs, `${itemIs?.data_key}.instance_id` || "");
  } else {
    getDataFromDataObjectByKey(record, dataIs, itemIs?.data_key || itemIs?.id || "");
  }
  let bgColor = "";
  if (typeof dataIs[0] === "string") {
    bgColor = colorIf[dataIs[0].toLowerCase()];
  } else if (typeof dataIs[0] === "number") {
    bgColor = colorIf[dataIs[0]];
  }
  if (itemIs.type === "field") {
    if (itemIs.data_type === "file" || itemIs.data_type === "image") {
      if (itemIs?.media?.type === "image") {
        let imageFile = [];
        for (let s = 0; s < dataIs.length; s++) {
          const element = dataIs[s];
          if (typeof element === "object") {
            imageFile = [...imageFile, ...element];
          } else {
            imageFile.push(element);
          }
        }
        return (
          <div key={index}>
            {imageFile?.length !== 0 ? (
              imageFile?.map((image_src, image_index) => {
                if (typeof image_src === "object") {
                  if (["image/png", "image/jpeg", "image/jpg"].includes(image_src?.type)) {
                    return (
                      <img
                        key={image_index}
                        style={{ height: "40px", width: "40px", borderRadius: "40px" }}
                        src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src?.name}`}
                        alt={image_src}
                        onClick={() => showPopupImagePreview(image_src)}
                      />
                    );
                  } else if (image_src.url) {
                    return undefined;
                  } else {
                    return (
                      <img
                        key={image_index}
                        style={{ height: "24px", width: "20px" }}
                        src={file_save}
                        alt={image_src}
                        onClick={() => {
                          const isConfirmed = window.confirm("Are you sure you want to download this file?");
                          if (isConfirmed) {
                            window.open(`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src?.name}`, "_blank");
                          }
                        }}
                      />
                    );
                  }
                } else {
                  const endWithPNGPattern = new RegExp(`^.*.png$`, "i");
                  const endWithJPGPattern = new RegExp(`^.*.jpg$`, "i");
                  const endWithJPEGPattern = new RegExp(`^.*.jpeg$`, "i");
                  if (
                    endWithPNGPattern.test(image_src) ||
                    endWithJPGPattern.test(image_src) ||
                    endWithJPEGPattern.test(image_src)
                  ) {
                    return (
                      <img
                        key={image_index}
                        style={{ height: "40px", width: "40px", borderRadius: "40px" }}
                        src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`}
                        alt={image_src}
                      />
                    );
                  } else {
                    if (image_src?.name) {
                      return (
                        <img
                          key={image_index}
                          style={{ height: "24px", width: "20px" }}
                          src={file_save}
                          alt={image_src}
                          onClick={() => {
                            const isConfirmed = window.confirm("Are you sure you want to download this file?");
                            if (isConfirmed) {
                              window.open(`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`, "_blank");
                            }
                          }}
                        />
                      );
                    } else {
                      return null;
                    }
                  }
                }
              })
            ) : (
              <div>-</div>
            )}
          </div>
        );
      } else {
        return <div className="recordfield theme_recordfield">{itemIs?.media?.type}</div>;
      }
    } else if (
      itemIs.data_type === "text" ||
      itemIs.data_type === "select" ||
      itemIs.data_type === "string" ||
      itemIs.field_type === "learning-text" ||
      itemIs.data_type === "number"
    ) {
      return (
        <div
          key={index}
          style={{ color: bgColor, width: "fit-content" }}
          className={"recordfield theme_recordfield " + getClassNames()}
        >
          {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
          {dataIs.join(" , ")}
        </div>
      );
    } else if (itemIs.data_type === "textarea") {
      return (
        <div
          key={index}
          style={{ color: bgColor, width: "fit-content" }}
          className={"recordfield theme_recordfield " + getClassNames()}
        >
          {itemIs?.icon && <IconRender iconValue={itemIs?.icon} />}
          <span dangerouslySetInnerHTML={{ __html: dataIs.join("<br/>").replaceAll(/\n/g, "<br/>") }}></span>
        </div>
      );
    } else if (itemIs.data_type === "datetime" || itemIs.data_type === "date" || itemIs.data_type === "day") {
      if (dataIs.length !== 0 && dataIs[0] !== "-") {
        let formattedDate = "";
        for (let index = 0; index < dataIs.length; index++) {
          const dateItem = dataIs[index];
          if (dateItem !== "-") {
            if (itemIs.field_type === "time") {
              if (index !== 0) formattedDate += " , ";
              formattedDate += convertNumbetToTime(dateItem);
            } else {
              if (index !== 0) formattedDate += " ";
              formattedDate += convertNumbetToDate(dateItem, itemIs.field_type);
            }
          } else {
            formattedDate += "-";
          }
        }
        return (
          <div
            key={index}
            style={{ color: bgColor, width: "fit-content" }}
            className={"recordfield theme_recordfield " + getClassNames()}
          >
            {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
            {formattedDate}
          </div>
        );
      } else {
        return (
          <div key={index} className={"recordfield theme_recordfield " + getClassNames()}>
            -
          </div>
        );
      }
    } else if (itemIs.data_type === "geolocation") {
      if (dataIs) {
        const lat = dataIs?.[0]?.[0];
        const lng = dataIs?.[0]?.[1];
        return (
          <div
            style={{ color: bgColor, width: "fit-content" }}
            className={"recordfield theme_recordfield " + getClassNames()}
            key={index}
          >
            <MapPopup lat={lat} lng={lng} />
          </div>
        );
      } else {
        return <div key={index}></div>;
      }
    } else if (itemIs.data_type === "selectboxes") {
      if (dataIs) {
        return (
          <div key={index} className={"recordfield theme_recordfield " + getClassNames()}>
            {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
            {dataIs.join(" , ")}
          </div>
        );
      } else {
        return (
          <div key={index} className="recordfield theme_recordfield">
            -
          </div>
        );
      }
    } else if (itemIs.data_type === "object") {
      if (dataIs) {
        return (
          <div key={index} className={"recordfield theme_recordfield " + getClassNames()}>
            {JSON.stringify(dataIs, null, 2)}
          </div>
        );
      } else {
        return <div key={index} className={"recordfield theme_recordfield " + getClassNames()}></div>;
      }
    } else if (itemIs.field_type === "radio") {
      return (
        <td className="recordfield theme_recordfield" key={index}>
          {dataIs.join(", ")}
        </td>
      );
    } else if (itemIs.data_type === "boolean") {
      return (
        <div
          style={{ color: colorIf[JSON.stringify(dataIs)?.toLowerCase()], width: "fit-content" }}
          className={"recordfield theme_recordfield " + getClassNames()}
          key={index}
        >
          {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
          {dataIs.join(" , ")}
        </div>
      );
    } else if (itemIs.field_type === "toggle") {
      return (
        <div
          key={index}
          className={"recordfield theme_recordfield " + getClassNames()}
          style={{ color: colorIf[JSON.stringify(dataIs)?.toLowerCase()], width: "fit-content" }}
        >
          {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
          {dataIs.join(" , ")}
        </div>
      );
    } else {
      return (
        <div key={index} className={"recordfield theme_recordfield " + getClassNames()}>
          {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
          {dataIs.join(" , ")}
        </div>
      );
    }
  } else if (itemIs.type === "constant") {
    if (itemIs?.data_type === "text") {
      return (
        <div style={{ color: bgColor }} key={index} className={"constantText theme_constantText " + getClassNames()}>
          {itemIs?.icon && <IconRender iconValue={itemIs.icon} />}
          {itemIs?.display}
        </div>
      );
    } else if (itemIs?.data_type === "image") {
      return (
        <div key={index}>
          <img src={itemIs?.display} alt="img" className="constantImage" />
        </div>
      );
    } else {
      return <div className={"recordfield theme_recordfield " + getClassNames()}>{itemIs?.data_type}</div>;
    }
  } else if (itemIs.type === "button") {
    let itemConfig = itemIs;
    itemConfig.data_id = table_id;
    return (
      <div className={"constantButton " + getClassNames()}>
        <JobAction
          key={"job" + itemIs?.id}
          itemConfig={itemConfig}
          itemType="single"
          reqData={{ data: record, instance_id: record?.id }}
        />
      </div>
    );
  } else {
    return (
      <div key={index} className={"recordfield theme_recordfield " + getClassNames()}>
        {itemIs.type}
      </div>
    );
  }
};

export default RecordDataViewerField;
