import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import BackButton from "../../components/buttons/BackButton";
import useAxios from "../../hooks/useAxios";
import PublicPageView from "../../components/publicScreens/PublicPageView";
import { PageHandlerContextProvider } from "../../pagehandler/PageHandlerContext";
import ProcessTaskComponent from "../../pagehandler/ProcessTaskComponent";
import Loader from "../../components/Loader";

const SelectedPublicPage = () => {
  const [screenData, setScreenData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  const domain = location.state.domain;
  const screenName = location.state.screenName;

  const { axiosInstance, handleError } = useAxios(domain);

  const getScreen = async (screenName) => {
    try {
      setIsLoading(true);

      const { data } = await axiosInstance.get("/guest/" + encodeURIComponent(screenName) + "/mobile");

      setScreenData(data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getScreen(screenName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let content = null;

  if (isLoading) {
    content = <Loader />;
  } else if (screenData && screenData.layout && screenData.layout.length > 0) {
    content = <div className="public_page_container">{<PublicPageView pageConfig={screenData.layout} />}</div>;
  }

  return (
    <PageHandlerContextProvider>
      <div className="header_container" style={{ padding: "0px", gap: "0px" }}>
        <BackButton />
        <h4>{screenName || ""}</h4>
      </div>

      {content}
      <ProcessTaskComponent />
    </PageHandlerContextProvider>
  );
};

export default SelectedPublicPage;
