import React, { useLayoutEffect, useState } from "react";
import { commonFunction } from "../utils/commonFunction";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";

const FFIdGenField = ({ component, currentConfigKey, namespace }) => {
  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || ""
  );
  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
  };

  let placeholderText = "";
  if (component.id_gen_config.prefix) {
    placeholderText += component.id_gen_config.prefix;
  }

  if (component.id_gen_config.date_format) {
    if (component.id_gen_config.prefix) {
      placeholderText += "-";
    }
    placeholderText += component.id_gen_config.date_format;
  }

  if (component.id_gen_config.sequence) {
    if (component.id_gen_config.prefix || component.id_gen_config.date_format) {
      placeholderText += "-";
    }
    placeholderText += "xxxxxx";
  }

  return (
    <div className="form_field_outer_box">
      {component.disable ? (
        <TextField
          id={component.id}
          type="text"
          fullWidth
          label={component.label}
          value={
            commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || placeholderText
          }
          disabled={true}
          size="small"
          sx={{
            "& .MuiInputLabel-root": {
              color: "var(--mui-label-color)"
            },
            "& .MuiInputLabel-root.Mui-error": {
              color: "var(--mui-label-error-color)" // color when there's an error
            },
            "& .MuiInputLabel-root.MuiInputLabel-shrink": {
              color: "var(--mui-label-shrink-color)" // color when the label is at the top (shrunk)
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderWidth: "var(--mui-border-width)",
                borderColor: "var(--mui-border-color)" // default border color
              },
              "&:hover fieldset": {
                borderWidth: "var(--mui-border-width)",
                borderColor: "var(--mui-border-color)" // border color on hover
              },
              "&.Mui-focused fieldset": {
                borderWidth: "var(--mui-border-width)",
                borderColor: "var(--mui-border-color)" // border color when focused
              },
              "&.Mui-disabled fieldset": {
                borderWidth: "var(--mui-border-width)",
                borderColor: "var(--mui-border-color)" // border color when focused
              },
              "&.Mui-error fieldset": {
                borderWidth: "var(--mui-border-width)",
                borderColor: "var(--mui-border-error-color)" // border color when there's an error
              }
            }
          }}
        />
      ) : (
        <TextField
          id={component.id}
          type="text"
          fullWidth
          error={errors.length > 0}
          label={component.label}
          placeholder={placeholderText}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={(e) => validate(e.target.value)}
          disabled={component.disable}
          required={component.validate.required}
          size="small"
          sx={{
            "& .MuiInputLabel-root": {
              color: "#8788a6"
            },
            "& .MuiInputLabel-root.Mui-error": {
              color: "#ff1744" // color when there's an error
            },
            "& .MuiInputLabel-root.MuiInputLabel-shrink": {
              color: "#667085" // color when the label is at the top (shrunk)
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#e4e7ec" // default border color
              },
              "&:hover fieldset": {
                borderColor: "#e4e7ec" // border color on hover
              },
              "&.Mui-focused fieldset": {
                borderWidth: "1px",
                borderColor: "#e4e7ec" // border color when focused
              },
              "&.Mui-error fieldset": {
                borderColor: "#ff1744" // border color when there's an error
              }
            }
          }}
        />
      )}
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FFIdGenField;
