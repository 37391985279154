import React, { useEffect, useState } from "react";
import { createFormConfigandAddGridKeyInFieldForm } from "../../lib/formrendererlib/utils/formUtilsFunction";
import { useDispatch } from "react-redux";
import { FormRenderer } from "../../lib";
import { updateFormData } from "../../redux/formview/formviewaction";
import axios from "axios";
import toast from "react-hot-toast";
import { toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { FSIconRenderer } from "../../lib/zinoIcon";
import { getStorageItem } from "../../utils/storageFunction";
import { ZINO_PUBLIC_DOMAIN_MOB } from "../../Constants/LocStorageKeys";

const PublicFormComponent = ({ itemConfig }) => {
  const dispatch = useDispatch();

  const [formConfig, setFormConfig] = useState(null);
  const [formResponseConfig, setFormResponseConfig] = useState(null);

  const getFormConfig = async () => {
    try {
      let data = await createFormConfigandAddGridKeyInFieldForm(itemConfig.config);
      setFormConfig(data);
    } catch (error) {
      setFormConfig(null);
    }
  };

  const submitSubFormCallback = async (res) => {
    try {
      let configObject = {
        data: res.data || {},
        function_name: "public_form_submit_bg",
        params: {
          form_id: formConfig.id || ""
        }
      };

      const domain = getStorageItem(ZINO_PUBLIC_DOMAIN_MOB);

      let { data } = await axios.post(`${domain}/api/v1/public/dynamic`, configObject, {
        headers: ""
      });

      let response = data.data || {};

      if (response?.type) {
        setFormResponseConfig(response);
      }

      dispatch(updateFormData(formConfig.id, { section_001: {} }));
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error || "", toastErrorMessageStyle());
    }
  };

  useEffect(() => {
    getFormConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFormResponseConfig = () => {
    setFormResponseConfig(null);
  };

  if (!formConfig) {
    return <div></div>;
  } else if (formResponseConfig) {
    if (formResponseConfig.type === "notification") {
      return (
        <div className="public_form_response_outerBox">
          <div onClick={resetFormResponseConfig} className="public_form_response_back_box">
            <FSIconRenderer icon={"arrow_back_ios"} marginR="-8px" iconColor={"gray"} />
          </div>
          <div className="public_form_response_notification_box">{formResponseConfig.notification || ""}</div>
        </div>
      );
    } else if (formResponseConfig.type === "list") {
      return (
        <div className="public_form_response_outerBox">
          <div onClick={resetFormResponseConfig} className="public_form_response_back_box">
            <FSIconRenderer icon={"arrow_back_ios"} marginR="-8px" iconColor={"gray"} />
          </div>
          <div className="public_form_response_list_box">
            {formResponseConfig.list && (
              <>
                {formResponseConfig.list.map((listItemBox, listBoxIndex) => {
                  return (
                    <div key={listBoxIndex} style={{ border: "1px solid gray", padding: "8px" }}>
                      {listItemBox.list_items.map((listItem, listIndex) => {
                        if (listItem.type === "text") {
                          return (
                            <div className="public_form_response_listItem" key={listIndex}>
                              <span className="label">{listItem?.label} </span>
                              <span className="data_text">{listItem?.data}</span>
                            </div>
                          );
                        } else if (listItem.type === "html") {
                          return (
                            <div
                              key={listIndex}
                              dangerouslySetInnerHTML={{
                                __html: listItem?.data
                              }}
                            ></div>
                          );
                        } else if (listItem.type === "label") {
                          return (
                            <div className="public_form_response_listItem" key={listIndex}>
                              <span className="data_label">{listItem?.label || listItem?.data}</span>
                            </div>
                          );
                        } else if (listItem.type === "image") {
                          return (
                            <div className="public_form_response_listItem" key={listIndex}>
                              <span className="label">{listItem?.label} </span>
                              <span className="data_image">
                                <img
                                  src={listItem?.data}
                                  alt={listItem?.data}
                                  style={{ aspectRatio: "16/9", height: "auto", width: "100%" }}
                                />
                              </span>
                            </div>
                          );
                        } else if (listItem.type === "file") {
                          return (
                            <div className="public_form_response_listItem" key={listIndex}>
                              <span className="label">{listItem?.label} </span>
                              <a className="data_file" rel="noreferrer" href={`${listItem?.data}`} target="_blank">
                                {listItem?.data}
                              </a>
                            </div>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      );
    } else if (formResponseConfig.type === "html") {
      return (
        <div className="public_form_response_outerBox">
          <div onClick={resetFormResponseConfig} className="public_form_response_back_box">
            <FSIconRenderer icon={"arrow_back_ios"} marginR="-8px" iconColor={"gray"} />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: formResponseConfig?.html
            }}
          ></div>
        </div>
      );
    } else {
      return (
        <div className="public_form_response_outerBox">
          <div onClick={resetFormResponseConfig} className="public_form_response_back_box">
            <FSIconRenderer icon={"arrow_back_ios"} marginR="-8px" iconColor={"gray"} />
          </div>
        </div>
      );
    }
  } else {
    return (
      <FormRenderer
        apidata={{
          apicalldata: formConfig
        }}
        callbackfunction={{
          formsubmitgetresponscbfun: submitSubFormCallback
        }}
        namespace={formConfig.id}
      />
    );
  }
};

export default PublicFormComponent;
