import React, { Fragment } from "react";
import FilterSelectFieldLV from "./filter/FilterSelectFieldLV";
import DateRangePickerField from "./filter/DateRangePickerField";
// import DatePickerField from "./filter/DatePickerField";
// import DateTimePickerField from "./filter/DateTimePickerField";
import FilterMSelectFieldLV from "./filter/FilterMSelectFieldLV";

const MapFilter = ({ namespace, recordviewTableData, recordviewDataConfig, tableDataHeader }) => {
  let filterDataArray = recordviewTableData.facets;
  let filterDataObject = {};
  for (let index = 0; index < filterDataArray.length; index++) {
    const element = filterDataArray[index];
    filterDataObject[element.id] = element.facet_values;
  }

  let objectOfTableHeader = {};
  for (let index = 0; index < tableDataHeader.length; index++) {
    const element = tableDataHeader[index];
    objectOfTableHeader[element.id] = element?.custom_label || element?.label || "";
  }

  return (
    <div className="top_filter_outerBox" style={{ padding: "12px" }}>
      {/* {recordviewDataConfig.onscreen_filters.length > 0 && (
        <p style={{ padding: "10px 0", fontSize: "16px", fontWeight: "600", color: "gray" }}>Filter</p>
      )} */}
      {recordviewDataConfig.onscreen_filters.map((filter, index) => {
        let options = filterDataObject[filter.id] || [];
        if (objectOfTableHeader[filter.id]) {
          filter.display = objectOfTableHeader[filter.id];
        }
        switch (filter.data_type) {
          case "text":
          case "string":
          case "select":
            if (filter.multiselect) {
              return <FilterMSelectFieldLV key={index} filter={filter} namespace={namespace} options={options} />;
            } else {
              return <FilterSelectFieldLV key={index} filter={filter} namespace={namespace} options={options} />;
            }

          // case "date":
          //   return <DatePickerField key={index} filter={filter} namespace={namespace} />;

          // case "datetime":
          //   return <DateTimePickerField key={index} filter={filter} namespace={namespace} />;

          case "date":
          case "datetime":
          case "date-range":
          case "datetime-range":
            return <DateRangePickerField key={index} filter={filter} namespace={namespace} />;

          // case "datetime-range":
          //   return <Fragment key={index}></Fragment>;

          case "number":
            return <Fragment key={index}></Fragment>;

          default:
            return <Fragment key={index}></Fragment>;
        }
      })}
    </div>
  );
};

export default MapFilter;
