import React, { useEffect } from "react";
import { getMapPopupDataComponent } from "./MapPopupDataComponent";
import { usePageHandlerContext } from "../../../pagehandler/PageHandlerContext";
import toast from "react-hot-toast";
import { toastErrorMessageStyle } from "../../../utils/apiCallFunction";

const MapGoogle = ({ locationArray, locationPoint, locationDataArray, namespace }) => {
  const { createNewTask } = usePageHandlerContext();

  useEffect(() => {
    function initMap() {
      const mapDiv = document.getElementById(`${namespace}_map`);
      if (!mapDiv) {
        console.error(`Map container with ID "${namespace}_map" not found.`);
        return;
      }

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const userLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            const map = new window.google.maps.Map(mapDiv, {
              center: new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
              zoom: 9
            });

            for (let i = 0; i < locationDataArray.length; i++) {
              const locationData = locationDataArray[i];
              let locationDataRes = JSON.parse(JSON.stringify(locationData));
              let keyArray = locationPoint.split(".");
              for (let j = 0; j < keyArray.length; j++) {
                locationDataRes = locationDataRes[keyArray[j]];
              }
              if (!locationDataRes) continue;

              const contentString = getMapPopupDataComponent(locationArray, locationData, locationPoint, namespace);
              const infowindow = new window.google.maps.InfoWindow({
                content: contentString
              });

              const marker = new window.google.maps.Marker({
                position: { lat: locationDataRes[0], lng: locationDataRes[1] },
                map
              });

              marker.addListener("click", () => infowindow.open(map, marker));
            }
          },
          function (error) {
            if (error.code === error.PERMISSION_DENIED) {
              mapDiv.innerHTML = `
                <div style="padding: 20px; text-align: center;">
                <span style="text-align:center;font-size:24px" class="material-symbols-outlined">location_off</span>
                  <p>Location access has been denied.</p>
                  <p style="word-break:break-word">Once location access is granted, refresh the page.</p>
                </div>
              `;
            } else {
              toast.error("Failed to get your location.", toastErrorMessageStyle());
            }
          }
        );
      } else {
        toast.error("Geolocation is not supported by this browser.", toastErrorMessageStyle());
      }
    }

    if (window.google && window.google.maps) {
      initMap();

      const handleJobCallFunction = (buttonId, dataID) => {
        let itemConfig;
        let breakFlag = false;
        for (let index = 0; index < locationArray.length; index++) {
          const customElement = locationArray[index];
          if (customElement.type === "custom") {
            for (let i = 0; i < customElement.config.length; i++) {
              const element = customElement.config[i];
              if (element.id === buttonId) {
                itemConfig = element;
                breakFlag = true;
                break;
              }
            }
          }
          if (breakFlag) break;
        }

        if (!itemConfig) return;

        let element_config = {
          element_id: itemConfig.id,
          action_start: itemConfig.action_start,
          action_end: itemConfig.action_end
        };

        let action_config = {
          action_in: itemConfig?.job_template || itemConfig?.action_in || itemConfig.action,
          navigate_to: itemConfig?.params?.name || itemConfig.navigate_to,
          action_data: itemConfig?.action_data,
          action_name: itemConfig?.job_template || itemConfig?.action_data?.job_name,
          sm_id: itemConfig.data_id || itemConfig?.action_data?.sm_id,
          activity_id: itemConfig?.action_data?.activity_id,
          data_source: itemConfig?.data_source,
          data_source_type: itemConfig?.data_type,
          params: itemConfig?.params || {}
        };

        if (itemConfig.refresh_block) {
          if (element_config.action_end) {
            element_config.action_end.refresh = [itemConfig.namespace];
          } else {
            element_config.action_end = { refresh: [itemConfig.namespace] };
          }
        }
        let reqData = {};
        for (let index = 0; index < locationDataArray.length; index++) {
          const element = locationDataArray[index];
          if (element.id === dataID) {
            reqData = element;
            break;
          }
        }

        createNewTask(element_config, action_config, reqData);
      };

      window.handleJobCallFunction = handleJobCallFunction;
    }
  }, [namespace, createNewTask, locationArray, locationDataArray, locationPoint]);

  return (
    <div
      className="DashboardRF_rightPartBox"
      id={`${namespace}_map`}
      style={{ height: "100%", width: "100%", minHeight: "180px" }}
    ></div>
  );
};

export default MapGoogle;
