const validatePreFiltersJoinedByString = (s) => {
  // Ensure the string starts with '(' and ends with ')'
  const pattern = /^\(.*\)$/;

  if (!pattern.test(s)) {
    return false;
  }

  // Strip the outer parentheses for further checks
  const inner = s.slice(1, -1);
  return isValidExpression(inner);
};

// Helper function to validate expressions recursively
function isValidExpression(s) {
  let balance = 0;
  let lastCharWasOperator = false; // Track if the last valid char was an operator
  let expectingOperand = true; // Initially, we expect an operand

  for (let i = 0; i < s.length; i++) {
    const char = s[i];

    if (char === "(") {
      if (!expectingOperand) {
        return false; // If we're not expecting an operand, an opening parenthesis is invalid
      }
      balance++;
      expectingOperand = true;
      lastCharWasOperator = false;
    } else if (char === ")") {
      balance--;
      if (balance < 0 || lastCharWasOperator) {
        return false; // More closing brackets than opening or directly after an operator
      }
      expectingOperand = false;
    } else if (/[A-Z]/.test(char)) {
      if (!expectingOperand) {
        return false; // No operator before this alphabet
      }
      expectingOperand = false;
      lastCharWasOperator = false;
    } else if (char === "|" || char === "&") {
      if (expectingOperand || lastCharWasOperator) {
        return false; // Operator without a preceding valid operand or two operators in a row
      }
      expectingOperand = true;
      lastCharWasOperator = true;
    } else {
      return false; // Invalid character
    }
  }

  return balance === 0 && !lastCharWasOperator; // Ensure balanced parentheses and no trailing operator
}

const evaluatePreFiltersJoinedByExpression = (expression) => {
  // Replace '|' with '||' and '&' with '&&'
  const jsExpression = expression.replace(/\|/g, "||").replace(/&/g, "&&");

  // Use eval to evaluate the JavaScript expression
  try {
    // eslint-disable-next-line no-eval
    return eval(jsExpression);
  } catch (e) {
    console.error("Invalid expression:", e);
    return null;
  }
};

const getDataFromDataObjectByKey = (data, ans, keyPath) => {
  let keysArray = keyPath.split(".");
  let dataIs = data;
  for (let i = 0; i < keysArray.length; i++) {
    let key = keysArray[i];
    if (key === "_id") {
      key = "id";
    }

    dataIs = dataIs?.[key];
    if (dataIs === undefined || dataIs === null) {
      break;
    }
    if (typeof dataIs === "object") {
      if (Array.isArray(dataIs)) {
        let myPath = [...keysArray];
        myPath.splice(0, i + 1);
        for (let j = 0; j < dataIs.length; j++) {
          getDataFromDataObjectByKey(dataIs[j], ans, myPath.join("."));
        }
      }
    }
  }
  ans.push(dataIs);
};

const validateDataByOperator = (preFiltersItem, dataArrayA, dataArrayB) => {
  const operator = preFiltersItem?.operator || "";
  let comFlag = false;

  for (let index = 0; index < 1; index++) {
    const dataA = dataArrayA[index];

    switch (operator) {
      case "=":
        comFlag = preFiltersItem.number_value === dataA;
        break;
      case "!=":
        comFlag = preFiltersItem.number_value !== dataA;
        break;
      case ">":
        comFlag = preFiltersItem.number_value < dataA;
        break;
      case ">=":
        comFlag = preFiltersItem.number_value <= dataA;
        break;
      case "<":
        comFlag = preFiltersItem.number_value > dataA;
        break;
      case "<=":
        comFlag = preFiltersItem.number_value >= dataA;
        break;
      case "between":
        comFlag =
          parseFloat(preFiltersItem?.number_range?.[0] || "0") < dataA &&
          parseFloat(preFiltersItem?.number_range?.[0] || "0") > dataA;
        break;
      case "contains":
        comFlag = dataA.includes(preFiltersItem.text_value);
        break;

      case "is":
        comFlag = preFiltersItem.text_value === dataA;
        break;
      case "isnot":
        comFlag = preFiltersItem.text_value !== dataA;
        break;
      case "!null":
      case "exists":
        comFlag = dataA !== null && dataA !== undefined && !(typeof dataA === "string" && dataA === "");
        break;
      case "null":
      case "does_not_exists":
        comFlag = dataA === null || dataA === undefined || typeof dataA === "undefined" || dataA === "";
        break;
      case "istrue":
        comFlag = dataA === true;
        break;
      case "isfalse":
        comFlag = dataA === false;
        break;
      case "in":
        let array = preFiltersItem?.text_array || [];
        comFlag = array.includes(dataA);
        break;

      default:
        break;
    }
  }
  return comFlag;
};

const evaluatePreFiltersExpression = (preFiltersJoinBy, preFilters, dataObject) => {
  if (!preFiltersJoinBy || !preFilters || preFilters.length === 0) {
    return true;
  }

  let vaildString = validatePreFiltersJoinedByString(preFiltersJoinBy);

  if (!vaildString) {
    return vaildString;
  }

  let preFiltersJoinByObject = {};

  for (let index = 0; index < preFilters.length; index++) {
    const preFiltersItem = preFilters[index];
    if (preFiltersItem["alias"]) {
      let dataIs = [];
      getDataFromDataObjectByKey(dataObject, dataIs, preFiltersItem?.id || "");
      preFiltersJoinByObject[preFiltersItem["alias"]] = validateDataByOperator(preFiltersItem, dataIs) || false;
    }
  }

  let evaluateExpressionString = "";
  for (let index = 0; index < preFiltersJoinBy.length; index++) {
    const preFiltersJoinByItem = preFiltersJoinBy[index];
    if (/[A-Z]/.test(preFiltersJoinByItem)) {
      evaluateExpressionString += preFiltersJoinByObject[preFiltersJoinByItem];
    } else {
      evaluateExpressionString += preFiltersJoinByItem;
    }
  }

  return evaluatePreFiltersJoinedByExpression(evaluateExpressionString);
};

export default evaluatePreFiltersExpression;

export { validatePreFiltersJoinedByString, evaluatePreFiltersJoinedByExpression };
