import axios from "axios";
import { toast } from "react-hot-toast";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";

//NEW ACTIONS TYPES
export const RECORDSVIEW_LAYOUT_CONFIG = "RECORDSVIEW_LAYYOUT_CONFIG";
export const RECORDSVIEW_DATA_CONFIG = "RECORDSVIEW_DATA_CONFIG";
export const REMOVE_RECORDSVIEW_DATA_CONFIG = "REMOVE_RECORDSVIEW_DATA_CONFIG";

//NEW ACTIONS
export const setRecordsViewLayoutConfig = (namespace, payloadData) => {
  return {
    type: RECORDSVIEW_LAYOUT_CONFIG,
    payload: { namespace, payloadData }
  };
};

export const setRecordsViewDataConfig = (namespace, payloadData) => {
  return {
    type: RECORDSVIEW_DATA_CONFIG,
    payload: { namespace, payloadData }
  };
};

export const removeRecordsViewDataConfig = (namespace) => {
  return {
    type: REMOVE_RECORDSVIEW_DATA_CONFIG,
    payload: { namespace }
  };
};
//create api payload
export const createRecordsViewPayload =
  ({
    namespace = "",
    layoutConfig,
    filter_by = [],
    search_term = "",
    screen_name = "",
    page = 1,
    per_page = 10,
    cacheProcessData = {}
  }) =>
  async (dispatch) => {
    const { layout = {} } = layoutConfig;
    function createFacetBy() {
      const { onscreen_filters = [] } = layout;
      let facetArray = [];
      for (let i = 0; i < onscreen_filters?.length; i++) {
        if (
          ["textfield", "email", "select", "radio", "learning-text", "varchar"].includes(
            onscreen_filters[i]?.field_type
          )
        ) {
          facetArray.push(onscreen_filters[i]?.id);
        }
      }
      return facetArray;
    }
    function createSearchFields() {
      const { header, footer, catalog } = layout;
      let searchFieldsArray = [];
      let allElementsArray = [
        ...(header?.column1?.elements || []),
        ...(header?.column2?.elements || []),
        ...(footer?.column1?.elements || []),
        ...(footer?.column2?.elements || [])
      ];
      for (let i = 0; i < allElementsArray?.length; i++) {
        if (
          ["textfield", "email", "select", "radio", "learning-text", "varchar", "id-gen"].includes(
            allElementsArray[i]?.field_type
          )
        ) {
          searchFieldsArray.push(allElementsArray[i]?.id);
        }
      }
      function catalogSearchFields({ elements }) {
        let ids = [];
        let elementsArray = [];
        for (let i = 0; i < elements?.length; i++) {
          elementsArray.push(elements[i]?.element_config);
        }
        for (let i = 0; i < elementsArray?.length; i++) {
          if (
            ["textfield", "email", "select", "radio", "learning-text", "varchar", "id-gen"].includes(
              elementsArray[i]?.field_type
            )
          ) {
            ids.push(elementsArray[i]?.id);
          } else if (elementsArray[i]?.type === "section") {
            let tempIds = catalogSearchFields({ elements: elementsArray[i]?.config?.layouts });
            ids = [...ids, ...tempIds];
          }
        }
        return ids;
      }

      let searchCatalogFieldsArray = [];
      searchCatalogFieldsArray = catalogSearchFields({ elements: catalog?.elements });
      searchFieldsArray = [...searchFieldsArray, ...searchCatalogFieldsArray];
      return searchFieldsArray;
    }
    let function_name = "perform_search";
    let apiPayload = {
      data: {},
      function_name: function_name,
      params: {
        recordsview_id: namespace,
        search_query: {
          search_fields: createSearchFields(),
          search_term: search_term,
          facet_by: createFacetBy(),
          filter_by: filter_by,
          page: parseInt(page),
          per_page: parseInt(per_page)
        },
        token_data: cacheProcessData?.value || {}
      }
    };
    localStorage.setItem(`${screen_name}_${namespace}`, JSON.stringify(apiPayload));
    dispatch(getRecordsViewData({ namespace: namespace, apiPayload: apiPayload }));
  };

//function to get RecordsData
export const getRecordsViewData =
  ({ namespace = "", apiPayload = {} }) =>
  async (dispatch) => {
    try {
      dispatch(removeRecordsViewDataConfig(namespace));
      const response = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, apiPayload, {
        headers: getApiCallHeadersData()
      });
      let recordsViewData = response?.data?.data;
      dispatch(setRecordsViewDataConfig(namespace, recordsViewData));
    } catch (error) {
      toast.error(error?.response?.data?.message || "records not found", toastErrorMessageStyle());
      dispatch(setRecordsViewDataConfig(namespace));
    }
  };
