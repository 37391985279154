import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import JobAction from "../actions/JobAction";
import DropdownAction from "../actions/DropdownAction";
import { getStorageItem } from "../../../../utils/storageFunction";

const RecordHeader = ({ namespace, selectedRecords, setSelectedRecords }) => {
  const storeData = useSelector((state) => state.recordviewstore);
  const recordsviewLayoutConfig = storeData?.[`${namespace}_recordsviewLayoutConfig`];
  const recordsviewDataConfig = storeData?.[`${namespace}_recordsviewDataConfig`];

  const [showMultiSelectMenu, setShowMultiSelectMenu] = useState(false);
  const handleSelectAllClick = (checked) => {
    if (checked) {
      let tableDataObject = recordsviewDataConfig?.documents;
      let newSelected = [];
      for (let i = 0; i < tableDataObject.length; i++) {
        let element = tableDataObject[i];
        newSelected.push(element?.id || element?.[recordsviewDataConfig?.primary_key]);
      }
      setSelectedRecords(newSelected);
    } else {
      setSelectedRecords([]);
    }
  };

  const callBackFunction = () => {
    setSelectedRecords([]);
    setShowMultiSelectMenu(false);
    let checkedElement = document.getElementsByClassName("recordsCheckbox")[0];
    if (checkedElement) {
      checkedElement.checked = false;
    }
  };

  const handleShowMenu = () => {
    if (selectedRecords.length !== 0) {
      setShowMultiSelectMenu(!showMultiSelectMenu);
    } else {
      setShowMultiSelectMenu(false);
    }
  };

  useEffect(() => {
    if (showMultiSelectMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showMultiSelectMenu]);

  const record_params = JSON.parse(getStorageItem(`${window.location.pathname}_${namespace}`));

  return (
    <>
      <div className="recordsViewHeaderContainer">
        <div className="recordsViewTitleHeaderContainer">
          {recordsviewLayoutConfig?.layout?.multi_select_custom_actions?.length !== 0 && (
            <div className="recordsViewCheckboxContainer">
              <input
                type="checkbox"
                className="recordsCheckbox"
                onChange={({ target: { checked } }) => handleSelectAllClick(checked)}
              />
            </div>
          )}
          {recordsviewLayoutConfig?.layout?.title && (
            <div className="recordsViewTitleContainer">
              <p className="recordsViewTitle">{recordsviewLayoutConfig?.layout?.title}</p>
            </div>
          )}
        </div>
        <div className="recordsViewHeaderActionContainer">
          {recordsviewLayoutConfig?.layout?.multi_select_custom_actions?.length !== 0 && (
            <div className="recordsViewHeaderActionMenuIcon" onClick={() => handleShowMenu()}>
              <span className="material-symbols-outlined records_icon_style">more_vert</span>
            </div>
          )}
        </div>
      </div>
      {showMultiSelectMenu && selectedRecords?.length !== 0 ? (
        <div className="recordsViewActionContainer">
          <div className="recordsViewActionBottomContainer">
            <div className="recordsViewActionHeaderContainer">
              <p style={{ fontSize: "14px" }}>
                <span className="material-symbols-outlined">arrow_split</span>
                Choose action
              </p>
              <span
                className="material-symbols-outlined icon_style"
                onClick={handleShowMenu}
                style={{
                  fontWeight: 600,
                  padding: "8px",
                  color: "#fff",
                  background: "#10182b",
                  borderRadius: "50%",
                  textAlign: "center"
                }}
              >
                close
              </span>
            </div>
            <div className="recordsViewActionBodyContainer">
              <div className="recordsViewHeaderActionMenuList">
                {recordsviewLayoutConfig?.layout?.multi_select_custom_actions?.map((custom_action, index) => {
                  custom_action.data_id = recordsviewLayoutConfig?.layout?.table_id;
                  custom_action.namespace = namespace;
                  switch (custom_action.type) {
                    case "button":
                      return (
                        <div key={index}>
                          <JobAction
                            key={"job" + index}
                            itemConfig={custom_action}
                            itemType="multi"
                            reqData={{
                              instance_ids: selectedRecords,
                              data: { recordsview_id: namespace, params: record_params?.params }
                            }}
                            callBackResult={callBackFunction}
                          />
                          <hr className="menuBreakLine" />
                        </div>
                      );
                    case "dropdown":
                      return (
                        <div key={index}>
                          <DropdownAction
                            key={"job" + index}
                            itemConfig={custom_action}
                            itemType="multi"
                            reqData={{ instance_ids: selectedRecords, data: {} }}
                            callBackFunction={callBackFunction}
                          />
                          <hr className="menuBreakLine" />
                        </div>
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RecordHeader;
