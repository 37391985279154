import React, { useState, useEffect } from "react";
import RememberImage from "../../assets/images/rememberimage.png";
import OtpSentImage from "../../assets/images/otpsentimage.png";
import toast from "react-hot-toast";
import { toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { validateOtp } from "../../redux/userview/userviewaction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getStorageItem } from "../../utils/storageFunction";

import axios from "axios";
const OTPScreen = () => {
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [resendTimer, setResendTimer] = useState(60);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authenticate, logincall } = useSelector((state) => state.userviewstore);
  const { selectedApp } = useSelector((state) => state.appviewstore);

  const generateOtp = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_LOGIN_URL}/api/v1/login/otp/${phoneNumber}`);
      setShowOtpScreen(true);
      startResendTimer();
    } catch (error) {
      toast.error(error?.response?.data?.message || "something went wrong", toastErrorMessageStyle());
    }
  };

  const generateNewOTP = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_LOGIN_URL}/api/v1/login/otp/${phoneNumber}/resend`);
      startResendTimer();
    } catch (error) {
      toast.error(error?.response?.data?.message || "something went wrong", toastErrorMessageStyle());
    }
  };

  const verifyOTP = async () => {
    try {
      dispatch(validateOtp(phoneNumber, otpValue));
    } catch (error) {
      toast.error(error?.response?.data?.message || "something went wrong", toastErrorMessageStyle());
    }
  };

  const startResendTimer = () => {
    setResendTimer(60); // Reset timer to 60 seconds
    const timer = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
    }, 60000); // Stop timer after 1 minute
  };

  useEffect(() => {
    if (authenticate && !logincall && getStorageItem("Zino_app_user")) {
      if (selectedApp?.id) {
        navigate("/home");
      } else {
        navigate("/apps menu");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate, logincall]);

  return (
    <>
      {showOtpScreen ? (
        <div className="otp_screen_container">
          <div className="otp_back_button_main_container">
            <div className="otp_back_button_container" onClick={() => setShowOtpScreen(false)}>
              <span className="material-symbols-outlined otp_back_icon">chevron_left</span>
              <p className="otp_back_label">Go back</p>
            </div>
          </div>
          <div className="otp_sent_image_container">
            <img src={OtpSentImage} alt="img not found" className="otp_sent_image" />
          </div>
          <div className="otp_sent_field_container">
            <p className="otp_sent_field_label">
              OTP shared successfully to your <span>{phoneNumber}</span>
            </p>
            <input
              className="otp_sent_field"
              placeholder="Enter OTP"
              value={otpValue}
              onChange={({ target: { value } }) => setOtpValue(value)}
              maxLength={6}
            />
            <div className="otp_sent_field_button_container">
              <button className="primary_default_button otp_sent_field_button" onClick={verifyOTP}>
                Log in
              </button>
              <p className="otp_sent_field_button_content">
                Didn't received the OTP?{" "}
                {resendTimer === 0 ? (
                  <span onClick={generateNewOTP}>Resend</span>
                ) : (
                  <span>
                    {resendTimer === 60 ? "01:00" : `00:${resendTimer < 10 ? `0${resendTimer}` : resendTimer}`}
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="otp_screen_container">
          <div className="otp_back_button_main_container">
            <div className="otp_back_button_container" onClick={() => navigate("/login")}>
              <span className="material-symbols-outlined otp_back_icon">chevron_left</span>
              <p className="otp_back_label">Go back</p>
            </div>
          </div>
          <div className="otp_image_container">
            <img src={RememberImage} alt="img not found" className="otp_image" />
          </div>
          <div className="otp_field_container">
            <p className="otp_field_label">Enter your email or number to get OTP</p>
            <input
              placeholder="Enter here"
              className="otp_field"
              value={phoneNumber}
              onChange={({ target: { value } }) => setPhoneNumber(value)}
            />
            <button className="primary_default_button otp_field_button" onClick={generateOtp}>
              Send OTP
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default OTPScreen;
