export default function swDev() {
  if ("serviceWorker" in navigator) {
    if (navigator.serviceWorker.controller) {
      // Service worker is already registered
      console.log("Service Worker already registered");
      return;
    }

    let swURL = `${process.env.PUBLIC_URL}/sw.js`;
    navigator.serviceWorker
      .register(swURL)
      .then((res) => {
        console.log("Service Worker registered", res);
      })
      .catch((err) => console.log("Error in swDev", err));
  } else {
    console.log("Service Worker not supported");
  }
}
