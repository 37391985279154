import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createRecordsViewPayload } from "../../../../redux/recordview/recordviewaction";
import { usePageHandlerContext } from "../../../../pagehandler/PageHandlerContext";
const RecordPageNavigation = ({ namespace }) => {
  const location = useLocation();
  const screen_name = location.pathname;
  const { cacheProcessData } = usePageHandlerContext();
  const [totalRecords, setTotalRecords] = useState(100);
  const [selectedCount, setSelectedCount] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [showingRecordsRange, setShowingRecordsRange] = useState({ start: 1, end: 10 });
  const optionsArray = [5, 10, 25, 50, 100, 250, 500];
  //records details
  const storeData = useSelector((state) => state.recordviewstore);
  const recordsviewDataConfig = storeData?.[`${namespace}_recordsviewDataConfig`];
  const recordsviewLayoutConfig = storeData?.[`${namespace}_recordsviewLayoutConfig`];
  const recordsviewPayloadConfig = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}`));
  const dispatch = useDispatch();

  const handlePerPageCount = (value) => {
    setSelectedCount(value);
    CreateApiCall({ page: pageNo, per_page: value });
  };

  const handlePageNo = (type) => {
    if (type === "inc") {
      setPageNo((prev) => prev + 1);
      CreateApiCall({ page: pageNo + 1, per_page: selectedCount });
    } else if (type === "dec") {
      setPageNo((prev) => prev - 1);
      CreateApiCall({ page: pageNo - 1, per_page: selectedCount });
    } else if (typeof type === "number") {
      setPageNo(type);
      CreateApiCall({ page: type, per_page: selectedCount });
    }
  };
  const CreateApiCall = ({ page = 1, per_page = 10 }) => {
    let previousFilters = recordsviewPayloadConfig?.params?.search_query?.filter_by;
    let previousSearch = recordsviewPayloadConfig?.params?.search_query?.search_term;
    dispatch(
      createRecordsViewPayload({
        namespace: namespace,
        layoutConfig: recordsviewLayoutConfig,
        filter_by: previousFilters || [],
        search_term: previousSearch || "",
        page: page,
        per_page: per_page,
        screen_name: screen_name,
        cacheProcessData: cacheProcessData
      })
    );
  };

  useEffect(() => {
    setTotalRecords(recordsviewDataConfig?.total_documents || 0);
    let value = recordsviewDataConfig?.total_documents / selectedCount;
    setMaxPage(Math.ceil(value));
    setPageNo(recordsviewPayloadConfig?.params?.search_query?.page || 1);
    setSelectedCount(recordsviewPayloadConfig?.params?.search_query?.per_page || 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsviewDataConfig]);

  const calculateShowingRecordsRange = () => {
    const end = Math.min(pageNo * selectedCount, totalRecords);
    const start = Math.min((pageNo - 1) * selectedCount + 1, end);
    setShowingRecordsRange({ start, end });
  };

  // Call this function in the useEffect hook that depends on totalRecords, pageNo, and selectedCount
  useEffect(() => {
    calculateShowingRecordsRange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalRecords, pageNo, selectedCount]);

  return (
    <div className="recordsViewBottomContainer">
      <div className="recordsCountContainer">
        <select value={selectedCount} onChange={(event) => handlePerPageCount(event.target.value)}>
          {optionsArray
            ?.filter((option) => option <= recordsviewLayoutConfig?.layout?.data_limit)
            ?.map((value, index) => (
              <option value={value} key={index}>
                {value}
              </option>
            ))}
        </select>
        <p>
          {showingRecordsRange.start} - {showingRecordsRange.end} of {totalRecords}
        </p>
      </div>
      <div className="recordsPageContainer">
        <button
          className="secondary_default_button record_icon_container"
          onClick={() => handlePageNo("dec")}
          disabled={pageNo === 1}
        >
          <span className="material-symbols-outlined record_page_icon">chevron_left</span>
        </button>
        <select value={pageNo || 0} onChange={(event) => handlePageNo(parseInt(event.target.value))}>
          {Array.from({ length: maxPage || 1 }, (_, index) => (
            <option key={index + 1} value={index + 1}>
              {index + 1}
            </option>
          ))}
        </select>
        <button
          className="secondary_default_button record_icon_container"
          onClick={() => handlePageNo("inc")}
          disabled={maxPage <= pageNo}
        >
          <span className="material-symbols-outlined record_page_icon">chevron_right</span>
        </button>
      </div>
    </div>
  );
};

export default RecordPageNavigation;
