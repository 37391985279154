const setStorageItem = (key, value) => {
  localStorage.setItem(key, value);
};

const getStorageItem = (key) => {
  return localStorage.getItem(key);
};

const removeStorageItem = (key = "", keys = []) => {
  if (key) {
    keys = [key, ...keys];
  }

  for (let index = 0; index < keys.length; index++) {
    const keyName = keys[index];
    localStorage.removeItem(keyName);
  }
};

const removeTheme = () => {
  //unload css
  const cssRoot = document.getElementById("cssroot");
  const cssLink = document.getElementById("stylesheetlink");
  if (cssRoot && cssLink) {
    cssRoot.removeChild(cssLink);
  }
};

const getApiCallHeadersData = (loaddata = {}) => {
  return {
    Authorization: `Bearer ${getStorageItem("Zino_app_user")}`,
    ...loaddata
  };
};

export { setStorageItem, getStorageItem, removeStorageItem, getApiCallHeadersData, removeTheme };

window.getApiCallHeadersData = getApiCallHeadersData;
