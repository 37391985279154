import React from "react";
import "./tilesStyles.css";
import IconRenderer from "../../components/IconRender";
import NumberFormater from "../components/NumberFormater";

const TilesRenderer = ({ tilesLayoutConfig = [], tilesDataConfig = {} }) => {
  return (
    <div className="tiles_list_container">
      {tilesLayoutConfig?.map((layout, index) => {
        let custom_css = {};
        try {
          custom_css = JSON.parse(layout?.custom_css || "{}");
        } catch (e) {
          console.error("Error parsing custom_css", e);
        }
        return (
          <div key={index} className={`tiles_container tiles_default_container`} style={custom_css}>
            <div className="tiles_title_container">
              {layout?.icon && <IconRenderer iconValue={layout?.icon} />}
              <span className="tiles_title">{layout?.title}</span>
            </div>
            <p className="tiles_value">
              {/* {formatValue(layout?.format_type || "none", tilesDataConfig[layout?.id] || 0)} */}
              <NumberFormater
                number={tilesDataConfig[layout?.id]}
                type={layout?.format_type}
                curr_icon={layout?.currency_code}
              />
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default TilesRenderer;
