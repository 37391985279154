import React, { useLayoutEffect } from "react";

import RightPart from "../components/RightPart";
import LeftPart from "../components/LeftPart";

import { getDashboardConfig } from "../../../redux/chartview/chartviewaction";
import { useDispatch, useSelector } from "react-redux";
import TilesRenderer from "../../tileslib/TilesRenderer";
import { useLocation } from "react-router-dom";

function ChartRendererFunction({ appdataobject, namespace, jobParamsTokenConfig = {} }) {
  const { chartDashboard } = appdataobject;
  const location = useLocation();
  const screen_name = location.pathname;

  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.chartviewstore);
  let filterDataForAPICall = storeData?.[`${namespace}_chartFilterDataForAPICall`] || [];
  let filterDataForField = storeData?.[`${namespace}_chartFilterDataForField`] || {};
  useLayoutEffect(() => {
    dispatch(getDashboardConfig(namespace, chartDashboard, screen_name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {chartDashboard?.tiles?.length !== 0 && filterDataForField?.tiles && (
        <TilesRenderer tilesLayoutConfig={chartDashboard.tiles} tilesDataConfig={filterDataForField?.tiles} />
      )}
      <div className="DashboardRF_dashboardBox">
        <div className="DashboardRF_rightPartBox DashboardRF_rightPartBox ">
          {filterDataForAPICall?.length > 0 && (
            <div>
              <LeftPart namespace={namespace} filterData={filterDataForAPICall} />
            </div>
          )}
          <RightPart namespace={namespace} />
        </div>
      </div>
    </>
  );
}

export default ChartRendererFunction;
