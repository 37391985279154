import React from "react";
import "./ProcessBarSection.css";

const ProcessBarSection = () => {
  return (
    <div className="popup_loading_overlay popupBoxSectionIsOpen">
      <div className="popup_loading_box"></div>
    </div>
  );
};

export default ProcessBarSection;
