import {
  DASHBOARD_CONFIG_APP_DATA,
  WORKFLOW_APP_OBJECT_DATA,
  DASHBOARD_CONFIG_ORG_DATA,
  ACTION_JOB_RES_DATA,
  ERROR_SHOW,
  NOTIFICATIONS_ARRAY,
  APPS_LIST,
  SELECTED_APP
} from "./appviewaction";

export const initState = {
  notificationsArray: [],
  dashboardAppConfig: {},
  dashboardOrgConfig: [],
  workflowobject: {},
  actionJobResData: {},
  errorShow: null,
  appList: [],
  selectedApp: {}
};

export const appviewreducer = (state = initState, { type, payload }) => {
  switch (type) {
    case WORKFLOW_APP_OBJECT_DATA:
      return {
        ...state,
        workflowobject: payload
      };

    case DASHBOARD_CONFIG_APP_DATA:
      return {
        ...state,
        dashboardAppConfig: payload
      };

    case DASHBOARD_CONFIG_ORG_DATA:
      return {
        ...state,
        dashboardOrgConfig: payload
      };

    case ACTION_JOB_RES_DATA:
      return {
        ...state,
        actionJobResData: payload
      };

    case ERROR_SHOW:
      return {
        ...state,
        errorShow: payload
      };

    case NOTIFICATIONS_ARRAY:
      return {
        ...state,
        notificationsArray: payload
      };

    case APPS_LIST:
      return {
        ...state,
        appList: payload
      };
    case SELECTED_APP:
      return {
        ...state,
        selectedApp: payload
      };
    default:
      return state;
  }
};
