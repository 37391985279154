import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const ModulesPopup = ({ setShowModulesPopup, setSelectedModule, setSelectedFooterModule, selectedModule }) => {
  const navigate = useNavigate();
  const modulesArray = useSelector((state) => state?.appviewstore?.dashboardAppConfig?.module_header);
  const { selectedApp } = useSelector((state) => state?.appviewstore);

  const handleModule = (module) => {
    if (Object.keys(module)?.length > 0) {
      localStorage.setItem("selected_module", module?.name);
      setSelectedModule(module);
      setSelectedFooterModule(module?.config?.menu_bottom || []);
      setShowModulesPopup(false);
      navigate(`/${module?.config?.menu[0]?.display || module?.config?.menu_bottom[0]?.display || "home"}`);
    } else {
      localStorage.removeItem("selected_module");
      setSelectedModule({});
      setSelectedFooterModule([]);
      setShowModulesPopup(false);
      navigate("/home");
    }
  };

  return (
    <div className="popup_outer_container">
      <div className="popup_inner_container module_inner_container">
        <div className="popup_header_container">
          <span className="material-symbols-outlined module_back_icon" onClick={() => setShowModulesPopup(false)}>
            chevron_left
          </span>
          <span className="popup_title">
            <img
              src={`${process.env.REACT_APP_IMAGE_HOST}/api/v1/public/${selectedApp.logo}`}
              alt="img"
              className="modules_logo_container"
            />{" "}
            {selectedApp?.name}{" "}
          </span>
        </div>
        <div className="popup_body_container">
          <div className="module_list">
            <div className="module_container " onClick={() => handleModule({})}>
              <span className="material-symbols-outlined module_icon">home</span>
              <span className="module_name">Default</span>
            </div>
            {modulesArray?.map((module, index) => (
              <div className="module_container" key={index} onClick={() => handleModule(module)}>
                <span className="material-symbols-outlined module_icon">{module?.icon?.split(" ")[0] || "schema"}</span>
                {/* <img src={`${process.env.REACT_APP_IMAGE_HOST}/api/v1/public/${selectedApp.logo}`} alt="img" className="module_logo_icon"/> */}
                <span className="module_name">{module?.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModulesPopup;
