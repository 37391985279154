import React from "react";
import "./TimelineRenderer.css";

import { getApiCallLocalPath } from "../../utils/apiCallFunction";
import DetailsViewItem from "../detailsviewlib/DetailsViewItem";

import file_save from "../../assets/images/downloadfileicon.png";

import { useImagePreviewContext } from "../../applicationcontext/ImagePreviewContext";
import MapPopup from "../../components/Map/MapPopup";
import { formatTimestamp } from "../utils/commonFunction";

const TimelineRenderer = ({
  itemConfig,
  timelineViewData,
  activityCardsConfig,
  detailsViewDataActivityCard,
  detailsViewDataActivitiesHistory,
  jobParamsConfig,
  detailsViewDataOrg,
  functionObject,
  detailsViewItemConfig
}) => {
  let activityCardsArray = Object.values(activityCardsConfig);
  let activityCardsObject = {};

  for (let index = 0; index < activityCardsArray.length; index++) {
    const element = activityCardsArray[index];
    activityCardsObject[element.data.activity_id] = element;
  }

  let detailsViewDataActivityCardObject = {};
  for (let index = 0; index < detailsViewDataActivityCard.length; index++) {
    const element = detailsViewDataActivityCard[index];
    detailsViewDataActivityCardObject[element.activity_id] = element;
  }

  const handleDetailsViewFormData = (timelineData) => {
    const obj = detailsViewDataActivityCard?.find((data) => timelineData?.created_at === data?.created_at);
    return obj?.form_data;
  };

  return (
    <>
      {timelineViewData?.map((timelineData, i) => {
        return (
          <div key={timelineData.id + i} className=" timeLine_block">
            {timelineData.activity_name !== "Init Activity" && (
              <p className="timeline_label theme_timeline_label">{timelineData.activity_name}</p>
            )}
            <p className="timeline_bottom_label">At : {formatDate(timelineData.created_at)}</p>
            <div>
              {activityCardsObject[timelineData.activity_id] ? (
                <DetailsViewItem
                  itemConfig={activityCardsObject[timelineData.activity_id].data.elements}
                  activityCardsConfig={activityCardsConfig}
                  detailsViewDataFormData={handleDetailsViewFormData(timelineData)}
                  detailsViewDataTimeline={timelineViewData}
                  detailsViewDataActivityCard={detailsViewDataActivityCard}
                  detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
                  jobParamsConfig={jobParamsConfig}
                  functionObject={functionObject}
                  detailsViewDataOrg={detailsViewDataOrg}
                  detailsViewItemConfig={detailsViewItemConfig}
                />
              ) : (
                <RendererFormData dataArray={timelineData.form_data} />
              )}
            </div>
            {timelineData?.activity_user?.email && (
              <div className="timeline_bottom_container">
                <p className="timeline_bottom_label">By : {timelineData.activity_user.email}</p>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default TimelineRenderer;

//for timeline handling data
function formatDate(inputDate) {
  const options = { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" };
  const formattedDate = new Date(inputDate).toLocaleDateString("en-US", options);
  return formattedDate;
}

const RendererFormData = ({ dataArray = [] }) => {
  const { showPopupImagePreview } = useImagePreviewContext();
  return (
    <div>
      {dataArray &&
        dataArray?.length > 0 &&
        dataArray?.map((item, key) => {
          if (
            ["textfield", "textarea", "number", "text", "select", "string"].includes(item.data_type) ||
            ["learning-text"]?.includes(item?.field_type)
          ) {
            return (
              <div key={key} className="timelineData_container">
                <span className="label">{item.label} : </span>
                <span className="value">{item.data}</span>
              </div>
            );
          } else if (["textarea"].includes(item.data_type)) {
            return (
              <div key={key} className="timelineData_container">
                <span className="label">{item.label} : </span>
                <span
                  className="value"
                  dangerouslySetInnerHTML={{ __html: item?.data?.join("<br/>")?.replaceAll(/\n/g, "<br/>") }}
                ></span>
              </div>
            );
          } else if (["selectboxes"].includes(item.data_type)) {
            return (
              <div key={key} className="timelineData_container">
                <span className="label">{item.label} : </span>
                <span className="value">{item.data?.join(" , ")}</span>
              </div>
            );
          } else if (["geolocation"].includes(item.data_type)) {
            const lat = item.data?.[0]?.[0];
            const lng = item.data?.[0]?.[1];
            return (
              <div key={key} className="timelineData_container">
                <MapPopup lat={lat} lng={lng} />
              </div>
            );
          } else if (["date", "day", "datetime"].includes(item.data_type)) {
            if (item.data) {
              let formattedDate = "";
              const dateItem = item.data;
              try {
                formattedDate += formatTimestamp(dateItem, item.field_type, item.format || "DD-MM-YYYY");
              } catch (error) {
                formattedDate += "-";
              }
              return (
                <div key={key} className="timelineData_container">
                  <span className="label">{item.label} : </span>
                  <span className="value">{formattedDate}</span>
                </div>
              );
            } else {
              return <div key={key} className="timelineData_container"></div>;
            }
          } else if (["object", "object[]"].includes(item.data_type)) {
            if (Array.isArray(item?.data)) {
              return <RendererFormData dataArray={item.data} key={key} />;
            } else {
              return null;
            }
          } else if (["image", "file"].includes(item.data_type)) {
            return (
              <div
                className={"overflow"}
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "20px"
                }}
              >
                <span className="label" style={{ fontSize: "12px", fontWeight: "500" }}>
                  {item?.label} :{" "}
                </span>
                {typeof item?.data === "object" &&
                  item?.data?.length > 0 &&
                  item?.data?.map((image_src, image_index) => {
                    if (typeof image_src === "object") {
                      if (["image/png", "image/jpeg", "image/jpg"].includes(image_src.type)) {
                        return (
                          <img
                            key={image_index}
                            style={{ height: "80px", width: "auto" }}
                            src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}`}
                            alt={image_src}
                            onClick={() => showPopupImagePreview(image_src)}
                          />
                        );
                      } else if (image_src.url) {
                        return undefined;
                      } else {
                        return (
                          <img
                            key={image_index}
                            style={{ height: "24px", width: "20px" }}
                            src={file_save}
                            alt={image_src}
                            onClick={() => {
                              const isConfirmed = window.confirm("Are you sure you want to download this file?");
                              if (isConfirmed) {
                                window.open(
                                  `${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}`,
                                  "_blank"
                                );
                              }
                            }}
                          />
                        );
                      }
                    } else {
                      const endWithPNGPattern = new RegExp(`^.*.png$`, "i");
                      const endWithJPGPattern = new RegExp(`^.*.jpg$`, "i");
                      const endWithJPEGPattern = new RegExp(`^.*.jpeg$`, "i");
                      if (
                        endWithPNGPattern.test(image_src) ||
                        endWithJPGPattern.test(image_src) ||
                        endWithJPEGPattern.test(image_src)
                      ) {
                        return (
                          <img
                            key={image_index}
                            style={{ height: "40px", width: "auto" }}
                            src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`}
                            alt={image_src}
                          />
                        );
                      } else {
                        return (
                          <img
                            key={image_index}
                            style={{ height: "24px", width: "20px" }}
                            src={file_save}
                            alt={image_src}
                            onClick={() => {
                              const isConfirmed = window.confirm("Are you sure you want to download this file?");
                              if (isConfirmed) {
                                window.open(`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`, "_blank");
                              }
                            }}
                          />
                        );
                      }
                    }
                  })}
              </div>
            );
          } else if (["video"].includes(item.data_type)) {
            return (
              <div key={key} style={{ aspectRatio: "16/9", height: "auto" }}>
                <iframe
                  style={{
                    height: "100%",
                    width: "100%",
                    border: "none",
                    outline: "none"
                  }}
                  src={item.data}
                  title={item.display}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            );
          } else {
            return null;
          }
        })}
    </div>
  );
};

const RendererFormDataInTable = ({ dataArray, dataKey }) => {
  return dataArray.map((item, key) => {
    if (["textfield", "textarea", "number", "text", "select", "string"].includes(item.data_type)) {
      if (dataKey === "body") {
        return <td key={key}>{item.data}</td>;
      } else {
        return <th key={key}>{item.label}</th>;
      }
    } else if (["radio"].includes(item.field_type)) {
      if (dataKey === "body") {
        return <td key={key}>{item.data?.join(" , ")}</td>;
      } else {
        return <th key={key}>{item.label}</th>;
      }
    } else if (["geolocation", "selectboxes"].includes(item.data_type)) {
      if (dataKey === "body") {
        return <td key={key}>{item.data?.join(" , ")}</td>;
      } else {
        return <th key={key}>{item.label}</th>;
      }
    } else if (["date", "day", "datetime"].includes(item.data_type)) {
      if (item.data) {
        let formattedDate = "";
        const dateItem = item.data;
        try {
          formattedDate += formatTimestamp(dateItem, item?.field_type, item?.format || "DD-MM-YYYY");
        } catch (error) {
          formattedDate += "-";
        }
        if (dataKey === "body") {
          return <td key={key}>{formattedDate}</td>;
        } else {
          return <th key={key}>{item.label}</th>;
        }
      } else {
        if (dataKey === "body") {
          return <td key={key}></td>;
        } else {
          return <th key={key}>{item.label}</th>;
        }
      }
    } else if (["object", "object[]"].includes(item.data_type)) {
      return <RendererFormDataInTable key={key} dataArray={item.data} dataKey={dataKey} />;
    } else if (["image", "file"].includes(item.data_type)) {
      if (dataKey === "body") {
        return (
          <td key={key}>
            {item.data?.map((image_src, image_index) => {
              if (typeof image_src === "object") {
                return (
                  <img
                    key={image_index}
                    style={{ height: "40px", width: "auto" }}
                    src={image_src.url}
                    alt={image_src.url}
                  />
                );
              }
              return (
                <img
                  key={image_index}
                  style={{ height: "40px", width: "auto" }}
                  src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`}
                  alt={image_src}
                />
              );
            })}
          </td>
        );
      } else {
        return <th key={key}>{item.label}</th>;
      }
    } else if (["video"].includes(item.data_type)) {
      if (dataKey === "body") {
        return (
          <td key={key} style={{ aspectRatio: "16/9", height: "auto" }}>
            <iframe
              style={{
                height: "100%",
                width: "100%",
                border: "none",
                outline: "none"
              }}
              src={item.data}
              title={item.display}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </td>
        );
      } else {
        return <th key={key}>{item.label}</th>;
      }
    } else {
      return null;
    }
  });
};
