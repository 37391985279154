import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import BackButton from "../../components/buttons/BackButton";

const PublicPageList = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const appData = location.state.appDetails;

  const selectPageHandler = (page) => {
    navigate(page, { state: { domain: appData.domain_mobile, screenName: page } });
  };

  return (
    <>
      <div className="header_container" style={{ padding: "0px 0px", gap: "0px" }}>
        <BackButton />
        <h4>{appData?.name}</h4>
      </div>

      <div className="public_page_container">
        {appData.public_screens.map((screen) => (
          <button key={screen} onClick={() => selectPageHandler(screen)} className="app_container">
            {screen}
          </button>
        ))}
      </div>
    </>
  );
};

export default PublicPageList;
