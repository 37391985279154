import React, { useState } from "react";
import IconRender from "../../components/IconRender";
import "../tabviewlib/TabViewRenderer.css";

const ScreenTabHeader = ({ headerData = [], openTab }) => {
  const [headerList, setHeaderList] = useState(headerData);

  const openNewTab = (tabId) => {
    let array = [...headerList];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      element.active = false;
      if (element.id === tabId) {
        element.active = true;
      }
    }
    setHeaderList(array);
    openTab(tabId);
  };

  function handleGridHeader() {
    let gridTemplateColumns = "";
    for (let i = 0; i < headerList.length; i++) {
      gridTemplateColumns += "1fr ";
    }
    return gridTemplateColumns;
  }

  return (
    <div
      className="tabview_tabhead  theme_tabview"
      style={{ display: "grid", gridTemplateColumns: handleGridHeader() }}
    >
      {headerList.map((header, headerIndex) => {
        return (
          <div className="tabview_tabhead_item_container" key={header.id || headerIndex}>
            <div
              className={
                header.active
                  ? "tabview_tabhead_item tabview_tabhead_item_active theme_tab_items theme_tab_items_active"
                  : "tabview_tabhead_item theme_tab_items"
              }
              onClick={() => {
                openNewTab(header.id);
              }}
            >
              {header?.icon && <IconRender iconValue={header.icon} />}
              {header.display}
            </div>
            <hr className={headerList?.length - 1 === headerIndex ? "final_hr" : ""} />
          </div>
        );
      })}
    </div>
  );
};

export default ScreenTabHeader;
