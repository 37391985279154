import React, { useState } from "react";
import { usePageHandlerContext } from "../../../../pagehandler/PageHandlerContext";
import IconRender from "../../../../components/IconRender";
import PopupBoxSection from "../../../viewlib/PopupBoxSection/PopupBoxSection";
import ProcessBarSection from "../../../viewlib/ProcessBarSection/PopupBoxSection";
import axios from "axios";
import { getApiCallHeadersData } from "../../../../utils/storageFunction";

import toast from "react-hot-toast";
import { toastErrorMessageStyle, getApiCallLocalPath } from "../../../../utils/apiCallFunction";
import evaluatePreFiltersExpression from "../../../../utils/prefiltersFn";

const JobAction = ({ itemConfig, itemType, reqData = {}, callBackFunction, callBackResult }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [aiPrompt, setAiPrompt] = useState("");
  const [aiResponsePopup, setAiResponsePopup] = useState(false);
  const [aiResponse, setAiResponse] = useState({});
  const [loader, setLoader] = useState(false);

  let aiJobs = ["perform_activity_ai", "perform_record_ai"];

  const { createNewTask } = usePageHandlerContext();
  const handleJobCallFunction = async () => {
    let element_config = {
      element_id: itemConfig.id,
      action_start: itemConfig.action_start,
      action_end: itemConfig?.action_end
        ? itemConfig.action_end
        : { refresh: itemConfig.refresh_block ? [itemConfig.namespace] : [] },
      callback_function: [],
      callback_function_after: [callBackFunction]
    };

    let action_config = {
      action_in: itemConfig?.job_template || itemConfig?.action_in || itemConfig.action,
      navigate_to: itemConfig?.params?.name || itemConfig.navigate_to,
      action_data: itemConfig?.action_data,
      action_name: itemConfig?.job_template || itemConfig?.action_data?.job_name,
      sm_id: itemConfig.data_id || itemConfig?.action_data?.sm_id,
      activity_id: itemConfig?.action_data?.activity_id,
      data_source: itemConfig?.data_source,
      data_source_type: itemConfig?.data_type,
      params: itemConfig?.params || {}
    };

    if (itemType === "single") {
      action_config["instance_id"] = reqData.instance_id || reqData.id || reqData._id || null;
      action_config["instance_ids"] = null;
    } else if (itemType === "multi") {
      let instance_ids = null;
      if (reqData.instance_ids) {
        instance_ids = reqData.instance_ids;
      } else if (reqData.id || reqData._id) {
        instance_ids = [reqData.id || reqData._id];
      }
      action_config["instance_id"] = null;
      action_config["instance_ids"] = instance_ids;
    }

    if (["send_email_bg", "send_sms_bg"].includes(action_config.action_name)) {
      reqData.data = {};
    } else if (["perform_activity_ai"].includes(action_config.action_name)) {
      reqData.data = { ...reqData.data, prompt: aiPrompt };
    } else if (["perform_record_ai"].includes(action_config.action_name)) {
      reqData.data = { ...reqData.data, user_prompt: aiPrompt };
      setIsPopupOpen(false);
      await handleRecordAiJob(action_config, reqData.data);
      setAiPrompt("");
      return null;
    } else {
      reqData.data = { ...reqData.data, sm_id: action_config.sm_id };
    }
    createNewTask(element_config, action_config, reqData.data);
    setAiPrompt("");
    setIsPopupOpen(false);
    if (callBackResult) {
      callBackResult();
    }
  };

  const handleRecordAiJob = async (action_config, reqData) => {
    try {
      setLoader(true);
      const payload = {
        function_name: "record_ai",
        function_id: "",
        data: {},
        params: {
          ...reqData.params,
          user_prompt: reqData.user_prompt,
          instance_id: action_config?.instance_id || null,
          instance_ids: action_config?.instance_ids || []
        }
      };
      const { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, payload, {
        headers: getApiCallHeadersData()
      });
      setAiResponse(data?.data);
      setAiResponsePopup(true);
    } catch (error) {
      toast.error("something went wrong", toastErrorMessageStyle());
    } finally {
      setLoader(false);
    }
  };

  let buttonClass = itemConfig.disabled ? "zino_btn_disabled" : "secondary_default_button";
  let class_name = "";
  if (itemConfig.className) {
    if (typeof itemConfig.className === "object") {
      class_name = Object.values(itemConfig.className).join(" ");
    } else {
      class_name = itemConfig.className;
    }
  }

  const showBtn = evaluatePreFiltersExpression(
    itemConfig?.pre_filters_join_by,
    itemConfig?.pre_filters || [],
    reqData?.data || reqData || {}
  );

  if (!showBtn) {
    return null;
  }

  if (aiJobs.includes(itemConfig?.job_template)) {
    return (
      <>
        <button
          className={`${buttonClass} ${class_name}`}
          onClick={() => {
            setIsPopupOpen(true);
          }}
          style={itemConfig.inline_style || {}}
        >
          {itemConfig?.icon && <IconRender iconValue={itemConfig.icon} />}
          {itemConfig?.name}
        </button>
        {isPopupOpen && (
          <PopupBoxSection>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "15px",
                position: "relative"
              }}
            >
              <label htmlFor="aiprompt"></label>
              <textarea
                className="aiprompt_textarea"
                id="aiprompt"
                name="prompt"
                rows="10"
                cols="40"
                onChange={(e) => {
                  setAiPrompt(e.target.value);
                }}
                value={aiPrompt}
                // defaultValue={aiPrompt}
              ></textarea>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "15px",
                padding: "15px"
              }}
            >
              <button
                className="secondary_default_button ai_secondary_button"
                onClick={() => {
                  setAiPrompt("");
                  setIsPopupOpen(false);
                }}
                style={itemConfig?.inline_style || {}}
              >
                Close
              </button>
              <button className="primary_default_button ai_primary_button" onClick={handleJobCallFunction}>
                Submit
              </button>
            </div>
          </PopupBoxSection>
        )}
        {aiResponsePopup && (
          <PopupBoxSection>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column"
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "12px",
                  borderBottom: "1px solid #dadada"
                }}
              >
                <p>Response</p>
                <span
                  className="material-symbols-outlined"
                  onClick={() => setAiResponsePopup(false)}
                  style={{
                    fontWeight: 600,
                    padding: "8px",
                    color: "#fff",
                    background: "#10182b",
                    borderRadius: "50%",
                    textAlign: "center"
                  }}
                >
                  close
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "12px",
                  minHeight: "50vh",
                  maxHeight: "80vh",
                  overflow: "scroll",
                  background: "white"
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: aiResponse?.output }} />
              </div>
            </div>
          </PopupBoxSection>
        )}
        {loader && <ProcessBarSection />}
      </>
    );
  } else {
    return (
      <button
        className={`${buttonClass} ${class_name}`}
        onClick={handleJobCallFunction}
        style={itemConfig.inline_style || {}}
      >
        {itemConfig?.icon && <IconRender iconValue={itemConfig.icon} />}
        {itemConfig?.name}
      </button>
    );
  }
};

export default JobAction;
