import React, { useLayoutEffect } from "react";
import { store } from "../../redux/store";
import { createRecordsViewPayload, setRecordsViewLayoutConfig } from "../../redux/recordview/recordviewaction";
import RecordsViewLayout from "./RecordsViewLayout";
import "./Components.css";
import { useLocation } from "react-router-dom";
import { usePageHandlerContext } from "../../pagehandler/PageHandlerContext";

const RecordsViewRenderer = ({ itemConfig, jobParamsTokenConfig }) => {
  const namespace = itemConfig?.id;
  const location = useLocation();
  const screen_name = location.pathname;
  // const storeData = useSelector((state) => state.recordviewstore);
  const recordsviewPayloadConfig = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}`));
  const { cacheProcessData } = usePageHandlerContext();
  useLayoutEffect(() => {
    if (namespace) {
      let updatedFilterArray = handleOnlyAvailableFilters();

      store.dispatch(setRecordsViewLayoutConfig(namespace, itemConfig?.config));
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");
      if (token) {
        if (cacheProcessData) {
          store.dispatch(
            createRecordsViewPayload({
              namespace: namespace,
              layoutConfig: itemConfig?.config,
              filter_by: updatedFilterArray || [],
              search_term: "",
              page: recordsviewPayloadConfig?.params?.search_query?.page || 1,
              per_page: recordsviewPayloadConfig?.params?.search_query?.per_page || 10,
              screen_name: screen_name,
              cacheProcessData: cacheProcessData
            })
          );
        }
      } else {
        store.dispatch(
          createRecordsViewPayload({
            namespace: namespace,
            layoutConfig: itemConfig?.config,
            filter_by: updatedFilterArray || [],
            search_term: "",
            page: recordsviewPayloadConfig?.params?.search_query?.page || 1,
            per_page: recordsviewPayloadConfig?.params?.search_query?.per_page || 10,
            screen_name: screen_name,
            cacheProcessData: cacheProcessData
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnlyAvailableFilters = () => {
    // Combine filters ensuring tabfilter is only included if it exists
    const allFiltersObjectArray = [
      ...(itemConfig?.config?.layout?.onscreen_filters || []),
      itemConfig?.config?.layout?.filter_layout?.filter_field || {}
    ];

    let allFiltersArray = allFiltersObjectArray?.filter((filterObjec) => filterObjec?.id);
    allFiltersArray = allFiltersArray?.map((obj) => obj?.id);
    let previousFiltersArray = recordsviewPayloadConfig?.params?.search_query?.filter_by || [];

    // Filter out elements from previousFiltersArray that are not in allFiltersArray
    previousFiltersArray = previousFiltersArray.filter((filter) => allFiltersArray.includes(filter.id));
    return previousFiltersArray || [];
  };

  if (!namespace) {
    return <div>Specific Records Not Availble</div>;
  } else {
    return <RecordsViewLayout namespace={namespace} />;
  }
};

export default RecordsViewRenderer;
