import React, { useEffect, useState } from "react";
import { useCartContext } from "../applicationcontext/CartContext";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { toastErrorMessageStyle } from "../utils/apiCallFunction";
import "./Pagestyle.css";
import RecordCatalogView from "../lib/newrecordsviewrendererlib/components/records/RecordCatalogView";
import { JobActionRenderer } from "../lib";
import { PageHandlerContextProvider } from "../pagehandler/PageHandlerContext";
import ProcessTaskComponent from "../pagehandler/ProcessTaskComponent";

const CartScreenRenderer = () => {
  const [showCart, setShowCart] = useState(false);
  const [openedCartItems, setOpenedCartItems] = useState([]);
  const { cartLayout, fetchCart, fetchCartItems, deleteCartItem } = useCartContext();
  const navigate = useNavigate();
  const location = useLocation();
  const cartObject = location.state?.cart;
  const handleFetchCart = async () => {
    try {
      const cartLayoutPayload = {
        function_name: "get_cart",
        data: {},
        params: {
          entity_id: ""
        }
      };
      await fetchCart(cartLayoutPayload);
      const cartItemsPayload = {
        function_name: "get_cart_items",
        data: {},
        params: {
          entity_id: ""
        }
      };
      const data = await fetchCartItems(cartItemsPayload);
      handleCartItems(data);
    } catch (error) {
      toast.error("something went wrong", toastErrorMessageStyle());
    }
  };

  const handleCartItems = (data) => {
    data?.forEach((cart) => {
      if (cart?.cart_details?.name === cartObject?.page_name) {
        let tempArray = cart?.cart_details?.instance_details;
        for (let i = 0; i < tempArray.length; i++) {
          tempArray[i].entity_id = cart?.cart_details?.sm_id;
        }
        // setOpenedCartItems(cart?.cart_details?.instance_details);
        setOpenedCartItems(tempArray);
      }
    });
  };

  useEffect(() => {
    handleFetchCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartObject]);

  function handleOpenCart(cart) {
    navigate(`/my cart/${cart?.page_name}`, { state: { cart } });
    setShowCart(false);
  }

  function handleBack() {
    navigate("/home");
  }

  async function deleteItem(dataObject) {
    try {
      const params = {
        data: {},
        function_name: "add_or_update_cart",
        params: {
          entity_id: dataObject?.entity_id,
          instance_id: dataObject?.instance_id,
          quantity: 0
        }
      };
      await deleteCartItem(params);
      handleOpenCart(cartObject);
    } catch (error) {
      toast.error("something went wrong", toastErrorMessageStyle());
    }
  }

  return (
    <PageHandlerContextProvider>
      <div className="cartContainer">
        <div className="cartHeaderContainer">
          <div className="cartNameContainer">
            <span className="material-symbols-outlined cartBackIcon" onClick={() => handleBack()}>
              chevron_left
            </span>
            <span>{cartObject?.page_name || "My cart"}</span>
          </div>
          <div className="cartIconContainer">
            <span className="material-symbols-outlined cartIcon" onClick={() => setShowCart(!showCart)}>
              shopping_cart
            </span>
            {showCart && (
              <div className="cartListContainer">
                {cartLayout?.map((cart, index) => (
                  <span key={index} onClick={() => handleOpenCart(cart)} className="cartItem">
                    {cart.page_name}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="cartBodyContainer">
          <div>
            <JobActionRenderer itemConfig={cartObject?.cart_action} />
          </div>
          {openedCartItems?.length > 0 ? (
            openedCartItems?.map((dataObject, index) => (
              <div className="cartItemContainer" key={index}>
                <RecordCatalogView elements={cartObject?.layout} record={dataObject?.product_details} namespace="" />
                <span className="material-symbols-outlined cart_delete_icon" onClick={() => deleteItem(dataObject)}>
                  delete
                </span>
              </div>
            ))
          ) : (
            <div>No Items added to cart</div>
          )}
        </div>
      </div>
      <ProcessTaskComponent />
    </PageHandlerContextProvider>
  );
};

export default CartScreenRenderer;
