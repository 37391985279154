import React from "react";

const AppCard = ({ app, callback }) => {
  return (
    <button
      onClick={() => callback(app)}
      className={app?.status !== "live" ? "blur_container app_container" : "app_container"}
      disabled={app?.status !== "live"}
    >
      <img
        src={
          app.logo
            ? `${process.env.REACT_APP_IMAGE_HOST}/api/v1/public/${app.logo}`
            : require("../../assets/icons/app_icon.png")
        }
        alt=""
        className="app_logo"
      />
      <p className="app_name">{app?.name.charAt(0).toUpperCase() + app?.name.slice(1)}</p>
    </button>
  );
};

export default AppCard;
