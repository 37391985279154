import React from "react";
import IconRender from "../IconRender";

const LabelField = ({ labelConfig = {} }) => {
  //classnames coming from builder for that element
  let class_name = "";
  if (labelConfig.className) {
    if (typeof labelConfig.className === "object") {
      class_name = Object.values(labelConfig.className).join(" ");
    } else {
      class_name = labelConfig.className;
    }
  }

  return (
    <div className={`label_container ${class_name}`}>
      {labelConfig.icon && <IconRender iconValue={labelConfig.icon} />}
      <p>{labelConfig.name}</p>
    </div>
  );
};

export default LabelField;
