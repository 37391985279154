import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { toastErrorMessageStyle, toastSuccessMessageStyle } from "../../utils/apiCallFunction";
import { useLocation, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [verificationCode, setVerficationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [resendTimer, setResendTimer] = useState(60);

  const navigate = useNavigate();
  const location = useLocation();
  const forgotEmail = location.state?.email;

  const handleResetPassword = async () => {
    if (newPassword.length >= 4 && newPassword === reEnterPassword) {
      try {
        await axios.put(`${process.env.REACT_APP_LOGIN_URL}/api/v1/verifyPassword`, {
          email: forgotEmail,
          new_password: newPassword,
          code: verificationCode
        });
        navigate("/resetSuccess");
      } catch (error) {
        toast.error("Invalid OTP Received", toastErrorMessageStyle());
      }
    } else {
      if (newPassword.length < 4) {
        toast.error("Password length must be 4 charcters", toastErrorMessageStyle());
      } else if (newPassword !== reEnterPassword) {
        toast.error("Password mismatch", toastErrorMessageStyle());
      }
    }
  };

  const handleResendOTP = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_LOGIN_URL}/api/v1/forgetPassword`, {
        email: forgotEmail
      });
      startResendTimer();
      toast.success("OTP sent successfully", toastSuccessMessageStyle());
    } catch (error) {
      toast.error("Something went wrong", toastErrorMessageStyle());
    }
  };

  const startResendTimer = () => {
    setResendTimer(60); // Reset timer to 60 seconds
    const timer = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
    }, 60000); // Stop timer after 1 minute
  };

  useEffect(() => {
    startResendTimer();
  }, []);

  return (
    <div className="resetScreenContainer">
      <div className="resetBackContainer">
        <span className="material-symbols-outlined back_icon">arrow_back_ios</span>
        <p className="back_value" onClick={() => navigate("/forgotPassword")}>
          Go back
        </p>
      </div>
      <div className="resetImageContainer">
        <img src={require("../../assets/images/resetpasswordimage.png")} alt="img" />
      </div>
      <div className="resetFieldsContainer">
        <div className="resetMessageContainer">
          <p className="resetMessage">
            To reset your password OTP shared to
            <p className="emailMessage"> {forgotEmail} </p>
            successfully
          </p>
        </div>
        <input
          className="resetInputField"
          placeholder="Enter Verification code"
          value={verificationCode}
          onChange={({ target: { value } }) => setVerficationCode(value)}
          autoComplete="off"
        />
        <input
          className="resetInputField"
          placeholder="Enter new password"
          value={newPassword}
          onChange={({ target: { value } }) => setNewPassword(value)}
          type="password"
          autoComplete="off"
        />
        <input
          className="resetInputField"
          placeholder="Confirm password"
          value={reEnterPassword}
          onChange={({ target: { value } }) => setReEnterPassword(value)}
          type="password"
          autoComplete="off"
        />
        <div className="resetButtonContainer">
          <button className="primary_default_button resetButton" onClick={handleResetPassword}>
            Reset Password
          </button>
        </div>
        <div className="resendMessageContainer">
          <p className="resendMessage">
            Didn't receive the email?{" "}
            {resendTimer === 0 ? (
              <span onClick={handleResendOTP} className="resendButton">
                Resend
              </span>
            ) : (
              <span className="resendButton">
                {resendTimer === 60 ? "01:00" : `00:${resendTimer < 10 ? `0${resendTimer}` : resendTimer}`}
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
