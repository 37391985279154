import React, { useEffect, useRef, useState } from "react";
// import { ExpandLessIconSvg, ExpandMoreIconSvg } from "../../../zinoIcon";
import { useDispatch, useSelector } from "react-redux";
import { createRecordsViewPayload } from "../../../../redux/recordview/recordviewaction";

import { useLocation } from "react-router-dom";
import { usePageHandlerContext } from "../../../../pagehandler/PageHandlerContext";

export default function FilterSelectFieldLV({ filter, namespace, options, reset }) {
  const location = useLocation();
  const screen_name = location.pathname;
  const { cacheProcessData } = usePageHandlerContext();
  const [popupShow, setPopupShow] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [multiSelectedOptions, setMultiSelectedOptions] = useState([]);
  const storeData = useSelector((state) => state.recordviewstore);
  const recordsviewPayloadConfig = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}`));
  const recordsviewLayoutConfig = storeData?.[`${namespace}_recordsviewLayoutConfig`];
  const dispatch = useDispatch();

  function handleDropDownFilterChanges(value) {
    let previousFilters = recordsviewPayloadConfig?.params?.search_query?.filter_by || [];
    let previousSearch = recordsviewPayloadConfig?.params?.search_query?.search_term || "";
    let previousPage = recordsviewPayloadConfig?.params?.search_query?.page || 1;
    let previousPerPage = recordsviewPayloadConfig?.params?.search_query?.per_page || 10;
    if (value.length > 0) {
      setSelectedOptions(value);
      let tempFilterObj = {
        id: filter?.id,
        operator: "in",
        text_value: "",
        text_array: value,
        number_value: "",
        number_range: "",
        human_date: "",
        custom_date_range: ""
      };
      let updatedFilters = previousFilters?.filter((prevFilter) => prevFilter?.id !== filter?.id);
      updatedFilters.push(tempFilterObj);
      dispatch(
        createRecordsViewPayload({
          namespace: namespace,
          layoutConfig: recordsviewLayoutConfig,
          filter_by: updatedFilters,
          search_term: previousSearch,
          page: previousPage,
          per_page: previousPerPage,
          screen_name: screen_name,
          cacheProcessData: cacheProcessData
        })
      );
    } else {
      let updatedFilters = previousFilters?.filter((prevFilter) => prevFilter?.id !== filter.id);
      setSelectedOptions([]);
      setMultiSelectedOptions([]);
      dispatch(
        createRecordsViewPayload({
          namespace: namespace,
          layoutConfig: recordsviewLayoutConfig,
          filter_by: updatedFilters,
          search_term: previousSearch,
          page: previousPage,
          per_page: previousPerPage,
          screen_name: screen_name,
          cacheProcessData: cacheProcessData
        })
      );
    }
    setPopupShow(false);
  }

  function handleDropdownClick() {
    setPopupShow(!popupShow);
  }

  // Function to handle the checkbox change event
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setMultiSelectedOptions([...multiSelectedOptions, value]);
    } else {
      setMultiSelectedOptions(multiSelectedOptions.filter((item) => item !== value));
    }
  };

  const handleRemoveSpecficOption = (event, option) => {
    event.stopPropagation();
    let newOptions = selectedOptions.filter((item) => item !== option);
    setMultiSelectedOptions(newOptions);
    handleDropDownFilterChanges(newOptions);
  };

  // Close popup on side click
  const isClickOutside = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isClickOutside.current && !isClickOutside.current.contains(event.target)) {
        setPopupShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //for previous options
  useEffect(() => {
    let previousFilters = recordsviewPayloadConfig?.params?.search_query?.filter_by;
    if (previousFilters?.length !== 0) {
      previousFilters?.forEach((prevfilter) => {
        if (prevfilter?.id === filter?.id) {
          setSelectedOptions(prevfilter?.text_array);
          setMultiSelectedOptions(prevfilter?.text_array);
        }
      });
    } else {
      setSelectedOptions([]);
      setMultiSelectedOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  return (
    <div className="filter_item_container">
      <span className="filter_item_label">{filter?.custom_label || filter?.display}</span>
      <div className="filter_dropdown_container" onClick={handleDropdownClick}>
        <div className="filter_dropdown_content">
          {filter?.is_multi_select ? (
            <>
              {selectedOptions?.length === 0 ? (
                <span className="filter_default_option">All</span>
              ) : (
                <div className="filter_selected_option_container">
                  {selectedOptions?.map((selectedOption, index) => (
                    <div className="filter_selected_option">
                      <span className="filter_selected_value" key={index}>
                        {selectedOption}
                      </span>
                      <span
                        className="material-symbols-outlined selected_close_icon"
                        onClick={(e) => handleRemoveSpecficOption(e, selectedOption)}
                      >
                        close
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            <span className="filter_default_option">{selectedOptions?.length === 0 ? "All" : selectedOptions[0]}</span>
          )}
        </div>
        <div className="filter_dropdown_icon_container">
          <span className="material-symbols-outlined">{popupShow ? "keyboard_arrow_up" : "keyboard_arrow_down"}</span>
        </div>
      </div>
      {popupShow && (
        <>
          {filter?.is_multi_select ? (
            <div className="filter_dropdown_menu_container" ref={isClickOutside}>
              {/* <div className="filter_dropdown_menu_search_container">
                <input className="filter_dropdown_menu_search" />
                <span className="material-symbols-outlined filter_search_icon">search</span>
              </div> */}
              <div className="filter_dropdown_menu_list">
                {options.map((optionItem, index) => {
                  return (
                    <label className="filter_dropdown_menu_multi" key={index}>
                      <input
                        type="checkbox"
                        value={optionItem.value}
                        checked={multiSelectedOptions.includes(optionItem.value)}
                        onChange={handleCheckboxChange}
                      />
                      <span>{optionItem.value}</span>
                    </label>
                  );
                })}
              </div>
              <div className="filter_button_container">
                <button className="secondary_default_button" onClick={() => handleDropDownFilterChanges([])}>
                  Clear
                </button>
                <button
                  className="primary_default_button"
                  onClick={() => handleDropDownFilterChanges(multiSelectedOptions)}
                >
                  Apply
                </button>
              </div>
            </div>
          ) : (
            <div className="filter_dropdown_menu_container" ref={isClickOutside}>
              <div className="filter_dropdown_menu_list">
                <div
                  className="filter_dropdown_menu"
                  onClick={() => {
                    handleDropDownFilterChanges([]);
                  }}
                >
                  All
                </div>
                {options.map((optionItem, index) => {
                  return (
                    <div
                      key={index}
                      className="filter_dropdown_menu"
                      onClick={() => {
                        handleDropDownFilterChanges([optionItem.value]);
                      }}
                    >
                      {optionItem.value}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
