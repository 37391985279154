import React, { useState } from "react";
import { FormRenderer, PopupBoxSection, ProcessBarSection } from "../lib";
import { usePageHandlerContext } from "./PageHandlerContext";
import toast from "react-hot-toast";
import { DetailsViewRenderPopup } from "../lib/detailsviewlib/DetailsViewRender";
import { PopupBoxSectionSide } from "../lib/viewlib/PopupBoxSection/PopupBoxSection";
import { toastSuccessMessageStyle } from "../utils/apiCallFunction";
import Pageview from "../components/Pageview";
import GetwayRenderer from "./GetwayRenderer";
const ProcessTaskComponent = () => {
  const { processTask, removeProcessTask, processBar, createSubTask, processTaskListArray } = usePageHandlerContext();
  const [ownerUpdateData, setOwnerUpdateData] = useState({});

  if (processTaskListArray.length === 0 && processBar && !processTask) {
    return <ProcessBarSection />;
  }
  if (processTaskListArray.length > 0) {
    return (
      <>
        {processBar && !processTask ? <ProcessBarSection /> : ""}
        {processTaskListArray.map((processTaskItem, processTaskIndex) => {
          if (processTaskItem.type === "detailsview") {
            document.body.style.overflow = "hidden";
            return (
              <PopupBoxSection key={processTaskIndex}>
                <div>
                  <div className="popupSection_navbar">
                    <span className="popupSection_navbar_display">
                      {processTaskItem?.detailsview_config?.name || ""}
                    </span>
                    <div
                      className="popupSection_navbar_close_container"
                      onClick={() => removeProcessTask(processTaskItem?.front_end.action_config.task_id)}
                    >
                      <span className="material-symbols-outlined popupSection_navbar_closeBTN">close</span>
                    </div>
                  </div>
                  <div className="popupSection_body detailsViewPopup">
                    <DetailsViewRenderPopup
                      itemConfig={processTaskItem.detailsview_config}
                      rowData={processTaskItem.front_end.params_config}
                    />
                  </div>
                </div>
              </PopupBoxSection>
            );
          } else if (processTaskItem.type === "screen") {
            let layout = processTaskItem?.screen_config?.layout || [];
            return (
              <PopupBoxSectionSide
                closePopup={() => removeProcessTask(processTaskItem?.front_end.action_config.task_id)}
                key={processTaskIndex}
              >
                <div>
                  <div className="popupSection_navbar">
                    <span className="popupSection_navbar_display">{""}</span>
                    <span
                      className="material-symbols-outlined popupSection_navbar_closeBTN"
                      onClick={() => removeProcessTask(processTaskItem?.front_end.action_config.task_id)}
                    >
                      close
                    </span>
                  </div>
                  <div className="detailsViewPopup">
                    <Pageview
                      pageConfig={layout}
                      callFrom="outside"
                      jobParamsTokenConfig={processTaskItem?.front_end?.token_data || {}}
                    />
                  </div>
                </div>
              </PopupBoxSectionSide>
            );
          } else if (processTaskItem.type === "form") {
            if (
              [
                "perform_activity",
                "perform_activity_bg",
                "register_entity",
                "register_entity_bg",
                "start_state_machine",
                "start_state_machine_bg",
                "edit_entity_fe",
                "edit_entity_plus_fe",
                "submit_edit_entity_bg",
                "get_add_app_user_form",
                "get_edit_app_user_form",
                "edit_app_user_form_bg",
                "perform_activity_ai",
                "do_state_transition"
              ].includes(processTaskItem.front_end.action_config.action_name)
            ) {
              if (
                [
                  "start_state_machine_bg",
                  "register_entity_bg",
                  "perform_activity_bg",
                  "submit_edit_entity_bg",
                  "edit_app_user_form_bg"
                ].includes(processTaskItem.front_end.action_config.action_in)
              ) {
                return <></>;
              }
              const submitSubFormCallback = (respons, form_local_key) => {
                if (
                  ["get_add_app_user_form", "get_edit_app_user_form"].includes(
                    processTaskItem.front_end.action_config.action_name
                  )
                ) {
                  let instanceId = "";
                  let sections = processTaskItem?.form_config?.sections || [];
                  for (let index = 0; index < sections.length; index++) {
                    const fields = sections[index].fields;
                    for (let j = 0; j < fields.length; j++) {
                      const field = fields[j];
                      if (field?.type === "app-user") {
                        instanceId = respons.data[field.id]["id"];
                        break;
                      }
                    }
                  }

                  let action_config = processTaskItem.front_end.action_config;
                  let params_config = {
                    sm_id: action_config?.sm_id || "",
                    activity_id: processTaskItem.activity_id || "",
                    user_id: instanceId || action_config?.instance_id || "",
                    instance_id: action_config?.instance_id || "",
                    instance_ids: action_config?.instance_ids || [],
                    state_id: action_config?.state_id || "",
                    ...processTaskItem.front_end.params_config
                  };

                  if (processTaskItem.front_end.action_config.action_name === "get_add_app_user_form") {
                    action_config.action_name = "edit_app_user_form_bg";
                  } else if (processTaskItem.front_end.action_config.action_name === "get_edit_app_user_form") {
                    action_config.action_name = "edit_app_user_form_bg";
                  }

                  createSubTask(params_config, respons.data, action_config);
                  //
                } else {
                  //
                  let action_config = processTaskItem.front_end.action_config;
                  let params_config = {
                    sm_id: action_config?.sm_id || "",
                    activity_id: processTaskItem.activity_id || "",
                    instance_id: action_config?.instance_id || "",
                    instance_ids: action_config?.instance_ids || [],
                    state_id: action_config?.state_id || "",
                    ...processTaskItem.front_end.params_config
                  };
                  if (processTaskItem.front_end.action_config.action_name === "register_entity") {
                    action_config.action_name = "register_entity_bg";
                  } else if (processTaskItem.front_end.action_config.action_name === "start_state_machine") {
                    action_config.action_name = "start_state_machine_bg";
                  } else if (processTaskItem.front_end.action_config.action_name === "perform_activity") {
                    action_config.action_name = "perform_activity_bg";
                  } else if (processTaskItem.front_end.action_config.action_name === "perform_activity_ai") {
                    action_config.action_name = "perform_activity_bg";
                  } else if (
                    processTaskItem.front_end.action_config.action_name === "edit_entity_fe" ||
                    processTaskItem.front_end.action_config.action_name === "edit_entity_plus_fe"
                  ) {
                    action_config.action_name = "submit_edit_entity_bg";
                  } else {
                    action_config.action_name = "do_activity";
                  }

                  createSubTask(params_config, respons.data, action_config, form_local_key);
                }
              };
              document.body.style.overflow = "hidden";
              return (
                <PopupBoxSection key={processTaskIndex}>
                  {processBar ? (
                    <ProcessBarSection />
                  ) : (
                    <>
                      <div className="popupSection_navbar">
                        <span className="popupSection_navbar_display">{processTaskItem?.form_config?.name || ""}</span>
                        <div
                          className="popupSection_navbar_close_container"
                          onClick={() => removeProcessTask(processTaskItem?.front_end.action_config.task_id)}
                        >
                          <span className="material-symbols-outlined popupSection_navbar_closeBTN">close</span>
                        </div>
                      </div>
                      <div className="popupSection_body">
                        {processTaskItem?.type === "form" ? (
                          <FormRenderer
                            apidata={{
                              apicalldata: processTaskItem.formconfig
                            }}
                            callbackfunction={{
                              formsubmitgetresponscbfun: submitSubFormCallback
                            }}
                            namespace={processTaskItem.front_end.action_config.subtask_id}
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </>
                  )}
                </PopupBoxSection>
              );
            } else if (
              ["get_owner", "set_owner", "set_owner_bg"].includes(processTaskItem.front_end.action_config.action_name)
            ) {
              if (["set_owner_bg"].includes(processTaskItem.front_end.action_config.action_in)) {
                return <></>;
              }
              const submitSubFormCallback = () => {
                let user = {};
                for (let index = 0; index < processTaskItem.users.length; index++) {
                  const element = processTaskItem.users[index];
                  if (element.id === ownerUpdateData.users) {
                    user = element;
                    break;
                  }
                }
                let action_config = processTaskItem.front_end.action_config;
                let params_config = {
                  sm_id: action_config?.sm_id || "",
                  activity_id: ownerUpdateData.activities || "",
                  instance_id: action_config?.instance_id || "",
                  instance_ids: action_config?.instance_ids || [],
                  state_id: action_config?.state_id || "",
                  owner_uid: ownerUpdateData.users || "",
                  activity_owner: user
                };
                action_config.action_name = "set_owner_bg";

                createSubTask(params_config, {}, action_config);
              };

              const changeValue = (event) => {
                let { name, value } = event.target;
                setOwnerUpdateData({ ...ownerUpdateData, [name]: value });
              };
              return (
                <PopupBoxSection>
                  {processBar ? (
                    <ProcessBarSection />
                  ) : (
                    <div>
                      <div className="popupSection_navbar">
                        <span className="popupSection_navbar_display">{processTaskItem?.form_config?.name || ""}</span>
                        <div
                          className="popupSection_navbar_close_container"
                          onClick={() => removeProcessTask(processTaskItem?.front_end.action_config.task_id)}
                        >
                          <span className="material-symbols-outlined popupSection_navbar_closeBTN">close</span>
                        </div>
                      </div>
                      <div style={{ padding: "0 5px" }}>
                        <div style={{ width: "85%", margin: "auto" }}>
                          <div style={{ display: "flex", flexDirection: "column", padding: "5px 0" }}>
                            <label htmlFor="activities">Activity</label>
                            <select
                              name="activities"
                              id="activities"
                              className="zino_inputBox"
                              onChange={(e) => changeValue(e)}
                            >
                              <option value="">Select Activity</option>
                              {processTaskItem.activities.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label htmlFor="users">Users</label>
                            <select name="users" id="users" className="zino_inputBox" onChange={(e) => changeValue(e)}>
                              <option value="">Select User</option>
                              {processTaskItem.users.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "15px" }}>
                            <button className={"zino_btn_primary"} onClick={submitSubFormCallback}>
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </PopupBoxSection>
              );
            }
          } else if (processTaskItem.type === "payment_gateway") {
            if (processTaskItem["gateway"] === "RAZORPAY") {
              return <GetwayRenderer getwayConfig={processTaskItem} removeProcessTask={removeProcessTask} />;
            } else if (processTaskItem["gateway"] === "STRIPE") {
              if (processTaskItem["url"]) {
                window.open(processTaskItem["url"], "_blank");
              }
            }
          }
          if (["debug_instance"].includes(processTaskItem.front_end.action_config.action_name)) {
            // etl_data instance_data ts_data
            function copyDataFromTextarea(id) {
              // Get the text field
              var copyText = document.getElementById(id);
              // Select the text field
              copyText.select();
              copyText.setSelectionRange(0, 99999); // For mobile devices

              // Copy the text inside the text field
              navigator.clipboard.writeText(copyText.value);

              toast.success("Copied to clipboard", toastSuccessMessageStyle());
            }
            return (
              <PopupBoxSection key={processTaskIndex}>
                {processBar ? (
                  <ProcessBarSection />
                ) : (
                  <div>
                    <div className="popupSection_navbar">
                      <span className="popupSection_navbar_display">
                        Instance Id : {processTaskItem?.data?.instance_id || ""}
                      </span>
                      <div
                        className="popupSection_navbar_close_container"
                        onClick={() => removeProcessTask(processTaskItem?.front_end.action_config.task_id)}
                      >
                        <span className="material-symbols-outlined popupSection_navbar_closeBTN">close</span>
                      </div>
                    </div>
                    <div style={{ padding: "0 5px" }}>
                      <div style={{ display: "flex", flexDirection: "column", padding: "15px" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "15px",
                            padding: "10px 0"
                          }}
                        >
                          <label>Instance Data</label>
                          <span
                            style={{ padding: "5px 10px 0 10px", cursor: "pointer" }}
                            onClick={() => copyDataFromTextarea("instancedata")}
                          >
                            <span className="material-symbols-outlined" style={{ fontSize: "16px" }}>
                              content_copy
                            </span>
                          </span>
                        </div>
                        <textarea
                          id="instancedata"
                          style={{ minHeight: "150px" }}
                          value={JSON.stringify(processTaskItem.data?.instance_data || {}, null, 2)}
                          rows={4}
                          cols={60}
                          disabled
                        />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "15px",
                            padding: "10px 0"
                          }}
                        >
                          <label>ETL Data</label>
                          <span
                            style={{ padding: "5px 10px 0 10px", cursor: "pointer" }}
                            onClick={() => copyDataFromTextarea("etldata")}
                          >
                            <span className="material-symbols-outlined" style={{ fontSize: "16px" }}>
                              content_copy
                            </span>
                          </span>
                        </div>
                        <textarea
                          id="etldata"
                          style={{ minHeight: "150px" }}
                          value={JSON.stringify(processTaskItem.data?.etl_data || {}, null, 2)}
                          rows={4}
                          cols={60}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                )}
              </PopupBoxSection>
            );
          } else {
            return <></>;
          }
        })}
      </>
    );
  } else {
    return <></>;
  }

  // if (processBar && !processTask) {
  //   return <ProcessBarSection />;
  // } else if (processTask) {
  //   if (processTask.type === "detailsview") {
  //     document.body.style.overflow = "hidden";
  //     return (
  //       <PopupBoxSection>
  //         <div>
  //           <div className="popupSection_navbar">
  //             <span className="popupSection_navbar_display">{processTask?.detailsview_config?.name || ""}</span>
  //             <div
  //               className="popupSection_navbar_close_container"
  //               onClick={() => removeProcessTask(processTask?.front_end.action_config.task_id)}
  //             >
  //               <span className="material-symbols-outlined popupSection_navbar_closeBTN">close</span>
  //             </div>
  //           </div>
  //           <div className="popupSection_body detailsViewPopup">
  //             <DetailsViewRenderPopup
  //               itemConfig={processTask.detailsview_config}
  //               rowData={processTask.front_end.params_config}
  //             />
  //           </div>
  //         </div>
  //       </PopupBoxSection>
  //     );
  //   } else if (processTask.type === "screen") {
  //     let layout = processTask?.screen_config?.layout || [];
  //     return (
  //       <PopupBoxSectionSide closePopup={() => removeProcessTask(processTask?.front_end.action_config.task_id)}>
  //         <div>
  //           <div className="popupSection_navbar">
  //             <span className="popupSection_navbar_display">{""}</span>
  //             <span
  //               className="material-symbols-outlined popupSection_navbar_closeBTN"
  //               onClick={() => removeProcessTask(processTask?.front_end.action_config.task_id)}
  //             >
  //               close
  //             </span>
  //           </div>
  //           <div className="detailsViewPopup">
  //             <Pageview
  //               pageConfig={layout}
  //               callFrom="outside"
  //               jobParamsTokenConfig={processTask?.front_end?.token_data || {}}
  //             />
  //           </div>
  //         </div>
  //       </PopupBoxSectionSide>
  //     );
  //   }

  //   if (
  //     [
  //       "get_activity",
  //       "register_entity",
  //       "do_state_transition",
  //       "start_state_machine",
  //       "perform_activity",
  //       "edit_entity_fe",
  //       "edit_entity_plus_fe",
  //       "get_add_app_user_form",
  //       "get_edit_app_user_form",
  //       "perform_activity_ai"
  //     ].includes(processTask?.front_end?.action_config?.action_name)
  //   ) {
  //     const submitSubFormCallback = (respons, form_local_key) => {
  //       if (
  //         ["get_add_app_user_form", "get_edit_app_user_form"].includes(processTask.front_end.action_config.action_name)
  //       ) {
  //         let instanceId = "";
  //         let sections = processTask?.form_config?.sections || [];
  //         for (let index = 0; index < sections.length; index++) {
  //           const fields = sections[index].fields;
  //           for (let j = 0; j < fields.length; j++) {
  //             const field = fields[j];
  //             if (field?.type === "app-user") {
  //               instanceId = respons.data[field.id]["id"];
  //               break;
  //             }
  //           }
  //         }

  //         let action_config = processTask.front_end.action_config;
  //         let params_config = {
  //           sm_id: action_config?.sm_id || "",
  //           activity_id: processTask.activity_id || "",
  //           user_id: instanceId || action_config?.instance_id || "",
  //           instance_id: action_config?.instance_id || "",
  //           instance_ids: action_config?.instance_ids || [],
  //           state_id: action_config?.state_id || "",
  //           ...processTask.front_end.params_config
  //         };

  //         if (processTask.front_end.action_config.action_name === "get_add_app_user_form") {
  //           action_config.action_name = "edit_app_user_form_bg";
  //         } else if (processTask.front_end.action_config.action_name === "get_edit_app_user_form") {
  //           action_config.action_name = "edit_app_user_form_bg";
  //         }

  //         createSubTask(params_config, respons.data, action_config);
  //         //
  //       } else {
  //         //
  //         let action_config = processTask.front_end.action_config;
  //         let params_config = {
  //           sm_id: action_config?.sm_id || "",
  //           activity_id: processTask.activity_id || "",
  //           instance_id: action_config?.instance_id || "",
  //           instance_ids: action_config?.instance_ids || [],
  //           state_id: action_config?.state_id || "",
  //           ...processTask.front_end.params_config
  //         };
  //         if (processTask.front_end.action_config.action_name === "register_entity") {
  //           action_config.action_name = "register_entity_bg";
  //         } else if (processTask.front_end.action_config.action_name === "start_state_machine") {
  //           action_config.action_name = "start_state_machine_bg";
  //         } else if (processTask.front_end.action_config.action_name === "perform_activity") {
  //           action_config.action_name = "perform_activity_bg";
  //         } else if (processTask.front_end.action_config.action_name === "perform_activity_ai") {
  //           action_config.action_name = "perform_activity_bg";
  //         } else if (
  //           processTask.front_end.action_config.action_name === "edit_entity_fe" ||
  //           processTask.front_end.action_config.action_name === "edit_entity_plus_fe"
  //         ) {
  //           action_config.action_name = "submit_edit_entity_bg";
  //         } else {
  //           action_config.action_name = "do_activity";
  //         }

  //         createSubTask(params_config, respons.data, action_config, form_local_key);
  //       }
  //     };
  //     document.body.style.overflow = "hidden";
  //     return (
  //       <PopupBoxSection>
  //         {processBar ? (
  //           <ProcessBarSection />
  //         ) : (
  //           <>
  //             <div className="popupSection_navbar">
  //               <span className="popupSection_navbar_display">{processTask?.form_config?.name || ""}</span>
  //               <div
  //                 className="popupSection_navbar_close_container"
  //                 onClick={() => removeProcessTask(processTask?.front_end.action_config.task_id)}
  //               >
  //                 <span className="material-symbols-outlined popupSection_navbar_closeBTN">close</span>
  //               </div>
  //             </div>
  //             <div className="popupSection_body">
  //               {processTask?.type === "form" ? (
  //                 <FormRenderer
  //                   apidata={{
  //                     apicalldata: processTask.formconfig
  //                   }}
  //                   callbackfunction={{
  //                     formsubmitgetresponscbfun: submitSubFormCallback
  //                   }}
  //                   namespace={processTask.front_end.action_config.subtask_id}
  //                 />
  //               ) : (
  //                 <div></div>
  //               )}
  //             </div>
  //           </>
  //         )}
  //       </PopupBoxSection>
  //     );
  //   } else if (["get_owner", "set_owner"].includes(processTask?.front_end?.action_config?.action_name)) {
  //     const submitSubFormCallback = () => {
  //       let user = {};
  //       for (let index = 0; index < processTask.users.length; index++) {
  //         const element = processTask.users[index];
  //         if (element.id === ownerUpdateData.users) {
  //           user = element;
  //           break;
  //         }
  //       }
  //       let action_config = processTask.front_end.action_config;
  //       let params_config = {
  //         sm_id: action_config?.sm_id || "",
  //         activity_id: ownerUpdateData.activities || "",
  //         instance_id: action_config?.instance_id || "",
  //         instance_ids: action_config?.instance_ids || [],
  //         state_id: action_config?.state_id || "",
  //         owner_uid: ownerUpdateData.users || "",
  //         activity_owner: user
  //       };
  //       action_config.action_name = "set_owner_bg";

  //       createSubTask(params_config, {}, action_config);
  //     };

  //     const changeValue = (event) => {
  //       let { name, value } = event.target;
  //       setOwnerUpdateData({ ...ownerUpdateData, [name]: value });
  //     };

  //     return (
  //       <PopupBoxSection>
  //         {processBar ? (
  //           <ProcessBarSection />
  //         ) : (
  //           <div>
  //             <div className="popupSection_navbar">
  //               <span className="popupSection_navbar_display">{processTask?.form_config?.name || ""}</span>
  //               <div
  //                 className="popupSection_navbar_close_container"
  //                 onClick={() => removeProcessTask(processTask?.front_end.action_config.task_id)}
  //               >
  //                 <span className="material-symbols-outlined popupSection_navbar_closeBTN">close</span>
  //               </div>
  //             </div>
  //             <div style={{ padding: "0 5px" }}>
  //               <div style={{ width: "85%", margin: "auto" }}>
  //                 <div style={{ display: "flex", flexDirection: "column", padding: "5px 0" }}>
  //                   <label htmlFor="activities">Activity</label>
  //                   <select
  //                     name="activities"
  //                     id="activities"
  //                     className="zino_inputBox"
  //                     onChange={(e) => changeValue(e)}
  //                   >
  //                     <option value="">Select Activity</option>
  //                     {processTask.activities.map((option) => (
  //                       <option key={option.id} value={option.id}>
  //                         {option.name}
  //                       </option>
  //                     ))}
  //                   </select>
  //                 </div>
  //                 <div>
  //                   <label htmlFor="users">Users</label>
  //                   <select name="users" id="users" className="zino_inputBox" onChange={(e) => changeValue(e)}>
  //                     <option value="">Select User</option>
  //                     {processTask.users.map((option) => (
  //                       <option key={option.id} value={option.id}>
  //                         {option.name}
  //                       </option>
  //                     ))}
  //                   </select>
  //                 </div>
  //                 <div style={{ display: "flex", justifyContent: "flex-end", paddingTop: "15px" }}>
  //                   <button className={"zino_btn_primary"} onClick={submitSubFormCallback}>
  //                     Submit
  //                   </button>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         )}
  //       </PopupBoxSection>
  //     );
  //   } else if (["debug_instance"].includes(processTask?.front_end?.action_config?.action_name)) {
  //     // etl_data instance_data ts_data
  //     function copyDataFromTextarea(id) {
  //       // Get the text field
  //       var copyText = document.getElementById(id);
  //       // Select the text field
  //       copyText.select();
  //       copyText.setSelectionRange(0, 99999); // For mobile devices

  //       // Copy the text inside the text field
  //       navigator.clipboard.writeText(copyText.value);

  //       toast.success("Copied to clipboard", toastSuccessMessageStyle());
  //     }
  //     return (
  //       <PopupBoxSection closePopup={() => removeProcessTask(processTask?.front_end.action_config.task_id)}>
  //         {processBar ? (
  //           <ProcessBarSection />
  //         ) : (
  //           <div>
  //             <div className="popupSection_navbar">
  //               <span className="popupSection_navbar_display">
  //                 Instance Id : {processTask?.data?.instance_id || ""}
  //               </span>
  //               <div
  //                 className="popupSection_navbar_close_container"
  //                 onClick={() => removeProcessTask(processTask?.front_end.action_config.task_id)}
  //               >
  //                 <span className="material-symbols-outlined popupSection_navbar_closeBTN">close</span>
  //               </div>
  //             </div>
  //             <div style={{ padding: "0 5px" }}>
  //               <div style={{ display: "flex", flexDirection: "column", padding: "15px" }}>
  //                 <div
  //                   style={{
  //                     display: "flex",
  //                     alignItems: "center",
  //                     justifyContent: "flex-start",
  //                     gap: "15px",
  //                     padding: "10px 0"
  //                   }}
  //                 >
  //                   <label>Instance Data</label>
  //                   <span
  //                     style={{ padding: "5px 10px 0 10px", cursor: "pointer" }}
  //                     onClick={() => copyDataFromTextarea("instancedata")}
  //                   >
  //                     <span className="material-symbols-outlined" style={{ fontSize: "16px" }}>
  //                       content_copy
  //                     </span>
  //                   </span>
  //                 </div>
  //                 <textarea
  //                   id="instancedata"
  //                   style={{ minHeight: "150px" }}
  //                   value={JSON.stringify(processTask.data?.instance_data || {}, null, 2)}
  //                   rows={4}
  //                   cols={60}
  //                   disabled
  //                 />
  //                 <div
  //                   style={{
  //                     display: "flex",
  //                     alignItems: "center",
  //                     justifyContent: "flex-start",
  //                     gap: "15px",
  //                     padding: "10px 0"
  //                   }}
  //                 >
  //                   <label>ETL Data</label>
  //                   <span
  //                     style={{ padding: "5px 10px 0 10px", cursor: "pointer" }}
  //                     onClick={() => copyDataFromTextarea("etldata")}
  //                   >
  //                     <span className="material-symbols-outlined" style={{ fontSize: "16px" }}>
  //                       content_copy
  //                     </span>
  //                   </span>
  //                 </div>
  //                 <textarea
  //                   id="etldata"
  //                   style={{ minHeight: "150px" }}
  //                   value={JSON.stringify(processTask.data?.etl_data || {}, null, 2)}
  //                   rows={4}
  //                   cols={60}
  //                   disabled
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         )}
  //       </PopupBoxSection>
  //     );
  //   }
  // }
};

export default ProcessTaskComponent;
