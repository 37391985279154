/* eslint-disable eqeqeq */
import { setFormOptionsData } from "../../../redux/formview/formviewaction";
import { store } from "../../../redux/store";
export class renderFunction {
  // Hide function
  static hideFormItem(component, formsArray) {
    let hideItem = [...component.child_id];
    for (let formsIndex = 0; formsIndex < formsArray.length; formsIndex++) {
      if (hideItem.length <= 0) return;
      const componentsArray = formsArray[formsIndex].components;
      for (let index = 0; index < componentsArray.length; index++) {
        const formArray = componentsArray[index].elements;
        renderFunction.setFormShowHideKey(hideItem, formArray, false);
      }
    }
  }

  // Show function
  static showFormItem(component, formsArray) {
    let showItem = [...component.child_id];
    for (let formsIndex = 0; formsIndex < formsArray.length; formsIndex++) {
      if (showItem.length <= 0) return;
      const componentsArray = formsArray[formsIndex].components;
      for (let index = 0; index < componentsArray.length; index++) {
        const formArray = componentsArray[index].elements;
        renderFunction.setFormShowHideKey(showItem, formArray, true);
      }
    }
  }

  // Show and Hide Key set function
  static setFormShowHideKey(formItemsArray, formArray, value) {
    for (let formIndex = 0; formIndex < formArray.length; formIndex++) {
      if (formItemsArray.length <= 0) return;
      if (["empty"].includes(formArray[formIndex].element_type)) continue;
      const item = formArray[formIndex].field;
      if (formItemsArray.indexOf(item.id) !== -1) {
        item.show = value;
        formItemsArray.splice(formItemsArray.indexOf(item.id), 1);
      }
      if (
        item.type === "container" ||
        item.type === "recordset" ||
        item.type === "recordset-list" ||
        item.type === "grid" ||
        item.type === "rich-grid"
      )
        renderFunction.setFormShowHideKey(formItemsArray, item.components, value);
    }
  }

  // Set Option function
  static setItemOptions(namespace, value, component, formsArray) {
    let dataConfig = renderFunction.getDataConfigFromMasterData(namespace, component.data_key);
    if (!dataConfig) return undefined;
    let componentReference;
    let optionsObject = store.getState()?.formviewstore?.[`${namespace}_formOptionsData`];
    for (let formsIndex = 0; formsIndex < formsArray.length; formsIndex++) {
      const componentsArray = formsArray[formsIndex].components;
      for (let index = 0; index < componentsArray.length; index++) {
        const formArray = componentsArray[index].elements;
        componentReference = renderFunction.getComponentRefFromForm(formArray, component.child_id);
        if (componentReference) break;
      }
      if (componentReference) break;
    }

    if (!componentReference) return;
    componentReference.show = true;
    if (["select", "selectboxes", "radio"].includes(componentReference.type)) {
      if (component["method"] === "lookup") {
        let searchIndex, valueIndex, lableIndex;
        for (let index = 0; index < dataConfig[0].length; index++) {
          const element = dataConfig[0][index];
          if (element === component.search_key_col) searchIndex = index;
          if (element === component.label_from_col) lableIndex = index;
          if (element === component.value_from_col) valueIndex = index;
        }
        let dataArray = [];
        for (let index = 1; index < dataConfig.length; index++) {
          const element = dataConfig[index];

          if (element[searchIndex] == value) {
            // eslint-disable-line eqeqeq
            dataArray.push({
              label: element[lableIndex],
              value: element[valueIndex]
            });
          }
        }

        componentReference.data.values = dataArray;
        store.dispatch(setFormOptionsData(namespace, { ...optionsObject, [componentReference.id]: dataArray }));
      } else {
        let lableIndex, valueIndex;
        for (let index = 0; index < dataConfig[0].length; index++) {
          const element = dataConfig[0][index];
          if (element === component.label_from_col) lableIndex = index;
          if (element === component.value_from_col) valueIndex = index;
        }
        let dataArray = [];
        for (let index = 1; index < dataConfig.length; index++) {
          const element = dataConfig[index];

          dataArray.push({
            label: element[lableIndex],
            value: element[valueIndex]
          });
        }
        componentReference.data.values = dataArray;
        store.dispatch(setFormOptionsData(namespace, { ...optionsObject, [componentReference.id]: dataArray }));
      }
    }
  }

  // Set Option in item
  static getComponentRefFromForm(formArray, componentKey) {
    for (let formIndex = 0; formIndex < formArray.length; formIndex++) {
      if (["empty"].includes(formArray[formIndex].element_type)) continue;
      const item = formArray[formIndex].field;
      if (item.id === componentKey) {
        return item;
      }
      if (
        item.type === "container" ||
        item.type === "recordset" ||
        item.type === "recordset-list" ||
        item.type === "grid" ||
        item.type === "rich-grid"
      ) {
        let data = renderFunction.getComponentRefFromForm(item.components, componentKey);
        if (data) return data;
      }
    }
    return;
  }

  // Set Value function
  static setItemValue(namespace, value, component, formsArray, formDataObj, keyArrayCopy) {
    let dataConfig = renderFunction.getDataConfigFromMasterData(namespace, component.data_key);
    if (!dataConfig) return undefined;

    for (let formsIndex = 0; formsIndex < formsArray.length; formsIndex++) {
      const componentsArray = formsArray[formsIndex].components;
      let formObject = {};
      if (!formDataObj[formsArray[formsIndex]["key"]])
        formDataObj[formsArray[formsIndex]["key"]] = {
          errorCount: 0,
          errors: []
        };
      else formObject = formDataObj[formsArray[formsIndex]["key"]];
      for (let index = 0; index < componentsArray.length; index++) {
        const formArray = componentsArray[index].elements;
        renderFunction.setComponentRefFromFormData(value, component, formArray, formObject, dataConfig, keyArrayCopy);
      }
    }
  }

  // Set Value in item
  static setComponentRefFromFormData(value, component, formArray, formObject, dataConfig, keyArrayCopy) {
    for (let formIndex = 0; formIndex < formArray.length; formIndex++) {
      if (["empty"].includes(formArray[formIndex].element_type)) continue;
      const item = formArray[formIndex].field;
      if (item.id === component.child_id) {
        // item.show = true;
        let searchIndex, valueIndex;
        for (let index = 0; index < dataConfig[0].length; index++) {
          const element = dataConfig[0][index];
          if (element === component.search_key_col) searchIndex = index;
          if (element === component.value_key_col) valueIndex = index;
        }
        let returnValue = "";
        for (let index = 1; index < dataConfig.length; index++) {
          const element = dataConfig[index][searchIndex];
          if (element == value) {
            // eslint-disable-line eqeqeq
            returnValue = dataConfig[index][valueIndex];
            break;
          }
        }

        if (typeof returnValue === "string") {
          returnValue = stringToJSONChange(returnValue) || returnValue;
        }

        // if (keyArrayCopy.length > 2) {
        //   let lastDataKey = keyArrayCopy[keyArrayCopy.length - 2];

        //   if (lastDataKey.substring(0, 3) === "set") {
        //     if (!formObject[lastDataKey]) {
        //       formObject[lastDataKey] = {
        //         errors: []
        //       };
        //     } else {
        //       formObject = formObject[lastDataKey];
        //     }
        //   }
        // }

        formObject[item.id] = {
          value: returnValue,
          errors: []
        };
        return;
      }

      if (
        item.type === "container" ||
        item.type === "recordset" ||
        item.type === "recordset-list" ||
        item.type === "grid" ||
        item.type === "rich-grid"
      ) {
        if (!formObject[item.id]) {
          formObject[item.id] = {
            errors: []
          };
        }
        renderFunction.setComponentRefFromFormData(
          value,
          component,
          item.components,
          formObject[item.id],
          dataConfig,
          keyArrayCopy
        );
      }
    }
  }

  static getDataConfigFromMasterData(namespace, data_key) {
    if (!data_key) return undefined;
    let dataConfig = store.getState()?.["formviewstore"]?.[`${namespace}_formMasterData`] || {};
    if (typeof dataConfig?.[data_key]?.[0] === "object" && Array.isArray(dataConfig[data_key][0])) {
      return dataConfig[data_key];
    }
  }

  // Copy Value function
  static copyItemValueAndUse(item, formsArray, formDataObj) {
    // change data object to array
    let keyArrayS = [];
    let keyArrayT = [];
    for (let index = 0; index < item.child_id.length; index++) {
      const element = item.child_id[index];
      keyArrayS.push(element.source_key);
      keyArrayT.push(element.target_key);
    }

    for (let formsIndex = 0; formsIndex < formsArray.length; formsIndex++) {
      const formArray = formsArray[formsIndex].components;
      renderFunction.getKeyPathFromFormData(keyArrayS, formArray, formsArray[formsIndex].id);
    }

    for (let formsIndex = 0; formsIndex < formsArray.length; formsIndex++) {
      const formArray = formsArray[formsIndex].components;
      renderFunction.getKeyPathFromFormData(keyArrayT, formArray, formsArray[formsIndex].id, keyArrayS);
    }

    for (let sKeyIndex = 0; sKeyIndex < keyArrayS.length; sKeyIndex++) {
      const element = keyArrayS[sKeyIndex];
      let keyArray = element.split(":");
      keyArray.pop();
      const lastKey = keyArray[keyArray.length - 1];
      let dataObject = formDataObj;
      for (let keyIndex = 0; keyIndex < keyArray.length - 1; keyIndex++) {
        const key = keyArray[keyIndex];
        dataObject = dataObject[key];
      }

      if (dataObject[lastKey]) {
        dataObject = dataObject[lastKey];
        keyArrayS[sKeyIndex] = dataObject.value;
      } else {
        keyArrayS[sKeyIndex] = undefined;
      }
    }

    for (let tKeyIndex = 0; tKeyIndex < keyArrayT.length; tKeyIndex++) {
      const value = keyArrayS[tKeyIndex];
      const element = keyArrayT[tKeyIndex];
      let keyArray = element.split(":");
      const lastKey = keyArray[keyArray.length - 1];
      let dataObject = formDataObj;
      for (let keyIndex = 0; keyIndex < keyArray.length - 1; keyIndex++) {
        const key = keyArray[keyIndex];
        if (!dataObject[key]) dataObject[key] = {};
        dataObject = dataObject[key];
      }

      if (value) {
        dataObject[lastKey] = { value: value, errors: [] };
      }
    }
  }

  // Copy Value function
  static getKeyPathFromFormData(keyArray, componentsArray, path, sArray = []) {
    for (let index = 0; index < componentsArray.length; index++) {
      const formArray = componentsArray[index].elements;
      for (let formIndex = 0; formIndex < formArray.length; formIndex++) {
        if (["empty"].includes(formArray[formIndex].element_type)) continue;
        const item = formArray[formIndex].field;
        let index = keyArray.indexOf(item.id);
        if (index !== -1) {
          let myPath = path + ":" + item.id;
          if (sArray.length > 0) {
            // const str = sArray[index].split(":");
            // if (str[str.length - 1] === "t") item.show = true;
            // else item.show = false;
          } else {
            if (item.show) myPath += ":t";
            else myPath += ":f";
          }
          keyArray[index] = myPath;
        }
      }
    }
  }
}

const stringToJSONChange = (value) => {
  try {
    if (!value || !value.trim()) {
      return;
    }
    value = value.replaceAll("'", '"');
    return JSON.parse(value);
  } catch (error) {
    return;
  }
};
